const packInfo = {
    lib: "src/lib/Icon/CustomIconsPack",
    prefix: "fa",
    iconsNameMap: {
        "faCurrentBranch": () => import('../CustomIconsPack/faCurrentBranch'),
        "faJustSelect": () => import('../CustomIconsPack/faJustSelect'),
        "farCog": () => import('../CustomIconsPack/farCog'),
        "fasTimes": () => import('../CustomIconsPack/fasTimes'),
        "falQuestionCustom": () => import('../CustomIconsPack/falQuestionCustom'),
        "faEnterSymbol": () => import('../CustomIconsPack/faEnterSymbol'),
    }
};
export default packInfo;
