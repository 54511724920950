var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import classNames from "classnames";
import { Input } from "reactstrap";
import { IconButton } from "../Button";
import { Icon } from "../Icon";
import ErrorText from "../ErrorText";
const inputSizeToIcon = {
    lg: "lg",
    sm: "sm",
    md: "md",
    undefined: "md",
    default: "md",
};
export function InputTextBase(_a) {
    var { type = "text", name, value, warning, validPositionLeft, clearable, clearButtonProps, loadable, className, compact, bsSize, onChange = () => { }, onClear = () => { }, blurInactive, withEvent, errorText } = _a, restProps = __rest(_a, ["type", "name", "value", "warning", "validPositionLeft", "clearable", "clearButtonProps", "loadable", "className", "compact", "bsSize", "onChange", "onClear", "blurInactive", "withEvent", "errorText"]);
    if (blurInactive) {
        restProps.onBlur = (e) => e.target.classList.add("blur_inactive");
        restProps.onFocus = (e) => e.target.classList.add("blur_inactive");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, Object.assign({ type: type, name: name, value: value, 
            //prevent value change on scroll
            onWheel: (e) => {
                e.preventDefault();
                return;
            }, bsSize: compact ? "sm" : bsSize, onChange: (event) => {
                return withEvent
                    ? onChange(event.target.value, name, event)
                    : onChange(event.target.value, name);
            }, className: classNames(className, "justui_input-text", {
                "is-warning": warning,
                positionLeft: validPositionLeft,
                clearable: clearable && !restProps.disabled && !restProps.readOnly,
                loadable: loadable && !restProps.disabled && !restProps.readOnly,
                blur_inactive: blurInactive,
            }) }, restProps)),
        clearable && !restProps.disabled && !restProps.readOnly ? (React.createElement(IconButton, Object.assign({ onClick: () => {
                onClear();
                onChange("", name);
            }, flat: true, className: "clear", size: bsSize, withoutPadding: true, color: "secondary", name: "faTimesCircle" }, clearButtonProps))) : null,
        loadable && !restProps.disabled && !restProps.readOnly ? (React.createElement(Icon, { className: "loadable fa-spin", size: inputSizeToIcon[bsSize], color: "secondary", name: "faSpinnerThird" })) : null,
        errorText && React.createElement(ErrorText, { text: errorText })));
}
