var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { CustomInput, } from "reactstrap";
import classNames from "classnames";
export function Checkbox(_a) {
    var { value, name, id, type, label, onChange = () => { }, inline = false, style, invalid, disabled, bsSize, className, noMargin = true, dark } = _a, restProps = __rest(_a, ["value", "name", "id", "type", "label", "onChange", "inline", "style", "invalid", "disabled", "bsSize", "className", "noMargin", "dark"]);
    return (React.createElement(CustomInput, Object.assign({ type: "checkbox", onChange: (event) => onChange(event.target.checked, name), style: Object.assign({ marginLeft: 0 }, style), checked: value, label: label, disabled: disabled, invalid: invalid, inline: inline, name: name, id: id || name, bsSize: bsSize, className: classNames(className, { noMargin: noMargin }, { dark: dark }) }, restProps)));
}
