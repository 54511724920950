const packInfo = {
    lib: "node_modules/@fortawesome/pro-solid-svg-icons",
    prefix: "fa",
    iconsNameMap: {
        "faAbacus": () => import('@fortawesome/pro-solid-svg-icons/faAbacus'),
        "faAcorn": () => import('@fortawesome/pro-solid-svg-icons/faAcorn'),
        "faAd": () => import('@fortawesome/pro-solid-svg-icons/faAd'),
        "faAddressBook": () => import('@fortawesome/pro-solid-svg-icons/faAddressBook'),
        "faAddressCard": () => import('@fortawesome/pro-solid-svg-icons/faAddressCard'),
        "faAdjust": () => import('@fortawesome/pro-solid-svg-icons/faAdjust'),
        "faAirConditioner": () => import('@fortawesome/pro-solid-svg-icons/faAirConditioner'),
        "faAirFreshener": () => import('@fortawesome/pro-solid-svg-icons/faAirFreshener'),
        "faAlarmClock": () => import('@fortawesome/pro-solid-svg-icons/faAlarmClock'),
        "faAlarmExclamation": () => import('@fortawesome/pro-solid-svg-icons/faAlarmExclamation'),
        "faAlarmPlus": () => import('@fortawesome/pro-solid-svg-icons/faAlarmPlus'),
        "faAlarmSnooze": () => import('@fortawesome/pro-solid-svg-icons/faAlarmSnooze'),
        "faAlbum": () => import('@fortawesome/pro-solid-svg-icons/faAlbum'),
        "faAlbumCollection": () => import('@fortawesome/pro-solid-svg-icons/faAlbumCollection'),
        "faAlicorn": () => import('@fortawesome/pro-solid-svg-icons/faAlicorn'),
        "faAlien": () => import('@fortawesome/pro-solid-svg-icons/faAlien'),
        "faAlienMonster": () => import('@fortawesome/pro-solid-svg-icons/faAlienMonster'),
        "faAlignCenter": () => import('@fortawesome/pro-solid-svg-icons/faAlignCenter'),
        "faAlignJustify": () => import('@fortawesome/pro-solid-svg-icons/faAlignJustify'),
        "faAlignLeft": () => import('@fortawesome/pro-solid-svg-icons/faAlignLeft'),
        "faAlignRight": () => import('@fortawesome/pro-solid-svg-icons/faAlignRight'),
        "faAlignSlash": () => import('@fortawesome/pro-solid-svg-icons/faAlignSlash'),
        "faAllergies": () => import('@fortawesome/pro-solid-svg-icons/faAllergies'),
        "faAmbulance": () => import('@fortawesome/pro-solid-svg-icons/faAmbulance'),
        "faAmericanSignLanguageInterpreting": () => import('@fortawesome/pro-solid-svg-icons/faAmericanSignLanguageInterpreting'),
        "faAmpGuitar": () => import('@fortawesome/pro-solid-svg-icons/faAmpGuitar'),
        "faAnalytics": () => import('@fortawesome/pro-solid-svg-icons/faAnalytics'),
        "faAnchor": () => import('@fortawesome/pro-solid-svg-icons/faAnchor'),
        "faAngel": () => import('@fortawesome/pro-solid-svg-icons/faAngel'),
        "faAngleDoubleDown": () => import('@fortawesome/pro-solid-svg-icons/faAngleDoubleDown'),
        "faAngleDoubleLeft": () => import('@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft'),
        "faAngleDoubleRight": () => import('@fortawesome/pro-solid-svg-icons/faAngleDoubleRight'),
        "faAngleDoubleUp": () => import('@fortawesome/pro-solid-svg-icons/faAngleDoubleUp'),
        "faAngleDown": () => import('@fortawesome/pro-solid-svg-icons/faAngleDown'),
        "faAngleLeft": () => import('@fortawesome/pro-solid-svg-icons/faAngleLeft'),
        "faAngleRight": () => import('@fortawesome/pro-solid-svg-icons/faAngleRight'),
        "faAngleUp": () => import('@fortawesome/pro-solid-svg-icons/faAngleUp'),
        "faAngry": () => import('@fortawesome/pro-solid-svg-icons/faAngry'),
        "faAnkh": () => import('@fortawesome/pro-solid-svg-icons/faAnkh'),
        "faAppleAlt": () => import('@fortawesome/pro-solid-svg-icons/faAppleAlt'),
        "faAppleCrate": () => import('@fortawesome/pro-solid-svg-icons/faAppleCrate'),
        "faArchive": () => import('@fortawesome/pro-solid-svg-icons/faArchive'),
        "faArchway": () => import('@fortawesome/pro-solid-svg-icons/faArchway'),
        "faArrowAltCircleDown": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltCircleDown'),
        "faArrowAltCircleLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltCircleLeft'),
        "faArrowAltCircleRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltCircleRight'),
        "faArrowAltCircleUp": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltCircleUp'),
        "faArrowAltDown": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltDown'),
        "faArrowAltFromBottom": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltFromBottom'),
        "faArrowAltFromLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltFromLeft'),
        "faArrowAltFromRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltFromRight'),
        "faArrowAltFromTop": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltFromTop'),
        "faArrowAltLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltLeft'),
        "faArrowAltRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltRight'),
        "faArrowAltSquareDown": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltSquareDown'),
        "faArrowAltSquareLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltSquareLeft'),
        "faArrowAltSquareRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltSquareRight'),
        "faArrowAltSquareUp": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltSquareUp'),
        "faArrowAltToBottom": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltToBottom'),
        "faArrowAltToLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltToLeft'),
        "faArrowAltToRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltToRight'),
        "faArrowAltToTop": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltToTop'),
        "faArrowAltUp": () => import('@fortawesome/pro-solid-svg-icons/faArrowAltUp'),
        "faArrowCircleDown": () => import('@fortawesome/pro-solid-svg-icons/faArrowCircleDown'),
        "faArrowCircleLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowCircleLeft'),
        "faArrowCircleRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowCircleRight'),
        "faArrowCircleUp": () => import('@fortawesome/pro-solid-svg-icons/faArrowCircleUp'),
        "faArrowDown": () => import('@fortawesome/pro-solid-svg-icons/faArrowDown'),
        "faArrowFromBottom": () => import('@fortawesome/pro-solid-svg-icons/faArrowFromBottom'),
        "faArrowFromLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowFromLeft'),
        "faArrowFromRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowFromRight'),
        "faArrowFromTop": () => import('@fortawesome/pro-solid-svg-icons/faArrowFromTop'),
        "faArrowLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowLeft'),
        "faArrowRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowRight'),
        "faArrowSquareDown": () => import('@fortawesome/pro-solid-svg-icons/faArrowSquareDown'),
        "faArrowSquareLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowSquareLeft'),
        "faArrowSquareRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowSquareRight'),
        "faArrowSquareUp": () => import('@fortawesome/pro-solid-svg-icons/faArrowSquareUp'),
        "faArrowToBottom": () => import('@fortawesome/pro-solid-svg-icons/faArrowToBottom'),
        "faArrowToLeft": () => import('@fortawesome/pro-solid-svg-icons/faArrowToLeft'),
        "faArrowToRight": () => import('@fortawesome/pro-solid-svg-icons/faArrowToRight'),
        "faArrowToTop": () => import('@fortawesome/pro-solid-svg-icons/faArrowToTop'),
        "faArrowUp": () => import('@fortawesome/pro-solid-svg-icons/faArrowUp'),
        "faArrows": () => import('@fortawesome/pro-solid-svg-icons/faArrows'),
        "faArrowsAlt": () => import('@fortawesome/pro-solid-svg-icons/faArrowsAlt'),
        "faArrowsAltH": () => import('@fortawesome/pro-solid-svg-icons/faArrowsAltH'),
        "faArrowsAltV": () => import('@fortawesome/pro-solid-svg-icons/faArrowsAltV'),
        "faArrowsH": () => import('@fortawesome/pro-solid-svg-icons/faArrowsH'),
        "faArrowsV": () => import('@fortawesome/pro-solid-svg-icons/faArrowsV'),
        "faAssistiveListeningSystems": () => import('@fortawesome/pro-solid-svg-icons/faAssistiveListeningSystems'),
        "faAsterisk": () => import('@fortawesome/pro-solid-svg-icons/faAsterisk'),
        "faAt": () => import('@fortawesome/pro-solid-svg-icons/faAt'),
        "faAtlas": () => import('@fortawesome/pro-solid-svg-icons/faAtlas'),
        "faAtom": () => import('@fortawesome/pro-solid-svg-icons/faAtom'),
        "faAtomAlt": () => import('@fortawesome/pro-solid-svg-icons/faAtomAlt'),
        "faAudioDescription": () => import('@fortawesome/pro-solid-svg-icons/faAudioDescription'),
        "faAward": () => import('@fortawesome/pro-solid-svg-icons/faAward'),
        "faAxe": () => import('@fortawesome/pro-solid-svg-icons/faAxe'),
        "faAxeBattle": () => import('@fortawesome/pro-solid-svg-icons/faAxeBattle'),
        "faBaby": () => import('@fortawesome/pro-solid-svg-icons/faBaby'),
        "faBabyCarriage": () => import('@fortawesome/pro-solid-svg-icons/faBabyCarriage'),
        "faBackpack": () => import('@fortawesome/pro-solid-svg-icons/faBackpack'),
        "faBackspace": () => import('@fortawesome/pro-solid-svg-icons/faBackspace'),
        "faBackward": () => import('@fortawesome/pro-solid-svg-icons/faBackward'),
        "faBacon": () => import('@fortawesome/pro-solid-svg-icons/faBacon'),
        "faBacteria": () => import('@fortawesome/pro-solid-svg-icons/faBacteria'),
        "faBacterium": () => import('@fortawesome/pro-solid-svg-icons/faBacterium'),
        "faBadge": () => import('@fortawesome/pro-solid-svg-icons/faBadge'),
        "faBadgeCheck": () => import('@fortawesome/pro-solid-svg-icons/faBadgeCheck'),
        "faBadgeDollar": () => import('@fortawesome/pro-solid-svg-icons/faBadgeDollar'),
        "faBadgePercent": () => import('@fortawesome/pro-solid-svg-icons/faBadgePercent'),
        "faBadgeSheriff": () => import('@fortawesome/pro-solid-svg-icons/faBadgeSheriff'),
        "faBadgerHoney": () => import('@fortawesome/pro-solid-svg-icons/faBadgerHoney'),
        "faBagsShopping": () => import('@fortawesome/pro-solid-svg-icons/faBagsShopping'),
        "faBahai": () => import('@fortawesome/pro-solid-svg-icons/faBahai'),
        "faBalanceScale": () => import('@fortawesome/pro-solid-svg-icons/faBalanceScale'),
        "faBalanceScaleLeft": () => import('@fortawesome/pro-solid-svg-icons/faBalanceScaleLeft'),
        "faBalanceScaleRight": () => import('@fortawesome/pro-solid-svg-icons/faBalanceScaleRight'),
        "faBallPile": () => import('@fortawesome/pro-solid-svg-icons/faBallPile'),
        "faBallot": () => import('@fortawesome/pro-solid-svg-icons/faBallot'),
        "faBallotCheck": () => import('@fortawesome/pro-solid-svg-icons/faBallotCheck'),
        "faBan": () => import('@fortawesome/pro-solid-svg-icons/faBan'),
        "faBandAid": () => import('@fortawesome/pro-solid-svg-icons/faBandAid'),
        "faBanjo": () => import('@fortawesome/pro-solid-svg-icons/faBanjo'),
        "faBarcode": () => import('@fortawesome/pro-solid-svg-icons/faBarcode'),
        "faBarcodeAlt": () => import('@fortawesome/pro-solid-svg-icons/faBarcodeAlt'),
        "faBarcodeRead": () => import('@fortawesome/pro-solid-svg-icons/faBarcodeRead'),
        "faBarcodeScan": () => import('@fortawesome/pro-solid-svg-icons/faBarcodeScan'),
        "faBars": () => import('@fortawesome/pro-solid-svg-icons/faBars'),
        "faBaseball": () => import('@fortawesome/pro-solid-svg-icons/faBaseball'),
        "faBaseballBall": () => import('@fortawesome/pro-solid-svg-icons/faBaseballBall'),
        "faBasketballBall": () => import('@fortawesome/pro-solid-svg-icons/faBasketballBall'),
        "faBasketballHoop": () => import('@fortawesome/pro-solid-svg-icons/faBasketballHoop'),
        "faBat": () => import('@fortawesome/pro-solid-svg-icons/faBat'),
        "faBath": () => import('@fortawesome/pro-solid-svg-icons/faBath'),
        "faBatteryBolt": () => import('@fortawesome/pro-solid-svg-icons/faBatteryBolt'),
        "faBatteryEmpty": () => import('@fortawesome/pro-solid-svg-icons/faBatteryEmpty'),
        "faBatteryFull": () => import('@fortawesome/pro-solid-svg-icons/faBatteryFull'),
        "faBatteryHalf": () => import('@fortawesome/pro-solid-svg-icons/faBatteryHalf'),
        "faBatteryQuarter": () => import('@fortawesome/pro-solid-svg-icons/faBatteryQuarter'),
        "faBatterySlash": () => import('@fortawesome/pro-solid-svg-icons/faBatterySlash'),
        "faBatteryThreeQuarters": () => import('@fortawesome/pro-solid-svg-icons/faBatteryThreeQuarters'),
        "faBed": () => import('@fortawesome/pro-solid-svg-icons/faBed'),
        "faBedAlt": () => import('@fortawesome/pro-solid-svg-icons/faBedAlt'),
        "faBedBunk": () => import('@fortawesome/pro-solid-svg-icons/faBedBunk'),
        "faBedEmpty": () => import('@fortawesome/pro-solid-svg-icons/faBedEmpty'),
        "faBeer": () => import('@fortawesome/pro-solid-svg-icons/faBeer'),
        "faBell": () => import('@fortawesome/pro-solid-svg-icons/faBell'),
        "faBellExclamation": () => import('@fortawesome/pro-solid-svg-icons/faBellExclamation'),
        "faBellOn": () => import('@fortawesome/pro-solid-svg-icons/faBellOn'),
        "faBellPlus": () => import('@fortawesome/pro-solid-svg-icons/faBellPlus'),
        "faBellSchool": () => import('@fortawesome/pro-solid-svg-icons/faBellSchool'),
        "faBellSchoolSlash": () => import('@fortawesome/pro-solid-svg-icons/faBellSchoolSlash'),
        "faBellSlash": () => import('@fortawesome/pro-solid-svg-icons/faBellSlash'),
        "faBells": () => import('@fortawesome/pro-solid-svg-icons/faBells'),
        "faBetamax": () => import('@fortawesome/pro-solid-svg-icons/faBetamax'),
        "faBezierCurve": () => import('@fortawesome/pro-solid-svg-icons/faBezierCurve'),
        "faBible": () => import('@fortawesome/pro-solid-svg-icons/faBible'),
        "faBicycle": () => import('@fortawesome/pro-solid-svg-icons/faBicycle'),
        "faBiking": () => import('@fortawesome/pro-solid-svg-icons/faBiking'),
        "faBikingMountain": () => import('@fortawesome/pro-solid-svg-icons/faBikingMountain'),
        "faBinoculars": () => import('@fortawesome/pro-solid-svg-icons/faBinoculars'),
        "faBiohazard": () => import('@fortawesome/pro-solid-svg-icons/faBiohazard'),
        "faBirthdayCake": () => import('@fortawesome/pro-solid-svg-icons/faBirthdayCake'),
        "faBlanket": () => import('@fortawesome/pro-solid-svg-icons/faBlanket'),
        "faBlender": () => import('@fortawesome/pro-solid-svg-icons/faBlender'),
        "faBlenderPhone": () => import('@fortawesome/pro-solid-svg-icons/faBlenderPhone'),
        "faBlind": () => import('@fortawesome/pro-solid-svg-icons/faBlind'),
        "faBlinds": () => import('@fortawesome/pro-solid-svg-icons/faBlinds'),
        "faBlindsOpen": () => import('@fortawesome/pro-solid-svg-icons/faBlindsOpen'),
        "faBlindsRaised": () => import('@fortawesome/pro-solid-svg-icons/faBlindsRaised'),
        "faBlog": () => import('@fortawesome/pro-solid-svg-icons/faBlog'),
        "faBold": () => import('@fortawesome/pro-solid-svg-icons/faBold'),
        "faBolt": () => import('@fortawesome/pro-solid-svg-icons/faBolt'),
        "faBomb": () => import('@fortawesome/pro-solid-svg-icons/faBomb'),
        "faBone": () => import('@fortawesome/pro-solid-svg-icons/faBone'),
        "faBoneBreak": () => import('@fortawesome/pro-solid-svg-icons/faBoneBreak'),
        "faBong": () => import('@fortawesome/pro-solid-svg-icons/faBong'),
        "faBook": () => import('@fortawesome/pro-solid-svg-icons/faBook'),
        "faBookAlt": () => import('@fortawesome/pro-solid-svg-icons/faBookAlt'),
        "faBookDead": () => import('@fortawesome/pro-solid-svg-icons/faBookDead'),
        "faBookHeart": () => import('@fortawesome/pro-solid-svg-icons/faBookHeart'),
        "faBookMedical": () => import('@fortawesome/pro-solid-svg-icons/faBookMedical'),
        "faBookOpen": () => import('@fortawesome/pro-solid-svg-icons/faBookOpen'),
        "faBookReader": () => import('@fortawesome/pro-solid-svg-icons/faBookReader'),
        "faBookSpells": () => import('@fortawesome/pro-solid-svg-icons/faBookSpells'),
        "faBookUser": () => import('@fortawesome/pro-solid-svg-icons/faBookUser'),
        "faBookmark": () => import('@fortawesome/pro-solid-svg-icons/faBookmark'),
        "faBooks": () => import('@fortawesome/pro-solid-svg-icons/faBooks'),
        "faBooksMedical": () => import('@fortawesome/pro-solid-svg-icons/faBooksMedical'),
        "faBoombox": () => import('@fortawesome/pro-solid-svg-icons/faBoombox'),
        "faBoot": () => import('@fortawesome/pro-solid-svg-icons/faBoot'),
        "faBoothCurtain": () => import('@fortawesome/pro-solid-svg-icons/faBoothCurtain'),
        "faBorderAll": () => import('@fortawesome/pro-solid-svg-icons/faBorderAll'),
        "faBorderBottom": () => import('@fortawesome/pro-solid-svg-icons/faBorderBottom'),
        "faBorderCenterH": () => import('@fortawesome/pro-solid-svg-icons/faBorderCenterH'),
        "faBorderCenterV": () => import('@fortawesome/pro-solid-svg-icons/faBorderCenterV'),
        "faBorderInner": () => import('@fortawesome/pro-solid-svg-icons/faBorderInner'),
        "faBorderLeft": () => import('@fortawesome/pro-solid-svg-icons/faBorderLeft'),
        "faBorderNone": () => import('@fortawesome/pro-solid-svg-icons/faBorderNone'),
        "faBorderOuter": () => import('@fortawesome/pro-solid-svg-icons/faBorderOuter'),
        "faBorderRight": () => import('@fortawesome/pro-solid-svg-icons/faBorderRight'),
        "faBorderStyle": () => import('@fortawesome/pro-solid-svg-icons/faBorderStyle'),
        "faBorderStyleAlt": () => import('@fortawesome/pro-solid-svg-icons/faBorderStyleAlt'),
        "faBorderTop": () => import('@fortawesome/pro-solid-svg-icons/faBorderTop'),
        "faBowArrow": () => import('@fortawesome/pro-solid-svg-icons/faBowArrow'),
        "faBowlingBall": () => import('@fortawesome/pro-solid-svg-icons/faBowlingBall'),
        "faBowlingPins": () => import('@fortawesome/pro-solid-svg-icons/faBowlingPins'),
        "faBox": () => import('@fortawesome/pro-solid-svg-icons/faBox'),
        "faBoxAlt": () => import('@fortawesome/pro-solid-svg-icons/faBoxAlt'),
        "faBoxBallot": () => import('@fortawesome/pro-solid-svg-icons/faBoxBallot'),
        "faBoxCheck": () => import('@fortawesome/pro-solid-svg-icons/faBoxCheck'),
        "faBoxFragile": () => import('@fortawesome/pro-solid-svg-icons/faBoxFragile'),
        "faBoxFull": () => import('@fortawesome/pro-solid-svg-icons/faBoxFull'),
        "faBoxHeart": () => import('@fortawesome/pro-solid-svg-icons/faBoxHeart'),
        "faBoxOpen": () => import('@fortawesome/pro-solid-svg-icons/faBoxOpen'),
        "faBoxTissue": () => import('@fortawesome/pro-solid-svg-icons/faBoxTissue'),
        "faBoxUp": () => import('@fortawesome/pro-solid-svg-icons/faBoxUp'),
        "faBoxUsd": () => import('@fortawesome/pro-solid-svg-icons/faBoxUsd'),
        "faBoxes": () => import('@fortawesome/pro-solid-svg-icons/faBoxes'),
        "faBoxesAlt": () => import('@fortawesome/pro-solid-svg-icons/faBoxesAlt'),
        "faBoxingGlove": () => import('@fortawesome/pro-solid-svg-icons/faBoxingGlove'),
        "faBrackets": () => import('@fortawesome/pro-solid-svg-icons/faBrackets'),
        "faBracketsCurly": () => import('@fortawesome/pro-solid-svg-icons/faBracketsCurly'),
        "faBraille": () => import('@fortawesome/pro-solid-svg-icons/faBraille'),
        "faBrain": () => import('@fortawesome/pro-solid-svg-icons/faBrain'),
        "faBreadLoaf": () => import('@fortawesome/pro-solid-svg-icons/faBreadLoaf'),
        "faBreadSlice": () => import('@fortawesome/pro-solid-svg-icons/faBreadSlice'),
        "faBriefcase": () => import('@fortawesome/pro-solid-svg-icons/faBriefcase'),
        "faBriefcaseMedical": () => import('@fortawesome/pro-solid-svg-icons/faBriefcaseMedical'),
        "faBringForward": () => import('@fortawesome/pro-solid-svg-icons/faBringForward'),
        "faBringFront": () => import('@fortawesome/pro-solid-svg-icons/faBringFront'),
        "faBroadcastTower": () => import('@fortawesome/pro-solid-svg-icons/faBroadcastTower'),
        "faBroom": () => import('@fortawesome/pro-solid-svg-icons/faBroom'),
        "faBrowser": () => import('@fortawesome/pro-solid-svg-icons/faBrowser'),
        "faBrush": () => import('@fortawesome/pro-solid-svg-icons/faBrush'),
        "faBug": () => import('@fortawesome/pro-solid-svg-icons/faBug'),
        "faBuilding": () => import('@fortawesome/pro-solid-svg-icons/faBuilding'),
        "faBullhorn": () => import('@fortawesome/pro-solid-svg-icons/faBullhorn'),
        "faBullseye": () => import('@fortawesome/pro-solid-svg-icons/faBullseye'),
        "faBullseyeArrow": () => import('@fortawesome/pro-solid-svg-icons/faBullseyeArrow'),
        "faBullseyePointer": () => import('@fortawesome/pro-solid-svg-icons/faBullseyePointer'),
        "faBurgerSoda": () => import('@fortawesome/pro-solid-svg-icons/faBurgerSoda'),
        "faBurn": () => import('@fortawesome/pro-solid-svg-icons/faBurn'),
        "faBurrito": () => import('@fortawesome/pro-solid-svg-icons/faBurrito'),
        "faBus": () => import('@fortawesome/pro-solid-svg-icons/faBus'),
        "faBusAlt": () => import('@fortawesome/pro-solid-svg-icons/faBusAlt'),
        "faBusSchool": () => import('@fortawesome/pro-solid-svg-icons/faBusSchool'),
        "faBusinessTime": () => import('@fortawesome/pro-solid-svg-icons/faBusinessTime'),
        "faCabinetFiling": () => import('@fortawesome/pro-solid-svg-icons/faCabinetFiling'),
        "faCactus": () => import('@fortawesome/pro-solid-svg-icons/faCactus'),
        "faCalculator": () => import('@fortawesome/pro-solid-svg-icons/faCalculator'),
        "faCalculatorAlt": () => import('@fortawesome/pro-solid-svg-icons/faCalculatorAlt'),
        "faCalendar": () => import('@fortawesome/pro-solid-svg-icons/faCalendar'),
        "faCalendarAlt": () => import('@fortawesome/pro-solid-svg-icons/faCalendarAlt'),
        "faCalendarCheck": () => import('@fortawesome/pro-solid-svg-icons/faCalendarCheck'),
        "faCalendarDay": () => import('@fortawesome/pro-solid-svg-icons/faCalendarDay'),
        "faCalendarEdit": () => import('@fortawesome/pro-solid-svg-icons/faCalendarEdit'),
        "faCalendarExclamation": () => import('@fortawesome/pro-solid-svg-icons/faCalendarExclamation'),
        "faCalendarMinus": () => import('@fortawesome/pro-solid-svg-icons/faCalendarMinus'),
        "faCalendarPlus": () => import('@fortawesome/pro-solid-svg-icons/faCalendarPlus'),
        "faCalendarStar": () => import('@fortawesome/pro-solid-svg-icons/faCalendarStar'),
        "faCalendarTimes": () => import('@fortawesome/pro-solid-svg-icons/faCalendarTimes'),
        "faCalendarWeek": () => import('@fortawesome/pro-solid-svg-icons/faCalendarWeek'),
        "faCamcorder": () => import('@fortawesome/pro-solid-svg-icons/faCamcorder'),
        "faCamera": () => import('@fortawesome/pro-solid-svg-icons/faCamera'),
        "faCameraAlt": () => import('@fortawesome/pro-solid-svg-icons/faCameraAlt'),
        "faCameraHome": () => import('@fortawesome/pro-solid-svg-icons/faCameraHome'),
        "faCameraMovie": () => import('@fortawesome/pro-solid-svg-icons/faCameraMovie'),
        "faCameraPolaroid": () => import('@fortawesome/pro-solid-svg-icons/faCameraPolaroid'),
        "faCameraRetro": () => import('@fortawesome/pro-solid-svg-icons/faCameraRetro'),
        "faCampfire": () => import('@fortawesome/pro-solid-svg-icons/faCampfire'),
        "faCampground": () => import('@fortawesome/pro-solid-svg-icons/faCampground'),
        "faCandleHolder": () => import('@fortawesome/pro-solid-svg-icons/faCandleHolder'),
        "faCandyCane": () => import('@fortawesome/pro-solid-svg-icons/faCandyCane'),
        "faCandyCorn": () => import('@fortawesome/pro-solid-svg-icons/faCandyCorn'),
        "faCannabis": () => import('@fortawesome/pro-solid-svg-icons/faCannabis'),
        "faCapsules": () => import('@fortawesome/pro-solid-svg-icons/faCapsules'),
        "faCar": () => import('@fortawesome/pro-solid-svg-icons/faCar'),
        "faCarAlt": () => import('@fortawesome/pro-solid-svg-icons/faCarAlt'),
        "faCarBattery": () => import('@fortawesome/pro-solid-svg-icons/faCarBattery'),
        "faCarBuilding": () => import('@fortawesome/pro-solid-svg-icons/faCarBuilding'),
        "faCarBump": () => import('@fortawesome/pro-solid-svg-icons/faCarBump'),
        "faCarBus": () => import('@fortawesome/pro-solid-svg-icons/faCarBus'),
        "faCarCrash": () => import('@fortawesome/pro-solid-svg-icons/faCarCrash'),
        "faCarGarage": () => import('@fortawesome/pro-solid-svg-icons/faCarGarage'),
        "faCarMechanic": () => import('@fortawesome/pro-solid-svg-icons/faCarMechanic'),
        "faCarSide": () => import('@fortawesome/pro-solid-svg-icons/faCarSide'),
        "faCarTilt": () => import('@fortawesome/pro-solid-svg-icons/faCarTilt'),
        "faCarWash": () => import('@fortawesome/pro-solid-svg-icons/faCarWash'),
        "faCaravan": () => import('@fortawesome/pro-solid-svg-icons/faCaravan'),
        "faCaravanAlt": () => import('@fortawesome/pro-solid-svg-icons/faCaravanAlt'),
        "faCaretCircleDown": () => import('@fortawesome/pro-solid-svg-icons/faCaretCircleDown'),
        "faCaretCircleLeft": () => import('@fortawesome/pro-solid-svg-icons/faCaretCircleLeft'),
        "faCaretCircleRight": () => import('@fortawesome/pro-solid-svg-icons/faCaretCircleRight'),
        "faCaretCircleUp": () => import('@fortawesome/pro-solid-svg-icons/faCaretCircleUp'),
        "faCaretDown": () => import('@fortawesome/pro-solid-svg-icons/faCaretDown'),
        "faCaretLeft": () => import('@fortawesome/pro-solid-svg-icons/faCaretLeft'),
        "faCaretRight": () => import('@fortawesome/pro-solid-svg-icons/faCaretRight'),
        "faCaretSquareDown": () => import('@fortawesome/pro-solid-svg-icons/faCaretSquareDown'),
        "faCaretSquareLeft": () => import('@fortawesome/pro-solid-svg-icons/faCaretSquareLeft'),
        "faCaretSquareRight": () => import('@fortawesome/pro-solid-svg-icons/faCaretSquareRight'),
        "faCaretSquareUp": () => import('@fortawesome/pro-solid-svg-icons/faCaretSquareUp'),
        "faCaretUp": () => import('@fortawesome/pro-solid-svg-icons/faCaretUp'),
        "faCarrot": () => import('@fortawesome/pro-solid-svg-icons/faCarrot'),
        "faCars": () => import('@fortawesome/pro-solid-svg-icons/faCars'),
        "faCartArrowDown": () => import('@fortawesome/pro-solid-svg-icons/faCartArrowDown'),
        "faCartPlus": () => import('@fortawesome/pro-solid-svg-icons/faCartPlus'),
        "faCashRegister": () => import('@fortawesome/pro-solid-svg-icons/faCashRegister'),
        "faCassetteTape": () => import('@fortawesome/pro-solid-svg-icons/faCassetteTape'),
        "faCat": () => import('@fortawesome/pro-solid-svg-icons/faCat'),
        "faCatSpace": () => import('@fortawesome/pro-solid-svg-icons/faCatSpace'),
        "faCauldron": () => import('@fortawesome/pro-solid-svg-icons/faCauldron'),
        "faCctv": () => import('@fortawesome/pro-solid-svg-icons/faCctv'),
        "faCertificate": () => import('@fortawesome/pro-solid-svg-icons/faCertificate'),
        "faChair": () => import('@fortawesome/pro-solid-svg-icons/faChair'),
        "faChairOffice": () => import('@fortawesome/pro-solid-svg-icons/faChairOffice'),
        "faChalkboard": () => import('@fortawesome/pro-solid-svg-icons/faChalkboard'),
        "faChalkboardTeacher": () => import('@fortawesome/pro-solid-svg-icons/faChalkboardTeacher'),
        "faChargingStation": () => import('@fortawesome/pro-solid-svg-icons/faChargingStation'),
        "faChartArea": () => import('@fortawesome/pro-solid-svg-icons/faChartArea'),
        "faChartBar": () => import('@fortawesome/pro-solid-svg-icons/faChartBar'),
        "faChartLine": () => import('@fortawesome/pro-solid-svg-icons/faChartLine'),
        "faChartLineDown": () => import('@fortawesome/pro-solid-svg-icons/faChartLineDown'),
        "faChartNetwork": () => import('@fortawesome/pro-solid-svg-icons/faChartNetwork'),
        "faChartPie": () => import('@fortawesome/pro-solid-svg-icons/faChartPie'),
        "faChartPieAlt": () => import('@fortawesome/pro-solid-svg-icons/faChartPieAlt'),
        "faChartScatter": () => import('@fortawesome/pro-solid-svg-icons/faChartScatter'),
        "faCheck": () => import('@fortawesome/pro-solid-svg-icons/faCheck'),
        "faCheckCircle": () => import('@fortawesome/pro-solid-svg-icons/faCheckCircle'),
        "faCheckDouble": () => import('@fortawesome/pro-solid-svg-icons/faCheckDouble'),
        "faCheckSquare": () => import('@fortawesome/pro-solid-svg-icons/faCheckSquare'),
        "faCheese": () => import('@fortawesome/pro-solid-svg-icons/faCheese'),
        "faCheeseSwiss": () => import('@fortawesome/pro-solid-svg-icons/faCheeseSwiss'),
        "faCheeseburger": () => import('@fortawesome/pro-solid-svg-icons/faCheeseburger'),
        "faChess": () => import('@fortawesome/pro-solid-svg-icons/faChess'),
        "faChessBishop": () => import('@fortawesome/pro-solid-svg-icons/faChessBishop'),
        "faChessBishopAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessBishopAlt'),
        "faChessBoard": () => import('@fortawesome/pro-solid-svg-icons/faChessBoard'),
        "faChessClock": () => import('@fortawesome/pro-solid-svg-icons/faChessClock'),
        "faChessClockAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessClockAlt'),
        "faChessKing": () => import('@fortawesome/pro-solid-svg-icons/faChessKing'),
        "faChessKingAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessKingAlt'),
        "faChessKnight": () => import('@fortawesome/pro-solid-svg-icons/faChessKnight'),
        "faChessKnightAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessKnightAlt'),
        "faChessPawn": () => import('@fortawesome/pro-solid-svg-icons/faChessPawn'),
        "faChessPawnAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessPawnAlt'),
        "faChessQueen": () => import('@fortawesome/pro-solid-svg-icons/faChessQueen'),
        "faChessQueenAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessQueenAlt'),
        "faChessRook": () => import('@fortawesome/pro-solid-svg-icons/faChessRook'),
        "faChessRookAlt": () => import('@fortawesome/pro-solid-svg-icons/faChessRookAlt'),
        "faChevronCircleDown": () => import('@fortawesome/pro-solid-svg-icons/faChevronCircleDown'),
        "faChevronCircleLeft": () => import('@fortawesome/pro-solid-svg-icons/faChevronCircleLeft'),
        "faChevronCircleRight": () => import('@fortawesome/pro-solid-svg-icons/faChevronCircleRight'),
        "faChevronCircleUp": () => import('@fortawesome/pro-solid-svg-icons/faChevronCircleUp'),
        "faChevronDoubleDown": () => import('@fortawesome/pro-solid-svg-icons/faChevronDoubleDown'),
        "faChevronDoubleLeft": () => import('@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft'),
        "faChevronDoubleRight": () => import('@fortawesome/pro-solid-svg-icons/faChevronDoubleRight'),
        "faChevronDoubleUp": () => import('@fortawesome/pro-solid-svg-icons/faChevronDoubleUp'),
        "faChevronDown": () => import('@fortawesome/pro-solid-svg-icons/faChevronDown'),
        "faChevronLeft": () => import('@fortawesome/pro-solid-svg-icons/faChevronLeft'),
        "faChevronRight": () => import('@fortawesome/pro-solid-svg-icons/faChevronRight'),
        "faChevronSquareDown": () => import('@fortawesome/pro-solid-svg-icons/faChevronSquareDown'),
        "faChevronSquareLeft": () => import('@fortawesome/pro-solid-svg-icons/faChevronSquareLeft'),
        "faChevronSquareRight": () => import('@fortawesome/pro-solid-svg-icons/faChevronSquareRight'),
        "faChevronSquareUp": () => import('@fortawesome/pro-solid-svg-icons/faChevronSquareUp'),
        "faChevronUp": () => import('@fortawesome/pro-solid-svg-icons/faChevronUp'),
        "faChild": () => import('@fortawesome/pro-solid-svg-icons/faChild'),
        "faChimney": () => import('@fortawesome/pro-solid-svg-icons/faChimney'),
        "faChurch": () => import('@fortawesome/pro-solid-svg-icons/faChurch'),
        "faCircle": () => import('@fortawesome/pro-solid-svg-icons/faCircle'),
        "faCircleNotch": () => import('@fortawesome/pro-solid-svg-icons/faCircleNotch'),
        "faCity": () => import('@fortawesome/pro-solid-svg-icons/faCity'),
        "faClarinet": () => import('@fortawesome/pro-solid-svg-icons/faClarinet'),
        "faClawMarks": () => import('@fortawesome/pro-solid-svg-icons/faClawMarks'),
        "faClinicMedical": () => import('@fortawesome/pro-solid-svg-icons/faClinicMedical'),
        "faClipboard": () => import('@fortawesome/pro-solid-svg-icons/faClipboard'),
        "faClipboardCheck": () => import('@fortawesome/pro-solid-svg-icons/faClipboardCheck'),
        "faClipboardList": () => import('@fortawesome/pro-solid-svg-icons/faClipboardList'),
        "faClipboardListCheck": () => import('@fortawesome/pro-solid-svg-icons/faClipboardListCheck'),
        "faClipboardPrescription": () => import('@fortawesome/pro-solid-svg-icons/faClipboardPrescription'),
        "faClipboardUser": () => import('@fortawesome/pro-solid-svg-icons/faClipboardUser'),
        "faClock": () => import('@fortawesome/pro-solid-svg-icons/faClock'),
        "faClone": () => import('@fortawesome/pro-solid-svg-icons/faClone'),
        "faClosedCaptioning": () => import('@fortawesome/pro-solid-svg-icons/faClosedCaptioning'),
        "faCloud": () => import('@fortawesome/pro-solid-svg-icons/faCloud'),
        "faCloudDownload": () => import('@fortawesome/pro-solid-svg-icons/faCloudDownload'),
        "faCloudDownloadAlt": () => import('@fortawesome/pro-solid-svg-icons/faCloudDownloadAlt'),
        "faCloudDrizzle": () => import('@fortawesome/pro-solid-svg-icons/faCloudDrizzle'),
        "faCloudHail": () => import('@fortawesome/pro-solid-svg-icons/faCloudHail'),
        "faCloudHailMixed": () => import('@fortawesome/pro-solid-svg-icons/faCloudHailMixed'),
        "faCloudMeatball": () => import('@fortawesome/pro-solid-svg-icons/faCloudMeatball'),
        "faCloudMoon": () => import('@fortawesome/pro-solid-svg-icons/faCloudMoon'),
        "faCloudMoonRain": () => import('@fortawesome/pro-solid-svg-icons/faCloudMoonRain'),
        "faCloudMusic": () => import('@fortawesome/pro-solid-svg-icons/faCloudMusic'),
        "faCloudRain": () => import('@fortawesome/pro-solid-svg-icons/faCloudRain'),
        "faCloudRainbow": () => import('@fortawesome/pro-solid-svg-icons/faCloudRainbow'),
        "faCloudShowers": () => import('@fortawesome/pro-solid-svg-icons/faCloudShowers'),
        "faCloudShowersHeavy": () => import('@fortawesome/pro-solid-svg-icons/faCloudShowersHeavy'),
        "faCloudSleet": () => import('@fortawesome/pro-solid-svg-icons/faCloudSleet'),
        "faCloudSnow": () => import('@fortawesome/pro-solid-svg-icons/faCloudSnow'),
        "faCloudSun": () => import('@fortawesome/pro-solid-svg-icons/faCloudSun'),
        "faCloudSunRain": () => import('@fortawesome/pro-solid-svg-icons/faCloudSunRain'),
        "faCloudUpload": () => import('@fortawesome/pro-solid-svg-icons/faCloudUpload'),
        "faCloudUploadAlt": () => import('@fortawesome/pro-solid-svg-icons/faCloudUploadAlt'),
        "faClouds": () => import('@fortawesome/pro-solid-svg-icons/faClouds'),
        "faCloudsMoon": () => import('@fortawesome/pro-solid-svg-icons/faCloudsMoon'),
        "faCloudsSun": () => import('@fortawesome/pro-solid-svg-icons/faCloudsSun'),
        "faClub": () => import('@fortawesome/pro-solid-svg-icons/faClub'),
        "faCocktail": () => import('@fortawesome/pro-solid-svg-icons/faCocktail'),
        "faCode": () => import('@fortawesome/pro-solid-svg-icons/faCode'),
        "faCodeBranch": () => import('@fortawesome/pro-solid-svg-icons/faCodeBranch'),
        "faCodeCommit": () => import('@fortawesome/pro-solid-svg-icons/faCodeCommit'),
        "faCodeMerge": () => import('@fortawesome/pro-solid-svg-icons/faCodeMerge'),
        "faCoffee": () => import('@fortawesome/pro-solid-svg-icons/faCoffee'),
        "faCoffeePot": () => import('@fortawesome/pro-solid-svg-icons/faCoffeePot'),
        "faCoffeeTogo": () => import('@fortawesome/pro-solid-svg-icons/faCoffeeTogo'),
        "faCoffin": () => import('@fortawesome/pro-solid-svg-icons/faCoffin'),
        "faCoffinCross": () => import('@fortawesome/pro-solid-svg-icons/faCoffinCross'),
        "faCog": () => import('@fortawesome/pro-solid-svg-icons/faCog'),
        "faCogs": () => import('@fortawesome/pro-solid-svg-icons/faCogs'),
        "faCoin": () => import('@fortawesome/pro-solid-svg-icons/faCoin'),
        "faCoins": () => import('@fortawesome/pro-solid-svg-icons/faCoins'),
        "faColumns": () => import('@fortawesome/pro-solid-svg-icons/faColumns'),
        "faComet": () => import('@fortawesome/pro-solid-svg-icons/faComet'),
        "faComment": () => import('@fortawesome/pro-solid-svg-icons/faComment'),
        "faCommentAlt": () => import('@fortawesome/pro-solid-svg-icons/faCommentAlt'),
        "faCommentAltCheck": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltCheck'),
        "faCommentAltDollar": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltDollar'),
        "faCommentAltDots": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltDots'),
        "faCommentAltEdit": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltEdit'),
        "faCommentAltExclamation": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltExclamation'),
        "faCommentAltLines": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltLines'),
        "faCommentAltMedical": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltMedical'),
        "faCommentAltMinus": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltMinus'),
        "faCommentAltMusic": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltMusic'),
        "faCommentAltPlus": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltPlus'),
        "faCommentAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltSlash'),
        "faCommentAltSmile": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltSmile'),
        "faCommentAltTimes": () => import('@fortawesome/pro-solid-svg-icons/faCommentAltTimes'),
        "faCommentCheck": () => import('@fortawesome/pro-solid-svg-icons/faCommentCheck'),
        "faCommentDollar": () => import('@fortawesome/pro-solid-svg-icons/faCommentDollar'),
        "faCommentDots": () => import('@fortawesome/pro-solid-svg-icons/faCommentDots'),
        "faCommentEdit": () => import('@fortawesome/pro-solid-svg-icons/faCommentEdit'),
        "faCommentExclamation": () => import('@fortawesome/pro-solid-svg-icons/faCommentExclamation'),
        "faCommentLines": () => import('@fortawesome/pro-solid-svg-icons/faCommentLines'),
        "faCommentMedical": () => import('@fortawesome/pro-solid-svg-icons/faCommentMedical'),
        "faCommentMinus": () => import('@fortawesome/pro-solid-svg-icons/faCommentMinus'),
        "faCommentMusic": () => import('@fortawesome/pro-solid-svg-icons/faCommentMusic'),
        "faCommentPlus": () => import('@fortawesome/pro-solid-svg-icons/faCommentPlus'),
        "faCommentSlash": () => import('@fortawesome/pro-solid-svg-icons/faCommentSlash'),
        "faCommentSmile": () => import('@fortawesome/pro-solid-svg-icons/faCommentSmile'),
        "faCommentTimes": () => import('@fortawesome/pro-solid-svg-icons/faCommentTimes'),
        "faComments": () => import('@fortawesome/pro-solid-svg-icons/faComments'),
        "faCommentsAlt": () => import('@fortawesome/pro-solid-svg-icons/faCommentsAlt'),
        "faCommentsAltDollar": () => import('@fortawesome/pro-solid-svg-icons/faCommentsAltDollar'),
        "faCommentsDollar": () => import('@fortawesome/pro-solid-svg-icons/faCommentsDollar'),
        "faCompactDisc": () => import('@fortawesome/pro-solid-svg-icons/faCompactDisc'),
        "faCompass": () => import('@fortawesome/pro-solid-svg-icons/faCompass'),
        "faCompassSlash": () => import('@fortawesome/pro-solid-svg-icons/faCompassSlash'),
        "faCompress": () => import('@fortawesome/pro-solid-svg-icons/faCompress'),
        "faCompressAlt": () => import('@fortawesome/pro-solid-svg-icons/faCompressAlt'),
        "faCompressArrowsAlt": () => import('@fortawesome/pro-solid-svg-icons/faCompressArrowsAlt'),
        "faCompressWide": () => import('@fortawesome/pro-solid-svg-icons/faCompressWide'),
        "faComputerClassic": () => import('@fortawesome/pro-solid-svg-icons/faComputerClassic'),
        "faComputerSpeaker": () => import('@fortawesome/pro-solid-svg-icons/faComputerSpeaker'),
        "faConciergeBell": () => import('@fortawesome/pro-solid-svg-icons/faConciergeBell'),
        "faConstruction": () => import('@fortawesome/pro-solid-svg-icons/faConstruction'),
        "faContainerStorage": () => import('@fortawesome/pro-solid-svg-icons/faContainerStorage'),
        "faConveyorBelt": () => import('@fortawesome/pro-solid-svg-icons/faConveyorBelt'),
        "faConveyorBeltAlt": () => import('@fortawesome/pro-solid-svg-icons/faConveyorBeltAlt'),
        "faCookie": () => import('@fortawesome/pro-solid-svg-icons/faCookie'),
        "faCookieBite": () => import('@fortawesome/pro-solid-svg-icons/faCookieBite'),
        "faCopy": () => import('@fortawesome/pro-solid-svg-icons/faCopy'),
        "faCopyright": () => import('@fortawesome/pro-solid-svg-icons/faCopyright'),
        "faCorn": () => import('@fortawesome/pro-solid-svg-icons/faCorn'),
        "faCouch": () => import('@fortawesome/pro-solid-svg-icons/faCouch'),
        "faCow": () => import('@fortawesome/pro-solid-svg-icons/faCow'),
        "faCowbell": () => import('@fortawesome/pro-solid-svg-icons/faCowbell'),
        "faCowbellMore": () => import('@fortawesome/pro-solid-svg-icons/faCowbellMore'),
        "faCreditCard": () => import('@fortawesome/pro-solid-svg-icons/faCreditCard'),
        "faCreditCardBlank": () => import('@fortawesome/pro-solid-svg-icons/faCreditCardBlank'),
        "faCreditCardFront": () => import('@fortawesome/pro-solid-svg-icons/faCreditCardFront'),
        "faCricket": () => import('@fortawesome/pro-solid-svg-icons/faCricket'),
        "faCroissant": () => import('@fortawesome/pro-solid-svg-icons/faCroissant'),
        "faCrop": () => import('@fortawesome/pro-solid-svg-icons/faCrop'),
        "faCropAlt": () => import('@fortawesome/pro-solid-svg-icons/faCropAlt'),
        "faCross": () => import('@fortawesome/pro-solid-svg-icons/faCross'),
        "faCrosshairs": () => import('@fortawesome/pro-solid-svg-icons/faCrosshairs'),
        "faCrow": () => import('@fortawesome/pro-solid-svg-icons/faCrow'),
        "faCrown": () => import('@fortawesome/pro-solid-svg-icons/faCrown'),
        "faCrutch": () => import('@fortawesome/pro-solid-svg-icons/faCrutch'),
        "faCrutches": () => import('@fortawesome/pro-solid-svg-icons/faCrutches'),
        "faCube": () => import('@fortawesome/pro-solid-svg-icons/faCube'),
        "faCubes": () => import('@fortawesome/pro-solid-svg-icons/faCubes'),
        "faCurling": () => import('@fortawesome/pro-solid-svg-icons/faCurling'),
        "faCut": () => import('@fortawesome/pro-solid-svg-icons/faCut'),
        "faDagger": () => import('@fortawesome/pro-solid-svg-icons/faDagger'),
        "faDatabase": () => import('@fortawesome/pro-solid-svg-icons/faDatabase'),
        "faDeaf": () => import('@fortawesome/pro-solid-svg-icons/faDeaf'),
        "faDebug": () => import('@fortawesome/pro-solid-svg-icons/faDebug'),
        "faDeer": () => import('@fortawesome/pro-solid-svg-icons/faDeer'),
        "faDeerRudolph": () => import('@fortawesome/pro-solid-svg-icons/faDeerRudolph'),
        "faDemocrat": () => import('@fortawesome/pro-solid-svg-icons/faDemocrat'),
        "faDesktop": () => import('@fortawesome/pro-solid-svg-icons/faDesktop'),
        "faDesktopAlt": () => import('@fortawesome/pro-solid-svg-icons/faDesktopAlt'),
        "faDewpoint": () => import('@fortawesome/pro-solid-svg-icons/faDewpoint'),
        "faDharmachakra": () => import('@fortawesome/pro-solid-svg-icons/faDharmachakra'),
        "faDiagnoses": () => import('@fortawesome/pro-solid-svg-icons/faDiagnoses'),
        "faDiamond": () => import('@fortawesome/pro-solid-svg-icons/faDiamond'),
        "faDice": () => import('@fortawesome/pro-solid-svg-icons/faDice'),
        "faDiceD10": () => import('@fortawesome/pro-solid-svg-icons/faDiceD10'),
        "faDiceD12": () => import('@fortawesome/pro-solid-svg-icons/faDiceD12'),
        "faDiceD20": () => import('@fortawesome/pro-solid-svg-icons/faDiceD20'),
        "faDiceD4": () => import('@fortawesome/pro-solid-svg-icons/faDiceD4'),
        "faDiceD6": () => import('@fortawesome/pro-solid-svg-icons/faDiceD6'),
        "faDiceD8": () => import('@fortawesome/pro-solid-svg-icons/faDiceD8'),
        "faDiceFive": () => import('@fortawesome/pro-solid-svg-icons/faDiceFive'),
        "faDiceFour": () => import('@fortawesome/pro-solid-svg-icons/faDiceFour'),
        "faDiceOne": () => import('@fortawesome/pro-solid-svg-icons/faDiceOne'),
        "faDiceSix": () => import('@fortawesome/pro-solid-svg-icons/faDiceSix'),
        "faDiceThree": () => import('@fortawesome/pro-solid-svg-icons/faDiceThree'),
        "faDiceTwo": () => import('@fortawesome/pro-solid-svg-icons/faDiceTwo'),
        "faDigging": () => import('@fortawesome/pro-solid-svg-icons/faDigging'),
        "faDigitalTachograph": () => import('@fortawesome/pro-solid-svg-icons/faDigitalTachograph'),
        "faDiploma": () => import('@fortawesome/pro-solid-svg-icons/faDiploma'),
        "faDirections": () => import('@fortawesome/pro-solid-svg-icons/faDirections'),
        "faDiscDrive": () => import('@fortawesome/pro-solid-svg-icons/faDiscDrive'),
        "faDisease": () => import('@fortawesome/pro-solid-svg-icons/faDisease'),
        "faDivide": () => import('@fortawesome/pro-solid-svg-icons/faDivide'),
        "faDizzy": () => import('@fortawesome/pro-solid-svg-icons/faDizzy'),
        "faDna": () => import('@fortawesome/pro-solid-svg-icons/faDna'),
        "faDoNotEnter": () => import('@fortawesome/pro-solid-svg-icons/faDoNotEnter'),
        "faDog": () => import('@fortawesome/pro-solid-svg-icons/faDog'),
        "faDogLeashed": () => import('@fortawesome/pro-solid-svg-icons/faDogLeashed'),
        "faDollarSign": () => import('@fortawesome/pro-solid-svg-icons/faDollarSign'),
        "faDolly": () => import('@fortawesome/pro-solid-svg-icons/faDolly'),
        "faDollyEmpty": () => import('@fortawesome/pro-solid-svg-icons/faDollyEmpty'),
        "faDollyFlatbed": () => import('@fortawesome/pro-solid-svg-icons/faDollyFlatbed'),
        "faDollyFlatbedAlt": () => import('@fortawesome/pro-solid-svg-icons/faDollyFlatbedAlt'),
        "faDollyFlatbedEmpty": () => import('@fortawesome/pro-solid-svg-icons/faDollyFlatbedEmpty'),
        "faDonate": () => import('@fortawesome/pro-solid-svg-icons/faDonate'),
        "faDoorClosed": () => import('@fortawesome/pro-solid-svg-icons/faDoorClosed'),
        "faDoorOpen": () => import('@fortawesome/pro-solid-svg-icons/faDoorOpen'),
        "faDotCircle": () => import('@fortawesome/pro-solid-svg-icons/faDotCircle'),
        "faDove": () => import('@fortawesome/pro-solid-svg-icons/faDove'),
        "faDownload": () => import('@fortawesome/pro-solid-svg-icons/faDownload'),
        "faDraftingCompass": () => import('@fortawesome/pro-solid-svg-icons/faDraftingCompass'),
        "faDragon": () => import('@fortawesome/pro-solid-svg-icons/faDragon'),
        "faDrawCircle": () => import('@fortawesome/pro-solid-svg-icons/faDrawCircle'),
        "faDrawPolygon": () => import('@fortawesome/pro-solid-svg-icons/faDrawPolygon'),
        "faDrawSquare": () => import('@fortawesome/pro-solid-svg-icons/faDrawSquare'),
        "faDreidel": () => import('@fortawesome/pro-solid-svg-icons/faDreidel'),
        "faDrone": () => import('@fortawesome/pro-solid-svg-icons/faDrone'),
        "faDroneAlt": () => import('@fortawesome/pro-solid-svg-icons/faDroneAlt'),
        "faDrum": () => import('@fortawesome/pro-solid-svg-icons/faDrum'),
        "faDrumSteelpan": () => import('@fortawesome/pro-solid-svg-icons/faDrumSteelpan'),
        "faDrumstick": () => import('@fortawesome/pro-solid-svg-icons/faDrumstick'),
        "faDrumstickBite": () => import('@fortawesome/pro-solid-svg-icons/faDrumstickBite'),
        "faDryer": () => import('@fortawesome/pro-solid-svg-icons/faDryer'),
        "faDryerAlt": () => import('@fortawesome/pro-solid-svg-icons/faDryerAlt'),
        "faDuck": () => import('@fortawesome/pro-solid-svg-icons/faDuck'),
        "faDumbbell": () => import('@fortawesome/pro-solid-svg-icons/faDumbbell'),
        "faDumpster": () => import('@fortawesome/pro-solid-svg-icons/faDumpster'),
        "faDumpsterFire": () => import('@fortawesome/pro-solid-svg-icons/faDumpsterFire'),
        "faDungeon": () => import('@fortawesome/pro-solid-svg-icons/faDungeon'),
        "faEar": () => import('@fortawesome/pro-solid-svg-icons/faEar'),
        "faEarMuffs": () => import('@fortawesome/pro-solid-svg-icons/faEarMuffs'),
        "faEclipse": () => import('@fortawesome/pro-solid-svg-icons/faEclipse'),
        "faEclipseAlt": () => import('@fortawesome/pro-solid-svg-icons/faEclipseAlt'),
        "faEdit": () => import('@fortawesome/pro-solid-svg-icons/faEdit'),
        "faEgg": () => import('@fortawesome/pro-solid-svg-icons/faEgg'),
        "faEggFried": () => import('@fortawesome/pro-solid-svg-icons/faEggFried'),
        "faEject": () => import('@fortawesome/pro-solid-svg-icons/faEject'),
        "faElephant": () => import('@fortawesome/pro-solid-svg-icons/faElephant'),
        "faEllipsisH": () => import('@fortawesome/pro-solid-svg-icons/faEllipsisH'),
        "faEllipsisHAlt": () => import('@fortawesome/pro-solid-svg-icons/faEllipsisHAlt'),
        "faEllipsisV": () => import('@fortawesome/pro-solid-svg-icons/faEllipsisV'),
        "faEllipsisVAlt": () => import('@fortawesome/pro-solid-svg-icons/faEllipsisVAlt'),
        "faEmptySet": () => import('@fortawesome/pro-solid-svg-icons/faEmptySet'),
        "faEngineWarning": () => import('@fortawesome/pro-solid-svg-icons/faEngineWarning'),
        "faEnvelope": () => import('@fortawesome/pro-solid-svg-icons/faEnvelope'),
        "faEnvelopeOpen": () => import('@fortawesome/pro-solid-svg-icons/faEnvelopeOpen'),
        "faEnvelopeOpenDollar": () => import('@fortawesome/pro-solid-svg-icons/faEnvelopeOpenDollar'),
        "faEnvelopeOpenText": () => import('@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText'),
        "faEnvelopeSquare": () => import('@fortawesome/pro-solid-svg-icons/faEnvelopeSquare'),
        "faEquals": () => import('@fortawesome/pro-solid-svg-icons/faEquals'),
        "faEraser": () => import('@fortawesome/pro-solid-svg-icons/faEraser'),
        "faEthernet": () => import('@fortawesome/pro-solid-svg-icons/faEthernet'),
        "faEuroSign": () => import('@fortawesome/pro-solid-svg-icons/faEuroSign'),
        "faExchange": () => import('@fortawesome/pro-solid-svg-icons/faExchange'),
        "faExchangeAlt": () => import('@fortawesome/pro-solid-svg-icons/faExchangeAlt'),
        "faExclamation": () => import('@fortawesome/pro-solid-svg-icons/faExclamation'),
        "faExclamationCircle": () => import('@fortawesome/pro-solid-svg-icons/faExclamationCircle'),
        "faExclamationSquare": () => import('@fortawesome/pro-solid-svg-icons/faExclamationSquare'),
        "faExclamationTriangle": () => import('@fortawesome/pro-solid-svg-icons/faExclamationTriangle'),
        "faExpand": () => import('@fortawesome/pro-solid-svg-icons/faExpand'),
        "faExpandAlt": () => import('@fortawesome/pro-solid-svg-icons/faExpandAlt'),
        "faExpandArrows": () => import('@fortawesome/pro-solid-svg-icons/faExpandArrows'),
        "faExpandArrowsAlt": () => import('@fortawesome/pro-solid-svg-icons/faExpandArrowsAlt'),
        "faExpandWide": () => import('@fortawesome/pro-solid-svg-icons/faExpandWide'),
        "faExternalLink": () => import('@fortawesome/pro-solid-svg-icons/faExternalLink'),
        "faExternalLinkAlt": () => import('@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'),
        "faExternalLinkSquare": () => import('@fortawesome/pro-solid-svg-icons/faExternalLinkSquare'),
        "faExternalLinkSquareAlt": () => import('@fortawesome/pro-solid-svg-icons/faExternalLinkSquareAlt'),
        "faEye": () => import('@fortawesome/pro-solid-svg-icons/faEye'),
        "faEyeDropper": () => import('@fortawesome/pro-solid-svg-icons/faEyeDropper'),
        "faEyeEvil": () => import('@fortawesome/pro-solid-svg-icons/faEyeEvil'),
        "faEyeSlash": () => import('@fortawesome/pro-solid-svg-icons/faEyeSlash'),
        "faFan": () => import('@fortawesome/pro-solid-svg-icons/faFan'),
        "faFanTable": () => import('@fortawesome/pro-solid-svg-icons/faFanTable'),
        "faFarm": () => import('@fortawesome/pro-solid-svg-icons/faFarm'),
        "faFastBackward": () => import('@fortawesome/pro-solid-svg-icons/faFastBackward'),
        "faFastForward": () => import('@fortawesome/pro-solid-svg-icons/faFastForward'),
        "faFaucet": () => import('@fortawesome/pro-solid-svg-icons/faFaucet'),
        "faFaucetDrip": () => import('@fortawesome/pro-solid-svg-icons/faFaucetDrip'),
        "faFax": () => import('@fortawesome/pro-solid-svg-icons/faFax'),
        "faFeather": () => import('@fortawesome/pro-solid-svg-icons/faFeather'),
        "faFeatherAlt": () => import('@fortawesome/pro-solid-svg-icons/faFeatherAlt'),
        "faFemale": () => import('@fortawesome/pro-solid-svg-icons/faFemale'),
        "faFieldHockey": () => import('@fortawesome/pro-solid-svg-icons/faFieldHockey'),
        "faFighterJet": () => import('@fortawesome/pro-solid-svg-icons/faFighterJet'),
        "faFile": () => import('@fortawesome/pro-solid-svg-icons/faFile'),
        "faFileAlt": () => import('@fortawesome/pro-solid-svg-icons/faFileAlt'),
        "faFileArchive": () => import('@fortawesome/pro-solid-svg-icons/faFileArchive'),
        "faFileAudio": () => import('@fortawesome/pro-solid-svg-icons/faFileAudio'),
        "faFileCertificate": () => import('@fortawesome/pro-solid-svg-icons/faFileCertificate'),
        "faFileChartLine": () => import('@fortawesome/pro-solid-svg-icons/faFileChartLine'),
        "faFileChartPie": () => import('@fortawesome/pro-solid-svg-icons/faFileChartPie'),
        "faFileCheck": () => import('@fortawesome/pro-solid-svg-icons/faFileCheck'),
        "faFileCode": () => import('@fortawesome/pro-solid-svg-icons/faFileCode'),
        "faFileContract": () => import('@fortawesome/pro-solid-svg-icons/faFileContract'),
        "faFileCsv": () => import('@fortawesome/pro-solid-svg-icons/faFileCsv'),
        "faFileDownload": () => import('@fortawesome/pro-solid-svg-icons/faFileDownload'),
        "faFileEdit": () => import('@fortawesome/pro-solid-svg-icons/faFileEdit'),
        "faFileExcel": () => import('@fortawesome/pro-solid-svg-icons/faFileExcel'),
        "faFileExclamation": () => import('@fortawesome/pro-solid-svg-icons/faFileExclamation'),
        "faFileExport": () => import('@fortawesome/pro-solid-svg-icons/faFileExport'),
        "faFileImage": () => import('@fortawesome/pro-solid-svg-icons/faFileImage'),
        "faFileImport": () => import('@fortawesome/pro-solid-svg-icons/faFileImport'),
        "faFileInvoice": () => import('@fortawesome/pro-solid-svg-icons/faFileInvoice'),
        "faFileInvoiceDollar": () => import('@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar'),
        "faFileMedical": () => import('@fortawesome/pro-solid-svg-icons/faFileMedical'),
        "faFileMedicalAlt": () => import('@fortawesome/pro-solid-svg-icons/faFileMedicalAlt'),
        "faFileMinus": () => import('@fortawesome/pro-solid-svg-icons/faFileMinus'),
        "faFileMusic": () => import('@fortawesome/pro-solid-svg-icons/faFileMusic'),
        "faFilePdf": () => import('@fortawesome/pro-solid-svg-icons/faFilePdf'),
        "faFilePlus": () => import('@fortawesome/pro-solid-svg-icons/faFilePlus'),
        "faFilePowerpoint": () => import('@fortawesome/pro-solid-svg-icons/faFilePowerpoint'),
        "faFilePrescription": () => import('@fortawesome/pro-solid-svg-icons/faFilePrescription'),
        "faFileSearch": () => import('@fortawesome/pro-solid-svg-icons/faFileSearch'),
        "faFileSignature": () => import('@fortawesome/pro-solid-svg-icons/faFileSignature'),
        "faFileSpreadsheet": () => import('@fortawesome/pro-solid-svg-icons/faFileSpreadsheet'),
        "faFileTimes": () => import('@fortawesome/pro-solid-svg-icons/faFileTimes'),
        "faFileUpload": () => import('@fortawesome/pro-solid-svg-icons/faFileUpload'),
        "faFileUser": () => import('@fortawesome/pro-solid-svg-icons/faFileUser'),
        "faFileVideo": () => import('@fortawesome/pro-solid-svg-icons/faFileVideo'),
        "faFileWord": () => import('@fortawesome/pro-solid-svg-icons/faFileWord'),
        "faFilesMedical": () => import('@fortawesome/pro-solid-svg-icons/faFilesMedical'),
        "faFill": () => import('@fortawesome/pro-solid-svg-icons/faFill'),
        "faFillDrip": () => import('@fortawesome/pro-solid-svg-icons/faFillDrip'),
        "faFilm": () => import('@fortawesome/pro-solid-svg-icons/faFilm'),
        "faFilmAlt": () => import('@fortawesome/pro-solid-svg-icons/faFilmAlt'),
        "faFilmCanister": () => import('@fortawesome/pro-solid-svg-icons/faFilmCanister'),
        "faFilter": () => import('@fortawesome/pro-solid-svg-icons/faFilter'),
        "faFingerprint": () => import('@fortawesome/pro-solid-svg-icons/faFingerprint'),
        "faFire": () => import('@fortawesome/pro-solid-svg-icons/faFire'),
        "faFireAlt": () => import('@fortawesome/pro-solid-svg-icons/faFireAlt'),
        "faFireExtinguisher": () => import('@fortawesome/pro-solid-svg-icons/faFireExtinguisher'),
        "faFireSmoke": () => import('@fortawesome/pro-solid-svg-icons/faFireSmoke'),
        "faFireplace": () => import('@fortawesome/pro-solid-svg-icons/faFireplace'),
        "faFirstAid": () => import('@fortawesome/pro-solid-svg-icons/faFirstAid'),
        "faFish": () => import('@fortawesome/pro-solid-svg-icons/faFish'),
        "faFishCooked": () => import('@fortawesome/pro-solid-svg-icons/faFishCooked'),
        "faFistRaised": () => import('@fortawesome/pro-solid-svg-icons/faFistRaised'),
        "faFlag": () => import('@fortawesome/pro-solid-svg-icons/faFlag'),
        "faFlagAlt": () => import('@fortawesome/pro-solid-svg-icons/faFlagAlt'),
        "faFlagCheckered": () => import('@fortawesome/pro-solid-svg-icons/faFlagCheckered'),
        "faFlagUsa": () => import('@fortawesome/pro-solid-svg-icons/faFlagUsa'),
        "faFlame": () => import('@fortawesome/pro-solid-svg-icons/faFlame'),
        "faFlashlight": () => import('@fortawesome/pro-solid-svg-icons/faFlashlight'),
        "faFlask": () => import('@fortawesome/pro-solid-svg-icons/faFlask'),
        "faFlaskPoison": () => import('@fortawesome/pro-solid-svg-icons/faFlaskPoison'),
        "faFlaskPotion": () => import('@fortawesome/pro-solid-svg-icons/faFlaskPotion'),
        "faFlower": () => import('@fortawesome/pro-solid-svg-icons/faFlower'),
        "faFlowerDaffodil": () => import('@fortawesome/pro-solid-svg-icons/faFlowerDaffodil'),
        "faFlowerTulip": () => import('@fortawesome/pro-solid-svg-icons/faFlowerTulip'),
        "faFlushed": () => import('@fortawesome/pro-solid-svg-icons/faFlushed'),
        "faFlute": () => import('@fortawesome/pro-solid-svg-icons/faFlute'),
        "faFluxCapacitor": () => import('@fortawesome/pro-solid-svg-icons/faFluxCapacitor'),
        "faFog": () => import('@fortawesome/pro-solid-svg-icons/faFog'),
        "faFolder": () => import('@fortawesome/pro-solid-svg-icons/faFolder'),
        "faFolderDownload": () => import('@fortawesome/pro-solid-svg-icons/faFolderDownload'),
        "faFolderMinus": () => import('@fortawesome/pro-solid-svg-icons/faFolderMinus'),
        "faFolderOpen": () => import('@fortawesome/pro-solid-svg-icons/faFolderOpen'),
        "faFolderPlus": () => import('@fortawesome/pro-solid-svg-icons/faFolderPlus'),
        "faFolderTimes": () => import('@fortawesome/pro-solid-svg-icons/faFolderTimes'),
        "faFolderTree": () => import('@fortawesome/pro-solid-svg-icons/faFolderTree'),
        "faFolderUpload": () => import('@fortawesome/pro-solid-svg-icons/faFolderUpload'),
        "faFolders": () => import('@fortawesome/pro-solid-svg-icons/faFolders'),
        "faFont": () => import('@fortawesome/pro-solid-svg-icons/faFont'),
        "faFontAwesomeLogoFull": () => import('@fortawesome/pro-solid-svg-icons/faFontAwesomeLogoFull'),
        "faFontCase": () => import('@fortawesome/pro-solid-svg-icons/faFontCase'),
        "faFootballBall": () => import('@fortawesome/pro-solid-svg-icons/faFootballBall'),
        "faFootballHelmet": () => import('@fortawesome/pro-solid-svg-icons/faFootballHelmet'),
        "faForklift": () => import('@fortawesome/pro-solid-svg-icons/faForklift'),
        "faForward": () => import('@fortawesome/pro-solid-svg-icons/faForward'),
        "faFragile": () => import('@fortawesome/pro-solid-svg-icons/faFragile'),
        "faFrenchFries": () => import('@fortawesome/pro-solid-svg-icons/faFrenchFries'),
        "faFrog": () => import('@fortawesome/pro-solid-svg-icons/faFrog'),
        "faFrostyHead": () => import('@fortawesome/pro-solid-svg-icons/faFrostyHead'),
        "faFrown": () => import('@fortawesome/pro-solid-svg-icons/faFrown'),
        "faFrownOpen": () => import('@fortawesome/pro-solid-svg-icons/faFrownOpen'),
        "faFunction": () => import('@fortawesome/pro-solid-svg-icons/faFunction'),
        "faFunnelDollar": () => import('@fortawesome/pro-solid-svg-icons/faFunnelDollar'),
        "faFutbol": () => import('@fortawesome/pro-solid-svg-icons/faFutbol'),
        "faGalaxy": () => import('@fortawesome/pro-solid-svg-icons/faGalaxy'),
        "faGameBoard": () => import('@fortawesome/pro-solid-svg-icons/faGameBoard'),
        "faGameBoardAlt": () => import('@fortawesome/pro-solid-svg-icons/faGameBoardAlt'),
        "faGameConsoleHandheld": () => import('@fortawesome/pro-solid-svg-icons/faGameConsoleHandheld'),
        "faGamepad": () => import('@fortawesome/pro-solid-svg-icons/faGamepad'),
        "faGamepadAlt": () => import('@fortawesome/pro-solid-svg-icons/faGamepadAlt'),
        "faGarage": () => import('@fortawesome/pro-solid-svg-icons/faGarage'),
        "faGarageCar": () => import('@fortawesome/pro-solid-svg-icons/faGarageCar'),
        "faGarageOpen": () => import('@fortawesome/pro-solid-svg-icons/faGarageOpen'),
        "faGasPump": () => import('@fortawesome/pro-solid-svg-icons/faGasPump'),
        "faGasPumpSlash": () => import('@fortawesome/pro-solid-svg-icons/faGasPumpSlash'),
        "faGavel": () => import('@fortawesome/pro-solid-svg-icons/faGavel'),
        "faGem": () => import('@fortawesome/pro-solid-svg-icons/faGem'),
        "faGenderless": () => import('@fortawesome/pro-solid-svg-icons/faGenderless'),
        "faGhost": () => import('@fortawesome/pro-solid-svg-icons/faGhost'),
        "faGift": () => import('@fortawesome/pro-solid-svg-icons/faGift'),
        "faGiftCard": () => import('@fortawesome/pro-solid-svg-icons/faGiftCard'),
        "faGifts": () => import('@fortawesome/pro-solid-svg-icons/faGifts'),
        "faGingerbreadMan": () => import('@fortawesome/pro-solid-svg-icons/faGingerbreadMan'),
        "faGlass": () => import('@fortawesome/pro-solid-svg-icons/faGlass'),
        "faGlassChampagne": () => import('@fortawesome/pro-solid-svg-icons/faGlassChampagne'),
        "faGlassCheers": () => import('@fortawesome/pro-solid-svg-icons/faGlassCheers'),
        "faGlassCitrus": () => import('@fortawesome/pro-solid-svg-icons/faGlassCitrus'),
        "faGlassMartini": () => import('@fortawesome/pro-solid-svg-icons/faGlassMartini'),
        "faGlassMartiniAlt": () => import('@fortawesome/pro-solid-svg-icons/faGlassMartiniAlt'),
        "faGlassWhiskey": () => import('@fortawesome/pro-solid-svg-icons/faGlassWhiskey'),
        "faGlassWhiskeyRocks": () => import('@fortawesome/pro-solid-svg-icons/faGlassWhiskeyRocks'),
        "faGlasses": () => import('@fortawesome/pro-solid-svg-icons/faGlasses'),
        "faGlassesAlt": () => import('@fortawesome/pro-solid-svg-icons/faGlassesAlt'),
        "faGlobe": () => import('@fortawesome/pro-solid-svg-icons/faGlobe'),
        "faGlobeAfrica": () => import('@fortawesome/pro-solid-svg-icons/faGlobeAfrica'),
        "faGlobeAmericas": () => import('@fortawesome/pro-solid-svg-icons/faGlobeAmericas'),
        "faGlobeAsia": () => import('@fortawesome/pro-solid-svg-icons/faGlobeAsia'),
        "faGlobeEurope": () => import('@fortawesome/pro-solid-svg-icons/faGlobeEurope'),
        "faGlobeSnow": () => import('@fortawesome/pro-solid-svg-icons/faGlobeSnow'),
        "faGlobeStand": () => import('@fortawesome/pro-solid-svg-icons/faGlobeStand'),
        "faGolfBall": () => import('@fortawesome/pro-solid-svg-icons/faGolfBall'),
        "faGolfClub": () => import('@fortawesome/pro-solid-svg-icons/faGolfClub'),
        "faGopuram": () => import('@fortawesome/pro-solid-svg-icons/faGopuram'),
        "faGraduationCap": () => import('@fortawesome/pro-solid-svg-icons/faGraduationCap'),
        "faGramophone": () => import('@fortawesome/pro-solid-svg-icons/faGramophone'),
        "faGreaterThan": () => import('@fortawesome/pro-solid-svg-icons/faGreaterThan'),
        "faGreaterThanEqual": () => import('@fortawesome/pro-solid-svg-icons/faGreaterThanEqual'),
        "faGrimace": () => import('@fortawesome/pro-solid-svg-icons/faGrimace'),
        "faGrin": () => import('@fortawesome/pro-solid-svg-icons/faGrin'),
        "faGrinAlt": () => import('@fortawesome/pro-solid-svg-icons/faGrinAlt'),
        "faGrinBeam": () => import('@fortawesome/pro-solid-svg-icons/faGrinBeam'),
        "faGrinBeamSweat": () => import('@fortawesome/pro-solid-svg-icons/faGrinBeamSweat'),
        "faGrinHearts": () => import('@fortawesome/pro-solid-svg-icons/faGrinHearts'),
        "faGrinSquint": () => import('@fortawesome/pro-solid-svg-icons/faGrinSquint'),
        "faGrinSquintTears": () => import('@fortawesome/pro-solid-svg-icons/faGrinSquintTears'),
        "faGrinStars": () => import('@fortawesome/pro-solid-svg-icons/faGrinStars'),
        "faGrinTears": () => import('@fortawesome/pro-solid-svg-icons/faGrinTears'),
        "faGrinTongue": () => import('@fortawesome/pro-solid-svg-icons/faGrinTongue'),
        "faGrinTongueSquint": () => import('@fortawesome/pro-solid-svg-icons/faGrinTongueSquint'),
        "faGrinTongueWink": () => import('@fortawesome/pro-solid-svg-icons/faGrinTongueWink'),
        "faGrinWink": () => import('@fortawesome/pro-solid-svg-icons/faGrinWink'),
        "faGripHorizontal": () => import('@fortawesome/pro-solid-svg-icons/faGripHorizontal'),
        "faGripLines": () => import('@fortawesome/pro-solid-svg-icons/faGripLines'),
        "faGripLinesVertical": () => import('@fortawesome/pro-solid-svg-icons/faGripLinesVertical'),
        "faGripVertical": () => import('@fortawesome/pro-solid-svg-icons/faGripVertical'),
        "faGuitar": () => import('@fortawesome/pro-solid-svg-icons/faGuitar'),
        "faGuitarElectric": () => import('@fortawesome/pro-solid-svg-icons/faGuitarElectric'),
        "faGuitars": () => import('@fortawesome/pro-solid-svg-icons/faGuitars'),
        "faH1": () => import('@fortawesome/pro-solid-svg-icons/faH1'),
        "faH2": () => import('@fortawesome/pro-solid-svg-icons/faH2'),
        "faH3": () => import('@fortawesome/pro-solid-svg-icons/faH3'),
        "faH4": () => import('@fortawesome/pro-solid-svg-icons/faH4'),
        "faHSquare": () => import('@fortawesome/pro-solid-svg-icons/faHSquare'),
        "faHamburger": () => import('@fortawesome/pro-solid-svg-icons/faHamburger'),
        "faHammer": () => import('@fortawesome/pro-solid-svg-icons/faHammer'),
        "faHammerWar": () => import('@fortawesome/pro-solid-svg-icons/faHammerWar'),
        "faHamsa": () => import('@fortawesome/pro-solid-svg-icons/faHamsa'),
        "faHandHeart": () => import('@fortawesome/pro-solid-svg-icons/faHandHeart'),
        "faHandHolding": () => import('@fortawesome/pro-solid-svg-icons/faHandHolding'),
        "faHandHoldingBox": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingBox'),
        "faHandHoldingHeart": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingHeart'),
        "faHandHoldingMagic": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingMagic'),
        "faHandHoldingMedical": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingMedical'),
        "faHandHoldingSeedling": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingSeedling'),
        "faHandHoldingUsd": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingUsd'),
        "faHandHoldingWater": () => import('@fortawesome/pro-solid-svg-icons/faHandHoldingWater'),
        "faHandLizard": () => import('@fortawesome/pro-solid-svg-icons/faHandLizard'),
        "faHandMiddleFinger": () => import('@fortawesome/pro-solid-svg-icons/faHandMiddleFinger'),
        "faHandPaper": () => import('@fortawesome/pro-solid-svg-icons/faHandPaper'),
        "faHandPeace": () => import('@fortawesome/pro-solid-svg-icons/faHandPeace'),
        "faHandPointDown": () => import('@fortawesome/pro-solid-svg-icons/faHandPointDown'),
        "faHandPointLeft": () => import('@fortawesome/pro-solid-svg-icons/faHandPointLeft'),
        "faHandPointRight": () => import('@fortawesome/pro-solid-svg-icons/faHandPointRight'),
        "faHandPointUp": () => import('@fortawesome/pro-solid-svg-icons/faHandPointUp'),
        "faHandPointer": () => import('@fortawesome/pro-solid-svg-icons/faHandPointer'),
        "faHandReceiving": () => import('@fortawesome/pro-solid-svg-icons/faHandReceiving'),
        "faHandRock": () => import('@fortawesome/pro-solid-svg-icons/faHandRock'),
        "faHandScissors": () => import('@fortawesome/pro-solid-svg-icons/faHandScissors'),
        "faHandSparkles": () => import('@fortawesome/pro-solid-svg-icons/faHandSparkles'),
        "faHandSpock": () => import('@fortawesome/pro-solid-svg-icons/faHandSpock'),
        "faHands": () => import('@fortawesome/pro-solid-svg-icons/faHands'),
        "faHandsHeart": () => import('@fortawesome/pro-solid-svg-icons/faHandsHeart'),
        "faHandsHelping": () => import('@fortawesome/pro-solid-svg-icons/faHandsHelping'),
        "faHandsUsd": () => import('@fortawesome/pro-solid-svg-icons/faHandsUsd'),
        "faHandsWash": () => import('@fortawesome/pro-solid-svg-icons/faHandsWash'),
        "faHandshake": () => import('@fortawesome/pro-solid-svg-icons/faHandshake'),
        "faHandshakeAlt": () => import('@fortawesome/pro-solid-svg-icons/faHandshakeAlt'),
        "faHandshakeAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faHandshakeAltSlash'),
        "faHandshakeSlash": () => import('@fortawesome/pro-solid-svg-icons/faHandshakeSlash'),
        "faHanukiah": () => import('@fortawesome/pro-solid-svg-icons/faHanukiah'),
        "faHardHat": () => import('@fortawesome/pro-solid-svg-icons/faHardHat'),
        "faHashtag": () => import('@fortawesome/pro-solid-svg-icons/faHashtag'),
        "faHatChef": () => import('@fortawesome/pro-solid-svg-icons/faHatChef'),
        "faHatCowboy": () => import('@fortawesome/pro-solid-svg-icons/faHatCowboy'),
        "faHatCowboySide": () => import('@fortawesome/pro-solid-svg-icons/faHatCowboySide'),
        "faHatSanta": () => import('@fortawesome/pro-solid-svg-icons/faHatSanta'),
        "faHatWinter": () => import('@fortawesome/pro-solid-svg-icons/faHatWinter'),
        "faHatWitch": () => import('@fortawesome/pro-solid-svg-icons/faHatWitch'),
        "faHatWizard": () => import('@fortawesome/pro-solid-svg-icons/faHatWizard'),
        "faHdd": () => import('@fortawesome/pro-solid-svg-icons/faHdd'),
        "faHeadSide": () => import('@fortawesome/pro-solid-svg-icons/faHeadSide'),
        "faHeadSideBrain": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideBrain'),
        "faHeadSideCough": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideCough'),
        "faHeadSideCoughSlash": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideCoughSlash'),
        "faHeadSideHeadphones": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideHeadphones'),
        "faHeadSideMask": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideMask'),
        "faHeadSideMedical": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideMedical'),
        "faHeadSideVirus": () => import('@fortawesome/pro-solid-svg-icons/faHeadSideVirus'),
        "faHeadVr": () => import('@fortawesome/pro-solid-svg-icons/faHeadVr'),
        "faHeading": () => import('@fortawesome/pro-solid-svg-icons/faHeading'),
        "faHeadphones": () => import('@fortawesome/pro-solid-svg-icons/faHeadphones'),
        "faHeadphonesAlt": () => import('@fortawesome/pro-solid-svg-icons/faHeadphonesAlt'),
        "faHeadset": () => import('@fortawesome/pro-solid-svg-icons/faHeadset'),
        "faHeart": () => import('@fortawesome/pro-solid-svg-icons/faHeart'),
        "faHeartBroken": () => import('@fortawesome/pro-solid-svg-icons/faHeartBroken'),
        "faHeartCircle": () => import('@fortawesome/pro-solid-svg-icons/faHeartCircle'),
        "faHeartRate": () => import('@fortawesome/pro-solid-svg-icons/faHeartRate'),
        "faHeartSquare": () => import('@fortawesome/pro-solid-svg-icons/faHeartSquare'),
        "faHeartbeat": () => import('@fortawesome/pro-solid-svg-icons/faHeartbeat'),
        "faHeat": () => import('@fortawesome/pro-solid-svg-icons/faHeat'),
        "faHelicopter": () => import('@fortawesome/pro-solid-svg-icons/faHelicopter'),
        "faHelmetBattle": () => import('@fortawesome/pro-solid-svg-icons/faHelmetBattle'),
        "faHexagon": () => import('@fortawesome/pro-solid-svg-icons/faHexagon'),
        "faHighlighter": () => import('@fortawesome/pro-solid-svg-icons/faHighlighter'),
        "faHiking": () => import('@fortawesome/pro-solid-svg-icons/faHiking'),
        "faHippo": () => import('@fortawesome/pro-solid-svg-icons/faHippo'),
        "faHistory": () => import('@fortawesome/pro-solid-svg-icons/faHistory'),
        "faHockeyMask": () => import('@fortawesome/pro-solid-svg-icons/faHockeyMask'),
        "faHockeyPuck": () => import('@fortawesome/pro-solid-svg-icons/faHockeyPuck'),
        "faHockeySticks": () => import('@fortawesome/pro-solid-svg-icons/faHockeySticks'),
        "faHollyBerry": () => import('@fortawesome/pro-solid-svg-icons/faHollyBerry'),
        "faHome": () => import('@fortawesome/pro-solid-svg-icons/faHome'),
        "faHomeAlt": () => import('@fortawesome/pro-solid-svg-icons/faHomeAlt'),
        "faHomeHeart": () => import('@fortawesome/pro-solid-svg-icons/faHomeHeart'),
        "faHomeLg": () => import('@fortawesome/pro-solid-svg-icons/faHomeLg'),
        "faHomeLgAlt": () => import('@fortawesome/pro-solid-svg-icons/faHomeLgAlt'),
        "faHoodCloak": () => import('@fortawesome/pro-solid-svg-icons/faHoodCloak'),
        "faHorizontalRule": () => import('@fortawesome/pro-solid-svg-icons/faHorizontalRule'),
        "faHorse": () => import('@fortawesome/pro-solid-svg-icons/faHorse'),
        "faHorseHead": () => import('@fortawesome/pro-solid-svg-icons/faHorseHead'),
        "faHorseSaddle": () => import('@fortawesome/pro-solid-svg-icons/faHorseSaddle'),
        "faHospital": () => import('@fortawesome/pro-solid-svg-icons/faHospital'),
        "faHospitalAlt": () => import('@fortawesome/pro-solid-svg-icons/faHospitalAlt'),
        "faHospitalSymbol": () => import('@fortawesome/pro-solid-svg-icons/faHospitalSymbol'),
        "faHospitalUser": () => import('@fortawesome/pro-solid-svg-icons/faHospitalUser'),
        "faHospitals": () => import('@fortawesome/pro-solid-svg-icons/faHospitals'),
        "faHotTub": () => import('@fortawesome/pro-solid-svg-icons/faHotTub'),
        "faHotdog": () => import('@fortawesome/pro-solid-svg-icons/faHotdog'),
        "faHotel": () => import('@fortawesome/pro-solid-svg-icons/faHotel'),
        "faHourglass": () => import('@fortawesome/pro-solid-svg-icons/faHourglass'),
        "faHourglassEnd": () => import('@fortawesome/pro-solid-svg-icons/faHourglassEnd'),
        "faHourglassHalf": () => import('@fortawesome/pro-solid-svg-icons/faHourglassHalf'),
        "faHourglassStart": () => import('@fortawesome/pro-solid-svg-icons/faHourglassStart'),
        "faHouse": () => import('@fortawesome/pro-solid-svg-icons/faHouse'),
        "faHouseDamage": () => import('@fortawesome/pro-solid-svg-icons/faHouseDamage'),
        "faHouseDay": () => import('@fortawesome/pro-solid-svg-icons/faHouseDay'),
        "faHouseFlood": () => import('@fortawesome/pro-solid-svg-icons/faHouseFlood'),
        "faHouseLeave": () => import('@fortawesome/pro-solid-svg-icons/faHouseLeave'),
        "faHouseNight": () => import('@fortawesome/pro-solid-svg-icons/faHouseNight'),
        "faHouseReturn": () => import('@fortawesome/pro-solid-svg-icons/faHouseReturn'),
        "faHouseSignal": () => import('@fortawesome/pro-solid-svg-icons/faHouseSignal'),
        "faHouseUser": () => import('@fortawesome/pro-solid-svg-icons/faHouseUser'),
        "faHryvnia": () => import('@fortawesome/pro-solid-svg-icons/faHryvnia'),
        "faHumidity": () => import('@fortawesome/pro-solid-svg-icons/faHumidity'),
        "faHurricane": () => import('@fortawesome/pro-solid-svg-icons/faHurricane'),
        "faICursor": () => import('@fortawesome/pro-solid-svg-icons/faICursor'),
        "faIceCream": () => import('@fortawesome/pro-solid-svg-icons/faIceCream'),
        "faIceSkate": () => import('@fortawesome/pro-solid-svg-icons/faIceSkate'),
        "faIcicles": () => import('@fortawesome/pro-solid-svg-icons/faIcicles'),
        "faIcons": () => import('@fortawesome/pro-solid-svg-icons/faIcons'),
        "faIconsAlt": () => import('@fortawesome/pro-solid-svg-icons/faIconsAlt'),
        "faIdBadge": () => import('@fortawesome/pro-solid-svg-icons/faIdBadge'),
        "faIdCard": () => import('@fortawesome/pro-solid-svg-icons/faIdCard'),
        "faIdCardAlt": () => import('@fortawesome/pro-solid-svg-icons/faIdCardAlt'),
        "faIgloo": () => import('@fortawesome/pro-solid-svg-icons/faIgloo'),
        "faImage": () => import('@fortawesome/pro-solid-svg-icons/faImage'),
        "faImagePolaroid": () => import('@fortawesome/pro-solid-svg-icons/faImagePolaroid'),
        "faImages": () => import('@fortawesome/pro-solid-svg-icons/faImages'),
        "faInbox": () => import('@fortawesome/pro-solid-svg-icons/faInbox'),
        "faInboxIn": () => import('@fortawesome/pro-solid-svg-icons/faInboxIn'),
        "faInboxOut": () => import('@fortawesome/pro-solid-svg-icons/faInboxOut'),
        "faIndent": () => import('@fortawesome/pro-solid-svg-icons/faIndent'),
        "faIndustry": () => import('@fortawesome/pro-solid-svg-icons/faIndustry'),
        "faIndustryAlt": () => import('@fortawesome/pro-solid-svg-icons/faIndustryAlt'),
        "faInfinity": () => import('@fortawesome/pro-solid-svg-icons/faInfinity'),
        "faInfo": () => import('@fortawesome/pro-solid-svg-icons/faInfo'),
        "faInfoCircle": () => import('@fortawesome/pro-solid-svg-icons/faInfoCircle'),
        "faInfoSquare": () => import('@fortawesome/pro-solid-svg-icons/faInfoSquare'),
        "faInhaler": () => import('@fortawesome/pro-solid-svg-icons/faInhaler'),
        "faIntegral": () => import('@fortawesome/pro-solid-svg-icons/faIntegral'),
        "faIntersection": () => import('@fortawesome/pro-solid-svg-icons/faIntersection'),
        "faInventory": () => import('@fortawesome/pro-solid-svg-icons/faInventory'),
        "faIslandTropical": () => import('@fortawesome/pro-solid-svg-icons/faIslandTropical'),
        "faItalic": () => import('@fortawesome/pro-solid-svg-icons/faItalic'),
        "faJackOLantern": () => import('@fortawesome/pro-solid-svg-icons/faJackOLantern'),
        "faJedi": () => import('@fortawesome/pro-solid-svg-icons/faJedi'),
        "faJoint": () => import('@fortawesome/pro-solid-svg-icons/faJoint'),
        "faJournalWhills": () => import('@fortawesome/pro-solid-svg-icons/faJournalWhills'),
        "faJoystick": () => import('@fortawesome/pro-solid-svg-icons/faJoystick'),
        "faJug": () => import('@fortawesome/pro-solid-svg-icons/faJug'),
        "faKaaba": () => import('@fortawesome/pro-solid-svg-icons/faKaaba'),
        "faKazoo": () => import('@fortawesome/pro-solid-svg-icons/faKazoo'),
        "faKerning": () => import('@fortawesome/pro-solid-svg-icons/faKerning'),
        "faKey": () => import('@fortawesome/pro-solid-svg-icons/faKey'),
        "faKeySkeleton": () => import('@fortawesome/pro-solid-svg-icons/faKeySkeleton'),
        "faKeyboard": () => import('@fortawesome/pro-solid-svg-icons/faKeyboard'),
        "faKeynote": () => import('@fortawesome/pro-solid-svg-icons/faKeynote'),
        "faKhanda": () => import('@fortawesome/pro-solid-svg-icons/faKhanda'),
        "faKidneys": () => import('@fortawesome/pro-solid-svg-icons/faKidneys'),
        "faKiss": () => import('@fortawesome/pro-solid-svg-icons/faKiss'),
        "faKissBeam": () => import('@fortawesome/pro-solid-svg-icons/faKissBeam'),
        "faKissWinkHeart": () => import('@fortawesome/pro-solid-svg-icons/faKissWinkHeart'),
        "faKite": () => import('@fortawesome/pro-solid-svg-icons/faKite'),
        "faKiwiBird": () => import('@fortawesome/pro-solid-svg-icons/faKiwiBird'),
        "faKnifeKitchen": () => import('@fortawesome/pro-solid-svg-icons/faKnifeKitchen'),
        "faLambda": () => import('@fortawesome/pro-solid-svg-icons/faLambda'),
        "faLamp": () => import('@fortawesome/pro-solid-svg-icons/faLamp'),
        "faLampDesk": () => import('@fortawesome/pro-solid-svg-icons/faLampDesk'),
        "faLampFloor": () => import('@fortawesome/pro-solid-svg-icons/faLampFloor'),
        "faLandmark": () => import('@fortawesome/pro-solid-svg-icons/faLandmark'),
        "faLandmarkAlt": () => import('@fortawesome/pro-solid-svg-icons/faLandmarkAlt'),
        "faLanguage": () => import('@fortawesome/pro-solid-svg-icons/faLanguage'),
        "faLaptop": () => import('@fortawesome/pro-solid-svg-icons/faLaptop'),
        "faLaptopCode": () => import('@fortawesome/pro-solid-svg-icons/faLaptopCode'),
        "faLaptopHouse": () => import('@fortawesome/pro-solid-svg-icons/faLaptopHouse'),
        "faLaptopMedical": () => import('@fortawesome/pro-solid-svg-icons/faLaptopMedical'),
        "faLasso": () => import('@fortawesome/pro-solid-svg-icons/faLasso'),
        "faLaugh": () => import('@fortawesome/pro-solid-svg-icons/faLaugh'),
        "faLaughBeam": () => import('@fortawesome/pro-solid-svg-icons/faLaughBeam'),
        "faLaughSquint": () => import('@fortawesome/pro-solid-svg-icons/faLaughSquint'),
        "faLaughWink": () => import('@fortawesome/pro-solid-svg-icons/faLaughWink'),
        "faLayerGroup": () => import('@fortawesome/pro-solid-svg-icons/faLayerGroup'),
        "faLayerMinus": () => import('@fortawesome/pro-solid-svg-icons/faLayerMinus'),
        "faLayerPlus": () => import('@fortawesome/pro-solid-svg-icons/faLayerPlus'),
        "faLeaf": () => import('@fortawesome/pro-solid-svg-icons/faLeaf'),
        "faLeafHeart": () => import('@fortawesome/pro-solid-svg-icons/faLeafHeart'),
        "faLeafMaple": () => import('@fortawesome/pro-solid-svg-icons/faLeafMaple'),
        "faLeafOak": () => import('@fortawesome/pro-solid-svg-icons/faLeafOak'),
        "faLemon": () => import('@fortawesome/pro-solid-svg-icons/faLemon'),
        "faLessThan": () => import('@fortawesome/pro-solid-svg-icons/faLessThan'),
        "faLessThanEqual": () => import('@fortawesome/pro-solid-svg-icons/faLessThanEqual'),
        "faLevelDown": () => import('@fortawesome/pro-solid-svg-icons/faLevelDown'),
        "faLevelDownAlt": () => import('@fortawesome/pro-solid-svg-icons/faLevelDownAlt'),
        "faLevelUp": () => import('@fortawesome/pro-solid-svg-icons/faLevelUp'),
        "faLevelUpAlt": () => import('@fortawesome/pro-solid-svg-icons/faLevelUpAlt'),
        "faLifeRing": () => import('@fortawesome/pro-solid-svg-icons/faLifeRing'),
        "faLightCeiling": () => import('@fortawesome/pro-solid-svg-icons/faLightCeiling'),
        "faLightSwitch": () => import('@fortawesome/pro-solid-svg-icons/faLightSwitch'),
        "faLightSwitchOff": () => import('@fortawesome/pro-solid-svg-icons/faLightSwitchOff'),
        "faLightSwitchOn": () => import('@fortawesome/pro-solid-svg-icons/faLightSwitchOn'),
        "faLightbulb": () => import('@fortawesome/pro-solid-svg-icons/faLightbulb'),
        "faLightbulbDollar": () => import('@fortawesome/pro-solid-svg-icons/faLightbulbDollar'),
        "faLightbulbExclamation": () => import('@fortawesome/pro-solid-svg-icons/faLightbulbExclamation'),
        "faLightbulbOn": () => import('@fortawesome/pro-solid-svg-icons/faLightbulbOn'),
        "faLightbulbSlash": () => import('@fortawesome/pro-solid-svg-icons/faLightbulbSlash'),
        "faLightsHoliday": () => import('@fortawesome/pro-solid-svg-icons/faLightsHoliday'),
        "faLineColumns": () => import('@fortawesome/pro-solid-svg-icons/faLineColumns'),
        "faLineHeight": () => import('@fortawesome/pro-solid-svg-icons/faLineHeight'),
        "faLink": () => import('@fortawesome/pro-solid-svg-icons/faLink'),
        "faLips": () => import('@fortawesome/pro-solid-svg-icons/faLips'),
        "faLiraSign": () => import('@fortawesome/pro-solid-svg-icons/faLiraSign'),
        "faList": () => import('@fortawesome/pro-solid-svg-icons/faList'),
        "faListAlt": () => import('@fortawesome/pro-solid-svg-icons/faListAlt'),
        "faListMusic": () => import('@fortawesome/pro-solid-svg-icons/faListMusic'),
        "faListOl": () => import('@fortawesome/pro-solid-svg-icons/faListOl'),
        "faListUl": () => import('@fortawesome/pro-solid-svg-icons/faListUl'),
        "faLocation": () => import('@fortawesome/pro-solid-svg-icons/faLocation'),
        "faLocationArrow": () => import('@fortawesome/pro-solid-svg-icons/faLocationArrow'),
        "faLocationCircle": () => import('@fortawesome/pro-solid-svg-icons/faLocationCircle'),
        "faLocationSlash": () => import('@fortawesome/pro-solid-svg-icons/faLocationSlash'),
        "faLock": () => import('@fortawesome/pro-solid-svg-icons/faLock'),
        "faLockAlt": () => import('@fortawesome/pro-solid-svg-icons/faLockAlt'),
        "faLockOpen": () => import('@fortawesome/pro-solid-svg-icons/faLockOpen'),
        "faLockOpenAlt": () => import('@fortawesome/pro-solid-svg-icons/faLockOpenAlt'),
        "faLongArrowAltDown": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowAltDown'),
        "faLongArrowAltLeft": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowAltLeft'),
        "faLongArrowAltRight": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowAltRight'),
        "faLongArrowAltUp": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowAltUp'),
        "faLongArrowDown": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowDown'),
        "faLongArrowLeft": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowLeft'),
        "faLongArrowRight": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowRight'),
        "faLongArrowUp": () => import('@fortawesome/pro-solid-svg-icons/faLongArrowUp'),
        "faLoveseat": () => import('@fortawesome/pro-solid-svg-icons/faLoveseat'),
        "faLowVision": () => import('@fortawesome/pro-solid-svg-icons/faLowVision'),
        "faLuchador": () => import('@fortawesome/pro-solid-svg-icons/faLuchador'),
        "faLuggageCart": () => import('@fortawesome/pro-solid-svg-icons/faLuggageCart'),
        "faLungs": () => import('@fortawesome/pro-solid-svg-icons/faLungs'),
        "faLungsVirus": () => import('@fortawesome/pro-solid-svg-icons/faLungsVirus'),
        "faMace": () => import('@fortawesome/pro-solid-svg-icons/faMace'),
        "faMagic": () => import('@fortawesome/pro-solid-svg-icons/faMagic'),
        "faMagnet": () => import('@fortawesome/pro-solid-svg-icons/faMagnet'),
        "faMailBulk": () => import('@fortawesome/pro-solid-svg-icons/faMailBulk'),
        "faMailbox": () => import('@fortawesome/pro-solid-svg-icons/faMailbox'),
        "faMale": () => import('@fortawesome/pro-solid-svg-icons/faMale'),
        "faMandolin": () => import('@fortawesome/pro-solid-svg-icons/faMandolin'),
        "faMap": () => import('@fortawesome/pro-solid-svg-icons/faMap'),
        "faMapMarked": () => import('@fortawesome/pro-solid-svg-icons/faMapMarked'),
        "faMapMarkedAlt": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkedAlt'),
        "faMapMarker": () => import('@fortawesome/pro-solid-svg-icons/faMapMarker'),
        "faMapMarkerAlt": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'),
        "faMapMarkerAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerAltSlash'),
        "faMapMarkerCheck": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerCheck'),
        "faMapMarkerEdit": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerEdit'),
        "faMapMarkerExclamation": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerExclamation'),
        "faMapMarkerMinus": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerMinus'),
        "faMapMarkerPlus": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerPlus'),
        "faMapMarkerQuestion": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerQuestion'),
        "faMapMarkerSlash": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerSlash'),
        "faMapMarkerSmile": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerSmile'),
        "faMapMarkerTimes": () => import('@fortawesome/pro-solid-svg-icons/faMapMarkerTimes'),
        "faMapPin": () => import('@fortawesome/pro-solid-svg-icons/faMapPin'),
        "faMapSigns": () => import('@fortawesome/pro-solid-svg-icons/faMapSigns'),
        "faMarker": () => import('@fortawesome/pro-solid-svg-icons/faMarker'),
        "faMars": () => import('@fortawesome/pro-solid-svg-icons/faMars'),
        "faMarsDouble": () => import('@fortawesome/pro-solid-svg-icons/faMarsDouble'),
        "faMarsStroke": () => import('@fortawesome/pro-solid-svg-icons/faMarsStroke'),
        "faMarsStrokeH": () => import('@fortawesome/pro-solid-svg-icons/faMarsStrokeH'),
        "faMarsStrokeV": () => import('@fortawesome/pro-solid-svg-icons/faMarsStrokeV'),
        "faMask": () => import('@fortawesome/pro-solid-svg-icons/faMask'),
        "faMeat": () => import('@fortawesome/pro-solid-svg-icons/faMeat'),
        "faMedal": () => import('@fortawesome/pro-solid-svg-icons/faMedal'),
        "faMedkit": () => import('@fortawesome/pro-solid-svg-icons/faMedkit'),
        "faMegaphone": () => import('@fortawesome/pro-solid-svg-icons/faMegaphone'),
        "faMeh": () => import('@fortawesome/pro-solid-svg-icons/faMeh'),
        "faMehBlank": () => import('@fortawesome/pro-solid-svg-icons/faMehBlank'),
        "faMehRollingEyes": () => import('@fortawesome/pro-solid-svg-icons/faMehRollingEyes'),
        "faMemory": () => import('@fortawesome/pro-solid-svg-icons/faMemory'),
        "faMenorah": () => import('@fortawesome/pro-solid-svg-icons/faMenorah'),
        "faMercury": () => import('@fortawesome/pro-solid-svg-icons/faMercury'),
        "faMeteor": () => import('@fortawesome/pro-solid-svg-icons/faMeteor'),
        "faMicrochip": () => import('@fortawesome/pro-solid-svg-icons/faMicrochip'),
        "faMicrophone": () => import('@fortawesome/pro-solid-svg-icons/faMicrophone'),
        "faMicrophoneAlt": () => import('@fortawesome/pro-solid-svg-icons/faMicrophoneAlt'),
        "faMicrophoneAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faMicrophoneAltSlash'),
        "faMicrophoneSlash": () => import('@fortawesome/pro-solid-svg-icons/faMicrophoneSlash'),
        "faMicrophoneStand": () => import('@fortawesome/pro-solid-svg-icons/faMicrophoneStand'),
        "faMicroscope": () => import('@fortawesome/pro-solid-svg-icons/faMicroscope'),
        "faMicrowave": () => import('@fortawesome/pro-solid-svg-icons/faMicrowave'),
        "faMindShare": () => import('@fortawesome/pro-solid-svg-icons/faMindShare'),
        "faMinus": () => import('@fortawesome/pro-solid-svg-icons/faMinus'),
        "faMinusCircle": () => import('@fortawesome/pro-solid-svg-icons/faMinusCircle'),
        "faMinusHexagon": () => import('@fortawesome/pro-solid-svg-icons/faMinusHexagon'),
        "faMinusOctagon": () => import('@fortawesome/pro-solid-svg-icons/faMinusOctagon'),
        "faMinusSquare": () => import('@fortawesome/pro-solid-svg-icons/faMinusSquare'),
        "faMistletoe": () => import('@fortawesome/pro-solid-svg-icons/faMistletoe'),
        "faMitten": () => import('@fortawesome/pro-solid-svg-icons/faMitten'),
        "faMobile": () => import('@fortawesome/pro-solid-svg-icons/faMobile'),
        "faMobileAlt": () => import('@fortawesome/pro-solid-svg-icons/faMobileAlt'),
        "faMobileAndroid": () => import('@fortawesome/pro-solid-svg-icons/faMobileAndroid'),
        "faMobileAndroidAlt": () => import('@fortawesome/pro-solid-svg-icons/faMobileAndroidAlt'),
        "faMoneyBill": () => import('@fortawesome/pro-solid-svg-icons/faMoneyBill'),
        "faMoneyBillAlt": () => import('@fortawesome/pro-solid-svg-icons/faMoneyBillAlt'),
        "faMoneyBillWave": () => import('@fortawesome/pro-solid-svg-icons/faMoneyBillWave'),
        "faMoneyBillWaveAlt": () => import('@fortawesome/pro-solid-svg-icons/faMoneyBillWaveAlt'),
        "faMoneyCheck": () => import('@fortawesome/pro-solid-svg-icons/faMoneyCheck'),
        "faMoneyCheckAlt": () => import('@fortawesome/pro-solid-svg-icons/faMoneyCheckAlt'),
        "faMoneyCheckEdit": () => import('@fortawesome/pro-solid-svg-icons/faMoneyCheckEdit'),
        "faMoneyCheckEditAlt": () => import('@fortawesome/pro-solid-svg-icons/faMoneyCheckEditAlt'),
        "faMonitorHeartRate": () => import('@fortawesome/pro-solid-svg-icons/faMonitorHeartRate'),
        "faMonkey": () => import('@fortawesome/pro-solid-svg-icons/faMonkey'),
        "faMonument": () => import('@fortawesome/pro-solid-svg-icons/faMonument'),
        "faMoon": () => import('@fortawesome/pro-solid-svg-icons/faMoon'),
        "faMoonCloud": () => import('@fortawesome/pro-solid-svg-icons/faMoonCloud'),
        "faMoonStars": () => import('@fortawesome/pro-solid-svg-icons/faMoonStars'),
        "faMortarPestle": () => import('@fortawesome/pro-solid-svg-icons/faMortarPestle'),
        "faMosque": () => import('@fortawesome/pro-solid-svg-icons/faMosque'),
        "faMotorcycle": () => import('@fortawesome/pro-solid-svg-icons/faMotorcycle'),
        "faMountain": () => import('@fortawesome/pro-solid-svg-icons/faMountain'),
        "faMountains": () => import('@fortawesome/pro-solid-svg-icons/faMountains'),
        "faMouse": () => import('@fortawesome/pro-solid-svg-icons/faMouse'),
        "faMouseAlt": () => import('@fortawesome/pro-solid-svg-icons/faMouseAlt'),
        "faMousePointer": () => import('@fortawesome/pro-solid-svg-icons/faMousePointer'),
        "faMp3Player": () => import('@fortawesome/pro-solid-svg-icons/faMp3Player'),
        "faMug": () => import('@fortawesome/pro-solid-svg-icons/faMug'),
        "faMugHot": () => import('@fortawesome/pro-solid-svg-icons/faMugHot'),
        "faMugMarshmallows": () => import('@fortawesome/pro-solid-svg-icons/faMugMarshmallows'),
        "faMugTea": () => import('@fortawesome/pro-solid-svg-icons/faMugTea'),
        "faMusic": () => import('@fortawesome/pro-solid-svg-icons/faMusic'),
        "faMusicAlt": () => import('@fortawesome/pro-solid-svg-icons/faMusicAlt'),
        "faMusicAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faMusicAltSlash'),
        "faMusicSlash": () => import('@fortawesome/pro-solid-svg-icons/faMusicSlash'),
        "faNarwhal": () => import('@fortawesome/pro-solid-svg-icons/faNarwhal'),
        "faNetworkWired": () => import('@fortawesome/pro-solid-svg-icons/faNetworkWired'),
        "faNeuter": () => import('@fortawesome/pro-solid-svg-icons/faNeuter'),
        "faNewspaper": () => import('@fortawesome/pro-solid-svg-icons/faNewspaper'),
        "faNotEqual": () => import('@fortawesome/pro-solid-svg-icons/faNotEqual'),
        "faNotesMedical": () => import('@fortawesome/pro-solid-svg-icons/faNotesMedical'),
        "faObjectGroup": () => import('@fortawesome/pro-solid-svg-icons/faObjectGroup'),
        "faObjectUngroup": () => import('@fortawesome/pro-solid-svg-icons/faObjectUngroup'),
        "faOctagon": () => import('@fortawesome/pro-solid-svg-icons/faOctagon'),
        "faOilCan": () => import('@fortawesome/pro-solid-svg-icons/faOilCan'),
        "faOilTemp": () => import('@fortawesome/pro-solid-svg-icons/faOilTemp'),
        "faOm": () => import('@fortawesome/pro-solid-svg-icons/faOm'),
        "faOmega": () => import('@fortawesome/pro-solid-svg-icons/faOmega'),
        "faOrnament": () => import('@fortawesome/pro-solid-svg-icons/faOrnament'),
        "faOtter": () => import('@fortawesome/pro-solid-svg-icons/faOtter'),
        "faOutdent": () => import('@fortawesome/pro-solid-svg-icons/faOutdent'),
        "faOutlet": () => import('@fortawesome/pro-solid-svg-icons/faOutlet'),
        "faOven": () => import('@fortawesome/pro-solid-svg-icons/faOven'),
        "faOverline": () => import('@fortawesome/pro-solid-svg-icons/faOverline'),
        "faPageBreak": () => import('@fortawesome/pro-solid-svg-icons/faPageBreak'),
        "faPager": () => import('@fortawesome/pro-solid-svg-icons/faPager'),
        "faPaintBrush": () => import('@fortawesome/pro-solid-svg-icons/faPaintBrush'),
        "faPaintBrushAlt": () => import('@fortawesome/pro-solid-svg-icons/faPaintBrushAlt'),
        "faPaintRoller": () => import('@fortawesome/pro-solid-svg-icons/faPaintRoller'),
        "faPalette": () => import('@fortawesome/pro-solid-svg-icons/faPalette'),
        "faPallet": () => import('@fortawesome/pro-solid-svg-icons/faPallet'),
        "faPalletAlt": () => import('@fortawesome/pro-solid-svg-icons/faPalletAlt'),
        "faPaperPlane": () => import('@fortawesome/pro-solid-svg-icons/faPaperPlane'),
        "faPaperclip": () => import('@fortawesome/pro-solid-svg-icons/faPaperclip'),
        "faParachuteBox": () => import('@fortawesome/pro-solid-svg-icons/faParachuteBox'),
        "faParagraph": () => import('@fortawesome/pro-solid-svg-icons/faParagraph'),
        "faParagraphRtl": () => import('@fortawesome/pro-solid-svg-icons/faParagraphRtl'),
        "faParking": () => import('@fortawesome/pro-solid-svg-icons/faParking'),
        "faParkingCircle": () => import('@fortawesome/pro-solid-svg-icons/faParkingCircle'),
        "faParkingCircleSlash": () => import('@fortawesome/pro-solid-svg-icons/faParkingCircleSlash'),
        "faParkingSlash": () => import('@fortawesome/pro-solid-svg-icons/faParkingSlash'),
        "faPassport": () => import('@fortawesome/pro-solid-svg-icons/faPassport'),
        "faPastafarianism": () => import('@fortawesome/pro-solid-svg-icons/faPastafarianism'),
        "faPaste": () => import('@fortawesome/pro-solid-svg-icons/faPaste'),
        "faPause": () => import('@fortawesome/pro-solid-svg-icons/faPause'),
        "faPauseCircle": () => import('@fortawesome/pro-solid-svg-icons/faPauseCircle'),
        "faPaw": () => import('@fortawesome/pro-solid-svg-icons/faPaw'),
        "faPawAlt": () => import('@fortawesome/pro-solid-svg-icons/faPawAlt'),
        "faPawClaws": () => import('@fortawesome/pro-solid-svg-icons/faPawClaws'),
        "faPeace": () => import('@fortawesome/pro-solid-svg-icons/faPeace'),
        "faPegasus": () => import('@fortawesome/pro-solid-svg-icons/faPegasus'),
        "faPen": () => import('@fortawesome/pro-solid-svg-icons/faPen'),
        "faPenAlt": () => import('@fortawesome/pro-solid-svg-icons/faPenAlt'),
        "faPenFancy": () => import('@fortawesome/pro-solid-svg-icons/faPenFancy'),
        "faPenNib": () => import('@fortawesome/pro-solid-svg-icons/faPenNib'),
        "faPenSquare": () => import('@fortawesome/pro-solid-svg-icons/faPenSquare'),
        "faPencil": () => import('@fortawesome/pro-solid-svg-icons/faPencil'),
        "faPencilAlt": () => import('@fortawesome/pro-solid-svg-icons/faPencilAlt'),
        "faPencilPaintbrush": () => import('@fortawesome/pro-solid-svg-icons/faPencilPaintbrush'),
        "faPencilRuler": () => import('@fortawesome/pro-solid-svg-icons/faPencilRuler'),
        "faPennant": () => import('@fortawesome/pro-solid-svg-icons/faPennant'),
        "faPeopleArrows": () => import('@fortawesome/pro-solid-svg-icons/faPeopleArrows'),
        "faPeopleCarry": () => import('@fortawesome/pro-solid-svg-icons/faPeopleCarry'),
        "faPepperHot": () => import('@fortawesome/pro-solid-svg-icons/faPepperHot'),
        "faPercent": () => import('@fortawesome/pro-solid-svg-icons/faPercent'),
        "faPercentage": () => import('@fortawesome/pro-solid-svg-icons/faPercentage'),
        "faPersonBooth": () => import('@fortawesome/pro-solid-svg-icons/faPersonBooth'),
        "faPersonCarry": () => import('@fortawesome/pro-solid-svg-icons/faPersonCarry'),
        "faPersonDolly": () => import('@fortawesome/pro-solid-svg-icons/faPersonDolly'),
        "faPersonDollyEmpty": () => import('@fortawesome/pro-solid-svg-icons/faPersonDollyEmpty'),
        "faPersonSign": () => import('@fortawesome/pro-solid-svg-icons/faPersonSign'),
        "faPhone": () => import('@fortawesome/pro-solid-svg-icons/faPhone'),
        "faPhoneAlt": () => import('@fortawesome/pro-solid-svg-icons/faPhoneAlt'),
        "faPhoneLaptop": () => import('@fortawesome/pro-solid-svg-icons/faPhoneLaptop'),
        "faPhoneOffice": () => import('@fortawesome/pro-solid-svg-icons/faPhoneOffice'),
        "faPhonePlus": () => import('@fortawesome/pro-solid-svg-icons/faPhonePlus'),
        "faPhoneRotary": () => import('@fortawesome/pro-solid-svg-icons/faPhoneRotary'),
        "faPhoneSlash": () => import('@fortawesome/pro-solid-svg-icons/faPhoneSlash'),
        "faPhoneSquare": () => import('@fortawesome/pro-solid-svg-icons/faPhoneSquare'),
        "faPhoneSquareAlt": () => import('@fortawesome/pro-solid-svg-icons/faPhoneSquareAlt'),
        "faPhoneVolume": () => import('@fortawesome/pro-solid-svg-icons/faPhoneVolume'),
        "faPhotoVideo": () => import('@fortawesome/pro-solid-svg-icons/faPhotoVideo'),
        "faPi": () => import('@fortawesome/pro-solid-svg-icons/faPi'),
        "faPiano": () => import('@fortawesome/pro-solid-svg-icons/faPiano'),
        "faPianoKeyboard": () => import('@fortawesome/pro-solid-svg-icons/faPianoKeyboard'),
        "faPie": () => import('@fortawesome/pro-solid-svg-icons/faPie'),
        "faPig": () => import('@fortawesome/pro-solid-svg-icons/faPig'),
        "faPiggyBank": () => import('@fortawesome/pro-solid-svg-icons/faPiggyBank'),
        "faPills": () => import('@fortawesome/pro-solid-svg-icons/faPills'),
        "faPizza": () => import('@fortawesome/pro-solid-svg-icons/faPizza'),
        "faPizzaSlice": () => import('@fortawesome/pro-solid-svg-icons/faPizzaSlice'),
        "faPlaceOfWorship": () => import('@fortawesome/pro-solid-svg-icons/faPlaceOfWorship'),
        "faPlane": () => import('@fortawesome/pro-solid-svg-icons/faPlane'),
        "faPlaneAlt": () => import('@fortawesome/pro-solid-svg-icons/faPlaneAlt'),
        "faPlaneArrival": () => import('@fortawesome/pro-solid-svg-icons/faPlaneArrival'),
        "faPlaneDeparture": () => import('@fortawesome/pro-solid-svg-icons/faPlaneDeparture'),
        "faPlaneSlash": () => import('@fortawesome/pro-solid-svg-icons/faPlaneSlash'),
        "faPlanetMoon": () => import('@fortawesome/pro-solid-svg-icons/faPlanetMoon'),
        "faPlanetRinged": () => import('@fortawesome/pro-solid-svg-icons/faPlanetRinged'),
        "faPlay": () => import('@fortawesome/pro-solid-svg-icons/faPlay'),
        "faPlayCircle": () => import('@fortawesome/pro-solid-svg-icons/faPlayCircle'),
        "faPlug": () => import('@fortawesome/pro-solid-svg-icons/faPlug'),
        "faPlus": () => import('@fortawesome/pro-solid-svg-icons/faPlus'),
        "faPlusCircle": () => import('@fortawesome/pro-solid-svg-icons/faPlusCircle'),
        "faPlusHexagon": () => import('@fortawesome/pro-solid-svg-icons/faPlusHexagon'),
        "faPlusOctagon": () => import('@fortawesome/pro-solid-svg-icons/faPlusOctagon'),
        "faPlusSquare": () => import('@fortawesome/pro-solid-svg-icons/faPlusSquare'),
        "faPodcast": () => import('@fortawesome/pro-solid-svg-icons/faPodcast'),
        "faPodium": () => import('@fortawesome/pro-solid-svg-icons/faPodium'),
        "faPodiumStar": () => import('@fortawesome/pro-solid-svg-icons/faPodiumStar'),
        "faPoliceBox": () => import('@fortawesome/pro-solid-svg-icons/faPoliceBox'),
        "faPoll": () => import('@fortawesome/pro-solid-svg-icons/faPoll'),
        "faPollH": () => import('@fortawesome/pro-solid-svg-icons/faPollH'),
        "faPollPeople": () => import('@fortawesome/pro-solid-svg-icons/faPollPeople'),
        "faPoo": () => import('@fortawesome/pro-solid-svg-icons/faPoo'),
        "faPooStorm": () => import('@fortawesome/pro-solid-svg-icons/faPooStorm'),
        "faPoop": () => import('@fortawesome/pro-solid-svg-icons/faPoop'),
        "faPopcorn": () => import('@fortawesome/pro-solid-svg-icons/faPopcorn'),
        "faPortalEnter": () => import('@fortawesome/pro-solid-svg-icons/faPortalEnter'),
        "faPortalExit": () => import('@fortawesome/pro-solid-svg-icons/faPortalExit'),
        "faPortrait": () => import('@fortawesome/pro-solid-svg-icons/faPortrait'),
        "faPoundSign": () => import('@fortawesome/pro-solid-svg-icons/faPoundSign'),
        "faPowerOff": () => import('@fortawesome/pro-solid-svg-icons/faPowerOff'),
        "faPray": () => import('@fortawesome/pro-solid-svg-icons/faPray'),
        "faPrayingHands": () => import('@fortawesome/pro-solid-svg-icons/faPrayingHands'),
        "faPrescription": () => import('@fortawesome/pro-solid-svg-icons/faPrescription'),
        "faPrescriptionBottle": () => import('@fortawesome/pro-solid-svg-icons/faPrescriptionBottle'),
        "faPrescriptionBottleAlt": () => import('@fortawesome/pro-solid-svg-icons/faPrescriptionBottleAlt'),
        "faPresentation": () => import('@fortawesome/pro-solid-svg-icons/faPresentation'),
        "faPrint": () => import('@fortawesome/pro-solid-svg-icons/faPrint'),
        "faPrintSearch": () => import('@fortawesome/pro-solid-svg-icons/faPrintSearch'),
        "faPrintSlash": () => import('@fortawesome/pro-solid-svg-icons/faPrintSlash'),
        "faProcedures": () => import('@fortawesome/pro-solid-svg-icons/faProcedures'),
        "faProjectDiagram": () => import('@fortawesome/pro-solid-svg-icons/faProjectDiagram'),
        "faProjector": () => import('@fortawesome/pro-solid-svg-icons/faProjector'),
        "faPumpMedical": () => import('@fortawesome/pro-solid-svg-icons/faPumpMedical'),
        "faPumpSoap": () => import('@fortawesome/pro-solid-svg-icons/faPumpSoap'),
        "faPumpkin": () => import('@fortawesome/pro-solid-svg-icons/faPumpkin'),
        "faPuzzlePiece": () => import('@fortawesome/pro-solid-svg-icons/faPuzzlePiece'),
        "faQrcode": () => import('@fortawesome/pro-solid-svg-icons/faQrcode'),
        "faQuestion": () => import('@fortawesome/pro-solid-svg-icons/faQuestion'),
        "faQuestionCircle": () => import('@fortawesome/pro-solid-svg-icons/faQuestionCircle'),
        "faQuestionSquare": () => import('@fortawesome/pro-solid-svg-icons/faQuestionSquare'),
        "faQuidditch": () => import('@fortawesome/pro-solid-svg-icons/faQuidditch'),
        "faQuoteLeft": () => import('@fortawesome/pro-solid-svg-icons/faQuoteLeft'),
        "faQuoteRight": () => import('@fortawesome/pro-solid-svg-icons/faQuoteRight'),
        "faQuran": () => import('@fortawesome/pro-solid-svg-icons/faQuran'),
        "faRabbit": () => import('@fortawesome/pro-solid-svg-icons/faRabbit'),
        "faRabbitFast": () => import('@fortawesome/pro-solid-svg-icons/faRabbitFast'),
        "faRacquet": () => import('@fortawesome/pro-solid-svg-icons/faRacquet'),
        "faRadar": () => import('@fortawesome/pro-solid-svg-icons/faRadar'),
        "faRadiation": () => import('@fortawesome/pro-solid-svg-icons/faRadiation'),
        "faRadiationAlt": () => import('@fortawesome/pro-solid-svg-icons/faRadiationAlt'),
        "faRadio": () => import('@fortawesome/pro-solid-svg-icons/faRadio'),
        "faRadioAlt": () => import('@fortawesome/pro-solid-svg-icons/faRadioAlt'),
        "faRainbow": () => import('@fortawesome/pro-solid-svg-icons/faRainbow'),
        "faRaindrops": () => import('@fortawesome/pro-solid-svg-icons/faRaindrops'),
        "faRam": () => import('@fortawesome/pro-solid-svg-icons/faRam'),
        "faRampLoading": () => import('@fortawesome/pro-solid-svg-icons/faRampLoading'),
        "faRandom": () => import('@fortawesome/pro-solid-svg-icons/faRandom'),
        "faRaygun": () => import('@fortawesome/pro-solid-svg-icons/faRaygun'),
        "faReceipt": () => import('@fortawesome/pro-solid-svg-icons/faReceipt'),
        "faRecordVinyl": () => import('@fortawesome/pro-solid-svg-icons/faRecordVinyl'),
        "faRectangleLandscape": () => import('@fortawesome/pro-solid-svg-icons/faRectangleLandscape'),
        "faRectanglePortrait": () => import('@fortawesome/pro-solid-svg-icons/faRectanglePortrait'),
        "faRectangleWide": () => import('@fortawesome/pro-solid-svg-icons/faRectangleWide'),
        "faRecycle": () => import('@fortawesome/pro-solid-svg-icons/faRecycle'),
        "faRedo": () => import('@fortawesome/pro-solid-svg-icons/faRedo'),
        "faRedoAlt": () => import('@fortawesome/pro-solid-svg-icons/faRedoAlt'),
        "faRefrigerator": () => import('@fortawesome/pro-solid-svg-icons/faRefrigerator'),
        "faRegistered": () => import('@fortawesome/pro-solid-svg-icons/faRegistered'),
        "faRemoveFormat": () => import('@fortawesome/pro-solid-svg-icons/faRemoveFormat'),
        "faRepeat": () => import('@fortawesome/pro-solid-svg-icons/faRepeat'),
        "faRepeat1": () => import('@fortawesome/pro-solid-svg-icons/faRepeat1'),
        "faRepeat1Alt": () => import('@fortawesome/pro-solid-svg-icons/faRepeat1Alt'),
        "faRepeatAlt": () => import('@fortawesome/pro-solid-svg-icons/faRepeatAlt'),
        "faReply": () => import('@fortawesome/pro-solid-svg-icons/faReply'),
        "faReplyAll": () => import('@fortawesome/pro-solid-svg-icons/faReplyAll'),
        "faRepublican": () => import('@fortawesome/pro-solid-svg-icons/faRepublican'),
        "faRestroom": () => import('@fortawesome/pro-solid-svg-icons/faRestroom'),
        "faRetweet": () => import('@fortawesome/pro-solid-svg-icons/faRetweet'),
        "faRetweetAlt": () => import('@fortawesome/pro-solid-svg-icons/faRetweetAlt'),
        "faRibbon": () => import('@fortawesome/pro-solid-svg-icons/faRibbon'),
        "faRing": () => import('@fortawesome/pro-solid-svg-icons/faRing'),
        "faRingsWedding": () => import('@fortawesome/pro-solid-svg-icons/faRingsWedding'),
        "faRoad": () => import('@fortawesome/pro-solid-svg-icons/faRoad'),
        "faRobot": () => import('@fortawesome/pro-solid-svg-icons/faRobot'),
        "faRocket": () => import('@fortawesome/pro-solid-svg-icons/faRocket'),
        "faRocketLaunch": () => import('@fortawesome/pro-solid-svg-icons/faRocketLaunch'),
        "faRoute": () => import('@fortawesome/pro-solid-svg-icons/faRoute'),
        "faRouteHighway": () => import('@fortawesome/pro-solid-svg-icons/faRouteHighway'),
        "faRouteInterstate": () => import('@fortawesome/pro-solid-svg-icons/faRouteInterstate'),
        "faRouter": () => import('@fortawesome/pro-solid-svg-icons/faRouter'),
        "faRss": () => import('@fortawesome/pro-solid-svg-icons/faRss'),
        "faRssSquare": () => import('@fortawesome/pro-solid-svg-icons/faRssSquare'),
        "faRubleSign": () => import('@fortawesome/pro-solid-svg-icons/faRubleSign'),
        "faRuler": () => import('@fortawesome/pro-solid-svg-icons/faRuler'),
        "faRulerCombined": () => import('@fortawesome/pro-solid-svg-icons/faRulerCombined'),
        "faRulerHorizontal": () => import('@fortawesome/pro-solid-svg-icons/faRulerHorizontal'),
        "faRulerTriangle": () => import('@fortawesome/pro-solid-svg-icons/faRulerTriangle'),
        "faRulerVertical": () => import('@fortawesome/pro-solid-svg-icons/faRulerVertical'),
        "faRunning": () => import('@fortawesome/pro-solid-svg-icons/faRunning'),
        "faRupeeSign": () => import('@fortawesome/pro-solid-svg-icons/faRupeeSign'),
        "faRv": () => import('@fortawesome/pro-solid-svg-icons/faRv'),
        "faSack": () => import('@fortawesome/pro-solid-svg-icons/faSack'),
        "faSackDollar": () => import('@fortawesome/pro-solid-svg-icons/faSackDollar'),
        "faSadCry": () => import('@fortawesome/pro-solid-svg-icons/faSadCry'),
        "faSadTear": () => import('@fortawesome/pro-solid-svg-icons/faSadTear'),
        "faSalad": () => import('@fortawesome/pro-solid-svg-icons/faSalad'),
        "faSandwich": () => import('@fortawesome/pro-solid-svg-icons/faSandwich'),
        "faSatellite": () => import('@fortawesome/pro-solid-svg-icons/faSatellite'),
        "faSatelliteDish": () => import('@fortawesome/pro-solid-svg-icons/faSatelliteDish'),
        "faSausage": () => import('@fortawesome/pro-solid-svg-icons/faSausage'),
        "faSave": () => import('@fortawesome/pro-solid-svg-icons/faSave'),
        "faSaxHot": () => import('@fortawesome/pro-solid-svg-icons/faSaxHot'),
        "faSaxophone": () => import('@fortawesome/pro-solid-svg-icons/faSaxophone'),
        "faScalpel": () => import('@fortawesome/pro-solid-svg-icons/faScalpel'),
        "faScalpelPath": () => import('@fortawesome/pro-solid-svg-icons/faScalpelPath'),
        "faScanner": () => import('@fortawesome/pro-solid-svg-icons/faScanner'),
        "faScannerImage": () => import('@fortawesome/pro-solid-svg-icons/faScannerImage'),
        "faScannerKeyboard": () => import('@fortawesome/pro-solid-svg-icons/faScannerKeyboard'),
        "faScannerTouchscreen": () => import('@fortawesome/pro-solid-svg-icons/faScannerTouchscreen'),
        "faScarecrow": () => import('@fortawesome/pro-solid-svg-icons/faScarecrow'),
        "faScarf": () => import('@fortawesome/pro-solid-svg-icons/faScarf'),
        "faSchool": () => import('@fortawesome/pro-solid-svg-icons/faSchool'),
        "faScrewdriver": () => import('@fortawesome/pro-solid-svg-icons/faScrewdriver'),
        "faScroll": () => import('@fortawesome/pro-solid-svg-icons/faScroll'),
        "faScrollOld": () => import('@fortawesome/pro-solid-svg-icons/faScrollOld'),
        "faScrubber": () => import('@fortawesome/pro-solid-svg-icons/faScrubber'),
        "faScythe": () => import('@fortawesome/pro-solid-svg-icons/faScythe'),
        "faSdCard": () => import('@fortawesome/pro-solid-svg-icons/faSdCard'),
        "faSearch": () => import('@fortawesome/pro-solid-svg-icons/faSearch'),
        "faSearchDollar": () => import('@fortawesome/pro-solid-svg-icons/faSearchDollar'),
        "faSearchLocation": () => import('@fortawesome/pro-solid-svg-icons/faSearchLocation'),
        "faSearchMinus": () => import('@fortawesome/pro-solid-svg-icons/faSearchMinus'),
        "faSearchPlus": () => import('@fortawesome/pro-solid-svg-icons/faSearchPlus'),
        "faSeedling": () => import('@fortawesome/pro-solid-svg-icons/faSeedling'),
        "faSendBack": () => import('@fortawesome/pro-solid-svg-icons/faSendBack'),
        "faSendBackward": () => import('@fortawesome/pro-solid-svg-icons/faSendBackward'),
        "faSensor": () => import('@fortawesome/pro-solid-svg-icons/faSensor'),
        "faSensorAlert": () => import('@fortawesome/pro-solid-svg-icons/faSensorAlert'),
        "faSensorFire": () => import('@fortawesome/pro-solid-svg-icons/faSensorFire'),
        "faSensorOn": () => import('@fortawesome/pro-solid-svg-icons/faSensorOn'),
        "faSensorSmoke": () => import('@fortawesome/pro-solid-svg-icons/faSensorSmoke'),
        "faServer": () => import('@fortawesome/pro-solid-svg-icons/faServer'),
        "faShapes": () => import('@fortawesome/pro-solid-svg-icons/faShapes'),
        "faShare": () => import('@fortawesome/pro-solid-svg-icons/faShare'),
        "faShareAll": () => import('@fortawesome/pro-solid-svg-icons/faShareAll'),
        "faShareAlt": () => import('@fortawesome/pro-solid-svg-icons/faShareAlt'),
        "faShareAltSquare": () => import('@fortawesome/pro-solid-svg-icons/faShareAltSquare'),
        "faShareSquare": () => import('@fortawesome/pro-solid-svg-icons/faShareSquare'),
        "faSheep": () => import('@fortawesome/pro-solid-svg-icons/faSheep'),
        "faShekelSign": () => import('@fortawesome/pro-solid-svg-icons/faShekelSign'),
        "faShield": () => import('@fortawesome/pro-solid-svg-icons/faShield'),
        "faShieldAlt": () => import('@fortawesome/pro-solid-svg-icons/faShieldAlt'),
        "faShieldCheck": () => import('@fortawesome/pro-solid-svg-icons/faShieldCheck'),
        "faShieldCross": () => import('@fortawesome/pro-solid-svg-icons/faShieldCross'),
        "faShieldVirus": () => import('@fortawesome/pro-solid-svg-icons/faShieldVirus'),
        "faShip": () => import('@fortawesome/pro-solid-svg-icons/faShip'),
        "faShippingFast": () => import('@fortawesome/pro-solid-svg-icons/faShippingFast'),
        "faShippingTimed": () => import('@fortawesome/pro-solid-svg-icons/faShippingTimed'),
        "faShishKebab": () => import('@fortawesome/pro-solid-svg-icons/faShishKebab'),
        "faShoePrints": () => import('@fortawesome/pro-solid-svg-icons/faShoePrints'),
        "faShoppingBag": () => import('@fortawesome/pro-solid-svg-icons/faShoppingBag'),
        "faShoppingBasket": () => import('@fortawesome/pro-solid-svg-icons/faShoppingBasket'),
        "faShoppingCart": () => import('@fortawesome/pro-solid-svg-icons/faShoppingCart'),
        "faShovel": () => import('@fortawesome/pro-solid-svg-icons/faShovel'),
        "faShovelSnow": () => import('@fortawesome/pro-solid-svg-icons/faShovelSnow'),
        "faShower": () => import('@fortawesome/pro-solid-svg-icons/faShower'),
        "faShredder": () => import('@fortawesome/pro-solid-svg-icons/faShredder'),
        "faShuttleVan": () => import('@fortawesome/pro-solid-svg-icons/faShuttleVan'),
        "faShuttlecock": () => import('@fortawesome/pro-solid-svg-icons/faShuttlecock'),
        "faSickle": () => import('@fortawesome/pro-solid-svg-icons/faSickle'),
        "faSigma": () => import('@fortawesome/pro-solid-svg-icons/faSigma'),
        "faSign": () => import('@fortawesome/pro-solid-svg-icons/faSign'),
        "faSignIn": () => import('@fortawesome/pro-solid-svg-icons/faSignIn'),
        "faSignInAlt": () => import('@fortawesome/pro-solid-svg-icons/faSignInAlt'),
        "faSignLanguage": () => import('@fortawesome/pro-solid-svg-icons/faSignLanguage'),
        "faSignOut": () => import('@fortawesome/pro-solid-svg-icons/faSignOut'),
        "faSignOutAlt": () => import('@fortawesome/pro-solid-svg-icons/faSignOutAlt'),
        "faSignal": () => import('@fortawesome/pro-solid-svg-icons/faSignal'),
        "faSignal1": () => import('@fortawesome/pro-solid-svg-icons/faSignal1'),
        "faSignal2": () => import('@fortawesome/pro-solid-svg-icons/faSignal2'),
        "faSignal3": () => import('@fortawesome/pro-solid-svg-icons/faSignal3'),
        "faSignal4": () => import('@fortawesome/pro-solid-svg-icons/faSignal4'),
        "faSignalAlt": () => import('@fortawesome/pro-solid-svg-icons/faSignalAlt'),
        "faSignalAlt1": () => import('@fortawesome/pro-solid-svg-icons/faSignalAlt1'),
        "faSignalAlt2": () => import('@fortawesome/pro-solid-svg-icons/faSignalAlt2'),
        "faSignalAlt3": () => import('@fortawesome/pro-solid-svg-icons/faSignalAlt3'),
        "faSignalAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faSignalAltSlash'),
        "faSignalSlash": () => import('@fortawesome/pro-solid-svg-icons/faSignalSlash'),
        "faSignalStream": () => import('@fortawesome/pro-solid-svg-icons/faSignalStream'),
        "faSignature": () => import('@fortawesome/pro-solid-svg-icons/faSignature'),
        "faSimCard": () => import('@fortawesome/pro-solid-svg-icons/faSimCard'),
        "faSink": () => import('@fortawesome/pro-solid-svg-icons/faSink'),
        "faSiren": () => import('@fortawesome/pro-solid-svg-icons/faSiren'),
        "faSirenOn": () => import('@fortawesome/pro-solid-svg-icons/faSirenOn'),
        "faSitemap": () => import('@fortawesome/pro-solid-svg-icons/faSitemap'),
        "faSkating": () => import('@fortawesome/pro-solid-svg-icons/faSkating'),
        "faSkeleton": () => import('@fortawesome/pro-solid-svg-icons/faSkeleton'),
        "faSkiJump": () => import('@fortawesome/pro-solid-svg-icons/faSkiJump'),
        "faSkiLift": () => import('@fortawesome/pro-solid-svg-icons/faSkiLift'),
        "faSkiing": () => import('@fortawesome/pro-solid-svg-icons/faSkiing'),
        "faSkiingNordic": () => import('@fortawesome/pro-solid-svg-icons/faSkiingNordic'),
        "faSkull": () => import('@fortawesome/pro-solid-svg-icons/faSkull'),
        "faSkullCow": () => import('@fortawesome/pro-solid-svg-icons/faSkullCow'),
        "faSkullCrossbones": () => import('@fortawesome/pro-solid-svg-icons/faSkullCrossbones'),
        "faSlash": () => import('@fortawesome/pro-solid-svg-icons/faSlash'),
        "faSledding": () => import('@fortawesome/pro-solid-svg-icons/faSledding'),
        "faSleigh": () => import('@fortawesome/pro-solid-svg-icons/faSleigh'),
        "faSlidersH": () => import('@fortawesome/pro-solid-svg-icons/faSlidersH'),
        "faSlidersHSquare": () => import('@fortawesome/pro-solid-svg-icons/faSlidersHSquare'),
        "faSlidersV": () => import('@fortawesome/pro-solid-svg-icons/faSlidersV'),
        "faSlidersVSquare": () => import('@fortawesome/pro-solid-svg-icons/faSlidersVSquare'),
        "faSmile": () => import('@fortawesome/pro-solid-svg-icons/faSmile'),
        "faSmileBeam": () => import('@fortawesome/pro-solid-svg-icons/faSmileBeam'),
        "faSmilePlus": () => import('@fortawesome/pro-solid-svg-icons/faSmilePlus'),
        "faSmileWink": () => import('@fortawesome/pro-solid-svg-icons/faSmileWink'),
        "faSmog": () => import('@fortawesome/pro-solid-svg-icons/faSmog'),
        "faSmoke": () => import('@fortawesome/pro-solid-svg-icons/faSmoke'),
        "faSmoking": () => import('@fortawesome/pro-solid-svg-icons/faSmoking'),
        "faSmokingBan": () => import('@fortawesome/pro-solid-svg-icons/faSmokingBan'),
        "faSms": () => import('@fortawesome/pro-solid-svg-icons/faSms'),
        "faSnake": () => import('@fortawesome/pro-solid-svg-icons/faSnake'),
        "faSnooze": () => import('@fortawesome/pro-solid-svg-icons/faSnooze'),
        "faSnowBlowing": () => import('@fortawesome/pro-solid-svg-icons/faSnowBlowing'),
        "faSnowboarding": () => import('@fortawesome/pro-solid-svg-icons/faSnowboarding'),
        "faSnowflake": () => import('@fortawesome/pro-solid-svg-icons/faSnowflake'),
        "faSnowflakes": () => import('@fortawesome/pro-solid-svg-icons/faSnowflakes'),
        "faSnowman": () => import('@fortawesome/pro-solid-svg-icons/faSnowman'),
        "faSnowmobile": () => import('@fortawesome/pro-solid-svg-icons/faSnowmobile'),
        "faSnowplow": () => import('@fortawesome/pro-solid-svg-icons/faSnowplow'),
        "faSoap": () => import('@fortawesome/pro-solid-svg-icons/faSoap'),
        "faSocks": () => import('@fortawesome/pro-solid-svg-icons/faSocks'),
        "faSolarPanel": () => import('@fortawesome/pro-solid-svg-icons/faSolarPanel'),
        "faSolarSystem": () => import('@fortawesome/pro-solid-svg-icons/faSolarSystem'),
        "faSort": () => import('@fortawesome/pro-solid-svg-icons/faSort'),
        "faSortAlphaDown": () => import('@fortawesome/pro-solid-svg-icons/faSortAlphaDown'),
        "faSortAlphaDownAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortAlphaDownAlt'),
        "faSortAlphaUp": () => import('@fortawesome/pro-solid-svg-icons/faSortAlphaUp'),
        "faSortAlphaUpAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortAlphaUpAlt'),
        "faSortAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortAlt'),
        "faSortAmountDown": () => import('@fortawesome/pro-solid-svg-icons/faSortAmountDown'),
        "faSortAmountDownAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortAmountDownAlt'),
        "faSortAmountUp": () => import('@fortawesome/pro-solid-svg-icons/faSortAmountUp'),
        "faSortAmountUpAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortAmountUpAlt'),
        "faSortCircle": () => import('@fortawesome/pro-solid-svg-icons/faSortCircle'),
        "faSortCircleDown": () => import('@fortawesome/pro-solid-svg-icons/faSortCircleDown'),
        "faSortCircleUp": () => import('@fortawesome/pro-solid-svg-icons/faSortCircleUp'),
        "faSortDown": () => import('@fortawesome/pro-solid-svg-icons/faSortDown'),
        "faSortNumericDown": () => import('@fortawesome/pro-solid-svg-icons/faSortNumericDown'),
        "faSortNumericDownAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortNumericDownAlt'),
        "faSortNumericUp": () => import('@fortawesome/pro-solid-svg-icons/faSortNumericUp'),
        "faSortNumericUpAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortNumericUpAlt'),
        "faSortShapesDown": () => import('@fortawesome/pro-solid-svg-icons/faSortShapesDown'),
        "faSortShapesDownAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortShapesDownAlt'),
        "faSortShapesUp": () => import('@fortawesome/pro-solid-svg-icons/faSortShapesUp'),
        "faSortShapesUpAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortShapesUpAlt'),
        "faSortSizeDown": () => import('@fortawesome/pro-solid-svg-icons/faSortSizeDown'),
        "faSortSizeDownAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortSizeDownAlt'),
        "faSortSizeUp": () => import('@fortawesome/pro-solid-svg-icons/faSortSizeUp'),
        "faSortSizeUpAlt": () => import('@fortawesome/pro-solid-svg-icons/faSortSizeUpAlt'),
        "faSortUp": () => import('@fortawesome/pro-solid-svg-icons/faSortUp'),
        "faSoup": () => import('@fortawesome/pro-solid-svg-icons/faSoup'),
        "faSpa": () => import('@fortawesome/pro-solid-svg-icons/faSpa'),
        "faSpaceShuttle": () => import('@fortawesome/pro-solid-svg-icons/faSpaceShuttle'),
        "faSpaceStationMoon": () => import('@fortawesome/pro-solid-svg-icons/faSpaceStationMoon'),
        "faSpaceStationMoonAlt": () => import('@fortawesome/pro-solid-svg-icons/faSpaceStationMoonAlt'),
        "faSpade": () => import('@fortawesome/pro-solid-svg-icons/faSpade'),
        "faSparkles": () => import('@fortawesome/pro-solid-svg-icons/faSparkles'),
        "faSpeaker": () => import('@fortawesome/pro-solid-svg-icons/faSpeaker'),
        "faSpeakers": () => import('@fortawesome/pro-solid-svg-icons/faSpeakers'),
        "faSpellCheck": () => import('@fortawesome/pro-solid-svg-icons/faSpellCheck'),
        "faSpider": () => import('@fortawesome/pro-solid-svg-icons/faSpider'),
        "faSpiderBlackWidow": () => import('@fortawesome/pro-solid-svg-icons/faSpiderBlackWidow'),
        "faSpiderWeb": () => import('@fortawesome/pro-solid-svg-icons/faSpiderWeb'),
        "faSpinner": () => import('@fortawesome/pro-solid-svg-icons/faSpinner'),
        "faSpinnerThird": () => import('@fortawesome/pro-solid-svg-icons/faSpinnerThird'),
        "faSplotch": () => import('@fortawesome/pro-solid-svg-icons/faSplotch'),
        "faSprayCan": () => import('@fortawesome/pro-solid-svg-icons/faSprayCan'),
        "faSprinkler": () => import('@fortawesome/pro-solid-svg-icons/faSprinkler'),
        "faSquare": () => import('@fortawesome/pro-solid-svg-icons/faSquare'),
        "faSquareFull": () => import('@fortawesome/pro-solid-svg-icons/faSquareFull'),
        "faSquareRoot": () => import('@fortawesome/pro-solid-svg-icons/faSquareRoot'),
        "faSquareRootAlt": () => import('@fortawesome/pro-solid-svg-icons/faSquareRootAlt'),
        "faSquirrel": () => import('@fortawesome/pro-solid-svg-icons/faSquirrel'),
        "faStaff": () => import('@fortawesome/pro-solid-svg-icons/faStaff'),
        "faStamp": () => import('@fortawesome/pro-solid-svg-icons/faStamp'),
        "faStar": () => import('@fortawesome/pro-solid-svg-icons/faStar'),
        "faStarAndCrescent": () => import('@fortawesome/pro-solid-svg-icons/faStarAndCrescent'),
        "faStarChristmas": () => import('@fortawesome/pro-solid-svg-icons/faStarChristmas'),
        "faStarExclamation": () => import('@fortawesome/pro-solid-svg-icons/faStarExclamation'),
        "faStarHalf": () => import('@fortawesome/pro-solid-svg-icons/faStarHalf'),
        "faStarHalfAlt": () => import('@fortawesome/pro-solid-svg-icons/faStarHalfAlt'),
        "faStarOfDavid": () => import('@fortawesome/pro-solid-svg-icons/faStarOfDavid'),
        "faStarOfLife": () => import('@fortawesome/pro-solid-svg-icons/faStarOfLife'),
        "faStarShooting": () => import('@fortawesome/pro-solid-svg-icons/faStarShooting'),
        "faStarfighter": () => import('@fortawesome/pro-solid-svg-icons/faStarfighter'),
        "faStarfighterAlt": () => import('@fortawesome/pro-solid-svg-icons/faStarfighterAlt'),
        "faStars": () => import('@fortawesome/pro-solid-svg-icons/faStars'),
        "faStarship": () => import('@fortawesome/pro-solid-svg-icons/faStarship'),
        "faStarshipFreighter": () => import('@fortawesome/pro-solid-svg-icons/faStarshipFreighter'),
        "faSteak": () => import('@fortawesome/pro-solid-svg-icons/faSteak'),
        "faSteeringWheel": () => import('@fortawesome/pro-solid-svg-icons/faSteeringWheel'),
        "faStepBackward": () => import('@fortawesome/pro-solid-svg-icons/faStepBackward'),
        "faStepForward": () => import('@fortawesome/pro-solid-svg-icons/faStepForward'),
        "faStethoscope": () => import('@fortawesome/pro-solid-svg-icons/faStethoscope'),
        "faStickyNote": () => import('@fortawesome/pro-solid-svg-icons/faStickyNote'),
        "faStocking": () => import('@fortawesome/pro-solid-svg-icons/faStocking'),
        "faStomach": () => import('@fortawesome/pro-solid-svg-icons/faStomach'),
        "faStop": () => import('@fortawesome/pro-solid-svg-icons/faStop'),
        "faStopCircle": () => import('@fortawesome/pro-solid-svg-icons/faStopCircle'),
        "faStopwatch": () => import('@fortawesome/pro-solid-svg-icons/faStopwatch'),
        "faStopwatch20": () => import('@fortawesome/pro-solid-svg-icons/faStopwatch20'),
        "faStore": () => import('@fortawesome/pro-solid-svg-icons/faStore'),
        "faStoreAlt": () => import('@fortawesome/pro-solid-svg-icons/faStoreAlt'),
        "faStoreAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faStoreAltSlash'),
        "faStoreSlash": () => import('@fortawesome/pro-solid-svg-icons/faStoreSlash'),
        "faStream": () => import('@fortawesome/pro-solid-svg-icons/faStream'),
        "faStreetView": () => import('@fortawesome/pro-solid-svg-icons/faStreetView'),
        "faStretcher": () => import('@fortawesome/pro-solid-svg-icons/faStretcher'),
        "faStrikethrough": () => import('@fortawesome/pro-solid-svg-icons/faStrikethrough'),
        "faStroopwafel": () => import('@fortawesome/pro-solid-svg-icons/faStroopwafel'),
        "faSubscript": () => import('@fortawesome/pro-solid-svg-icons/faSubscript'),
        "faSubway": () => import('@fortawesome/pro-solid-svg-icons/faSubway'),
        "faSuitcase": () => import('@fortawesome/pro-solid-svg-icons/faSuitcase'),
        "faSuitcaseRolling": () => import('@fortawesome/pro-solid-svg-icons/faSuitcaseRolling'),
        "faSun": () => import('@fortawesome/pro-solid-svg-icons/faSun'),
        "faSunCloud": () => import('@fortawesome/pro-solid-svg-icons/faSunCloud'),
        "faSunDust": () => import('@fortawesome/pro-solid-svg-icons/faSunDust'),
        "faSunHaze": () => import('@fortawesome/pro-solid-svg-icons/faSunHaze'),
        "faSunglasses": () => import('@fortawesome/pro-solid-svg-icons/faSunglasses'),
        "faSunrise": () => import('@fortawesome/pro-solid-svg-icons/faSunrise'),
        "faSunset": () => import('@fortawesome/pro-solid-svg-icons/faSunset'),
        "faSuperscript": () => import('@fortawesome/pro-solid-svg-icons/faSuperscript'),
        "faSurprise": () => import('@fortawesome/pro-solid-svg-icons/faSurprise'),
        "faSwatchbook": () => import('@fortawesome/pro-solid-svg-icons/faSwatchbook'),
        "faSwimmer": () => import('@fortawesome/pro-solid-svg-icons/faSwimmer'),
        "faSwimmingPool": () => import('@fortawesome/pro-solid-svg-icons/faSwimmingPool'),
        "faSword": () => import('@fortawesome/pro-solid-svg-icons/faSword'),
        "faSwordLaser": () => import('@fortawesome/pro-solid-svg-icons/faSwordLaser'),
        "faSwordLaserAlt": () => import('@fortawesome/pro-solid-svg-icons/faSwordLaserAlt'),
        "faSwords": () => import('@fortawesome/pro-solid-svg-icons/faSwords'),
        "faSwordsLaser": () => import('@fortawesome/pro-solid-svg-icons/faSwordsLaser'),
        "faSynagogue": () => import('@fortawesome/pro-solid-svg-icons/faSynagogue'),
        "faSync": () => import('@fortawesome/pro-solid-svg-icons/faSync'),
        "faSyncAlt": () => import('@fortawesome/pro-solid-svg-icons/faSyncAlt'),
        "faSyringe": () => import('@fortawesome/pro-solid-svg-icons/faSyringe'),
        "faTable": () => import('@fortawesome/pro-solid-svg-icons/faTable'),
        "faTableTennis": () => import('@fortawesome/pro-solid-svg-icons/faTableTennis'),
        "faTablet": () => import('@fortawesome/pro-solid-svg-icons/faTablet'),
        "faTabletAlt": () => import('@fortawesome/pro-solid-svg-icons/faTabletAlt'),
        "faTabletAndroid": () => import('@fortawesome/pro-solid-svg-icons/faTabletAndroid'),
        "faTabletAndroidAlt": () => import('@fortawesome/pro-solid-svg-icons/faTabletAndroidAlt'),
        "faTabletRugged": () => import('@fortawesome/pro-solid-svg-icons/faTabletRugged'),
        "faTablets": () => import('@fortawesome/pro-solid-svg-icons/faTablets'),
        "faTachometer": () => import('@fortawesome/pro-solid-svg-icons/faTachometer'),
        "faTachometerAlt": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAlt'),
        "faTachometerAltAverage": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAltAverage'),
        "faTachometerAltFast": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAltFast'),
        "faTachometerAltFastest": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAltFastest'),
        "faTachometerAltSlow": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAltSlow'),
        "faTachometerAltSlowest": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAltSlowest'),
        "faTachometerAverage": () => import('@fortawesome/pro-solid-svg-icons/faTachometerAverage'),
        "faTachometerFast": () => import('@fortawesome/pro-solid-svg-icons/faTachometerFast'),
        "faTachometerFastest": () => import('@fortawesome/pro-solid-svg-icons/faTachometerFastest'),
        "faTachometerSlow": () => import('@fortawesome/pro-solid-svg-icons/faTachometerSlow'),
        "faTachometerSlowest": () => import('@fortawesome/pro-solid-svg-icons/faTachometerSlowest'),
        "faTaco": () => import('@fortawesome/pro-solid-svg-icons/faTaco'),
        "faTag": () => import('@fortawesome/pro-solid-svg-icons/faTag'),
        "faTags": () => import('@fortawesome/pro-solid-svg-icons/faTags'),
        "faTally": () => import('@fortawesome/pro-solid-svg-icons/faTally'),
        "faTanakh": () => import('@fortawesome/pro-solid-svg-icons/faTanakh'),
        "faTape": () => import('@fortawesome/pro-solid-svg-icons/faTape'),
        "faTasks": () => import('@fortawesome/pro-solid-svg-icons/faTasks'),
        "faTasksAlt": () => import('@fortawesome/pro-solid-svg-icons/faTasksAlt'),
        "faTaxi": () => import('@fortawesome/pro-solid-svg-icons/faTaxi'),
        "faTeeth": () => import('@fortawesome/pro-solid-svg-icons/faTeeth'),
        "faTeethOpen": () => import('@fortawesome/pro-solid-svg-icons/faTeethOpen'),
        "faTelescope": () => import('@fortawesome/pro-solid-svg-icons/faTelescope'),
        "faTemperatureDown": () => import('@fortawesome/pro-solid-svg-icons/faTemperatureDown'),
        "faTemperatureFrigid": () => import('@fortawesome/pro-solid-svg-icons/faTemperatureFrigid'),
        "faTemperatureHigh": () => import('@fortawesome/pro-solid-svg-icons/faTemperatureHigh'),
        "faTemperatureHot": () => import('@fortawesome/pro-solid-svg-icons/faTemperatureHot'),
        "faTemperatureLow": () => import('@fortawesome/pro-solid-svg-icons/faTemperatureLow'),
        "faTemperatureUp": () => import('@fortawesome/pro-solid-svg-icons/faTemperatureUp'),
        "faTenge": () => import('@fortawesome/pro-solid-svg-icons/faTenge'),
        "faTennisBall": () => import('@fortawesome/pro-solid-svg-icons/faTennisBall'),
        "faTerminal": () => import('@fortawesome/pro-solid-svg-icons/faTerminal'),
        "faText": () => import('@fortawesome/pro-solid-svg-icons/faText'),
        "faTextHeight": () => import('@fortawesome/pro-solid-svg-icons/faTextHeight'),
        "faTextSize": () => import('@fortawesome/pro-solid-svg-icons/faTextSize'),
        "faTextWidth": () => import('@fortawesome/pro-solid-svg-icons/faTextWidth'),
        "faTh": () => import('@fortawesome/pro-solid-svg-icons/faTh'),
        "faThLarge": () => import('@fortawesome/pro-solid-svg-icons/faThLarge'),
        "faThList": () => import('@fortawesome/pro-solid-svg-icons/faThList'),
        "faTheaterMasks": () => import('@fortawesome/pro-solid-svg-icons/faTheaterMasks'),
        "faThermometer": () => import('@fortawesome/pro-solid-svg-icons/faThermometer'),
        "faThermometerEmpty": () => import('@fortawesome/pro-solid-svg-icons/faThermometerEmpty'),
        "faThermometerFull": () => import('@fortawesome/pro-solid-svg-icons/faThermometerFull'),
        "faThermometerHalf": () => import('@fortawesome/pro-solid-svg-icons/faThermometerHalf'),
        "faThermometerQuarter": () => import('@fortawesome/pro-solid-svg-icons/faThermometerQuarter'),
        "faThermometerThreeQuarters": () => import('@fortawesome/pro-solid-svg-icons/faThermometerThreeQuarters'),
        "faTheta": () => import('@fortawesome/pro-solid-svg-icons/faTheta'),
        "faThumbsDown": () => import('@fortawesome/pro-solid-svg-icons/faThumbsDown'),
        "faThumbsUp": () => import('@fortawesome/pro-solid-svg-icons/faThumbsUp'),
        "faThumbtack": () => import('@fortawesome/pro-solid-svg-icons/faThumbtack'),
        "faThunderstorm": () => import('@fortawesome/pro-solid-svg-icons/faThunderstorm'),
        "faThunderstormMoon": () => import('@fortawesome/pro-solid-svg-icons/faThunderstormMoon'),
        "faThunderstormSun": () => import('@fortawesome/pro-solid-svg-icons/faThunderstormSun'),
        "faTicket": () => import('@fortawesome/pro-solid-svg-icons/faTicket'),
        "faTicketAlt": () => import('@fortawesome/pro-solid-svg-icons/faTicketAlt'),
        "faTilde": () => import('@fortawesome/pro-solid-svg-icons/faTilde'),
        "faTimes": () => import('@fortawesome/pro-solid-svg-icons/faTimes'),
        "faTimesCircle": () => import('@fortawesome/pro-solid-svg-icons/faTimesCircle'),
        "faTimesHexagon": () => import('@fortawesome/pro-solid-svg-icons/faTimesHexagon'),
        "faTimesOctagon": () => import('@fortawesome/pro-solid-svg-icons/faTimesOctagon'),
        "faTimesSquare": () => import('@fortawesome/pro-solid-svg-icons/faTimesSquare'),
        "faTint": () => import('@fortawesome/pro-solid-svg-icons/faTint'),
        "faTintSlash": () => import('@fortawesome/pro-solid-svg-icons/faTintSlash'),
        "faTire": () => import('@fortawesome/pro-solid-svg-icons/faTire'),
        "faTireFlat": () => import('@fortawesome/pro-solid-svg-icons/faTireFlat'),
        "faTirePressureWarning": () => import('@fortawesome/pro-solid-svg-icons/faTirePressureWarning'),
        "faTireRugged": () => import('@fortawesome/pro-solid-svg-icons/faTireRugged'),
        "faTired": () => import('@fortawesome/pro-solid-svg-icons/faTired'),
        "faToggleOff": () => import('@fortawesome/pro-solid-svg-icons/faToggleOff'),
        "faToggleOn": () => import('@fortawesome/pro-solid-svg-icons/faToggleOn'),
        "faToilet": () => import('@fortawesome/pro-solid-svg-icons/faToilet'),
        "faToiletPaper": () => import('@fortawesome/pro-solid-svg-icons/faToiletPaper'),
        "faToiletPaperAlt": () => import('@fortawesome/pro-solid-svg-icons/faToiletPaperAlt'),
        "faToiletPaperSlash": () => import('@fortawesome/pro-solid-svg-icons/faToiletPaperSlash'),
        "faTombstone": () => import('@fortawesome/pro-solid-svg-icons/faTombstone'),
        "faTombstoneAlt": () => import('@fortawesome/pro-solid-svg-icons/faTombstoneAlt'),
        "faToolbox": () => import('@fortawesome/pro-solid-svg-icons/faToolbox'),
        "faTools": () => import('@fortawesome/pro-solid-svg-icons/faTools'),
        "faTooth": () => import('@fortawesome/pro-solid-svg-icons/faTooth'),
        "faToothbrush": () => import('@fortawesome/pro-solid-svg-icons/faToothbrush'),
        "faTorah": () => import('@fortawesome/pro-solid-svg-icons/faTorah'),
        "faToriiGate": () => import('@fortawesome/pro-solid-svg-icons/faToriiGate'),
        "faTornado": () => import('@fortawesome/pro-solid-svg-icons/faTornado'),
        "faTractor": () => import('@fortawesome/pro-solid-svg-icons/faTractor'),
        "faTrademark": () => import('@fortawesome/pro-solid-svg-icons/faTrademark'),
        "faTrafficCone": () => import('@fortawesome/pro-solid-svg-icons/faTrafficCone'),
        "faTrafficLight": () => import('@fortawesome/pro-solid-svg-icons/faTrafficLight'),
        "faTrafficLightGo": () => import('@fortawesome/pro-solid-svg-icons/faTrafficLightGo'),
        "faTrafficLightSlow": () => import('@fortawesome/pro-solid-svg-icons/faTrafficLightSlow'),
        "faTrafficLightStop": () => import('@fortawesome/pro-solid-svg-icons/faTrafficLightStop'),
        "faTrailer": () => import('@fortawesome/pro-solid-svg-icons/faTrailer'),
        "faTrain": () => import('@fortawesome/pro-solid-svg-icons/faTrain'),
        "faTram": () => import('@fortawesome/pro-solid-svg-icons/faTram'),
        "faTransgender": () => import('@fortawesome/pro-solid-svg-icons/faTransgender'),
        "faTransgenderAlt": () => import('@fortawesome/pro-solid-svg-icons/faTransgenderAlt'),
        "faTransporter": () => import('@fortawesome/pro-solid-svg-icons/faTransporter'),
        "faTransporter1": () => import('@fortawesome/pro-solid-svg-icons/faTransporter1'),
        "faTransporter2": () => import('@fortawesome/pro-solid-svg-icons/faTransporter2'),
        "faTransporter3": () => import('@fortawesome/pro-solid-svg-icons/faTransporter3'),
        "faTransporterEmpty": () => import('@fortawesome/pro-solid-svg-icons/faTransporterEmpty'),
        "faTrash": () => import('@fortawesome/pro-solid-svg-icons/faTrash'),
        "faTrashAlt": () => import('@fortawesome/pro-solid-svg-icons/faTrashAlt'),
        "faTrashRestore": () => import('@fortawesome/pro-solid-svg-icons/faTrashRestore'),
        "faTrashRestoreAlt": () => import('@fortawesome/pro-solid-svg-icons/faTrashRestoreAlt'),
        "faTrashUndo": () => import('@fortawesome/pro-solid-svg-icons/faTrashUndo'),
        "faTrashUndoAlt": () => import('@fortawesome/pro-solid-svg-icons/faTrashUndoAlt'),
        "faTreasureChest": () => import('@fortawesome/pro-solid-svg-icons/faTreasureChest'),
        "faTree": () => import('@fortawesome/pro-solid-svg-icons/faTree'),
        "faTreeAlt": () => import('@fortawesome/pro-solid-svg-icons/faTreeAlt'),
        "faTreeChristmas": () => import('@fortawesome/pro-solid-svg-icons/faTreeChristmas'),
        "faTreeDecorated": () => import('@fortawesome/pro-solid-svg-icons/faTreeDecorated'),
        "faTreeLarge": () => import('@fortawesome/pro-solid-svg-icons/faTreeLarge'),
        "faTreePalm": () => import('@fortawesome/pro-solid-svg-icons/faTreePalm'),
        "faTrees": () => import('@fortawesome/pro-solid-svg-icons/faTrees'),
        "faTriangle": () => import('@fortawesome/pro-solid-svg-icons/faTriangle'),
        "faTriangleMusic": () => import('@fortawesome/pro-solid-svg-icons/faTriangleMusic'),
        "faTrophy": () => import('@fortawesome/pro-solid-svg-icons/faTrophy'),
        "faTrophyAlt": () => import('@fortawesome/pro-solid-svg-icons/faTrophyAlt'),
        "faTruck": () => import('@fortawesome/pro-solid-svg-icons/faTruck'),
        "faTruckContainer": () => import('@fortawesome/pro-solid-svg-icons/faTruckContainer'),
        "faTruckCouch": () => import('@fortawesome/pro-solid-svg-icons/faTruckCouch'),
        "faTruckLoading": () => import('@fortawesome/pro-solid-svg-icons/faTruckLoading'),
        "faTruckMonster": () => import('@fortawesome/pro-solid-svg-icons/faTruckMonster'),
        "faTruckMoving": () => import('@fortawesome/pro-solid-svg-icons/faTruckMoving'),
        "faTruckPickup": () => import('@fortawesome/pro-solid-svg-icons/faTruckPickup'),
        "faTruckPlow": () => import('@fortawesome/pro-solid-svg-icons/faTruckPlow'),
        "faTruckRamp": () => import('@fortawesome/pro-solid-svg-icons/faTruckRamp'),
        "faTrumpet": () => import('@fortawesome/pro-solid-svg-icons/faTrumpet'),
        "faTshirt": () => import('@fortawesome/pro-solid-svg-icons/faTshirt'),
        "faTty": () => import('@fortawesome/pro-solid-svg-icons/faTty'),
        "faTurkey": () => import('@fortawesome/pro-solid-svg-icons/faTurkey'),
        "faTurntable": () => import('@fortawesome/pro-solid-svg-icons/faTurntable'),
        "faTurtle": () => import('@fortawesome/pro-solid-svg-icons/faTurtle'),
        "faTv": () => import('@fortawesome/pro-solid-svg-icons/faTv'),
        "faTvAlt": () => import('@fortawesome/pro-solid-svg-icons/faTvAlt'),
        "faTvMusic": () => import('@fortawesome/pro-solid-svg-icons/faTvMusic'),
        "faTvRetro": () => import('@fortawesome/pro-solid-svg-icons/faTvRetro'),
        "faTypewriter": () => import('@fortawesome/pro-solid-svg-icons/faTypewriter'),
        "faUfo": () => import('@fortawesome/pro-solid-svg-icons/faUfo'),
        "faUfoBeam": () => import('@fortawesome/pro-solid-svg-icons/faUfoBeam'),
        "faUmbrella": () => import('@fortawesome/pro-solid-svg-icons/faUmbrella'),
        "faUmbrellaBeach": () => import('@fortawesome/pro-solid-svg-icons/faUmbrellaBeach'),
        "faUnderline": () => import('@fortawesome/pro-solid-svg-icons/faUnderline'),
        "faUndo": () => import('@fortawesome/pro-solid-svg-icons/faUndo'),
        "faUndoAlt": () => import('@fortawesome/pro-solid-svg-icons/faUndoAlt'),
        "faUnicorn": () => import('@fortawesome/pro-solid-svg-icons/faUnicorn'),
        "faUnion": () => import('@fortawesome/pro-solid-svg-icons/faUnion'),
        "faUniversalAccess": () => import('@fortawesome/pro-solid-svg-icons/faUniversalAccess'),
        "faUniversity": () => import('@fortawesome/pro-solid-svg-icons/faUniversity'),
        "faUnlink": () => import('@fortawesome/pro-solid-svg-icons/faUnlink'),
        "faUnlock": () => import('@fortawesome/pro-solid-svg-icons/faUnlock'),
        "faUnlockAlt": () => import('@fortawesome/pro-solid-svg-icons/faUnlockAlt'),
        "faUpload": () => import('@fortawesome/pro-solid-svg-icons/faUpload'),
        "faUsbDrive": () => import('@fortawesome/pro-solid-svg-icons/faUsbDrive'),
        "faUsdCircle": () => import('@fortawesome/pro-solid-svg-icons/faUsdCircle'),
        "faUsdSquare": () => import('@fortawesome/pro-solid-svg-icons/faUsdSquare'),
        "faUser": () => import('@fortawesome/pro-solid-svg-icons/faUser'),
        "faUserAlien": () => import('@fortawesome/pro-solid-svg-icons/faUserAlien'),
        "faUserAlt": () => import('@fortawesome/pro-solid-svg-icons/faUserAlt'),
        "faUserAltSlash": () => import('@fortawesome/pro-solid-svg-icons/faUserAltSlash'),
        "faUserAstronaut": () => import('@fortawesome/pro-solid-svg-icons/faUserAstronaut'),
        "faUserChart": () => import('@fortawesome/pro-solid-svg-icons/faUserChart'),
        "faUserCheck": () => import('@fortawesome/pro-solid-svg-icons/faUserCheck'),
        "faUserCircle": () => import('@fortawesome/pro-solid-svg-icons/faUserCircle'),
        "faUserClock": () => import('@fortawesome/pro-solid-svg-icons/faUserClock'),
        "faUserCog": () => import('@fortawesome/pro-solid-svg-icons/faUserCog'),
        "faUserCowboy": () => import('@fortawesome/pro-solid-svg-icons/faUserCowboy'),
        "faUserCrown": () => import('@fortawesome/pro-solid-svg-icons/faUserCrown'),
        "faUserEdit": () => import('@fortawesome/pro-solid-svg-icons/faUserEdit'),
        "faUserFriends": () => import('@fortawesome/pro-solid-svg-icons/faUserFriends'),
        "faUserGraduate": () => import('@fortawesome/pro-solid-svg-icons/faUserGraduate'),
        "faUserHardHat": () => import('@fortawesome/pro-solid-svg-icons/faUserHardHat'),
        "faUserHeadset": () => import('@fortawesome/pro-solid-svg-icons/faUserHeadset'),
        "faUserInjured": () => import('@fortawesome/pro-solid-svg-icons/faUserInjured'),
        "faUserLock": () => import('@fortawesome/pro-solid-svg-icons/faUserLock'),
        "faUserMd": () => import('@fortawesome/pro-solid-svg-icons/faUserMd'),
        "faUserMdChat": () => import('@fortawesome/pro-solid-svg-icons/faUserMdChat'),
        "faUserMinus": () => import('@fortawesome/pro-solid-svg-icons/faUserMinus'),
        "faUserMusic": () => import('@fortawesome/pro-solid-svg-icons/faUserMusic'),
        "faUserNinja": () => import('@fortawesome/pro-solid-svg-icons/faUserNinja'),
        "faUserNurse": () => import('@fortawesome/pro-solid-svg-icons/faUserNurse'),
        "faUserPlus": () => import('@fortawesome/pro-solid-svg-icons/faUserPlus'),
        "faUserRobot": () => import('@fortawesome/pro-solid-svg-icons/faUserRobot'),
        "faUserSecret": () => import('@fortawesome/pro-solid-svg-icons/faUserSecret'),
        "faUserShield": () => import('@fortawesome/pro-solid-svg-icons/faUserShield'),
        "faUserSlash": () => import('@fortawesome/pro-solid-svg-icons/faUserSlash'),
        "faUserTag": () => import('@fortawesome/pro-solid-svg-icons/faUserTag'),
        "faUserTie": () => import('@fortawesome/pro-solid-svg-icons/faUserTie'),
        "faUserTimes": () => import('@fortawesome/pro-solid-svg-icons/faUserTimes'),
        "faUserUnlock": () => import('@fortawesome/pro-solid-svg-icons/faUserUnlock'),
        "faUserVisor": () => import('@fortawesome/pro-solid-svg-icons/faUserVisor'),
        "faUsers": () => import('@fortawesome/pro-solid-svg-icons/faUsers'),
        "faUsersClass": () => import('@fortawesome/pro-solid-svg-icons/faUsersClass'),
        "faUsersCog": () => import('@fortawesome/pro-solid-svg-icons/faUsersCog'),
        "faUsersCrown": () => import('@fortawesome/pro-solid-svg-icons/faUsersCrown'),
        "faUsersMedical": () => import('@fortawesome/pro-solid-svg-icons/faUsersMedical'),
        "faUsersSlash": () => import('@fortawesome/pro-solid-svg-icons/faUsersSlash'),
        "faUtensilFork": () => import('@fortawesome/pro-solid-svg-icons/faUtensilFork'),
        "faUtensilKnife": () => import('@fortawesome/pro-solid-svg-icons/faUtensilKnife'),
        "faUtensilSpoon": () => import('@fortawesome/pro-solid-svg-icons/faUtensilSpoon'),
        "faUtensils": () => import('@fortawesome/pro-solid-svg-icons/faUtensils'),
        "faUtensilsAlt": () => import('@fortawesome/pro-solid-svg-icons/faUtensilsAlt'),
        "faVacuum": () => import('@fortawesome/pro-solid-svg-icons/faVacuum'),
        "faVacuumRobot": () => import('@fortawesome/pro-solid-svg-icons/faVacuumRobot'),
        "faValueAbsolute": () => import('@fortawesome/pro-solid-svg-icons/faValueAbsolute'),
        "faVectorSquare": () => import('@fortawesome/pro-solid-svg-icons/faVectorSquare'),
        "faVenus": () => import('@fortawesome/pro-solid-svg-icons/faVenus'),
        "faVenusDouble": () => import('@fortawesome/pro-solid-svg-icons/faVenusDouble'),
        "faVenusMars": () => import('@fortawesome/pro-solid-svg-icons/faVenusMars'),
        "faVest": () => import('@fortawesome/pro-solid-svg-icons/faVest'),
        "faVestPatches": () => import('@fortawesome/pro-solid-svg-icons/faVestPatches'),
        "faVhs": () => import('@fortawesome/pro-solid-svg-icons/faVhs'),
        "faVial": () => import('@fortawesome/pro-solid-svg-icons/faVial'),
        "faVials": () => import('@fortawesome/pro-solid-svg-icons/faVials'),
        "faVideo": () => import('@fortawesome/pro-solid-svg-icons/faVideo'),
        "faVideoPlus": () => import('@fortawesome/pro-solid-svg-icons/faVideoPlus'),
        "faVideoSlash": () => import('@fortawesome/pro-solid-svg-icons/faVideoSlash'),
        "faVihara": () => import('@fortawesome/pro-solid-svg-icons/faVihara'),
        "faViolin": () => import('@fortawesome/pro-solid-svg-icons/faViolin'),
        "faVirus": () => import('@fortawesome/pro-solid-svg-icons/faVirus'),
        "faVirusSlash": () => import('@fortawesome/pro-solid-svg-icons/faVirusSlash'),
        "faViruses": () => import('@fortawesome/pro-solid-svg-icons/faViruses'),
        "faVoicemail": () => import('@fortawesome/pro-solid-svg-icons/faVoicemail'),
        "faVolcano": () => import('@fortawesome/pro-solid-svg-icons/faVolcano'),
        "faVolleyballBall": () => import('@fortawesome/pro-solid-svg-icons/faVolleyballBall'),
        "faVolume": () => import('@fortawesome/pro-solid-svg-icons/faVolume'),
        "faVolumeDown": () => import('@fortawesome/pro-solid-svg-icons/faVolumeDown'),
        "faVolumeMute": () => import('@fortawesome/pro-solid-svg-icons/faVolumeMute'),
        "faVolumeOff": () => import('@fortawesome/pro-solid-svg-icons/faVolumeOff'),
        "faVolumeSlash": () => import('@fortawesome/pro-solid-svg-icons/faVolumeSlash'),
        "faVolumeUp": () => import('@fortawesome/pro-solid-svg-icons/faVolumeUp'),
        "faVoteNay": () => import('@fortawesome/pro-solid-svg-icons/faVoteNay'),
        "faVoteYea": () => import('@fortawesome/pro-solid-svg-icons/faVoteYea'),
        "faVrCardboard": () => import('@fortawesome/pro-solid-svg-icons/faVrCardboard'),
        "faWagonCovered": () => import('@fortawesome/pro-solid-svg-icons/faWagonCovered'),
        "faWalker": () => import('@fortawesome/pro-solid-svg-icons/faWalker'),
        "faWalkieTalkie": () => import('@fortawesome/pro-solid-svg-icons/faWalkieTalkie'),
        "faWalking": () => import('@fortawesome/pro-solid-svg-icons/faWalking'),
        "faWallet": () => import('@fortawesome/pro-solid-svg-icons/faWallet'),
        "faWand": () => import('@fortawesome/pro-solid-svg-icons/faWand'),
        "faWandMagic": () => import('@fortawesome/pro-solid-svg-icons/faWandMagic'),
        "faWarehouse": () => import('@fortawesome/pro-solid-svg-icons/faWarehouse'),
        "faWarehouseAlt": () => import('@fortawesome/pro-solid-svg-icons/faWarehouseAlt'),
        "faWasher": () => import('@fortawesome/pro-solid-svg-icons/faWasher'),
        "faWatch": () => import('@fortawesome/pro-solid-svg-icons/faWatch'),
        "faWatchCalculator": () => import('@fortawesome/pro-solid-svg-icons/faWatchCalculator'),
        "faWatchFitness": () => import('@fortawesome/pro-solid-svg-icons/faWatchFitness'),
        "faWater": () => import('@fortawesome/pro-solid-svg-icons/faWater'),
        "faWaterLower": () => import('@fortawesome/pro-solid-svg-icons/faWaterLower'),
        "faWaterRise": () => import('@fortawesome/pro-solid-svg-icons/faWaterRise'),
        "faWaveSine": () => import('@fortawesome/pro-solid-svg-icons/faWaveSine'),
        "faWaveSquare": () => import('@fortawesome/pro-solid-svg-icons/faWaveSquare'),
        "faWaveTriangle": () => import('@fortawesome/pro-solid-svg-icons/faWaveTriangle'),
        "faWaveform": () => import('@fortawesome/pro-solid-svg-icons/faWaveform'),
        "faWaveformPath": () => import('@fortawesome/pro-solid-svg-icons/faWaveformPath'),
        "faWebcam": () => import('@fortawesome/pro-solid-svg-icons/faWebcam'),
        "faWebcamSlash": () => import('@fortawesome/pro-solid-svg-icons/faWebcamSlash'),
        "faWeight": () => import('@fortawesome/pro-solid-svg-icons/faWeight'),
        "faWeightHanging": () => import('@fortawesome/pro-solid-svg-icons/faWeightHanging'),
        "faWhale": () => import('@fortawesome/pro-solid-svg-icons/faWhale'),
        "faWheat": () => import('@fortawesome/pro-solid-svg-icons/faWheat'),
        "faWheelchair": () => import('@fortawesome/pro-solid-svg-icons/faWheelchair'),
        "faWhistle": () => import('@fortawesome/pro-solid-svg-icons/faWhistle'),
        "faWifi": () => import('@fortawesome/pro-solid-svg-icons/faWifi'),
        "faWifi1": () => import('@fortawesome/pro-solid-svg-icons/faWifi1'),
        "faWifi2": () => import('@fortawesome/pro-solid-svg-icons/faWifi2'),
        "faWifiSlash": () => import('@fortawesome/pro-solid-svg-icons/faWifiSlash'),
        "faWind": () => import('@fortawesome/pro-solid-svg-icons/faWind'),
        "faWindTurbine": () => import('@fortawesome/pro-solid-svg-icons/faWindTurbine'),
        "faWindWarning": () => import('@fortawesome/pro-solid-svg-icons/faWindWarning'),
        "faWindow": () => import('@fortawesome/pro-solid-svg-icons/faWindow'),
        "faWindowAlt": () => import('@fortawesome/pro-solid-svg-icons/faWindowAlt'),
        "faWindowClose": () => import('@fortawesome/pro-solid-svg-icons/faWindowClose'),
        "faWindowFrame": () => import('@fortawesome/pro-solid-svg-icons/faWindowFrame'),
        "faWindowFrameOpen": () => import('@fortawesome/pro-solid-svg-icons/faWindowFrameOpen'),
        "faWindowMaximize": () => import('@fortawesome/pro-solid-svg-icons/faWindowMaximize'),
        "faWindowMinimize": () => import('@fortawesome/pro-solid-svg-icons/faWindowMinimize'),
        "faWindowRestore": () => import('@fortawesome/pro-solid-svg-icons/faWindowRestore'),
        "faWindsock": () => import('@fortawesome/pro-solid-svg-icons/faWindsock'),
        "faWineBottle": () => import('@fortawesome/pro-solid-svg-icons/faWineBottle'),
        "faWineGlass": () => import('@fortawesome/pro-solid-svg-icons/faWineGlass'),
        "faWineGlassAlt": () => import('@fortawesome/pro-solid-svg-icons/faWineGlassAlt'),
        "faWonSign": () => import('@fortawesome/pro-solid-svg-icons/faWonSign'),
        "faWreath": () => import('@fortawesome/pro-solid-svg-icons/faWreath'),
        "faWrench": () => import('@fortawesome/pro-solid-svg-icons/faWrench'),
        "faXRay": () => import('@fortawesome/pro-solid-svg-icons/faXRay'),
        "faYenSign": () => import('@fortawesome/pro-solid-svg-icons/faYenSign'),
        "faYinYang": () => import('@fortawesome/pro-solid-svg-icons/faYinYang')
    }
};
export default packInfo;
