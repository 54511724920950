var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Spinner, Icon } from "../";
import "./FileUpload.scss";
import classNames from "classnames";
import { ProgressBar } from "../ProgressBar";
const inputStyles = {
    display: "block",
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden",
    border: "none",
};
export const FileUpload = (_a) => {
    var { loading = false, loadingLabel, children, name, onChange = () => {
    }, className, loadingType = "spinner", accept, iconSize } = _a, restProps = __rest(_a, ["loading", "loadingLabel", "children", "name", "onChange", "className", "loadingType", "accept", "iconSize"]);
    const onDrop = useCallback((acceptedFiles) => {
        onChange(acceptedFiles, name);
    }, [name, onChange]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const loadingComponent = loadingType === "spinner" ? (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
        } },
        React.createElement("div", { style: { position: "relative", height: 50 } },
            React.createElement(Spinner, { size: "3x" })),
        React.createElement("span", null, loadingLabel))) : (React.createElement("div", { className: "file-upload__loading--progress" },
        React.createElement("p", null, loadingLabel),
        React.createElement(ProgressBar, null)));
    return (React.createElement("div", Object.assign({}, getRootProps(), { className: classNames(className, "FileUploadContainer", { 'FileUploadContainer_DnD': isDragActive }) }, restProps), loading ? (loadingComponent) : (React.createElement(React.Fragment, null,
        React.createElement("input", Object.assign({}, getInputProps({ style: inputStyles, accept }))),
        isDragActive ? React.createElement(Icon, { name: "farCloudUpload", color: "primary", size: iconSize || "5x" }) : children))));
};
