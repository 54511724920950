var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Icon } from "../Icon";
import memoize from "memoizerific";
import { DefaultListItem } from "./JustSelectItem";
const DefaultSelectedItemWrapper = ({ onDelete, label, children }) => {
    return (React.createElement("div", { className: 'just-select-selected-item', "data-test-id": 'default-just-select-selected-item', role: 'selected-item' },
        React.createElement("div", { className: 'just-select-selected-item-label-name', "data-test-id": `default-just-select-selected-item-name-${label}` }, children),
        React.createElement("div", { className: 'just-select-selected-item-delete', onClick: onDelete },
            React.createElement(Icon, { name: 'fasTimes' }))));
};
const createJustSelectSelectedItem = (Component, _a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const SelectedItem = React.memo(props => (React.createElement(DefaultSelectedItemWrapper, { onDelete: props.onDelete, label: props.label },
        React.createElement(Component, Object.assign({}, rest, { label: props.label })))));
    SelectedItem.displayName = `JustSelectedChild_${Component.displayName}`;
    return SelectedItem;
};
export const getSelectedItem = memoize(1)((SelectedElement = DefaultListItem, props = {}) => {
    return createJustSelectSelectedItem(SelectedElement, props);
});
