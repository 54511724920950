var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from "react";
import classNames from "classnames";
export default function SwitchToggle(props) {
    const { id, name, value, defaultChecked, checkboxOptions = [], inputRef, dataTestId, required, onChange, disabled, size = "md", switchType = "square", color = "primary", className } = props, restProps = __rest(props, ["id", "name", "value", "defaultChecked", "checkboxOptions", "inputRef", "dataTestId", "required", "onChange", "disabled", "size", "switchType", "color", "className"]);
    return (React.createElement(Fragment, null,
        React.createElement("span", null,
            checkboxOptions[0],
            " "),
        React.createElement("label", Object.assign({ className: classNames("switch", `switch-${switchType === "square" ? "default" : "3d"}`, `switch-${color}`, `switch-${size}`, className) }, restProps),
            React.createElement("input", { name: name, defaultChecked: defaultChecked, ref: inputRef, required: required, checked: value, onChange: (event) => {
                    !!onChange && onChange(event.target.checked, name);
                }, disabled: disabled, id: id, type: "checkbox", className: "switch-input", "data-test-id": dataTestId || "ChannelForm.Toggle" }),
            React.createElement("span", { className: "switch-label" }),
            React.createElement("span", { className: "switch-handle" })),
        React.createElement("span", null,
            " ",
            checkboxOptions[1])));
}
