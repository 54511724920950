const packInfo = {
    lib: "node_modules/@fortawesome/pro-regular-svg-icons",
    prefix: "far",
    iconsNameMap: {
        "farAbacus": () => import('@fortawesome/pro-regular-svg-icons/faAbacus'),
        "farAcorn": () => import('@fortawesome/pro-regular-svg-icons/faAcorn'),
        "farAd": () => import('@fortawesome/pro-regular-svg-icons/faAd'),
        "farAddressBook": () => import('@fortawesome/pro-regular-svg-icons/faAddressBook'),
        "farAddressCard": () => import('@fortawesome/pro-regular-svg-icons/faAddressCard'),
        "farAdjust": () => import('@fortawesome/pro-regular-svg-icons/faAdjust'),
        "farAirConditioner": () => import('@fortawesome/pro-regular-svg-icons/faAirConditioner'),
        "farAirFreshener": () => import('@fortawesome/pro-regular-svg-icons/faAirFreshener'),
        "farAlarmClock": () => import('@fortawesome/pro-regular-svg-icons/faAlarmClock'),
        "farAlarmExclamation": () => import('@fortawesome/pro-regular-svg-icons/faAlarmExclamation'),
        "farAlarmPlus": () => import('@fortawesome/pro-regular-svg-icons/faAlarmPlus'),
        "farAlarmSnooze": () => import('@fortawesome/pro-regular-svg-icons/faAlarmSnooze'),
        "farAlbum": () => import('@fortawesome/pro-regular-svg-icons/faAlbum'),
        "farAlbumCollection": () => import('@fortawesome/pro-regular-svg-icons/faAlbumCollection'),
        "farAlicorn": () => import('@fortawesome/pro-regular-svg-icons/faAlicorn'),
        "farAlien": () => import('@fortawesome/pro-regular-svg-icons/faAlien'),
        "farAlienMonster": () => import('@fortawesome/pro-regular-svg-icons/faAlienMonster'),
        "farAlignCenter": () => import('@fortawesome/pro-regular-svg-icons/faAlignCenter'),
        "farAlignJustify": () => import('@fortawesome/pro-regular-svg-icons/faAlignJustify'),
        "farAlignLeft": () => import('@fortawesome/pro-regular-svg-icons/faAlignLeft'),
        "farAlignRight": () => import('@fortawesome/pro-regular-svg-icons/faAlignRight'),
        "farAlignSlash": () => import('@fortawesome/pro-regular-svg-icons/faAlignSlash'),
        "farAllergies": () => import('@fortawesome/pro-regular-svg-icons/faAllergies'),
        "farAmbulance": () => import('@fortawesome/pro-regular-svg-icons/faAmbulance'),
        "farAmericanSignLanguageInterpreting": () => import('@fortawesome/pro-regular-svg-icons/faAmericanSignLanguageInterpreting'),
        "farAmpGuitar": () => import('@fortawesome/pro-regular-svg-icons/faAmpGuitar'),
        "farAnalytics": () => import('@fortawesome/pro-regular-svg-icons/faAnalytics'),
        "farAnchor": () => import('@fortawesome/pro-regular-svg-icons/faAnchor'),
        "farAngel": () => import('@fortawesome/pro-regular-svg-icons/faAngel'),
        "farAngleDoubleDown": () => import('@fortawesome/pro-regular-svg-icons/faAngleDoubleDown'),
        "farAngleDoubleLeft": () => import('@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft'),
        "farAngleDoubleRight": () => import('@fortawesome/pro-regular-svg-icons/faAngleDoubleRight'),
        "farAngleDoubleUp": () => import('@fortawesome/pro-regular-svg-icons/faAngleDoubleUp'),
        "farAngleDown": () => import('@fortawesome/pro-regular-svg-icons/faAngleDown'),
        "farAngleLeft": () => import('@fortawesome/pro-regular-svg-icons/faAngleLeft'),
        "farAngleRight": () => import('@fortawesome/pro-regular-svg-icons/faAngleRight'),
        "farAngleUp": () => import('@fortawesome/pro-regular-svg-icons/faAngleUp'),
        "farAngry": () => import('@fortawesome/pro-regular-svg-icons/faAngry'),
        "farAnkh": () => import('@fortawesome/pro-regular-svg-icons/faAnkh'),
        "farAppleAlt": () => import('@fortawesome/pro-regular-svg-icons/faAppleAlt'),
        "farAppleCrate": () => import('@fortawesome/pro-regular-svg-icons/faAppleCrate'),
        "farArchive": () => import('@fortawesome/pro-regular-svg-icons/faArchive'),
        "farArchway": () => import('@fortawesome/pro-regular-svg-icons/faArchway'),
        "farArrowAltCircleDown": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltCircleDown'),
        "farArrowAltCircleLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltCircleLeft'),
        "farArrowAltCircleRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltCircleRight'),
        "farArrowAltCircleUp": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltCircleUp'),
        "farArrowAltDown": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltDown'),
        "farArrowAltFromBottom": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltFromBottom'),
        "farArrowAltFromLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltFromLeft'),
        "farArrowAltFromRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltFromRight'),
        "farArrowAltFromTop": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltFromTop'),
        "farArrowAltLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltLeft'),
        "farArrowAltRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltRight'),
        "farArrowAltSquareDown": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltSquareDown'),
        "farArrowAltSquareLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltSquareLeft'),
        "farArrowAltSquareRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltSquareRight'),
        "farArrowAltSquareUp": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltSquareUp'),
        "farArrowAltToBottom": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltToBottom'),
        "farArrowAltToLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltToLeft'),
        "farArrowAltToRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltToRight'),
        "farArrowAltToTop": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltToTop'),
        "farArrowAltUp": () => import('@fortawesome/pro-regular-svg-icons/faArrowAltUp'),
        "farArrowCircleDown": () => import('@fortawesome/pro-regular-svg-icons/faArrowCircleDown'),
        "farArrowCircleLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'),
        "farArrowCircleRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowCircleRight'),
        "farArrowCircleUp": () => import('@fortawesome/pro-regular-svg-icons/faArrowCircleUp'),
        "farArrowDown": () => import('@fortawesome/pro-regular-svg-icons/faArrowDown'),
        "farArrowFromBottom": () => import('@fortawesome/pro-regular-svg-icons/faArrowFromBottom'),
        "farArrowFromLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowFromLeft'),
        "farArrowFromRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowFromRight'),
        "farArrowFromTop": () => import('@fortawesome/pro-regular-svg-icons/faArrowFromTop'),
        "farArrowLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowLeft'),
        "farArrowRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowRight'),
        "farArrowSquareDown": () => import('@fortawesome/pro-regular-svg-icons/faArrowSquareDown'),
        "farArrowSquareLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowSquareLeft'),
        "farArrowSquareRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowSquareRight'),
        "farArrowSquareUp": () => import('@fortawesome/pro-regular-svg-icons/faArrowSquareUp'),
        "farArrowToBottom": () => import('@fortawesome/pro-regular-svg-icons/faArrowToBottom'),
        "farArrowToLeft": () => import('@fortawesome/pro-regular-svg-icons/faArrowToLeft'),
        "farArrowToRight": () => import('@fortawesome/pro-regular-svg-icons/faArrowToRight'),
        "farArrowToTop": () => import('@fortawesome/pro-regular-svg-icons/faArrowToTop'),
        "farArrowUp": () => import('@fortawesome/pro-regular-svg-icons/faArrowUp'),
        "farArrows": () => import('@fortawesome/pro-regular-svg-icons/faArrows'),
        "farArrowsAlt": () => import('@fortawesome/pro-regular-svg-icons/faArrowsAlt'),
        "farArrowsAltH": () => import('@fortawesome/pro-regular-svg-icons/faArrowsAltH'),
        "farArrowsAltV": () => import('@fortawesome/pro-regular-svg-icons/faArrowsAltV'),
        "farArrowsH": () => import('@fortawesome/pro-regular-svg-icons/faArrowsH'),
        "farArrowsV": () => import('@fortawesome/pro-regular-svg-icons/faArrowsV'),
        "farAssistiveListeningSystems": () => import('@fortawesome/pro-regular-svg-icons/faAssistiveListeningSystems'),
        "farAsterisk": () => import('@fortawesome/pro-regular-svg-icons/faAsterisk'),
        "farAt": () => import('@fortawesome/pro-regular-svg-icons/faAt'),
        "farAtlas": () => import('@fortawesome/pro-regular-svg-icons/faAtlas'),
        "farAtom": () => import('@fortawesome/pro-regular-svg-icons/faAtom'),
        "farAtomAlt": () => import('@fortawesome/pro-regular-svg-icons/faAtomAlt'),
        "farAudioDescription": () => import('@fortawesome/pro-regular-svg-icons/faAudioDescription'),
        "farAward": () => import('@fortawesome/pro-regular-svg-icons/faAward'),
        "farAxe": () => import('@fortawesome/pro-regular-svg-icons/faAxe'),
        "farAxeBattle": () => import('@fortawesome/pro-regular-svg-icons/faAxeBattle'),
        "farBaby": () => import('@fortawesome/pro-regular-svg-icons/faBaby'),
        "farBabyCarriage": () => import('@fortawesome/pro-regular-svg-icons/faBabyCarriage'),
        "farBackpack": () => import('@fortawesome/pro-regular-svg-icons/faBackpack'),
        "farBackspace": () => import('@fortawesome/pro-regular-svg-icons/faBackspace'),
        "farBackward": () => import('@fortawesome/pro-regular-svg-icons/faBackward'),
        "farBacon": () => import('@fortawesome/pro-regular-svg-icons/faBacon'),
        "farBacteria": () => import('@fortawesome/pro-regular-svg-icons/faBacteria'),
        "farBacterium": () => import('@fortawesome/pro-regular-svg-icons/faBacterium'),
        "farBadge": () => import('@fortawesome/pro-regular-svg-icons/faBadge'),
        "farBadgeCheck": () => import('@fortawesome/pro-regular-svg-icons/faBadgeCheck'),
        "farBadgeDollar": () => import('@fortawesome/pro-regular-svg-icons/faBadgeDollar'),
        "farBadgePercent": () => import('@fortawesome/pro-regular-svg-icons/faBadgePercent'),
        "farBadgeSheriff": () => import('@fortawesome/pro-regular-svg-icons/faBadgeSheriff'),
        "farBadgerHoney": () => import('@fortawesome/pro-regular-svg-icons/faBadgerHoney'),
        "farBagsShopping": () => import('@fortawesome/pro-regular-svg-icons/faBagsShopping'),
        "farBahai": () => import('@fortawesome/pro-regular-svg-icons/faBahai'),
        "farBalanceScale": () => import('@fortawesome/pro-regular-svg-icons/faBalanceScale'),
        "farBalanceScaleLeft": () => import('@fortawesome/pro-regular-svg-icons/faBalanceScaleLeft'),
        "farBalanceScaleRight": () => import('@fortawesome/pro-regular-svg-icons/faBalanceScaleRight'),
        "farBallPile": () => import('@fortawesome/pro-regular-svg-icons/faBallPile'),
        "farBallot": () => import('@fortawesome/pro-regular-svg-icons/faBallot'),
        "farBallotCheck": () => import('@fortawesome/pro-regular-svg-icons/faBallotCheck'),
        "farBan": () => import('@fortawesome/pro-regular-svg-icons/faBan'),
        "farBandAid": () => import('@fortawesome/pro-regular-svg-icons/faBandAid'),
        "farBanjo": () => import('@fortawesome/pro-regular-svg-icons/faBanjo'),
        "farBarcode": () => import('@fortawesome/pro-regular-svg-icons/faBarcode'),
        "farBarcodeAlt": () => import('@fortawesome/pro-regular-svg-icons/faBarcodeAlt'),
        "farBarcodeRead": () => import('@fortawesome/pro-regular-svg-icons/faBarcodeRead'),
        "farBarcodeScan": () => import('@fortawesome/pro-regular-svg-icons/faBarcodeScan'),
        "farBars": () => import('@fortawesome/pro-regular-svg-icons/faBars'),
        "farBaseball": () => import('@fortawesome/pro-regular-svg-icons/faBaseball'),
        "farBaseballBall": () => import('@fortawesome/pro-regular-svg-icons/faBaseballBall'),
        "farBasketballBall": () => import('@fortawesome/pro-regular-svg-icons/faBasketballBall'),
        "farBasketballHoop": () => import('@fortawesome/pro-regular-svg-icons/faBasketballHoop'),
        "farBat": () => import('@fortawesome/pro-regular-svg-icons/faBat'),
        "farBath": () => import('@fortawesome/pro-regular-svg-icons/faBath'),
        "farBatteryBolt": () => import('@fortawesome/pro-regular-svg-icons/faBatteryBolt'),
        "farBatteryEmpty": () => import('@fortawesome/pro-regular-svg-icons/faBatteryEmpty'),
        "farBatteryFull": () => import('@fortawesome/pro-regular-svg-icons/faBatteryFull'),
        "farBatteryHalf": () => import('@fortawesome/pro-regular-svg-icons/faBatteryHalf'),
        "farBatteryQuarter": () => import('@fortawesome/pro-regular-svg-icons/faBatteryQuarter'),
        "farBatterySlash": () => import('@fortawesome/pro-regular-svg-icons/faBatterySlash'),
        "farBatteryThreeQuarters": () => import('@fortawesome/pro-regular-svg-icons/faBatteryThreeQuarters'),
        "farBed": () => import('@fortawesome/pro-regular-svg-icons/faBed'),
        "farBedAlt": () => import('@fortawesome/pro-regular-svg-icons/faBedAlt'),
        "farBedBunk": () => import('@fortawesome/pro-regular-svg-icons/faBedBunk'),
        "farBedEmpty": () => import('@fortawesome/pro-regular-svg-icons/faBedEmpty'),
        "farBeer": () => import('@fortawesome/pro-regular-svg-icons/faBeer'),
        "farBell": () => import('@fortawesome/pro-regular-svg-icons/faBell'),
        "farBellExclamation": () => import('@fortawesome/pro-regular-svg-icons/faBellExclamation'),
        "farBellOn": () => import('@fortawesome/pro-regular-svg-icons/faBellOn'),
        "farBellPlus": () => import('@fortawesome/pro-regular-svg-icons/faBellPlus'),
        "farBellSchool": () => import('@fortawesome/pro-regular-svg-icons/faBellSchool'),
        "farBellSchoolSlash": () => import('@fortawesome/pro-regular-svg-icons/faBellSchoolSlash'),
        "farBellSlash": () => import('@fortawesome/pro-regular-svg-icons/faBellSlash'),
        "farBells": () => import('@fortawesome/pro-regular-svg-icons/faBells'),
        "farBetamax": () => import('@fortawesome/pro-regular-svg-icons/faBetamax'),
        "farBezierCurve": () => import('@fortawesome/pro-regular-svg-icons/faBezierCurve'),
        "farBible": () => import('@fortawesome/pro-regular-svg-icons/faBible'),
        "farBicycle": () => import('@fortawesome/pro-regular-svg-icons/faBicycle'),
        "farBiking": () => import('@fortawesome/pro-regular-svg-icons/faBiking'),
        "farBikingMountain": () => import('@fortawesome/pro-regular-svg-icons/faBikingMountain'),
        "farBinoculars": () => import('@fortawesome/pro-regular-svg-icons/faBinoculars'),
        "farBiohazard": () => import('@fortawesome/pro-regular-svg-icons/faBiohazard'),
        "farBirthdayCake": () => import('@fortawesome/pro-regular-svg-icons/faBirthdayCake'),
        "farBlanket": () => import('@fortawesome/pro-regular-svg-icons/faBlanket'),
        "farBlender": () => import('@fortawesome/pro-regular-svg-icons/faBlender'),
        "farBlenderPhone": () => import('@fortawesome/pro-regular-svg-icons/faBlenderPhone'),
        "farBlind": () => import('@fortawesome/pro-regular-svg-icons/faBlind'),
        "farBlinds": () => import('@fortawesome/pro-regular-svg-icons/faBlinds'),
        "farBlindsOpen": () => import('@fortawesome/pro-regular-svg-icons/faBlindsOpen'),
        "farBlindsRaised": () => import('@fortawesome/pro-regular-svg-icons/faBlindsRaised'),
        "farBlog": () => import('@fortawesome/pro-regular-svg-icons/faBlog'),
        "farBold": () => import('@fortawesome/pro-regular-svg-icons/faBold'),
        "farBolt": () => import('@fortawesome/pro-regular-svg-icons/faBolt'),
        "farBomb": () => import('@fortawesome/pro-regular-svg-icons/faBomb'),
        "farBone": () => import('@fortawesome/pro-regular-svg-icons/faBone'),
        "farBoneBreak": () => import('@fortawesome/pro-regular-svg-icons/faBoneBreak'),
        "farBong": () => import('@fortawesome/pro-regular-svg-icons/faBong'),
        "farBook": () => import('@fortawesome/pro-regular-svg-icons/faBook'),
        "farBookAlt": () => import('@fortawesome/pro-regular-svg-icons/faBookAlt'),
        "farBookDead": () => import('@fortawesome/pro-regular-svg-icons/faBookDead'),
        "farBookHeart": () => import('@fortawesome/pro-regular-svg-icons/faBookHeart'),
        "farBookMedical": () => import('@fortawesome/pro-regular-svg-icons/faBookMedical'),
        "farBookOpen": () => import('@fortawesome/pro-regular-svg-icons/faBookOpen'),
        "farBookReader": () => import('@fortawesome/pro-regular-svg-icons/faBookReader'),
        "farBookSpells": () => import('@fortawesome/pro-regular-svg-icons/faBookSpells'),
        "farBookUser": () => import('@fortawesome/pro-regular-svg-icons/faBookUser'),
        "farBookmark": () => import('@fortawesome/pro-regular-svg-icons/faBookmark'),
        "farBooks": () => import('@fortawesome/pro-regular-svg-icons/faBooks'),
        "farBooksMedical": () => import('@fortawesome/pro-regular-svg-icons/faBooksMedical'),
        "farBoombox": () => import('@fortawesome/pro-regular-svg-icons/faBoombox'),
        "farBoot": () => import('@fortawesome/pro-regular-svg-icons/faBoot'),
        "farBoothCurtain": () => import('@fortawesome/pro-regular-svg-icons/faBoothCurtain'),
        "farBorderAll": () => import('@fortawesome/pro-regular-svg-icons/faBorderAll'),
        "farBorderBottom": () => import('@fortawesome/pro-regular-svg-icons/faBorderBottom'),
        "farBorderCenterH": () => import('@fortawesome/pro-regular-svg-icons/faBorderCenterH'),
        "farBorderCenterV": () => import('@fortawesome/pro-regular-svg-icons/faBorderCenterV'),
        "farBorderInner": () => import('@fortawesome/pro-regular-svg-icons/faBorderInner'),
        "farBorderLeft": () => import('@fortawesome/pro-regular-svg-icons/faBorderLeft'),
        "farBorderNone": () => import('@fortawesome/pro-regular-svg-icons/faBorderNone'),
        "farBorderOuter": () => import('@fortawesome/pro-regular-svg-icons/faBorderOuter'),
        "farBorderRight": () => import('@fortawesome/pro-regular-svg-icons/faBorderRight'),
        "farBorderStyle": () => import('@fortawesome/pro-regular-svg-icons/faBorderStyle'),
        "farBorderStyleAlt": () => import('@fortawesome/pro-regular-svg-icons/faBorderStyleAlt'),
        "farBorderTop": () => import('@fortawesome/pro-regular-svg-icons/faBorderTop'),
        "farBowArrow": () => import('@fortawesome/pro-regular-svg-icons/faBowArrow'),
        "farBowlingBall": () => import('@fortawesome/pro-regular-svg-icons/faBowlingBall'),
        "farBowlingPins": () => import('@fortawesome/pro-regular-svg-icons/faBowlingPins'),
        "farBox": () => import('@fortawesome/pro-regular-svg-icons/faBox'),
        "farBoxAlt": () => import('@fortawesome/pro-regular-svg-icons/faBoxAlt'),
        "farBoxBallot": () => import('@fortawesome/pro-regular-svg-icons/faBoxBallot'),
        "farBoxCheck": () => import('@fortawesome/pro-regular-svg-icons/faBoxCheck'),
        "farBoxFragile": () => import('@fortawesome/pro-regular-svg-icons/faBoxFragile'),
        "farBoxFull": () => import('@fortawesome/pro-regular-svg-icons/faBoxFull'),
        "farBoxHeart": () => import('@fortawesome/pro-regular-svg-icons/faBoxHeart'),
        "farBoxOpen": () => import('@fortawesome/pro-regular-svg-icons/faBoxOpen'),
        "farBoxTissue": () => import('@fortawesome/pro-regular-svg-icons/faBoxTissue'),
        "farBoxUp": () => import('@fortawesome/pro-regular-svg-icons/faBoxUp'),
        "farBoxUsd": () => import('@fortawesome/pro-regular-svg-icons/faBoxUsd'),
        "farBoxes": () => import('@fortawesome/pro-regular-svg-icons/faBoxes'),
        "farBoxesAlt": () => import('@fortawesome/pro-regular-svg-icons/faBoxesAlt'),
        "farBoxingGlove": () => import('@fortawesome/pro-regular-svg-icons/faBoxingGlove'),
        "farBrackets": () => import('@fortawesome/pro-regular-svg-icons/faBrackets'),
        "farBracketsCurly": () => import('@fortawesome/pro-regular-svg-icons/faBracketsCurly'),
        "farBraille": () => import('@fortawesome/pro-regular-svg-icons/faBraille'),
        "farBrain": () => import('@fortawesome/pro-regular-svg-icons/faBrain'),
        "farBreadLoaf": () => import('@fortawesome/pro-regular-svg-icons/faBreadLoaf'),
        "farBreadSlice": () => import('@fortawesome/pro-regular-svg-icons/faBreadSlice'),
        "farBriefcase": () => import('@fortawesome/pro-regular-svg-icons/faBriefcase'),
        "farBriefcaseMedical": () => import('@fortawesome/pro-regular-svg-icons/faBriefcaseMedical'),
        "farBringForward": () => import('@fortawesome/pro-regular-svg-icons/faBringForward'),
        "farBringFront": () => import('@fortawesome/pro-regular-svg-icons/faBringFront'),
        "farBroadcastTower": () => import('@fortawesome/pro-regular-svg-icons/faBroadcastTower'),
        "farBroom": () => import('@fortawesome/pro-regular-svg-icons/faBroom'),
        "farBrowser": () => import('@fortawesome/pro-regular-svg-icons/faBrowser'),
        "farBrush": () => import('@fortawesome/pro-regular-svg-icons/faBrush'),
        "farBug": () => import('@fortawesome/pro-regular-svg-icons/faBug'),
        "farBuilding": () => import('@fortawesome/pro-regular-svg-icons/faBuilding'),
        "farBullhorn": () => import('@fortawesome/pro-regular-svg-icons/faBullhorn'),
        "farBullseye": () => import('@fortawesome/pro-regular-svg-icons/faBullseye'),
        "farBullseyeArrow": () => import('@fortawesome/pro-regular-svg-icons/faBullseyeArrow'),
        "farBullseyePointer": () => import('@fortawesome/pro-regular-svg-icons/faBullseyePointer'),
        "farBurgerSoda": () => import('@fortawesome/pro-regular-svg-icons/faBurgerSoda'),
        "farBurn": () => import('@fortawesome/pro-regular-svg-icons/faBurn'),
        "farBurrito": () => import('@fortawesome/pro-regular-svg-icons/faBurrito'),
        "farBus": () => import('@fortawesome/pro-regular-svg-icons/faBus'),
        "farBusAlt": () => import('@fortawesome/pro-regular-svg-icons/faBusAlt'),
        "farBusSchool": () => import('@fortawesome/pro-regular-svg-icons/faBusSchool'),
        "farBusinessTime": () => import('@fortawesome/pro-regular-svg-icons/faBusinessTime'),
        "farCabinetFiling": () => import('@fortawesome/pro-regular-svg-icons/faCabinetFiling'),
        "farCactus": () => import('@fortawesome/pro-regular-svg-icons/faCactus'),
        "farCalculator": () => import('@fortawesome/pro-regular-svg-icons/faCalculator'),
        "farCalculatorAlt": () => import('@fortawesome/pro-regular-svg-icons/faCalculatorAlt'),
        "farCalendar": () => import('@fortawesome/pro-regular-svg-icons/faCalendar'),
        "farCalendarAlt": () => import('@fortawesome/pro-regular-svg-icons/faCalendarAlt'),
        "farCalendarCheck": () => import('@fortawesome/pro-regular-svg-icons/faCalendarCheck'),
        "farCalendarDay": () => import('@fortawesome/pro-regular-svg-icons/faCalendarDay'),
        "farCalendarEdit": () => import('@fortawesome/pro-regular-svg-icons/faCalendarEdit'),
        "farCalendarExclamation": () => import('@fortawesome/pro-regular-svg-icons/faCalendarExclamation'),
        "farCalendarMinus": () => import('@fortawesome/pro-regular-svg-icons/faCalendarMinus'),
        "farCalendarPlus": () => import('@fortawesome/pro-regular-svg-icons/faCalendarPlus'),
        "farCalendarStar": () => import('@fortawesome/pro-regular-svg-icons/faCalendarStar'),
        "farCalendarTimes": () => import('@fortawesome/pro-regular-svg-icons/faCalendarTimes'),
        "farCalendarWeek": () => import('@fortawesome/pro-regular-svg-icons/faCalendarWeek'),
        "farCamcorder": () => import('@fortawesome/pro-regular-svg-icons/faCamcorder'),
        "farCamera": () => import('@fortawesome/pro-regular-svg-icons/faCamera'),
        "farCameraAlt": () => import('@fortawesome/pro-regular-svg-icons/faCameraAlt'),
        "farCameraHome": () => import('@fortawesome/pro-regular-svg-icons/faCameraHome'),
        "farCameraMovie": () => import('@fortawesome/pro-regular-svg-icons/faCameraMovie'),
        "farCameraPolaroid": () => import('@fortawesome/pro-regular-svg-icons/faCameraPolaroid'),
        "farCameraRetro": () => import('@fortawesome/pro-regular-svg-icons/faCameraRetro'),
        "farCampfire": () => import('@fortawesome/pro-regular-svg-icons/faCampfire'),
        "farCampground": () => import('@fortawesome/pro-regular-svg-icons/faCampground'),
        "farCandleHolder": () => import('@fortawesome/pro-regular-svg-icons/faCandleHolder'),
        "farCandyCane": () => import('@fortawesome/pro-regular-svg-icons/faCandyCane'),
        "farCandyCorn": () => import('@fortawesome/pro-regular-svg-icons/faCandyCorn'),
        "farCannabis": () => import('@fortawesome/pro-regular-svg-icons/faCannabis'),
        "farCapsules": () => import('@fortawesome/pro-regular-svg-icons/faCapsules'),
        "farCar": () => import('@fortawesome/pro-regular-svg-icons/faCar'),
        "farCarAlt": () => import('@fortawesome/pro-regular-svg-icons/faCarAlt'),
        "farCarBattery": () => import('@fortawesome/pro-regular-svg-icons/faCarBattery'),
        "farCarBuilding": () => import('@fortawesome/pro-regular-svg-icons/faCarBuilding'),
        "farCarBump": () => import('@fortawesome/pro-regular-svg-icons/faCarBump'),
        "farCarBus": () => import('@fortawesome/pro-regular-svg-icons/faCarBus'),
        "farCarCrash": () => import('@fortawesome/pro-regular-svg-icons/faCarCrash'),
        "farCarGarage": () => import('@fortawesome/pro-regular-svg-icons/faCarGarage'),
        "farCarMechanic": () => import('@fortawesome/pro-regular-svg-icons/faCarMechanic'),
        "farCarSide": () => import('@fortawesome/pro-regular-svg-icons/faCarSide'),
        "farCarTilt": () => import('@fortawesome/pro-regular-svg-icons/faCarTilt'),
        "farCarWash": () => import('@fortawesome/pro-regular-svg-icons/faCarWash'),
        "farCaravan": () => import('@fortawesome/pro-regular-svg-icons/faCaravan'),
        "farCaravanAlt": () => import('@fortawesome/pro-regular-svg-icons/faCaravanAlt'),
        "farCaretCircleDown": () => import('@fortawesome/pro-regular-svg-icons/faCaretCircleDown'),
        "farCaretCircleLeft": () => import('@fortawesome/pro-regular-svg-icons/faCaretCircleLeft'),
        "farCaretCircleRight": () => import('@fortawesome/pro-regular-svg-icons/faCaretCircleRight'),
        "farCaretCircleUp": () => import('@fortawesome/pro-regular-svg-icons/faCaretCircleUp'),
        "farCaretDown": () => import('@fortawesome/pro-regular-svg-icons/faCaretDown'),
        "farCaretLeft": () => import('@fortawesome/pro-regular-svg-icons/faCaretLeft'),
        "farCaretRight": () => import('@fortawesome/pro-regular-svg-icons/faCaretRight'),
        "farCaretSquareDown": () => import('@fortawesome/pro-regular-svg-icons/faCaretSquareDown'),
        "farCaretSquareLeft": () => import('@fortawesome/pro-regular-svg-icons/faCaretSquareLeft'),
        "farCaretSquareRight": () => import('@fortawesome/pro-regular-svg-icons/faCaretSquareRight'),
        "farCaretSquareUp": () => import('@fortawesome/pro-regular-svg-icons/faCaretSquareUp'),
        "farCaretUp": () => import('@fortawesome/pro-regular-svg-icons/faCaretUp'),
        "farCarrot": () => import('@fortawesome/pro-regular-svg-icons/faCarrot'),
        "farCars": () => import('@fortawesome/pro-regular-svg-icons/faCars'),
        "farCartArrowDown": () => import('@fortawesome/pro-regular-svg-icons/faCartArrowDown'),
        "farCartPlus": () => import('@fortawesome/pro-regular-svg-icons/faCartPlus'),
        "farCashRegister": () => import('@fortawesome/pro-regular-svg-icons/faCashRegister'),
        "farCassetteTape": () => import('@fortawesome/pro-regular-svg-icons/faCassetteTape'),
        "farCat": () => import('@fortawesome/pro-regular-svg-icons/faCat'),
        "farCatSpace": () => import('@fortawesome/pro-regular-svg-icons/faCatSpace'),
        "farCauldron": () => import('@fortawesome/pro-regular-svg-icons/faCauldron'),
        "farCctv": () => import('@fortawesome/pro-regular-svg-icons/faCctv'),
        "farCertificate": () => import('@fortawesome/pro-regular-svg-icons/faCertificate'),
        "farChair": () => import('@fortawesome/pro-regular-svg-icons/faChair'),
        "farChairOffice": () => import('@fortawesome/pro-regular-svg-icons/faChairOffice'),
        "farChalkboard": () => import('@fortawesome/pro-regular-svg-icons/faChalkboard'),
        "farChalkboardTeacher": () => import('@fortawesome/pro-regular-svg-icons/faChalkboardTeacher'),
        "farChargingStation": () => import('@fortawesome/pro-regular-svg-icons/faChargingStation'),
        "farChartArea": () => import('@fortawesome/pro-regular-svg-icons/faChartArea'),
        "farChartBar": () => import('@fortawesome/pro-regular-svg-icons/faChartBar'),
        "farChartLine": () => import('@fortawesome/pro-regular-svg-icons/faChartLine'),
        "farChartLineDown": () => import('@fortawesome/pro-regular-svg-icons/faChartLineDown'),
        "farChartNetwork": () => import('@fortawesome/pro-regular-svg-icons/faChartNetwork'),
        "farChartPie": () => import('@fortawesome/pro-regular-svg-icons/faChartPie'),
        "farChartPieAlt": () => import('@fortawesome/pro-regular-svg-icons/faChartPieAlt'),
        "farChartScatter": () => import('@fortawesome/pro-regular-svg-icons/faChartScatter'),
        "farCheck": () => import('@fortawesome/pro-regular-svg-icons/faCheck'),
        "farCheckCircle": () => import('@fortawesome/pro-regular-svg-icons/faCheckCircle'),
        "farCheckDouble": () => import('@fortawesome/pro-regular-svg-icons/faCheckDouble'),
        "farCheckSquare": () => import('@fortawesome/pro-regular-svg-icons/faCheckSquare'),
        "farCheese": () => import('@fortawesome/pro-regular-svg-icons/faCheese'),
        "farCheeseSwiss": () => import('@fortawesome/pro-regular-svg-icons/faCheeseSwiss'),
        "farCheeseburger": () => import('@fortawesome/pro-regular-svg-icons/faCheeseburger'),
        "farChess": () => import('@fortawesome/pro-regular-svg-icons/faChess'),
        "farChessBishop": () => import('@fortawesome/pro-regular-svg-icons/faChessBishop'),
        "farChessBishopAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessBishopAlt'),
        "farChessBoard": () => import('@fortawesome/pro-regular-svg-icons/faChessBoard'),
        "farChessClock": () => import('@fortawesome/pro-regular-svg-icons/faChessClock'),
        "farChessClockAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessClockAlt'),
        "farChessKing": () => import('@fortawesome/pro-regular-svg-icons/faChessKing'),
        "farChessKingAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessKingAlt'),
        "farChessKnight": () => import('@fortawesome/pro-regular-svg-icons/faChessKnight'),
        "farChessKnightAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessKnightAlt'),
        "farChessPawn": () => import('@fortawesome/pro-regular-svg-icons/faChessPawn'),
        "farChessPawnAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessPawnAlt'),
        "farChessQueen": () => import('@fortawesome/pro-regular-svg-icons/faChessQueen'),
        "farChessQueenAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessQueenAlt'),
        "farChessRook": () => import('@fortawesome/pro-regular-svg-icons/faChessRook'),
        "farChessRookAlt": () => import('@fortawesome/pro-regular-svg-icons/faChessRookAlt'),
        "farChevronCircleDown": () => import('@fortawesome/pro-regular-svg-icons/faChevronCircleDown'),
        "farChevronCircleLeft": () => import('@fortawesome/pro-regular-svg-icons/faChevronCircleLeft'),
        "farChevronCircleRight": () => import('@fortawesome/pro-regular-svg-icons/faChevronCircleRight'),
        "farChevronCircleUp": () => import('@fortawesome/pro-regular-svg-icons/faChevronCircleUp'),
        "farChevronDoubleDown": () => import('@fortawesome/pro-regular-svg-icons/faChevronDoubleDown'),
        "farChevronDoubleLeft": () => import('@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft'),
        "farChevronDoubleRight": () => import('@fortawesome/pro-regular-svg-icons/faChevronDoubleRight'),
        "farChevronDoubleUp": () => import('@fortawesome/pro-regular-svg-icons/faChevronDoubleUp'),
        "farChevronDown": () => import('@fortawesome/pro-regular-svg-icons/faChevronDown'),
        "farChevronLeft": () => import('@fortawesome/pro-regular-svg-icons/faChevronLeft'),
        "farChevronRight": () => import('@fortawesome/pro-regular-svg-icons/faChevronRight'),
        "farChevronSquareDown": () => import('@fortawesome/pro-regular-svg-icons/faChevronSquareDown'),
        "farChevronSquareLeft": () => import('@fortawesome/pro-regular-svg-icons/faChevronSquareLeft'),
        "farChevronSquareRight": () => import('@fortawesome/pro-regular-svg-icons/faChevronSquareRight'),
        "farChevronSquareUp": () => import('@fortawesome/pro-regular-svg-icons/faChevronSquareUp'),
        "farChevronUp": () => import('@fortawesome/pro-regular-svg-icons/faChevronUp'),
        "farChild": () => import('@fortawesome/pro-regular-svg-icons/faChild'),
        "farChimney": () => import('@fortawesome/pro-regular-svg-icons/faChimney'),
        "farChurch": () => import('@fortawesome/pro-regular-svg-icons/faChurch'),
        "farCircle": () => import('@fortawesome/pro-regular-svg-icons/faCircle'),
        "farCircleNotch": () => import('@fortawesome/pro-regular-svg-icons/faCircleNotch'),
        "farCity": () => import('@fortawesome/pro-regular-svg-icons/faCity'),
        "farClarinet": () => import('@fortawesome/pro-regular-svg-icons/faClarinet'),
        "farClawMarks": () => import('@fortawesome/pro-regular-svg-icons/faClawMarks'),
        "farClinicMedical": () => import('@fortawesome/pro-regular-svg-icons/faClinicMedical'),
        "farClipboard": () => import('@fortawesome/pro-regular-svg-icons/faClipboard'),
        "farClipboardCheck": () => import('@fortawesome/pro-regular-svg-icons/faClipboardCheck'),
        "farClipboardList": () => import('@fortawesome/pro-regular-svg-icons/faClipboardList'),
        "farClipboardListCheck": () => import('@fortawesome/pro-regular-svg-icons/faClipboardListCheck'),
        "farClipboardPrescription": () => import('@fortawesome/pro-regular-svg-icons/faClipboardPrescription'),
        "farClipboardUser": () => import('@fortawesome/pro-regular-svg-icons/faClipboardUser'),
        "farClock": () => import('@fortawesome/pro-regular-svg-icons/faClock'),
        "farClone": () => import('@fortawesome/pro-regular-svg-icons/faClone'),
        "farClosedCaptioning": () => import('@fortawesome/pro-regular-svg-icons/faClosedCaptioning'),
        "farCloud": () => import('@fortawesome/pro-regular-svg-icons/faCloud'),
        "farCloudDownload": () => import('@fortawesome/pro-regular-svg-icons/faCloudDownload'),
        "farCloudDownloadAlt": () => import('@fortawesome/pro-regular-svg-icons/faCloudDownloadAlt'),
        "farCloudDrizzle": () => import('@fortawesome/pro-regular-svg-icons/faCloudDrizzle'),
        "farCloudHail": () => import('@fortawesome/pro-regular-svg-icons/faCloudHail'),
        "farCloudHailMixed": () => import('@fortawesome/pro-regular-svg-icons/faCloudHailMixed'),
        "farCloudMeatball": () => import('@fortawesome/pro-regular-svg-icons/faCloudMeatball'),
        "farCloudMoon": () => import('@fortawesome/pro-regular-svg-icons/faCloudMoon'),
        "farCloudMoonRain": () => import('@fortawesome/pro-regular-svg-icons/faCloudMoonRain'),
        "farCloudMusic": () => import('@fortawesome/pro-regular-svg-icons/faCloudMusic'),
        "farCloudRain": () => import('@fortawesome/pro-regular-svg-icons/faCloudRain'),
        "farCloudRainbow": () => import('@fortawesome/pro-regular-svg-icons/faCloudRainbow'),
        "farCloudShowers": () => import('@fortawesome/pro-regular-svg-icons/faCloudShowers'),
        "farCloudShowersHeavy": () => import('@fortawesome/pro-regular-svg-icons/faCloudShowersHeavy'),
        "farCloudSleet": () => import('@fortawesome/pro-regular-svg-icons/faCloudSleet'),
        "farCloudSnow": () => import('@fortawesome/pro-regular-svg-icons/faCloudSnow'),
        "farCloudSun": () => import('@fortawesome/pro-regular-svg-icons/faCloudSun'),
        "farCloudSunRain": () => import('@fortawesome/pro-regular-svg-icons/faCloudSunRain'),
        "farCloudUpload": () => import('@fortawesome/pro-regular-svg-icons/faCloudUpload'),
        "farCloudUploadAlt": () => import('@fortawesome/pro-regular-svg-icons/faCloudUploadAlt'),
        "farClouds": () => import('@fortawesome/pro-regular-svg-icons/faClouds'),
        "farCloudsMoon": () => import('@fortawesome/pro-regular-svg-icons/faCloudsMoon'),
        "farCloudsSun": () => import('@fortawesome/pro-regular-svg-icons/faCloudsSun'),
        "farClub": () => import('@fortawesome/pro-regular-svg-icons/faClub'),
        "farCocktail": () => import('@fortawesome/pro-regular-svg-icons/faCocktail'),
        "farCode": () => import('@fortawesome/pro-regular-svg-icons/faCode'),
        "farCodeBranch": () => import('@fortawesome/pro-regular-svg-icons/faCodeBranch'),
        "farCodeCommit": () => import('@fortawesome/pro-regular-svg-icons/faCodeCommit'),
        "farCodeMerge": () => import('@fortawesome/pro-regular-svg-icons/faCodeMerge'),
        "farCoffee": () => import('@fortawesome/pro-regular-svg-icons/faCoffee'),
        "farCoffeePot": () => import('@fortawesome/pro-regular-svg-icons/faCoffeePot'),
        "farCoffeeTogo": () => import('@fortawesome/pro-regular-svg-icons/faCoffeeTogo'),
        "farCoffin": () => import('@fortawesome/pro-regular-svg-icons/faCoffin'),
        "farCoffinCross": () => import('@fortawesome/pro-regular-svg-icons/faCoffinCross'),
        "farCog": () => import('@fortawesome/pro-regular-svg-icons/faCog'),
        "farCogs": () => import('@fortawesome/pro-regular-svg-icons/faCogs'),
        "farCoin": () => import('@fortawesome/pro-regular-svg-icons/faCoin'),
        "farCoins": () => import('@fortawesome/pro-regular-svg-icons/faCoins'),
        "farColumns": () => import('@fortawesome/pro-regular-svg-icons/faColumns'),
        "farComet": () => import('@fortawesome/pro-regular-svg-icons/faComet'),
        "farComment": () => import('@fortawesome/pro-regular-svg-icons/faComment'),
        "farCommentAlt": () => import('@fortawesome/pro-regular-svg-icons/faCommentAlt'),
        "farCommentAltCheck": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltCheck'),
        "farCommentAltDollar": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltDollar'),
        "farCommentAltDots": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltDots'),
        "farCommentAltEdit": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltEdit'),
        "farCommentAltExclamation": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltExclamation'),
        "farCommentAltLines": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltLines'),
        "farCommentAltMedical": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltMedical'),
        "farCommentAltMinus": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltMinus'),
        "farCommentAltMusic": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltMusic'),
        "farCommentAltPlus": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltPlus'),
        "farCommentAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltSlash'),
        "farCommentAltSmile": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltSmile'),
        "farCommentAltTimes": () => import('@fortawesome/pro-regular-svg-icons/faCommentAltTimes'),
        "farCommentCheck": () => import('@fortawesome/pro-regular-svg-icons/faCommentCheck'),
        "farCommentDollar": () => import('@fortawesome/pro-regular-svg-icons/faCommentDollar'),
        "farCommentDots": () => import('@fortawesome/pro-regular-svg-icons/faCommentDots'),
        "farCommentEdit": () => import('@fortawesome/pro-regular-svg-icons/faCommentEdit'),
        "farCommentExclamation": () => import('@fortawesome/pro-regular-svg-icons/faCommentExclamation'),
        "farCommentLines": () => import('@fortawesome/pro-regular-svg-icons/faCommentLines'),
        "farCommentMedical": () => import('@fortawesome/pro-regular-svg-icons/faCommentMedical'),
        "farCommentMinus": () => import('@fortawesome/pro-regular-svg-icons/faCommentMinus'),
        "farCommentMusic": () => import('@fortawesome/pro-regular-svg-icons/faCommentMusic'),
        "farCommentPlus": () => import('@fortawesome/pro-regular-svg-icons/faCommentPlus'),
        "farCommentSlash": () => import('@fortawesome/pro-regular-svg-icons/faCommentSlash'),
        "farCommentSmile": () => import('@fortawesome/pro-regular-svg-icons/faCommentSmile'),
        "farCommentTimes": () => import('@fortawesome/pro-regular-svg-icons/faCommentTimes'),
        "farComments": () => import('@fortawesome/pro-regular-svg-icons/faComments'),
        "farCommentsAlt": () => import('@fortawesome/pro-regular-svg-icons/faCommentsAlt'),
        "farCommentsAltDollar": () => import('@fortawesome/pro-regular-svg-icons/faCommentsAltDollar'),
        "farCommentsDollar": () => import('@fortawesome/pro-regular-svg-icons/faCommentsDollar'),
        "farCompactDisc": () => import('@fortawesome/pro-regular-svg-icons/faCompactDisc'),
        "farCompass": () => import('@fortawesome/pro-regular-svg-icons/faCompass'),
        "farCompassSlash": () => import('@fortawesome/pro-regular-svg-icons/faCompassSlash'),
        "farCompress": () => import('@fortawesome/pro-regular-svg-icons/faCompress'),
        "farCompressAlt": () => import('@fortawesome/pro-regular-svg-icons/faCompressAlt'),
        "farCompressArrowsAlt": () => import('@fortawesome/pro-regular-svg-icons/faCompressArrowsAlt'),
        "farCompressWide": () => import('@fortawesome/pro-regular-svg-icons/faCompressWide'),
        "farComputerClassic": () => import('@fortawesome/pro-regular-svg-icons/faComputerClassic'),
        "farComputerSpeaker": () => import('@fortawesome/pro-regular-svg-icons/faComputerSpeaker'),
        "farConciergeBell": () => import('@fortawesome/pro-regular-svg-icons/faConciergeBell'),
        "farConstruction": () => import('@fortawesome/pro-regular-svg-icons/faConstruction'),
        "farContainerStorage": () => import('@fortawesome/pro-regular-svg-icons/faContainerStorage'),
        "farConveyorBelt": () => import('@fortawesome/pro-regular-svg-icons/faConveyorBelt'),
        "farConveyorBeltAlt": () => import('@fortawesome/pro-regular-svg-icons/faConveyorBeltAlt'),
        "farCookie": () => import('@fortawesome/pro-regular-svg-icons/faCookie'),
        "farCookieBite": () => import('@fortawesome/pro-regular-svg-icons/faCookieBite'),
        "farCopy": () => import('@fortawesome/pro-regular-svg-icons/faCopy'),
        "farCopyright": () => import('@fortawesome/pro-regular-svg-icons/faCopyright'),
        "farCorn": () => import('@fortawesome/pro-regular-svg-icons/faCorn'),
        "farCouch": () => import('@fortawesome/pro-regular-svg-icons/faCouch'),
        "farCow": () => import('@fortawesome/pro-regular-svg-icons/faCow'),
        "farCowbell": () => import('@fortawesome/pro-regular-svg-icons/faCowbell'),
        "farCowbellMore": () => import('@fortawesome/pro-regular-svg-icons/faCowbellMore'),
        "farCreditCard": () => import('@fortawesome/pro-regular-svg-icons/faCreditCard'),
        "farCreditCardBlank": () => import('@fortawesome/pro-regular-svg-icons/faCreditCardBlank'),
        "farCreditCardFront": () => import('@fortawesome/pro-regular-svg-icons/faCreditCardFront'),
        "farCricket": () => import('@fortawesome/pro-regular-svg-icons/faCricket'),
        "farCroissant": () => import('@fortawesome/pro-regular-svg-icons/faCroissant'),
        "farCrop": () => import('@fortawesome/pro-regular-svg-icons/faCrop'),
        "farCropAlt": () => import('@fortawesome/pro-regular-svg-icons/faCropAlt'),
        "farCross": () => import('@fortawesome/pro-regular-svg-icons/faCross'),
        "farCrosshairs": () => import('@fortawesome/pro-regular-svg-icons/faCrosshairs'),
        "farCrow": () => import('@fortawesome/pro-regular-svg-icons/faCrow'),
        "farCrown": () => import('@fortawesome/pro-regular-svg-icons/faCrown'),
        "farCrutch": () => import('@fortawesome/pro-regular-svg-icons/faCrutch'),
        "farCrutches": () => import('@fortawesome/pro-regular-svg-icons/faCrutches'),
        "farCube": () => import('@fortawesome/pro-regular-svg-icons/faCube'),
        "farCubes": () => import('@fortawesome/pro-regular-svg-icons/faCubes'),
        "farCurling": () => import('@fortawesome/pro-regular-svg-icons/faCurling'),
        "farCut": () => import('@fortawesome/pro-regular-svg-icons/faCut'),
        "farDagger": () => import('@fortawesome/pro-regular-svg-icons/faDagger'),
        "farDatabase": () => import('@fortawesome/pro-regular-svg-icons/faDatabase'),
        "farDeaf": () => import('@fortawesome/pro-regular-svg-icons/faDeaf'),
        "farDebug": () => import('@fortawesome/pro-regular-svg-icons/faDebug'),
        "farDeer": () => import('@fortawesome/pro-regular-svg-icons/faDeer'),
        "farDeerRudolph": () => import('@fortawesome/pro-regular-svg-icons/faDeerRudolph'),
        "farDemocrat": () => import('@fortawesome/pro-regular-svg-icons/faDemocrat'),
        "farDesktop": () => import('@fortawesome/pro-regular-svg-icons/faDesktop'),
        "farDesktopAlt": () => import('@fortawesome/pro-regular-svg-icons/faDesktopAlt'),
        "farDewpoint": () => import('@fortawesome/pro-regular-svg-icons/faDewpoint'),
        "farDharmachakra": () => import('@fortawesome/pro-regular-svg-icons/faDharmachakra'),
        "farDiagnoses": () => import('@fortawesome/pro-regular-svg-icons/faDiagnoses'),
        "farDiamond": () => import('@fortawesome/pro-regular-svg-icons/faDiamond'),
        "farDice": () => import('@fortawesome/pro-regular-svg-icons/faDice'),
        "farDiceD10": () => import('@fortawesome/pro-regular-svg-icons/faDiceD10'),
        "farDiceD12": () => import('@fortawesome/pro-regular-svg-icons/faDiceD12'),
        "farDiceD20": () => import('@fortawesome/pro-regular-svg-icons/faDiceD20'),
        "farDiceD4": () => import('@fortawesome/pro-regular-svg-icons/faDiceD4'),
        "farDiceD6": () => import('@fortawesome/pro-regular-svg-icons/faDiceD6'),
        "farDiceD8": () => import('@fortawesome/pro-regular-svg-icons/faDiceD8'),
        "farDiceFive": () => import('@fortawesome/pro-regular-svg-icons/faDiceFive'),
        "farDiceFour": () => import('@fortawesome/pro-regular-svg-icons/faDiceFour'),
        "farDiceOne": () => import('@fortawesome/pro-regular-svg-icons/faDiceOne'),
        "farDiceSix": () => import('@fortawesome/pro-regular-svg-icons/faDiceSix'),
        "farDiceThree": () => import('@fortawesome/pro-regular-svg-icons/faDiceThree'),
        "farDiceTwo": () => import('@fortawesome/pro-regular-svg-icons/faDiceTwo'),
        "farDigging": () => import('@fortawesome/pro-regular-svg-icons/faDigging'),
        "farDigitalTachograph": () => import('@fortawesome/pro-regular-svg-icons/faDigitalTachograph'),
        "farDiploma": () => import('@fortawesome/pro-regular-svg-icons/faDiploma'),
        "farDirections": () => import('@fortawesome/pro-regular-svg-icons/faDirections'),
        "farDiscDrive": () => import('@fortawesome/pro-regular-svg-icons/faDiscDrive'),
        "farDisease": () => import('@fortawesome/pro-regular-svg-icons/faDisease'),
        "farDivide": () => import('@fortawesome/pro-regular-svg-icons/faDivide'),
        "farDizzy": () => import('@fortawesome/pro-regular-svg-icons/faDizzy'),
        "farDna": () => import('@fortawesome/pro-regular-svg-icons/faDna'),
        "farDoNotEnter": () => import('@fortawesome/pro-regular-svg-icons/faDoNotEnter'),
        "farDog": () => import('@fortawesome/pro-regular-svg-icons/faDog'),
        "farDogLeashed": () => import('@fortawesome/pro-regular-svg-icons/faDogLeashed'),
        "farDollarSign": () => import('@fortawesome/pro-regular-svg-icons/faDollarSign'),
        "farDolly": () => import('@fortawesome/pro-regular-svg-icons/faDolly'),
        "farDollyEmpty": () => import('@fortawesome/pro-regular-svg-icons/faDollyEmpty'),
        "farDollyFlatbed": () => import('@fortawesome/pro-regular-svg-icons/faDollyFlatbed'),
        "farDollyFlatbedAlt": () => import('@fortawesome/pro-regular-svg-icons/faDollyFlatbedAlt'),
        "farDollyFlatbedEmpty": () => import('@fortawesome/pro-regular-svg-icons/faDollyFlatbedEmpty'),
        "farDonate": () => import('@fortawesome/pro-regular-svg-icons/faDonate'),
        "farDoorClosed": () => import('@fortawesome/pro-regular-svg-icons/faDoorClosed'),
        "farDoorOpen": () => import('@fortawesome/pro-regular-svg-icons/faDoorOpen'),
        "farDotCircle": () => import('@fortawesome/pro-regular-svg-icons/faDotCircle'),
        "farDove": () => import('@fortawesome/pro-regular-svg-icons/faDove'),
        "farDownload": () => import('@fortawesome/pro-regular-svg-icons/faDownload'),
        "farDraftingCompass": () => import('@fortawesome/pro-regular-svg-icons/faDraftingCompass'),
        "farDragon": () => import('@fortawesome/pro-regular-svg-icons/faDragon'),
        "farDrawCircle": () => import('@fortawesome/pro-regular-svg-icons/faDrawCircle'),
        "farDrawPolygon": () => import('@fortawesome/pro-regular-svg-icons/faDrawPolygon'),
        "farDrawSquare": () => import('@fortawesome/pro-regular-svg-icons/faDrawSquare'),
        "farDreidel": () => import('@fortawesome/pro-regular-svg-icons/faDreidel'),
        "farDrone": () => import('@fortawesome/pro-regular-svg-icons/faDrone'),
        "farDroneAlt": () => import('@fortawesome/pro-regular-svg-icons/faDroneAlt'),
        "farDrum": () => import('@fortawesome/pro-regular-svg-icons/faDrum'),
        "farDrumSteelpan": () => import('@fortawesome/pro-regular-svg-icons/faDrumSteelpan'),
        "farDrumstick": () => import('@fortawesome/pro-regular-svg-icons/faDrumstick'),
        "farDrumstickBite": () => import('@fortawesome/pro-regular-svg-icons/faDrumstickBite'),
        "farDryer": () => import('@fortawesome/pro-regular-svg-icons/faDryer'),
        "farDryerAlt": () => import('@fortawesome/pro-regular-svg-icons/faDryerAlt'),
        "farDuck": () => import('@fortawesome/pro-regular-svg-icons/faDuck'),
        "farDumbbell": () => import('@fortawesome/pro-regular-svg-icons/faDumbbell'),
        "farDumpster": () => import('@fortawesome/pro-regular-svg-icons/faDumpster'),
        "farDumpsterFire": () => import('@fortawesome/pro-regular-svg-icons/faDumpsterFire'),
        "farDungeon": () => import('@fortawesome/pro-regular-svg-icons/faDungeon'),
        "farEar": () => import('@fortawesome/pro-regular-svg-icons/faEar'),
        "farEarMuffs": () => import('@fortawesome/pro-regular-svg-icons/faEarMuffs'),
        "farEclipse": () => import('@fortawesome/pro-regular-svg-icons/faEclipse'),
        "farEclipseAlt": () => import('@fortawesome/pro-regular-svg-icons/faEclipseAlt'),
        "farEdit": () => import('@fortawesome/pro-regular-svg-icons/faEdit'),
        "farEgg": () => import('@fortawesome/pro-regular-svg-icons/faEgg'),
        "farEggFried": () => import('@fortawesome/pro-regular-svg-icons/faEggFried'),
        "farEject": () => import('@fortawesome/pro-regular-svg-icons/faEject'),
        "farElephant": () => import('@fortawesome/pro-regular-svg-icons/faElephant'),
        "farEllipsisH": () => import('@fortawesome/pro-regular-svg-icons/faEllipsisH'),
        "farEllipsisHAlt": () => import('@fortawesome/pro-regular-svg-icons/faEllipsisHAlt'),
        "farEllipsisV": () => import('@fortawesome/pro-regular-svg-icons/faEllipsisV'),
        "farEllipsisVAlt": () => import('@fortawesome/pro-regular-svg-icons/faEllipsisVAlt'),
        "farEmptySet": () => import('@fortawesome/pro-regular-svg-icons/faEmptySet'),
        "farEngineWarning": () => import('@fortawesome/pro-regular-svg-icons/faEngineWarning'),
        "farEnvelope": () => import('@fortawesome/pro-regular-svg-icons/faEnvelope'),
        "farEnvelopeOpen": () => import('@fortawesome/pro-regular-svg-icons/faEnvelopeOpen'),
        "farEnvelopeOpenDollar": () => import('@fortawesome/pro-regular-svg-icons/faEnvelopeOpenDollar'),
        "farEnvelopeOpenText": () => import('@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText'),
        "farEnvelopeSquare": () => import('@fortawesome/pro-regular-svg-icons/faEnvelopeSquare'),
        "farEquals": () => import('@fortawesome/pro-regular-svg-icons/faEquals'),
        "farEraser": () => import('@fortawesome/pro-regular-svg-icons/faEraser'),
        "farEthernet": () => import('@fortawesome/pro-regular-svg-icons/faEthernet'),
        "farEuroSign": () => import('@fortawesome/pro-regular-svg-icons/faEuroSign'),
        "farExchange": () => import('@fortawesome/pro-regular-svg-icons/faExchange'),
        "farExchangeAlt": () => import('@fortawesome/pro-regular-svg-icons/faExchangeAlt'),
        "farExclamation": () => import('@fortawesome/pro-regular-svg-icons/faExclamation'),
        "farExclamationCircle": () => import('@fortawesome/pro-regular-svg-icons/faExclamationCircle'),
        "farExclamationSquare": () => import('@fortawesome/pro-regular-svg-icons/faExclamationSquare'),
        "farExclamationTriangle": () => import('@fortawesome/pro-regular-svg-icons/faExclamationTriangle'),
        "farExpand": () => import('@fortawesome/pro-regular-svg-icons/faExpand'),
        "farExpandAlt": () => import('@fortawesome/pro-regular-svg-icons/faExpandAlt'),
        "farExpandArrows": () => import('@fortawesome/pro-regular-svg-icons/faExpandArrows'),
        "farExpandArrowsAlt": () => import('@fortawesome/pro-regular-svg-icons/faExpandArrowsAlt'),
        "farExpandWide": () => import('@fortawesome/pro-regular-svg-icons/faExpandWide'),
        "farExternalLink": () => import('@fortawesome/pro-regular-svg-icons/faExternalLink'),
        "farExternalLinkAlt": () => import('@fortawesome/pro-regular-svg-icons/faExternalLinkAlt'),
        "farExternalLinkSquare": () => import('@fortawesome/pro-regular-svg-icons/faExternalLinkSquare'),
        "farExternalLinkSquareAlt": () => import('@fortawesome/pro-regular-svg-icons/faExternalLinkSquareAlt'),
        "farEye": () => import('@fortawesome/pro-regular-svg-icons/faEye'),
        "farEyeDropper": () => import('@fortawesome/pro-regular-svg-icons/faEyeDropper'),
        "farEyeEvil": () => import('@fortawesome/pro-regular-svg-icons/faEyeEvil'),
        "farEyeSlash": () => import('@fortawesome/pro-regular-svg-icons/faEyeSlash'),
        "farFan": () => import('@fortawesome/pro-regular-svg-icons/faFan'),
        "farFanTable": () => import('@fortawesome/pro-regular-svg-icons/faFanTable'),
        "farFarm": () => import('@fortawesome/pro-regular-svg-icons/faFarm'),
        "farFastBackward": () => import('@fortawesome/pro-regular-svg-icons/faFastBackward'),
        "farFastForward": () => import('@fortawesome/pro-regular-svg-icons/faFastForward'),
        "farFaucet": () => import('@fortawesome/pro-regular-svg-icons/faFaucet'),
        "farFaucetDrip": () => import('@fortawesome/pro-regular-svg-icons/faFaucetDrip'),
        "farFax": () => import('@fortawesome/pro-regular-svg-icons/faFax'),
        "farFeather": () => import('@fortawesome/pro-regular-svg-icons/faFeather'),
        "farFeatherAlt": () => import('@fortawesome/pro-regular-svg-icons/faFeatherAlt'),
        "farFemale": () => import('@fortawesome/pro-regular-svg-icons/faFemale'),
        "farFieldHockey": () => import('@fortawesome/pro-regular-svg-icons/faFieldHockey'),
        "farFighterJet": () => import('@fortawesome/pro-regular-svg-icons/faFighterJet'),
        "farFile": () => import('@fortawesome/pro-regular-svg-icons/faFile'),
        "farFileAlt": () => import('@fortawesome/pro-regular-svg-icons/faFileAlt'),
        "farFileArchive": () => import('@fortawesome/pro-regular-svg-icons/faFileArchive'),
        "farFileAudio": () => import('@fortawesome/pro-regular-svg-icons/faFileAudio'),
        "farFileCertificate": () => import('@fortawesome/pro-regular-svg-icons/faFileCertificate'),
        "farFileChartLine": () => import('@fortawesome/pro-regular-svg-icons/faFileChartLine'),
        "farFileChartPie": () => import('@fortawesome/pro-regular-svg-icons/faFileChartPie'),
        "farFileCheck": () => import('@fortawesome/pro-regular-svg-icons/faFileCheck'),
        "farFileCode": () => import('@fortawesome/pro-regular-svg-icons/faFileCode'),
        "farFileContract": () => import('@fortawesome/pro-regular-svg-icons/faFileContract'),
        "farFileCsv": () => import('@fortawesome/pro-regular-svg-icons/faFileCsv'),
        "farFileDownload": () => import('@fortawesome/pro-regular-svg-icons/faFileDownload'),
        "farFileEdit": () => import('@fortawesome/pro-regular-svg-icons/faFileEdit'),
        "farFileExcel": () => import('@fortawesome/pro-regular-svg-icons/faFileExcel'),
        "farFileExclamation": () => import('@fortawesome/pro-regular-svg-icons/faFileExclamation'),
        "farFileExport": () => import('@fortawesome/pro-regular-svg-icons/faFileExport'),
        "farFileImage": () => import('@fortawesome/pro-regular-svg-icons/faFileImage'),
        "farFileImport": () => import('@fortawesome/pro-regular-svg-icons/faFileImport'),
        "farFileInvoice": () => import('@fortawesome/pro-regular-svg-icons/faFileInvoice'),
        "farFileInvoiceDollar": () => import('@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar'),
        "farFileMedical": () => import('@fortawesome/pro-regular-svg-icons/faFileMedical'),
        "farFileMedicalAlt": () => import('@fortawesome/pro-regular-svg-icons/faFileMedicalAlt'),
        "farFileMinus": () => import('@fortawesome/pro-regular-svg-icons/faFileMinus'),
        "farFileMusic": () => import('@fortawesome/pro-regular-svg-icons/faFileMusic'),
        "farFilePdf": () => import('@fortawesome/pro-regular-svg-icons/faFilePdf'),
        "farFilePlus": () => import('@fortawesome/pro-regular-svg-icons/faFilePlus'),
        "farFilePowerpoint": () => import('@fortawesome/pro-regular-svg-icons/faFilePowerpoint'),
        "farFilePrescription": () => import('@fortawesome/pro-regular-svg-icons/faFilePrescription'),
        "farFileSearch": () => import('@fortawesome/pro-regular-svg-icons/faFileSearch'),
        "farFileSignature": () => import('@fortawesome/pro-regular-svg-icons/faFileSignature'),
        "farFileSpreadsheet": () => import('@fortawesome/pro-regular-svg-icons/faFileSpreadsheet'),
        "farFileTimes": () => import('@fortawesome/pro-regular-svg-icons/faFileTimes'),
        "farFileUpload": () => import('@fortawesome/pro-regular-svg-icons/faFileUpload'),
        "farFileUser": () => import('@fortawesome/pro-regular-svg-icons/faFileUser'),
        "farFileVideo": () => import('@fortawesome/pro-regular-svg-icons/faFileVideo'),
        "farFileWord": () => import('@fortawesome/pro-regular-svg-icons/faFileWord'),
        "farFilesMedical": () => import('@fortawesome/pro-regular-svg-icons/faFilesMedical'),
        "farFill": () => import('@fortawesome/pro-regular-svg-icons/faFill'),
        "farFillDrip": () => import('@fortawesome/pro-regular-svg-icons/faFillDrip'),
        "farFilm": () => import('@fortawesome/pro-regular-svg-icons/faFilm'),
        "farFilmAlt": () => import('@fortawesome/pro-regular-svg-icons/faFilmAlt'),
        "farFilmCanister": () => import('@fortawesome/pro-regular-svg-icons/faFilmCanister'),
        "farFilter": () => import('@fortawesome/pro-regular-svg-icons/faFilter'),
        "farFingerprint": () => import('@fortawesome/pro-regular-svg-icons/faFingerprint'),
        "farFire": () => import('@fortawesome/pro-regular-svg-icons/faFire'),
        "farFireAlt": () => import('@fortawesome/pro-regular-svg-icons/faFireAlt'),
        "farFireExtinguisher": () => import('@fortawesome/pro-regular-svg-icons/faFireExtinguisher'),
        "farFireSmoke": () => import('@fortawesome/pro-regular-svg-icons/faFireSmoke'),
        "farFireplace": () => import('@fortawesome/pro-regular-svg-icons/faFireplace'),
        "farFirstAid": () => import('@fortawesome/pro-regular-svg-icons/faFirstAid'),
        "farFish": () => import('@fortawesome/pro-regular-svg-icons/faFish'),
        "farFishCooked": () => import('@fortawesome/pro-regular-svg-icons/faFishCooked'),
        "farFistRaised": () => import('@fortawesome/pro-regular-svg-icons/faFistRaised'),
        "farFlag": () => import('@fortawesome/pro-regular-svg-icons/faFlag'),
        "farFlagAlt": () => import('@fortawesome/pro-regular-svg-icons/faFlagAlt'),
        "farFlagCheckered": () => import('@fortawesome/pro-regular-svg-icons/faFlagCheckered'),
        "farFlagUsa": () => import('@fortawesome/pro-regular-svg-icons/faFlagUsa'),
        "farFlame": () => import('@fortawesome/pro-regular-svg-icons/faFlame'),
        "farFlashlight": () => import('@fortawesome/pro-regular-svg-icons/faFlashlight'),
        "farFlask": () => import('@fortawesome/pro-regular-svg-icons/faFlask'),
        "farFlaskPoison": () => import('@fortawesome/pro-regular-svg-icons/faFlaskPoison'),
        "farFlaskPotion": () => import('@fortawesome/pro-regular-svg-icons/faFlaskPotion'),
        "farFlower": () => import('@fortawesome/pro-regular-svg-icons/faFlower'),
        "farFlowerDaffodil": () => import('@fortawesome/pro-regular-svg-icons/faFlowerDaffodil'),
        "farFlowerTulip": () => import('@fortawesome/pro-regular-svg-icons/faFlowerTulip'),
        "farFlushed": () => import('@fortawesome/pro-regular-svg-icons/faFlushed'),
        "farFlute": () => import('@fortawesome/pro-regular-svg-icons/faFlute'),
        "farFluxCapacitor": () => import('@fortawesome/pro-regular-svg-icons/faFluxCapacitor'),
        "farFog": () => import('@fortawesome/pro-regular-svg-icons/faFog'),
        "farFolder": () => import('@fortawesome/pro-regular-svg-icons/faFolder'),
        "farFolderDownload": () => import('@fortawesome/pro-regular-svg-icons/faFolderDownload'),
        "farFolderMinus": () => import('@fortawesome/pro-regular-svg-icons/faFolderMinus'),
        "farFolderOpen": () => import('@fortawesome/pro-regular-svg-icons/faFolderOpen'),
        "farFolderPlus": () => import('@fortawesome/pro-regular-svg-icons/faFolderPlus'),
        "farFolderTimes": () => import('@fortawesome/pro-regular-svg-icons/faFolderTimes'),
        "farFolderTree": () => import('@fortawesome/pro-regular-svg-icons/faFolderTree'),
        "farFolderUpload": () => import('@fortawesome/pro-regular-svg-icons/faFolderUpload'),
        "farFolders": () => import('@fortawesome/pro-regular-svg-icons/faFolders'),
        "farFont": () => import('@fortawesome/pro-regular-svg-icons/faFont'),
        "farFontAwesomeLogoFull": () => import('@fortawesome/pro-regular-svg-icons/faFontAwesomeLogoFull'),
        "farFontCase": () => import('@fortawesome/pro-regular-svg-icons/faFontCase'),
        "farFootballBall": () => import('@fortawesome/pro-regular-svg-icons/faFootballBall'),
        "farFootballHelmet": () => import('@fortawesome/pro-regular-svg-icons/faFootballHelmet'),
        "farForklift": () => import('@fortawesome/pro-regular-svg-icons/faForklift'),
        "farForward": () => import('@fortawesome/pro-regular-svg-icons/faForward'),
        "farFragile": () => import('@fortawesome/pro-regular-svg-icons/faFragile'),
        "farFrenchFries": () => import('@fortawesome/pro-regular-svg-icons/faFrenchFries'),
        "farFrog": () => import('@fortawesome/pro-regular-svg-icons/faFrog'),
        "farFrostyHead": () => import('@fortawesome/pro-regular-svg-icons/faFrostyHead'),
        "farFrown": () => import('@fortawesome/pro-regular-svg-icons/faFrown'),
        "farFrownOpen": () => import('@fortawesome/pro-regular-svg-icons/faFrownOpen'),
        "farFunction": () => import('@fortawesome/pro-regular-svg-icons/faFunction'),
        "farFunnelDollar": () => import('@fortawesome/pro-regular-svg-icons/faFunnelDollar'),
        "farFutbol": () => import('@fortawesome/pro-regular-svg-icons/faFutbol'),
        "farGalaxy": () => import('@fortawesome/pro-regular-svg-icons/faGalaxy'),
        "farGameBoard": () => import('@fortawesome/pro-regular-svg-icons/faGameBoard'),
        "farGameBoardAlt": () => import('@fortawesome/pro-regular-svg-icons/faGameBoardAlt'),
        "farGameConsoleHandheld": () => import('@fortawesome/pro-regular-svg-icons/faGameConsoleHandheld'),
        "farGamepad": () => import('@fortawesome/pro-regular-svg-icons/faGamepad'),
        "farGamepadAlt": () => import('@fortawesome/pro-regular-svg-icons/faGamepadAlt'),
        "farGarage": () => import('@fortawesome/pro-regular-svg-icons/faGarage'),
        "farGarageCar": () => import('@fortawesome/pro-regular-svg-icons/faGarageCar'),
        "farGarageOpen": () => import('@fortawesome/pro-regular-svg-icons/faGarageOpen'),
        "farGasPump": () => import('@fortawesome/pro-regular-svg-icons/faGasPump'),
        "farGasPumpSlash": () => import('@fortawesome/pro-regular-svg-icons/faGasPumpSlash'),
        "farGavel": () => import('@fortawesome/pro-regular-svg-icons/faGavel'),
        "farGem": () => import('@fortawesome/pro-regular-svg-icons/faGem'),
        "farGenderless": () => import('@fortawesome/pro-regular-svg-icons/faGenderless'),
        "farGhost": () => import('@fortawesome/pro-regular-svg-icons/faGhost'),
        "farGift": () => import('@fortawesome/pro-regular-svg-icons/faGift'),
        "farGiftCard": () => import('@fortawesome/pro-regular-svg-icons/faGiftCard'),
        "farGifts": () => import('@fortawesome/pro-regular-svg-icons/faGifts'),
        "farGingerbreadMan": () => import('@fortawesome/pro-regular-svg-icons/faGingerbreadMan'),
        "farGlass": () => import('@fortawesome/pro-regular-svg-icons/faGlass'),
        "farGlassChampagne": () => import('@fortawesome/pro-regular-svg-icons/faGlassChampagne'),
        "farGlassCheers": () => import('@fortawesome/pro-regular-svg-icons/faGlassCheers'),
        "farGlassCitrus": () => import('@fortawesome/pro-regular-svg-icons/faGlassCitrus'),
        "farGlassMartini": () => import('@fortawesome/pro-regular-svg-icons/faGlassMartini'),
        "farGlassMartiniAlt": () => import('@fortawesome/pro-regular-svg-icons/faGlassMartiniAlt'),
        "farGlassWhiskey": () => import('@fortawesome/pro-regular-svg-icons/faGlassWhiskey'),
        "farGlassWhiskeyRocks": () => import('@fortawesome/pro-regular-svg-icons/faGlassWhiskeyRocks'),
        "farGlasses": () => import('@fortawesome/pro-regular-svg-icons/faGlasses'),
        "farGlassesAlt": () => import('@fortawesome/pro-regular-svg-icons/faGlassesAlt'),
        "farGlobe": () => import('@fortawesome/pro-regular-svg-icons/faGlobe'),
        "farGlobeAfrica": () => import('@fortawesome/pro-regular-svg-icons/faGlobeAfrica'),
        "farGlobeAmericas": () => import('@fortawesome/pro-regular-svg-icons/faGlobeAmericas'),
        "farGlobeAsia": () => import('@fortawesome/pro-regular-svg-icons/faGlobeAsia'),
        "farGlobeEurope": () => import('@fortawesome/pro-regular-svg-icons/faGlobeEurope'),
        "farGlobeSnow": () => import('@fortawesome/pro-regular-svg-icons/faGlobeSnow'),
        "farGlobeStand": () => import('@fortawesome/pro-regular-svg-icons/faGlobeStand'),
        "farGolfBall": () => import('@fortawesome/pro-regular-svg-icons/faGolfBall'),
        "farGolfClub": () => import('@fortawesome/pro-regular-svg-icons/faGolfClub'),
        "farGopuram": () => import('@fortawesome/pro-regular-svg-icons/faGopuram'),
        "farGraduationCap": () => import('@fortawesome/pro-regular-svg-icons/faGraduationCap'),
        "farGramophone": () => import('@fortawesome/pro-regular-svg-icons/faGramophone'),
        "farGreaterThan": () => import('@fortawesome/pro-regular-svg-icons/faGreaterThan'),
        "farGreaterThanEqual": () => import('@fortawesome/pro-regular-svg-icons/faGreaterThanEqual'),
        "farGrimace": () => import('@fortawesome/pro-regular-svg-icons/faGrimace'),
        "farGrin": () => import('@fortawesome/pro-regular-svg-icons/faGrin'),
        "farGrinAlt": () => import('@fortawesome/pro-regular-svg-icons/faGrinAlt'),
        "farGrinBeam": () => import('@fortawesome/pro-regular-svg-icons/faGrinBeam'),
        "farGrinBeamSweat": () => import('@fortawesome/pro-regular-svg-icons/faGrinBeamSweat'),
        "farGrinHearts": () => import('@fortawesome/pro-regular-svg-icons/faGrinHearts'),
        "farGrinSquint": () => import('@fortawesome/pro-regular-svg-icons/faGrinSquint'),
        "farGrinSquintTears": () => import('@fortawesome/pro-regular-svg-icons/faGrinSquintTears'),
        "farGrinStars": () => import('@fortawesome/pro-regular-svg-icons/faGrinStars'),
        "farGrinTears": () => import('@fortawesome/pro-regular-svg-icons/faGrinTears'),
        "farGrinTongue": () => import('@fortawesome/pro-regular-svg-icons/faGrinTongue'),
        "farGrinTongueSquint": () => import('@fortawesome/pro-regular-svg-icons/faGrinTongueSquint'),
        "farGrinTongueWink": () => import('@fortawesome/pro-regular-svg-icons/faGrinTongueWink'),
        "farGrinWink": () => import('@fortawesome/pro-regular-svg-icons/faGrinWink'),
        "farGripHorizontal": () => import('@fortawesome/pro-regular-svg-icons/faGripHorizontal'),
        "farGripLines": () => import('@fortawesome/pro-regular-svg-icons/faGripLines'),
        "farGripLinesVertical": () => import('@fortawesome/pro-regular-svg-icons/faGripLinesVertical'),
        "farGripVertical": () => import('@fortawesome/pro-regular-svg-icons/faGripVertical'),
        "farGuitar": () => import('@fortawesome/pro-regular-svg-icons/faGuitar'),
        "farGuitarElectric": () => import('@fortawesome/pro-regular-svg-icons/faGuitarElectric'),
        "farGuitars": () => import('@fortawesome/pro-regular-svg-icons/faGuitars'),
        "farH1": () => import('@fortawesome/pro-regular-svg-icons/faH1'),
        "farH2": () => import('@fortawesome/pro-regular-svg-icons/faH2'),
        "farH3": () => import('@fortawesome/pro-regular-svg-icons/faH3'),
        "farH4": () => import('@fortawesome/pro-regular-svg-icons/faH4'),
        "farHSquare": () => import('@fortawesome/pro-regular-svg-icons/faHSquare'),
        "farHamburger": () => import('@fortawesome/pro-regular-svg-icons/faHamburger'),
        "farHammer": () => import('@fortawesome/pro-regular-svg-icons/faHammer'),
        "farHammerWar": () => import('@fortawesome/pro-regular-svg-icons/faHammerWar'),
        "farHamsa": () => import('@fortawesome/pro-regular-svg-icons/faHamsa'),
        "farHandHeart": () => import('@fortawesome/pro-regular-svg-icons/faHandHeart'),
        "farHandHolding": () => import('@fortawesome/pro-regular-svg-icons/faHandHolding'),
        "farHandHoldingBox": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingBox'),
        "farHandHoldingHeart": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingHeart'),
        "farHandHoldingMagic": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingMagic'),
        "farHandHoldingMedical": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingMedical'),
        "farHandHoldingSeedling": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingSeedling'),
        "farHandHoldingUsd": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingUsd'),
        "farHandHoldingWater": () => import('@fortawesome/pro-regular-svg-icons/faHandHoldingWater'),
        "farHandLizard": () => import('@fortawesome/pro-regular-svg-icons/faHandLizard'),
        "farHandMiddleFinger": () => import('@fortawesome/pro-regular-svg-icons/faHandMiddleFinger'),
        "farHandPaper": () => import('@fortawesome/pro-regular-svg-icons/faHandPaper'),
        "farHandPeace": () => import('@fortawesome/pro-regular-svg-icons/faHandPeace'),
        "farHandPointDown": () => import('@fortawesome/pro-regular-svg-icons/faHandPointDown'),
        "farHandPointLeft": () => import('@fortawesome/pro-regular-svg-icons/faHandPointLeft'),
        "farHandPointRight": () => import('@fortawesome/pro-regular-svg-icons/faHandPointRight'),
        "farHandPointUp": () => import('@fortawesome/pro-regular-svg-icons/faHandPointUp'),
        "farHandPointer": () => import('@fortawesome/pro-regular-svg-icons/faHandPointer'),
        "farHandReceiving": () => import('@fortawesome/pro-regular-svg-icons/faHandReceiving'),
        "farHandRock": () => import('@fortawesome/pro-regular-svg-icons/faHandRock'),
        "farHandScissors": () => import('@fortawesome/pro-regular-svg-icons/faHandScissors'),
        "farHandSparkles": () => import('@fortawesome/pro-regular-svg-icons/faHandSparkles'),
        "farHandSpock": () => import('@fortawesome/pro-regular-svg-icons/faHandSpock'),
        "farHands": () => import('@fortawesome/pro-regular-svg-icons/faHands'),
        "farHandsHeart": () => import('@fortawesome/pro-regular-svg-icons/faHandsHeart'),
        "farHandsHelping": () => import('@fortawesome/pro-regular-svg-icons/faHandsHelping'),
        "farHandsUsd": () => import('@fortawesome/pro-regular-svg-icons/faHandsUsd'),
        "farHandsWash": () => import('@fortawesome/pro-regular-svg-icons/faHandsWash'),
        "farHandshake": () => import('@fortawesome/pro-regular-svg-icons/faHandshake'),
        "farHandshakeAlt": () => import('@fortawesome/pro-regular-svg-icons/faHandshakeAlt'),
        "farHandshakeAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faHandshakeAltSlash'),
        "farHandshakeSlash": () => import('@fortawesome/pro-regular-svg-icons/faHandshakeSlash'),
        "farHanukiah": () => import('@fortawesome/pro-regular-svg-icons/faHanukiah'),
        "farHardHat": () => import('@fortawesome/pro-regular-svg-icons/faHardHat'),
        "farHashtag": () => import('@fortawesome/pro-regular-svg-icons/faHashtag'),
        "farHatChef": () => import('@fortawesome/pro-regular-svg-icons/faHatChef'),
        "farHatCowboy": () => import('@fortawesome/pro-regular-svg-icons/faHatCowboy'),
        "farHatCowboySide": () => import('@fortawesome/pro-regular-svg-icons/faHatCowboySide'),
        "farHatSanta": () => import('@fortawesome/pro-regular-svg-icons/faHatSanta'),
        "farHatWinter": () => import('@fortawesome/pro-regular-svg-icons/faHatWinter'),
        "farHatWitch": () => import('@fortawesome/pro-regular-svg-icons/faHatWitch'),
        "farHatWizard": () => import('@fortawesome/pro-regular-svg-icons/faHatWizard'),
        "farHdd": () => import('@fortawesome/pro-regular-svg-icons/faHdd'),
        "farHeadSide": () => import('@fortawesome/pro-regular-svg-icons/faHeadSide'),
        "farHeadSideBrain": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideBrain'),
        "farHeadSideCough": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideCough'),
        "farHeadSideCoughSlash": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideCoughSlash'),
        "farHeadSideHeadphones": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideHeadphones'),
        "farHeadSideMask": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideMask'),
        "farHeadSideMedical": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideMedical'),
        "farHeadSideVirus": () => import('@fortawesome/pro-regular-svg-icons/faHeadSideVirus'),
        "farHeadVr": () => import('@fortawesome/pro-regular-svg-icons/faHeadVr'),
        "farHeading": () => import('@fortawesome/pro-regular-svg-icons/faHeading'),
        "farHeadphones": () => import('@fortawesome/pro-regular-svg-icons/faHeadphones'),
        "farHeadphonesAlt": () => import('@fortawesome/pro-regular-svg-icons/faHeadphonesAlt'),
        "farHeadset": () => import('@fortawesome/pro-regular-svg-icons/faHeadset'),
        "farHeart": () => import('@fortawesome/pro-regular-svg-icons/faHeart'),
        "farHeartBroken": () => import('@fortawesome/pro-regular-svg-icons/faHeartBroken'),
        "farHeartCircle": () => import('@fortawesome/pro-regular-svg-icons/faHeartCircle'),
        "farHeartRate": () => import('@fortawesome/pro-regular-svg-icons/faHeartRate'),
        "farHeartSquare": () => import('@fortawesome/pro-regular-svg-icons/faHeartSquare'),
        "farHeartbeat": () => import('@fortawesome/pro-regular-svg-icons/faHeartbeat'),
        "farHeat": () => import('@fortawesome/pro-regular-svg-icons/faHeat'),
        "farHelicopter": () => import('@fortawesome/pro-regular-svg-icons/faHelicopter'),
        "farHelmetBattle": () => import('@fortawesome/pro-regular-svg-icons/faHelmetBattle'),
        "farHexagon": () => import('@fortawesome/pro-regular-svg-icons/faHexagon'),
        "farHighlighter": () => import('@fortawesome/pro-regular-svg-icons/faHighlighter'),
        "farHiking": () => import('@fortawesome/pro-regular-svg-icons/faHiking'),
        "farHippo": () => import('@fortawesome/pro-regular-svg-icons/faHippo'),
        "farHistory": () => import('@fortawesome/pro-regular-svg-icons/faHistory'),
        "farHockeyMask": () => import('@fortawesome/pro-regular-svg-icons/faHockeyMask'),
        "farHockeyPuck": () => import('@fortawesome/pro-regular-svg-icons/faHockeyPuck'),
        "farHockeySticks": () => import('@fortawesome/pro-regular-svg-icons/faHockeySticks'),
        "farHollyBerry": () => import('@fortawesome/pro-regular-svg-icons/faHollyBerry'),
        "farHome": () => import('@fortawesome/pro-regular-svg-icons/faHome'),
        "farHomeAlt": () => import('@fortawesome/pro-regular-svg-icons/faHomeAlt'),
        "farHomeHeart": () => import('@fortawesome/pro-regular-svg-icons/faHomeHeart'),
        "farHomeLg": () => import('@fortawesome/pro-regular-svg-icons/faHomeLg'),
        "farHomeLgAlt": () => import('@fortawesome/pro-regular-svg-icons/faHomeLgAlt'),
        "farHoodCloak": () => import('@fortawesome/pro-regular-svg-icons/faHoodCloak'),
        "farHorizontalRule": () => import('@fortawesome/pro-regular-svg-icons/faHorizontalRule'),
        "farHorse": () => import('@fortawesome/pro-regular-svg-icons/faHorse'),
        "farHorseHead": () => import('@fortawesome/pro-regular-svg-icons/faHorseHead'),
        "farHorseSaddle": () => import('@fortawesome/pro-regular-svg-icons/faHorseSaddle'),
        "farHospital": () => import('@fortawesome/pro-regular-svg-icons/faHospital'),
        "farHospitalAlt": () => import('@fortawesome/pro-regular-svg-icons/faHospitalAlt'),
        "farHospitalSymbol": () => import('@fortawesome/pro-regular-svg-icons/faHospitalSymbol'),
        "farHospitalUser": () => import('@fortawesome/pro-regular-svg-icons/faHospitalUser'),
        "farHospitals": () => import('@fortawesome/pro-regular-svg-icons/faHospitals'),
        "farHotTub": () => import('@fortawesome/pro-regular-svg-icons/faHotTub'),
        "farHotdog": () => import('@fortawesome/pro-regular-svg-icons/faHotdog'),
        "farHotel": () => import('@fortawesome/pro-regular-svg-icons/faHotel'),
        "farHourglass": () => import('@fortawesome/pro-regular-svg-icons/faHourglass'),
        "farHourglassEnd": () => import('@fortawesome/pro-regular-svg-icons/faHourglassEnd'),
        "farHourglassHalf": () => import('@fortawesome/pro-regular-svg-icons/faHourglassHalf'),
        "farHourglassStart": () => import('@fortawesome/pro-regular-svg-icons/faHourglassStart'),
        "farHouse": () => import('@fortawesome/pro-regular-svg-icons/faHouse'),
        "farHouseDamage": () => import('@fortawesome/pro-regular-svg-icons/faHouseDamage'),
        "farHouseDay": () => import('@fortawesome/pro-regular-svg-icons/faHouseDay'),
        "farHouseFlood": () => import('@fortawesome/pro-regular-svg-icons/faHouseFlood'),
        "farHouseLeave": () => import('@fortawesome/pro-regular-svg-icons/faHouseLeave'),
        "farHouseNight": () => import('@fortawesome/pro-regular-svg-icons/faHouseNight'),
        "farHouseReturn": () => import('@fortawesome/pro-regular-svg-icons/faHouseReturn'),
        "farHouseSignal": () => import('@fortawesome/pro-regular-svg-icons/faHouseSignal'),
        "farHouseUser": () => import('@fortawesome/pro-regular-svg-icons/faHouseUser'),
        "farHryvnia": () => import('@fortawesome/pro-regular-svg-icons/faHryvnia'),
        "farHumidity": () => import('@fortawesome/pro-regular-svg-icons/faHumidity'),
        "farHurricane": () => import('@fortawesome/pro-regular-svg-icons/faHurricane'),
        "farICursor": () => import('@fortawesome/pro-regular-svg-icons/faICursor'),
        "farIceCream": () => import('@fortawesome/pro-regular-svg-icons/faIceCream'),
        "farIceSkate": () => import('@fortawesome/pro-regular-svg-icons/faIceSkate'),
        "farIcicles": () => import('@fortawesome/pro-regular-svg-icons/faIcicles'),
        "farIcons": () => import('@fortawesome/pro-regular-svg-icons/faIcons'),
        "farIconsAlt": () => import('@fortawesome/pro-regular-svg-icons/faIconsAlt'),
        "farIdBadge": () => import('@fortawesome/pro-regular-svg-icons/faIdBadge'),
        "farIdCard": () => import('@fortawesome/pro-regular-svg-icons/faIdCard'),
        "farIdCardAlt": () => import('@fortawesome/pro-regular-svg-icons/faIdCardAlt'),
        "farIgloo": () => import('@fortawesome/pro-regular-svg-icons/faIgloo'),
        "farImage": () => import('@fortawesome/pro-regular-svg-icons/faImage'),
        "farImagePolaroid": () => import('@fortawesome/pro-regular-svg-icons/faImagePolaroid'),
        "farImages": () => import('@fortawesome/pro-regular-svg-icons/faImages'),
        "farInbox": () => import('@fortawesome/pro-regular-svg-icons/faInbox'),
        "farInboxIn": () => import('@fortawesome/pro-regular-svg-icons/faInboxIn'),
        "farInboxOut": () => import('@fortawesome/pro-regular-svg-icons/faInboxOut'),
        "farIndent": () => import('@fortawesome/pro-regular-svg-icons/faIndent'),
        "farIndustry": () => import('@fortawesome/pro-regular-svg-icons/faIndustry'),
        "farIndustryAlt": () => import('@fortawesome/pro-regular-svg-icons/faIndustryAlt'),
        "farInfinity": () => import('@fortawesome/pro-regular-svg-icons/faInfinity'),
        "farInfo": () => import('@fortawesome/pro-regular-svg-icons/faInfo'),
        "farInfoCircle": () => import('@fortawesome/pro-regular-svg-icons/faInfoCircle'),
        "farInfoSquare": () => import('@fortawesome/pro-regular-svg-icons/faInfoSquare'),
        "farInhaler": () => import('@fortawesome/pro-regular-svg-icons/faInhaler'),
        "farIntegral": () => import('@fortawesome/pro-regular-svg-icons/faIntegral'),
        "farIntersection": () => import('@fortawesome/pro-regular-svg-icons/faIntersection'),
        "farInventory": () => import('@fortawesome/pro-regular-svg-icons/faInventory'),
        "farIslandTropical": () => import('@fortawesome/pro-regular-svg-icons/faIslandTropical'),
        "farItalic": () => import('@fortawesome/pro-regular-svg-icons/faItalic'),
        "farJackOLantern": () => import('@fortawesome/pro-regular-svg-icons/faJackOLantern'),
        "farJedi": () => import('@fortawesome/pro-regular-svg-icons/faJedi'),
        "farJoint": () => import('@fortawesome/pro-regular-svg-icons/faJoint'),
        "farJournalWhills": () => import('@fortawesome/pro-regular-svg-icons/faJournalWhills'),
        "farJoystick": () => import('@fortawesome/pro-regular-svg-icons/faJoystick'),
        "farJug": () => import('@fortawesome/pro-regular-svg-icons/faJug'),
        "farKaaba": () => import('@fortawesome/pro-regular-svg-icons/faKaaba'),
        "farKazoo": () => import('@fortawesome/pro-regular-svg-icons/faKazoo'),
        "farKerning": () => import('@fortawesome/pro-regular-svg-icons/faKerning'),
        "farKey": () => import('@fortawesome/pro-regular-svg-icons/faKey'),
        "farKeySkeleton": () => import('@fortawesome/pro-regular-svg-icons/faKeySkeleton'),
        "farKeyboard": () => import('@fortawesome/pro-regular-svg-icons/faKeyboard'),
        "farKeynote": () => import('@fortawesome/pro-regular-svg-icons/faKeynote'),
        "farKhanda": () => import('@fortawesome/pro-regular-svg-icons/faKhanda'),
        "farKidneys": () => import('@fortawesome/pro-regular-svg-icons/faKidneys'),
        "farKiss": () => import('@fortawesome/pro-regular-svg-icons/faKiss'),
        "farKissBeam": () => import('@fortawesome/pro-regular-svg-icons/faKissBeam'),
        "farKissWinkHeart": () => import('@fortawesome/pro-regular-svg-icons/faKissWinkHeart'),
        "farKite": () => import('@fortawesome/pro-regular-svg-icons/faKite'),
        "farKiwiBird": () => import('@fortawesome/pro-regular-svg-icons/faKiwiBird'),
        "farKnifeKitchen": () => import('@fortawesome/pro-regular-svg-icons/faKnifeKitchen'),
        "farLambda": () => import('@fortawesome/pro-regular-svg-icons/faLambda'),
        "farLamp": () => import('@fortawesome/pro-regular-svg-icons/faLamp'),
        "farLampDesk": () => import('@fortawesome/pro-regular-svg-icons/faLampDesk'),
        "farLampFloor": () => import('@fortawesome/pro-regular-svg-icons/faLampFloor'),
        "farLandmark": () => import('@fortawesome/pro-regular-svg-icons/faLandmark'),
        "farLandmarkAlt": () => import('@fortawesome/pro-regular-svg-icons/faLandmarkAlt'),
        "farLanguage": () => import('@fortawesome/pro-regular-svg-icons/faLanguage'),
        "farLaptop": () => import('@fortawesome/pro-regular-svg-icons/faLaptop'),
        "farLaptopCode": () => import('@fortawesome/pro-regular-svg-icons/faLaptopCode'),
        "farLaptopHouse": () => import('@fortawesome/pro-regular-svg-icons/faLaptopHouse'),
        "farLaptopMedical": () => import('@fortawesome/pro-regular-svg-icons/faLaptopMedical'),
        "farLasso": () => import('@fortawesome/pro-regular-svg-icons/faLasso'),
        "farLaugh": () => import('@fortawesome/pro-regular-svg-icons/faLaugh'),
        "farLaughBeam": () => import('@fortawesome/pro-regular-svg-icons/faLaughBeam'),
        "farLaughSquint": () => import('@fortawesome/pro-regular-svg-icons/faLaughSquint'),
        "farLaughWink": () => import('@fortawesome/pro-regular-svg-icons/faLaughWink'),
        "farLayerGroup": () => import('@fortawesome/pro-regular-svg-icons/faLayerGroup'),
        "farLayerMinus": () => import('@fortawesome/pro-regular-svg-icons/faLayerMinus'),
        "farLayerPlus": () => import('@fortawesome/pro-regular-svg-icons/faLayerPlus'),
        "farLeaf": () => import('@fortawesome/pro-regular-svg-icons/faLeaf'),
        "farLeafHeart": () => import('@fortawesome/pro-regular-svg-icons/faLeafHeart'),
        "farLeafMaple": () => import('@fortawesome/pro-regular-svg-icons/faLeafMaple'),
        "farLeafOak": () => import('@fortawesome/pro-regular-svg-icons/faLeafOak'),
        "farLemon": () => import('@fortawesome/pro-regular-svg-icons/faLemon'),
        "farLessThan": () => import('@fortawesome/pro-regular-svg-icons/faLessThan'),
        "farLessThanEqual": () => import('@fortawesome/pro-regular-svg-icons/faLessThanEqual'),
        "farLevelDown": () => import('@fortawesome/pro-regular-svg-icons/faLevelDown'),
        "farLevelDownAlt": () => import('@fortawesome/pro-regular-svg-icons/faLevelDownAlt'),
        "farLevelUp": () => import('@fortawesome/pro-regular-svg-icons/faLevelUp'),
        "farLevelUpAlt": () => import('@fortawesome/pro-regular-svg-icons/faLevelUpAlt'),
        "farLifeRing": () => import('@fortawesome/pro-regular-svg-icons/faLifeRing'),
        "farLightCeiling": () => import('@fortawesome/pro-regular-svg-icons/faLightCeiling'),
        "farLightSwitch": () => import('@fortawesome/pro-regular-svg-icons/faLightSwitch'),
        "farLightSwitchOff": () => import('@fortawesome/pro-regular-svg-icons/faLightSwitchOff'),
        "farLightSwitchOn": () => import('@fortawesome/pro-regular-svg-icons/faLightSwitchOn'),
        "farLightbulb": () => import('@fortawesome/pro-regular-svg-icons/faLightbulb'),
        "farLightbulbDollar": () => import('@fortawesome/pro-regular-svg-icons/faLightbulbDollar'),
        "farLightbulbExclamation": () => import('@fortawesome/pro-regular-svg-icons/faLightbulbExclamation'),
        "farLightbulbOn": () => import('@fortawesome/pro-regular-svg-icons/faLightbulbOn'),
        "farLightbulbSlash": () => import('@fortawesome/pro-regular-svg-icons/faLightbulbSlash'),
        "farLightsHoliday": () => import('@fortawesome/pro-regular-svg-icons/faLightsHoliday'),
        "farLineColumns": () => import('@fortawesome/pro-regular-svg-icons/faLineColumns'),
        "farLineHeight": () => import('@fortawesome/pro-regular-svg-icons/faLineHeight'),
        "farLink": () => import('@fortawesome/pro-regular-svg-icons/faLink'),
        "farLips": () => import('@fortawesome/pro-regular-svg-icons/faLips'),
        "farLiraSign": () => import('@fortawesome/pro-regular-svg-icons/faLiraSign'),
        "farList": () => import('@fortawesome/pro-regular-svg-icons/faList'),
        "farListAlt": () => import('@fortawesome/pro-regular-svg-icons/faListAlt'),
        "farListMusic": () => import('@fortawesome/pro-regular-svg-icons/faListMusic'),
        "farListOl": () => import('@fortawesome/pro-regular-svg-icons/faListOl'),
        "farListUl": () => import('@fortawesome/pro-regular-svg-icons/faListUl'),
        "farLocation": () => import('@fortawesome/pro-regular-svg-icons/faLocation'),
        "farLocationArrow": () => import('@fortawesome/pro-regular-svg-icons/faLocationArrow'),
        "farLocationCircle": () => import('@fortawesome/pro-regular-svg-icons/faLocationCircle'),
        "farLocationSlash": () => import('@fortawesome/pro-regular-svg-icons/faLocationSlash'),
        "farLock": () => import('@fortawesome/pro-regular-svg-icons/faLock'),
        "farLockAlt": () => import('@fortawesome/pro-regular-svg-icons/faLockAlt'),
        "farLockOpen": () => import('@fortawesome/pro-regular-svg-icons/faLockOpen'),
        "farLockOpenAlt": () => import('@fortawesome/pro-regular-svg-icons/faLockOpenAlt'),
        "farLongArrowAltDown": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowAltDown'),
        "farLongArrowAltLeft": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowAltLeft'),
        "farLongArrowAltRight": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowAltRight'),
        "farLongArrowAltUp": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowAltUp'),
        "farLongArrowDown": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowDown'),
        "farLongArrowLeft": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowLeft'),
        "farLongArrowRight": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowRight'),
        "farLongArrowUp": () => import('@fortawesome/pro-regular-svg-icons/faLongArrowUp'),
        "farLoveseat": () => import('@fortawesome/pro-regular-svg-icons/faLoveseat'),
        "farLowVision": () => import('@fortawesome/pro-regular-svg-icons/faLowVision'),
        "farLuchador": () => import('@fortawesome/pro-regular-svg-icons/faLuchador'),
        "farLuggageCart": () => import('@fortawesome/pro-regular-svg-icons/faLuggageCart'),
        "farLungs": () => import('@fortawesome/pro-regular-svg-icons/faLungs'),
        "farLungsVirus": () => import('@fortawesome/pro-regular-svg-icons/faLungsVirus'),
        "farMace": () => import('@fortawesome/pro-regular-svg-icons/faMace'),
        "farMagic": () => import('@fortawesome/pro-regular-svg-icons/faMagic'),
        "farMagnet": () => import('@fortawesome/pro-regular-svg-icons/faMagnet'),
        "farMailBulk": () => import('@fortawesome/pro-regular-svg-icons/faMailBulk'),
        "farMailbox": () => import('@fortawesome/pro-regular-svg-icons/faMailbox'),
        "farMale": () => import('@fortawesome/pro-regular-svg-icons/faMale'),
        "farMandolin": () => import('@fortawesome/pro-regular-svg-icons/faMandolin'),
        "farMap": () => import('@fortawesome/pro-regular-svg-icons/faMap'),
        "farMapMarked": () => import('@fortawesome/pro-regular-svg-icons/faMapMarked'),
        "farMapMarkedAlt": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkedAlt'),
        "farMapMarker": () => import('@fortawesome/pro-regular-svg-icons/faMapMarker'),
        "farMapMarkerAlt": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerAlt'),
        "farMapMarkerAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerAltSlash'),
        "farMapMarkerCheck": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerCheck'),
        "farMapMarkerEdit": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerEdit'),
        "farMapMarkerExclamation": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerExclamation'),
        "farMapMarkerMinus": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerMinus'),
        "farMapMarkerPlus": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerPlus'),
        "farMapMarkerQuestion": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerQuestion'),
        "farMapMarkerSlash": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerSlash'),
        "farMapMarkerSmile": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerSmile'),
        "farMapMarkerTimes": () => import('@fortawesome/pro-regular-svg-icons/faMapMarkerTimes'),
        "farMapPin": () => import('@fortawesome/pro-regular-svg-icons/faMapPin'),
        "farMapSigns": () => import('@fortawesome/pro-regular-svg-icons/faMapSigns'),
        "farMarker": () => import('@fortawesome/pro-regular-svg-icons/faMarker'),
        "farMars": () => import('@fortawesome/pro-regular-svg-icons/faMars'),
        "farMarsDouble": () => import('@fortawesome/pro-regular-svg-icons/faMarsDouble'),
        "farMarsStroke": () => import('@fortawesome/pro-regular-svg-icons/faMarsStroke'),
        "farMarsStrokeH": () => import('@fortawesome/pro-regular-svg-icons/faMarsStrokeH'),
        "farMarsStrokeV": () => import('@fortawesome/pro-regular-svg-icons/faMarsStrokeV'),
        "farMask": () => import('@fortawesome/pro-regular-svg-icons/faMask'),
        "farMeat": () => import('@fortawesome/pro-regular-svg-icons/faMeat'),
        "farMedal": () => import('@fortawesome/pro-regular-svg-icons/faMedal'),
        "farMedkit": () => import('@fortawesome/pro-regular-svg-icons/faMedkit'),
        "farMegaphone": () => import('@fortawesome/pro-regular-svg-icons/faMegaphone'),
        "farMeh": () => import('@fortawesome/pro-regular-svg-icons/faMeh'),
        "farMehBlank": () => import('@fortawesome/pro-regular-svg-icons/faMehBlank'),
        "farMehRollingEyes": () => import('@fortawesome/pro-regular-svg-icons/faMehRollingEyes'),
        "farMemory": () => import('@fortawesome/pro-regular-svg-icons/faMemory'),
        "farMenorah": () => import('@fortawesome/pro-regular-svg-icons/faMenorah'),
        "farMercury": () => import('@fortawesome/pro-regular-svg-icons/faMercury'),
        "farMeteor": () => import('@fortawesome/pro-regular-svg-icons/faMeteor'),
        "farMicrochip": () => import('@fortawesome/pro-regular-svg-icons/faMicrochip'),
        "farMicrophone": () => import('@fortawesome/pro-regular-svg-icons/faMicrophone'),
        "farMicrophoneAlt": () => import('@fortawesome/pro-regular-svg-icons/faMicrophoneAlt'),
        "farMicrophoneAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faMicrophoneAltSlash'),
        "farMicrophoneSlash": () => import('@fortawesome/pro-regular-svg-icons/faMicrophoneSlash'),
        "farMicrophoneStand": () => import('@fortawesome/pro-regular-svg-icons/faMicrophoneStand'),
        "farMicroscope": () => import('@fortawesome/pro-regular-svg-icons/faMicroscope'),
        "farMicrowave": () => import('@fortawesome/pro-regular-svg-icons/faMicrowave'),
        "farMindShare": () => import('@fortawesome/pro-regular-svg-icons/faMindShare'),
        "farMinus": () => import('@fortawesome/pro-regular-svg-icons/faMinus'),
        "farMinusCircle": () => import('@fortawesome/pro-regular-svg-icons/faMinusCircle'),
        "farMinusHexagon": () => import('@fortawesome/pro-regular-svg-icons/faMinusHexagon'),
        "farMinusOctagon": () => import('@fortawesome/pro-regular-svg-icons/faMinusOctagon'),
        "farMinusSquare": () => import('@fortawesome/pro-regular-svg-icons/faMinusSquare'),
        "farMistletoe": () => import('@fortawesome/pro-regular-svg-icons/faMistletoe'),
        "farMitten": () => import('@fortawesome/pro-regular-svg-icons/faMitten'),
        "farMobile": () => import('@fortawesome/pro-regular-svg-icons/faMobile'),
        "farMobileAlt": () => import('@fortawesome/pro-regular-svg-icons/faMobileAlt'),
        "farMobileAndroid": () => import('@fortawesome/pro-regular-svg-icons/faMobileAndroid'),
        "farMobileAndroidAlt": () => import('@fortawesome/pro-regular-svg-icons/faMobileAndroidAlt'),
        "farMoneyBill": () => import('@fortawesome/pro-regular-svg-icons/faMoneyBill'),
        "farMoneyBillAlt": () => import('@fortawesome/pro-regular-svg-icons/faMoneyBillAlt'),
        "farMoneyBillWave": () => import('@fortawesome/pro-regular-svg-icons/faMoneyBillWave'),
        "farMoneyBillWaveAlt": () => import('@fortawesome/pro-regular-svg-icons/faMoneyBillWaveAlt'),
        "farMoneyCheck": () => import('@fortawesome/pro-regular-svg-icons/faMoneyCheck'),
        "farMoneyCheckAlt": () => import('@fortawesome/pro-regular-svg-icons/faMoneyCheckAlt'),
        "farMoneyCheckEdit": () => import('@fortawesome/pro-regular-svg-icons/faMoneyCheckEdit'),
        "farMoneyCheckEditAlt": () => import('@fortawesome/pro-regular-svg-icons/faMoneyCheckEditAlt'),
        "farMonitorHeartRate": () => import('@fortawesome/pro-regular-svg-icons/faMonitorHeartRate'),
        "farMonkey": () => import('@fortawesome/pro-regular-svg-icons/faMonkey'),
        "farMonument": () => import('@fortawesome/pro-regular-svg-icons/faMonument'),
        "farMoon": () => import('@fortawesome/pro-regular-svg-icons/faMoon'),
        "farMoonCloud": () => import('@fortawesome/pro-regular-svg-icons/faMoonCloud'),
        "farMoonStars": () => import('@fortawesome/pro-regular-svg-icons/faMoonStars'),
        "farMortarPestle": () => import('@fortawesome/pro-regular-svg-icons/faMortarPestle'),
        "farMosque": () => import('@fortawesome/pro-regular-svg-icons/faMosque'),
        "farMotorcycle": () => import('@fortawesome/pro-regular-svg-icons/faMotorcycle'),
        "farMountain": () => import('@fortawesome/pro-regular-svg-icons/faMountain'),
        "farMountains": () => import('@fortawesome/pro-regular-svg-icons/faMountains'),
        "farMouse": () => import('@fortawesome/pro-regular-svg-icons/faMouse'),
        "farMouseAlt": () => import('@fortawesome/pro-regular-svg-icons/faMouseAlt'),
        "farMousePointer": () => import('@fortawesome/pro-regular-svg-icons/faMousePointer'),
        "farMp3Player": () => import('@fortawesome/pro-regular-svg-icons/faMp3Player'),
        "farMug": () => import('@fortawesome/pro-regular-svg-icons/faMug'),
        "farMugHot": () => import('@fortawesome/pro-regular-svg-icons/faMugHot'),
        "farMugMarshmallows": () => import('@fortawesome/pro-regular-svg-icons/faMugMarshmallows'),
        "farMugTea": () => import('@fortawesome/pro-regular-svg-icons/faMugTea'),
        "farMusic": () => import('@fortawesome/pro-regular-svg-icons/faMusic'),
        "farMusicAlt": () => import('@fortawesome/pro-regular-svg-icons/faMusicAlt'),
        "farMusicAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faMusicAltSlash'),
        "farMusicSlash": () => import('@fortawesome/pro-regular-svg-icons/faMusicSlash'),
        "farNarwhal": () => import('@fortawesome/pro-regular-svg-icons/faNarwhal'),
        "farNetworkWired": () => import('@fortawesome/pro-regular-svg-icons/faNetworkWired'),
        "farNeuter": () => import('@fortawesome/pro-regular-svg-icons/faNeuter'),
        "farNewspaper": () => import('@fortawesome/pro-regular-svg-icons/faNewspaper'),
        "farNotEqual": () => import('@fortawesome/pro-regular-svg-icons/faNotEqual'),
        "farNotesMedical": () => import('@fortawesome/pro-regular-svg-icons/faNotesMedical'),
        "farObjectGroup": () => import('@fortawesome/pro-regular-svg-icons/faObjectGroup'),
        "farObjectUngroup": () => import('@fortawesome/pro-regular-svg-icons/faObjectUngroup'),
        "farOctagon": () => import('@fortawesome/pro-regular-svg-icons/faOctagon'),
        "farOilCan": () => import('@fortawesome/pro-regular-svg-icons/faOilCan'),
        "farOilTemp": () => import('@fortawesome/pro-regular-svg-icons/faOilTemp'),
        "farOm": () => import('@fortawesome/pro-regular-svg-icons/faOm'),
        "farOmega": () => import('@fortawesome/pro-regular-svg-icons/faOmega'),
        "farOrnament": () => import('@fortawesome/pro-regular-svg-icons/faOrnament'),
        "farOtter": () => import('@fortawesome/pro-regular-svg-icons/faOtter'),
        "farOutdent": () => import('@fortawesome/pro-regular-svg-icons/faOutdent'),
        "farOutlet": () => import('@fortawesome/pro-regular-svg-icons/faOutlet'),
        "farOven": () => import('@fortawesome/pro-regular-svg-icons/faOven'),
        "farOverline": () => import('@fortawesome/pro-regular-svg-icons/faOverline'),
        "farPageBreak": () => import('@fortawesome/pro-regular-svg-icons/faPageBreak'),
        "farPager": () => import('@fortawesome/pro-regular-svg-icons/faPager'),
        "farPaintBrush": () => import('@fortawesome/pro-regular-svg-icons/faPaintBrush'),
        "farPaintBrushAlt": () => import('@fortawesome/pro-regular-svg-icons/faPaintBrushAlt'),
        "farPaintRoller": () => import('@fortawesome/pro-regular-svg-icons/faPaintRoller'),
        "farPalette": () => import('@fortawesome/pro-regular-svg-icons/faPalette'),
        "farPallet": () => import('@fortawesome/pro-regular-svg-icons/faPallet'),
        "farPalletAlt": () => import('@fortawesome/pro-regular-svg-icons/faPalletAlt'),
        "farPaperPlane": () => import('@fortawesome/pro-regular-svg-icons/faPaperPlane'),
        "farPaperclip": () => import('@fortawesome/pro-regular-svg-icons/faPaperclip'),
        "farParachuteBox": () => import('@fortawesome/pro-regular-svg-icons/faParachuteBox'),
        "farParagraph": () => import('@fortawesome/pro-regular-svg-icons/faParagraph'),
        "farParagraphRtl": () => import('@fortawesome/pro-regular-svg-icons/faParagraphRtl'),
        "farParking": () => import('@fortawesome/pro-regular-svg-icons/faParking'),
        "farParkingCircle": () => import('@fortawesome/pro-regular-svg-icons/faParkingCircle'),
        "farParkingCircleSlash": () => import('@fortawesome/pro-regular-svg-icons/faParkingCircleSlash'),
        "farParkingSlash": () => import('@fortawesome/pro-regular-svg-icons/faParkingSlash'),
        "farPassport": () => import('@fortawesome/pro-regular-svg-icons/faPassport'),
        "farPastafarianism": () => import('@fortawesome/pro-regular-svg-icons/faPastafarianism'),
        "farPaste": () => import('@fortawesome/pro-regular-svg-icons/faPaste'),
        "farPause": () => import('@fortawesome/pro-regular-svg-icons/faPause'),
        "farPauseCircle": () => import('@fortawesome/pro-regular-svg-icons/faPauseCircle'),
        "farPaw": () => import('@fortawesome/pro-regular-svg-icons/faPaw'),
        "farPawAlt": () => import('@fortawesome/pro-regular-svg-icons/faPawAlt'),
        "farPawClaws": () => import('@fortawesome/pro-regular-svg-icons/faPawClaws'),
        "farPeace": () => import('@fortawesome/pro-regular-svg-icons/faPeace'),
        "farPegasus": () => import('@fortawesome/pro-regular-svg-icons/faPegasus'),
        "farPen": () => import('@fortawesome/pro-regular-svg-icons/faPen'),
        "farPenAlt": () => import('@fortawesome/pro-regular-svg-icons/faPenAlt'),
        "farPenFancy": () => import('@fortawesome/pro-regular-svg-icons/faPenFancy'),
        "farPenNib": () => import('@fortawesome/pro-regular-svg-icons/faPenNib'),
        "farPenSquare": () => import('@fortawesome/pro-regular-svg-icons/faPenSquare'),
        "farPencil": () => import('@fortawesome/pro-regular-svg-icons/faPencil'),
        "farPencilAlt": () => import('@fortawesome/pro-regular-svg-icons/faPencilAlt'),
        "farPencilPaintbrush": () => import('@fortawesome/pro-regular-svg-icons/faPencilPaintbrush'),
        "farPencilRuler": () => import('@fortawesome/pro-regular-svg-icons/faPencilRuler'),
        "farPennant": () => import('@fortawesome/pro-regular-svg-icons/faPennant'),
        "farPeopleArrows": () => import('@fortawesome/pro-regular-svg-icons/faPeopleArrows'),
        "farPeopleCarry": () => import('@fortawesome/pro-regular-svg-icons/faPeopleCarry'),
        "farPepperHot": () => import('@fortawesome/pro-regular-svg-icons/faPepperHot'),
        "farPercent": () => import('@fortawesome/pro-regular-svg-icons/faPercent'),
        "farPercentage": () => import('@fortawesome/pro-regular-svg-icons/faPercentage'),
        "farPersonBooth": () => import('@fortawesome/pro-regular-svg-icons/faPersonBooth'),
        "farPersonCarry": () => import('@fortawesome/pro-regular-svg-icons/faPersonCarry'),
        "farPersonDolly": () => import('@fortawesome/pro-regular-svg-icons/faPersonDolly'),
        "farPersonDollyEmpty": () => import('@fortawesome/pro-regular-svg-icons/faPersonDollyEmpty'),
        "farPersonSign": () => import('@fortawesome/pro-regular-svg-icons/faPersonSign'),
        "farPhone": () => import('@fortawesome/pro-regular-svg-icons/faPhone'),
        "farPhoneAlt": () => import('@fortawesome/pro-regular-svg-icons/faPhoneAlt'),
        "farPhoneLaptop": () => import('@fortawesome/pro-regular-svg-icons/faPhoneLaptop'),
        "farPhoneOffice": () => import('@fortawesome/pro-regular-svg-icons/faPhoneOffice'),
        "farPhonePlus": () => import('@fortawesome/pro-regular-svg-icons/faPhonePlus'),
        "farPhoneRotary": () => import('@fortawesome/pro-regular-svg-icons/faPhoneRotary'),
        "farPhoneSlash": () => import('@fortawesome/pro-regular-svg-icons/faPhoneSlash'),
        "farPhoneSquare": () => import('@fortawesome/pro-regular-svg-icons/faPhoneSquare'),
        "farPhoneSquareAlt": () => import('@fortawesome/pro-regular-svg-icons/faPhoneSquareAlt'),
        "farPhoneVolume": () => import('@fortawesome/pro-regular-svg-icons/faPhoneVolume'),
        "farPhotoVideo": () => import('@fortawesome/pro-regular-svg-icons/faPhotoVideo'),
        "farPi": () => import('@fortawesome/pro-regular-svg-icons/faPi'),
        "farPiano": () => import('@fortawesome/pro-regular-svg-icons/faPiano'),
        "farPianoKeyboard": () => import('@fortawesome/pro-regular-svg-icons/faPianoKeyboard'),
        "farPie": () => import('@fortawesome/pro-regular-svg-icons/faPie'),
        "farPig": () => import('@fortawesome/pro-regular-svg-icons/faPig'),
        "farPiggyBank": () => import('@fortawesome/pro-regular-svg-icons/faPiggyBank'),
        "farPills": () => import('@fortawesome/pro-regular-svg-icons/faPills'),
        "farPizza": () => import('@fortawesome/pro-regular-svg-icons/faPizza'),
        "farPizzaSlice": () => import('@fortawesome/pro-regular-svg-icons/faPizzaSlice'),
        "farPlaceOfWorship": () => import('@fortawesome/pro-regular-svg-icons/faPlaceOfWorship'),
        "farPlane": () => import('@fortawesome/pro-regular-svg-icons/faPlane'),
        "farPlaneAlt": () => import('@fortawesome/pro-regular-svg-icons/faPlaneAlt'),
        "farPlaneArrival": () => import('@fortawesome/pro-regular-svg-icons/faPlaneArrival'),
        "farPlaneDeparture": () => import('@fortawesome/pro-regular-svg-icons/faPlaneDeparture'),
        "farPlaneSlash": () => import('@fortawesome/pro-regular-svg-icons/faPlaneSlash'),
        "farPlanetMoon": () => import('@fortawesome/pro-regular-svg-icons/faPlanetMoon'),
        "farPlanetRinged": () => import('@fortawesome/pro-regular-svg-icons/faPlanetRinged'),
        "farPlay": () => import('@fortawesome/pro-regular-svg-icons/faPlay'),
        "farPlayCircle": () => import('@fortawesome/pro-regular-svg-icons/faPlayCircle'),
        "farPlug": () => import('@fortawesome/pro-regular-svg-icons/faPlug'),
        "farPlus": () => import('@fortawesome/pro-regular-svg-icons/faPlus'),
        "farPlusCircle": () => import('@fortawesome/pro-regular-svg-icons/faPlusCircle'),
        "farPlusHexagon": () => import('@fortawesome/pro-regular-svg-icons/faPlusHexagon'),
        "farPlusOctagon": () => import('@fortawesome/pro-regular-svg-icons/faPlusOctagon'),
        "farPlusSquare": () => import('@fortawesome/pro-regular-svg-icons/faPlusSquare'),
        "farPodcast": () => import('@fortawesome/pro-regular-svg-icons/faPodcast'),
        "farPodium": () => import('@fortawesome/pro-regular-svg-icons/faPodium'),
        "farPodiumStar": () => import('@fortawesome/pro-regular-svg-icons/faPodiumStar'),
        "farPoliceBox": () => import('@fortawesome/pro-regular-svg-icons/faPoliceBox'),
        "farPoll": () => import('@fortawesome/pro-regular-svg-icons/faPoll'),
        "farPollH": () => import('@fortawesome/pro-regular-svg-icons/faPollH'),
        "farPollPeople": () => import('@fortawesome/pro-regular-svg-icons/faPollPeople'),
        "farPoo": () => import('@fortawesome/pro-regular-svg-icons/faPoo'),
        "farPooStorm": () => import('@fortawesome/pro-regular-svg-icons/faPooStorm'),
        "farPoop": () => import('@fortawesome/pro-regular-svg-icons/faPoop'),
        "farPopcorn": () => import('@fortawesome/pro-regular-svg-icons/faPopcorn'),
        "farPortalEnter": () => import('@fortawesome/pro-regular-svg-icons/faPortalEnter'),
        "farPortalExit": () => import('@fortawesome/pro-regular-svg-icons/faPortalExit'),
        "farPortrait": () => import('@fortawesome/pro-regular-svg-icons/faPortrait'),
        "farPoundSign": () => import('@fortawesome/pro-regular-svg-icons/faPoundSign'),
        "farPowerOff": () => import('@fortawesome/pro-regular-svg-icons/faPowerOff'),
        "farPray": () => import('@fortawesome/pro-regular-svg-icons/faPray'),
        "farPrayingHands": () => import('@fortawesome/pro-regular-svg-icons/faPrayingHands'),
        "farPrescription": () => import('@fortawesome/pro-regular-svg-icons/faPrescription'),
        "farPrescriptionBottle": () => import('@fortawesome/pro-regular-svg-icons/faPrescriptionBottle'),
        "farPrescriptionBottleAlt": () => import('@fortawesome/pro-regular-svg-icons/faPrescriptionBottleAlt'),
        "farPresentation": () => import('@fortawesome/pro-regular-svg-icons/faPresentation'),
        "farPrint": () => import('@fortawesome/pro-regular-svg-icons/faPrint'),
        "farPrintSearch": () => import('@fortawesome/pro-regular-svg-icons/faPrintSearch'),
        "farPrintSlash": () => import('@fortawesome/pro-regular-svg-icons/faPrintSlash'),
        "farProcedures": () => import('@fortawesome/pro-regular-svg-icons/faProcedures'),
        "farProjectDiagram": () => import('@fortawesome/pro-regular-svg-icons/faProjectDiagram'),
        "farProjector": () => import('@fortawesome/pro-regular-svg-icons/faProjector'),
        "farPumpMedical": () => import('@fortawesome/pro-regular-svg-icons/faPumpMedical'),
        "farPumpSoap": () => import('@fortawesome/pro-regular-svg-icons/faPumpSoap'),
        "farPumpkin": () => import('@fortawesome/pro-regular-svg-icons/faPumpkin'),
        "farPuzzlePiece": () => import('@fortawesome/pro-regular-svg-icons/faPuzzlePiece'),
        "farQrcode": () => import('@fortawesome/pro-regular-svg-icons/faQrcode'),
        "farQuestion": () => import('@fortawesome/pro-regular-svg-icons/faQuestion'),
        "farQuestionCircle": () => import('@fortawesome/pro-regular-svg-icons/faQuestionCircle'),
        "farQuestionSquare": () => import('@fortawesome/pro-regular-svg-icons/faQuestionSquare'),
        "farQuidditch": () => import('@fortawesome/pro-regular-svg-icons/faQuidditch'),
        "farQuoteLeft": () => import('@fortawesome/pro-regular-svg-icons/faQuoteLeft'),
        "farQuoteRight": () => import('@fortawesome/pro-regular-svg-icons/faQuoteRight'),
        "farQuran": () => import('@fortawesome/pro-regular-svg-icons/faQuran'),
        "farRabbit": () => import('@fortawesome/pro-regular-svg-icons/faRabbit'),
        "farRabbitFast": () => import('@fortawesome/pro-regular-svg-icons/faRabbitFast'),
        "farRacquet": () => import('@fortawesome/pro-regular-svg-icons/faRacquet'),
        "farRadar": () => import('@fortawesome/pro-regular-svg-icons/faRadar'),
        "farRadiation": () => import('@fortawesome/pro-regular-svg-icons/faRadiation'),
        "farRadiationAlt": () => import('@fortawesome/pro-regular-svg-icons/faRadiationAlt'),
        "farRadio": () => import('@fortawesome/pro-regular-svg-icons/faRadio'),
        "farRadioAlt": () => import('@fortawesome/pro-regular-svg-icons/faRadioAlt'),
        "farRainbow": () => import('@fortawesome/pro-regular-svg-icons/faRainbow'),
        "farRaindrops": () => import('@fortawesome/pro-regular-svg-icons/faRaindrops'),
        "farRam": () => import('@fortawesome/pro-regular-svg-icons/faRam'),
        "farRampLoading": () => import('@fortawesome/pro-regular-svg-icons/faRampLoading'),
        "farRandom": () => import('@fortawesome/pro-regular-svg-icons/faRandom'),
        "farRaygun": () => import('@fortawesome/pro-regular-svg-icons/faRaygun'),
        "farReceipt": () => import('@fortawesome/pro-regular-svg-icons/faReceipt'),
        "farRecordVinyl": () => import('@fortawesome/pro-regular-svg-icons/faRecordVinyl'),
        "farRectangleLandscape": () => import('@fortawesome/pro-regular-svg-icons/faRectangleLandscape'),
        "farRectanglePortrait": () => import('@fortawesome/pro-regular-svg-icons/faRectanglePortrait'),
        "farRectangleWide": () => import('@fortawesome/pro-regular-svg-icons/faRectangleWide'),
        "farRecycle": () => import('@fortawesome/pro-regular-svg-icons/faRecycle'),
        "farRedo": () => import('@fortawesome/pro-regular-svg-icons/faRedo'),
        "farRedoAlt": () => import('@fortawesome/pro-regular-svg-icons/faRedoAlt'),
        "farRefrigerator": () => import('@fortawesome/pro-regular-svg-icons/faRefrigerator'),
        "farRegistered": () => import('@fortawesome/pro-regular-svg-icons/faRegistered'),
        "farRemoveFormat": () => import('@fortawesome/pro-regular-svg-icons/faRemoveFormat'),
        "farRepeat": () => import('@fortawesome/pro-regular-svg-icons/faRepeat'),
        "farRepeat1": () => import('@fortawesome/pro-regular-svg-icons/faRepeat1'),
        "farRepeat1Alt": () => import('@fortawesome/pro-regular-svg-icons/faRepeat1Alt'),
        "farRepeatAlt": () => import('@fortawesome/pro-regular-svg-icons/faRepeatAlt'),
        "farReply": () => import('@fortawesome/pro-regular-svg-icons/faReply'),
        "farReplyAll": () => import('@fortawesome/pro-regular-svg-icons/faReplyAll'),
        "farRepublican": () => import('@fortawesome/pro-regular-svg-icons/faRepublican'),
        "farRestroom": () => import('@fortawesome/pro-regular-svg-icons/faRestroom'),
        "farRetweet": () => import('@fortawesome/pro-regular-svg-icons/faRetweet'),
        "farRetweetAlt": () => import('@fortawesome/pro-regular-svg-icons/faRetweetAlt'),
        "farRibbon": () => import('@fortawesome/pro-regular-svg-icons/faRibbon'),
        "farRing": () => import('@fortawesome/pro-regular-svg-icons/faRing'),
        "farRingsWedding": () => import('@fortawesome/pro-regular-svg-icons/faRingsWedding'),
        "farRoad": () => import('@fortawesome/pro-regular-svg-icons/faRoad'),
        "farRobot": () => import('@fortawesome/pro-regular-svg-icons/faRobot'),
        "farRocket": () => import('@fortawesome/pro-regular-svg-icons/faRocket'),
        "farRocketLaunch": () => import('@fortawesome/pro-regular-svg-icons/faRocketLaunch'),
        "farRoute": () => import('@fortawesome/pro-regular-svg-icons/faRoute'),
        "farRouteHighway": () => import('@fortawesome/pro-regular-svg-icons/faRouteHighway'),
        "farRouteInterstate": () => import('@fortawesome/pro-regular-svg-icons/faRouteInterstate'),
        "farRouter": () => import('@fortawesome/pro-regular-svg-icons/faRouter'),
        "farRss": () => import('@fortawesome/pro-regular-svg-icons/faRss'),
        "farRssSquare": () => import('@fortawesome/pro-regular-svg-icons/faRssSquare'),
        "farRubleSign": () => import('@fortawesome/pro-regular-svg-icons/faRubleSign'),
        "farRuler": () => import('@fortawesome/pro-regular-svg-icons/faRuler'),
        "farRulerCombined": () => import('@fortawesome/pro-regular-svg-icons/faRulerCombined'),
        "farRulerHorizontal": () => import('@fortawesome/pro-regular-svg-icons/faRulerHorizontal'),
        "farRulerTriangle": () => import('@fortawesome/pro-regular-svg-icons/faRulerTriangle'),
        "farRulerVertical": () => import('@fortawesome/pro-regular-svg-icons/faRulerVertical'),
        "farRunning": () => import('@fortawesome/pro-regular-svg-icons/faRunning'),
        "farRupeeSign": () => import('@fortawesome/pro-regular-svg-icons/faRupeeSign'),
        "farRv": () => import('@fortawesome/pro-regular-svg-icons/faRv'),
        "farSack": () => import('@fortawesome/pro-regular-svg-icons/faSack'),
        "farSackDollar": () => import('@fortawesome/pro-regular-svg-icons/faSackDollar'),
        "farSadCry": () => import('@fortawesome/pro-regular-svg-icons/faSadCry'),
        "farSadTear": () => import('@fortawesome/pro-regular-svg-icons/faSadTear'),
        "farSalad": () => import('@fortawesome/pro-regular-svg-icons/faSalad'),
        "farSandwich": () => import('@fortawesome/pro-regular-svg-icons/faSandwich'),
        "farSatellite": () => import('@fortawesome/pro-regular-svg-icons/faSatellite'),
        "farSatelliteDish": () => import('@fortawesome/pro-regular-svg-icons/faSatelliteDish'),
        "farSausage": () => import('@fortawesome/pro-regular-svg-icons/faSausage'),
        "farSave": () => import('@fortawesome/pro-regular-svg-icons/faSave'),
        "farSaxHot": () => import('@fortawesome/pro-regular-svg-icons/faSaxHot'),
        "farSaxophone": () => import('@fortawesome/pro-regular-svg-icons/faSaxophone'),
        "farScalpel": () => import('@fortawesome/pro-regular-svg-icons/faScalpel'),
        "farScalpelPath": () => import('@fortawesome/pro-regular-svg-icons/faScalpelPath'),
        "farScanner": () => import('@fortawesome/pro-regular-svg-icons/faScanner'),
        "farScannerImage": () => import('@fortawesome/pro-regular-svg-icons/faScannerImage'),
        "farScannerKeyboard": () => import('@fortawesome/pro-regular-svg-icons/faScannerKeyboard'),
        "farScannerTouchscreen": () => import('@fortawesome/pro-regular-svg-icons/faScannerTouchscreen'),
        "farScarecrow": () => import('@fortawesome/pro-regular-svg-icons/faScarecrow'),
        "farScarf": () => import('@fortawesome/pro-regular-svg-icons/faScarf'),
        "farSchool": () => import('@fortawesome/pro-regular-svg-icons/faSchool'),
        "farScrewdriver": () => import('@fortawesome/pro-regular-svg-icons/faScrewdriver'),
        "farScroll": () => import('@fortawesome/pro-regular-svg-icons/faScroll'),
        "farScrollOld": () => import('@fortawesome/pro-regular-svg-icons/faScrollOld'),
        "farScrubber": () => import('@fortawesome/pro-regular-svg-icons/faScrubber'),
        "farScythe": () => import('@fortawesome/pro-regular-svg-icons/faScythe'),
        "farSdCard": () => import('@fortawesome/pro-regular-svg-icons/faSdCard'),
        "farSearch": () => import('@fortawesome/pro-regular-svg-icons/faSearch'),
        "farSearchDollar": () => import('@fortawesome/pro-regular-svg-icons/faSearchDollar'),
        "farSearchLocation": () => import('@fortawesome/pro-regular-svg-icons/faSearchLocation'),
        "farSearchMinus": () => import('@fortawesome/pro-regular-svg-icons/faSearchMinus'),
        "farSearchPlus": () => import('@fortawesome/pro-regular-svg-icons/faSearchPlus'),
        "farSeedling": () => import('@fortawesome/pro-regular-svg-icons/faSeedling'),
        "farSendBack": () => import('@fortawesome/pro-regular-svg-icons/faSendBack'),
        "farSendBackward": () => import('@fortawesome/pro-regular-svg-icons/faSendBackward'),
        "farSensor": () => import('@fortawesome/pro-regular-svg-icons/faSensor'),
        "farSensorAlert": () => import('@fortawesome/pro-regular-svg-icons/faSensorAlert'),
        "farSensorFire": () => import('@fortawesome/pro-regular-svg-icons/faSensorFire'),
        "farSensorOn": () => import('@fortawesome/pro-regular-svg-icons/faSensorOn'),
        "farSensorSmoke": () => import('@fortawesome/pro-regular-svg-icons/faSensorSmoke'),
        "farServer": () => import('@fortawesome/pro-regular-svg-icons/faServer'),
        "farShapes": () => import('@fortawesome/pro-regular-svg-icons/faShapes'),
        "farShare": () => import('@fortawesome/pro-regular-svg-icons/faShare'),
        "farShareAll": () => import('@fortawesome/pro-regular-svg-icons/faShareAll'),
        "farShareAlt": () => import('@fortawesome/pro-regular-svg-icons/faShareAlt'),
        "farShareAltSquare": () => import('@fortawesome/pro-regular-svg-icons/faShareAltSquare'),
        "farShareSquare": () => import('@fortawesome/pro-regular-svg-icons/faShareSquare'),
        "farSheep": () => import('@fortawesome/pro-regular-svg-icons/faSheep'),
        "farShekelSign": () => import('@fortawesome/pro-regular-svg-icons/faShekelSign'),
        "farShield": () => import('@fortawesome/pro-regular-svg-icons/faShield'),
        "farShieldAlt": () => import('@fortawesome/pro-regular-svg-icons/faShieldAlt'),
        "farShieldCheck": () => import('@fortawesome/pro-regular-svg-icons/faShieldCheck'),
        "farShieldCross": () => import('@fortawesome/pro-regular-svg-icons/faShieldCross'),
        "farShieldVirus": () => import('@fortawesome/pro-regular-svg-icons/faShieldVirus'),
        "farShip": () => import('@fortawesome/pro-regular-svg-icons/faShip'),
        "farShippingFast": () => import('@fortawesome/pro-regular-svg-icons/faShippingFast'),
        "farShippingTimed": () => import('@fortawesome/pro-regular-svg-icons/faShippingTimed'),
        "farShishKebab": () => import('@fortawesome/pro-regular-svg-icons/faShishKebab'),
        "farShoePrints": () => import('@fortawesome/pro-regular-svg-icons/faShoePrints'),
        "farShoppingBag": () => import('@fortawesome/pro-regular-svg-icons/faShoppingBag'),
        "farShoppingBasket": () => import('@fortawesome/pro-regular-svg-icons/faShoppingBasket'),
        "farShoppingCart": () => import('@fortawesome/pro-regular-svg-icons/faShoppingCart'),
        "farShovel": () => import('@fortawesome/pro-regular-svg-icons/faShovel'),
        "farShovelSnow": () => import('@fortawesome/pro-regular-svg-icons/faShovelSnow'),
        "farShower": () => import('@fortawesome/pro-regular-svg-icons/faShower'),
        "farShredder": () => import('@fortawesome/pro-regular-svg-icons/faShredder'),
        "farShuttleVan": () => import('@fortawesome/pro-regular-svg-icons/faShuttleVan'),
        "farShuttlecock": () => import('@fortawesome/pro-regular-svg-icons/faShuttlecock'),
        "farSickle": () => import('@fortawesome/pro-regular-svg-icons/faSickle'),
        "farSigma": () => import('@fortawesome/pro-regular-svg-icons/faSigma'),
        "farSign": () => import('@fortawesome/pro-regular-svg-icons/faSign'),
        "farSignIn": () => import('@fortawesome/pro-regular-svg-icons/faSignIn'),
        "farSignInAlt": () => import('@fortawesome/pro-regular-svg-icons/faSignInAlt'),
        "farSignLanguage": () => import('@fortawesome/pro-regular-svg-icons/faSignLanguage'),
        "farSignOut": () => import('@fortawesome/pro-regular-svg-icons/faSignOut'),
        "farSignOutAlt": () => import('@fortawesome/pro-regular-svg-icons/faSignOutAlt'),
        "farSignal": () => import('@fortawesome/pro-regular-svg-icons/faSignal'),
        "farSignal1": () => import('@fortawesome/pro-regular-svg-icons/faSignal1'),
        "farSignal2": () => import('@fortawesome/pro-regular-svg-icons/faSignal2'),
        "farSignal3": () => import('@fortawesome/pro-regular-svg-icons/faSignal3'),
        "farSignal4": () => import('@fortawesome/pro-regular-svg-icons/faSignal4'),
        "farSignalAlt": () => import('@fortawesome/pro-regular-svg-icons/faSignalAlt'),
        "farSignalAlt1": () => import('@fortawesome/pro-regular-svg-icons/faSignalAlt1'),
        "farSignalAlt2": () => import('@fortawesome/pro-regular-svg-icons/faSignalAlt2'),
        "farSignalAlt3": () => import('@fortawesome/pro-regular-svg-icons/faSignalAlt3'),
        "farSignalAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faSignalAltSlash'),
        "farSignalSlash": () => import('@fortawesome/pro-regular-svg-icons/faSignalSlash'),
        "farSignalStream": () => import('@fortawesome/pro-regular-svg-icons/faSignalStream'),
        "farSignature": () => import('@fortawesome/pro-regular-svg-icons/faSignature'),
        "farSimCard": () => import('@fortawesome/pro-regular-svg-icons/faSimCard'),
        "farSink": () => import('@fortawesome/pro-regular-svg-icons/faSink'),
        "farSiren": () => import('@fortawesome/pro-regular-svg-icons/faSiren'),
        "farSirenOn": () => import('@fortawesome/pro-regular-svg-icons/faSirenOn'),
        "farSitemap": () => import('@fortawesome/pro-regular-svg-icons/faSitemap'),
        "farSkating": () => import('@fortawesome/pro-regular-svg-icons/faSkating'),
        "farSkeleton": () => import('@fortawesome/pro-regular-svg-icons/faSkeleton'),
        "farSkiJump": () => import('@fortawesome/pro-regular-svg-icons/faSkiJump'),
        "farSkiLift": () => import('@fortawesome/pro-regular-svg-icons/faSkiLift'),
        "farSkiing": () => import('@fortawesome/pro-regular-svg-icons/faSkiing'),
        "farSkiingNordic": () => import('@fortawesome/pro-regular-svg-icons/faSkiingNordic'),
        "farSkull": () => import('@fortawesome/pro-regular-svg-icons/faSkull'),
        "farSkullCow": () => import('@fortawesome/pro-regular-svg-icons/faSkullCow'),
        "farSkullCrossbones": () => import('@fortawesome/pro-regular-svg-icons/faSkullCrossbones'),
        "farSlash": () => import('@fortawesome/pro-regular-svg-icons/faSlash'),
        "farSledding": () => import('@fortawesome/pro-regular-svg-icons/faSledding'),
        "farSleigh": () => import('@fortawesome/pro-regular-svg-icons/faSleigh'),
        "farSlidersH": () => import('@fortawesome/pro-regular-svg-icons/faSlidersH'),
        "farSlidersHSquare": () => import('@fortawesome/pro-regular-svg-icons/faSlidersHSquare'),
        "farSlidersV": () => import('@fortawesome/pro-regular-svg-icons/faSlidersV'),
        "farSlidersVSquare": () => import('@fortawesome/pro-regular-svg-icons/faSlidersVSquare'),
        "farSmile": () => import('@fortawesome/pro-regular-svg-icons/faSmile'),
        "farSmileBeam": () => import('@fortawesome/pro-regular-svg-icons/faSmileBeam'),
        "farSmilePlus": () => import('@fortawesome/pro-regular-svg-icons/faSmilePlus'),
        "farSmileWink": () => import('@fortawesome/pro-regular-svg-icons/faSmileWink'),
        "farSmog": () => import('@fortawesome/pro-regular-svg-icons/faSmog'),
        "farSmoke": () => import('@fortawesome/pro-regular-svg-icons/faSmoke'),
        "farSmoking": () => import('@fortawesome/pro-regular-svg-icons/faSmoking'),
        "farSmokingBan": () => import('@fortawesome/pro-regular-svg-icons/faSmokingBan'),
        "farSms": () => import('@fortawesome/pro-regular-svg-icons/faSms'),
        "farSnake": () => import('@fortawesome/pro-regular-svg-icons/faSnake'),
        "farSnooze": () => import('@fortawesome/pro-regular-svg-icons/faSnooze'),
        "farSnowBlowing": () => import('@fortawesome/pro-regular-svg-icons/faSnowBlowing'),
        "farSnowboarding": () => import('@fortawesome/pro-regular-svg-icons/faSnowboarding'),
        "farSnowflake": () => import('@fortawesome/pro-regular-svg-icons/faSnowflake'),
        "farSnowflakes": () => import('@fortawesome/pro-regular-svg-icons/faSnowflakes'),
        "farSnowman": () => import('@fortawesome/pro-regular-svg-icons/faSnowman'),
        "farSnowmobile": () => import('@fortawesome/pro-regular-svg-icons/faSnowmobile'),
        "farSnowplow": () => import('@fortawesome/pro-regular-svg-icons/faSnowplow'),
        "farSoap": () => import('@fortawesome/pro-regular-svg-icons/faSoap'),
        "farSocks": () => import('@fortawesome/pro-regular-svg-icons/faSocks'),
        "farSolarPanel": () => import('@fortawesome/pro-regular-svg-icons/faSolarPanel'),
        "farSolarSystem": () => import('@fortawesome/pro-regular-svg-icons/faSolarSystem'),
        "farSort": () => import('@fortawesome/pro-regular-svg-icons/faSort'),
        "farSortAlphaDown": () => import('@fortawesome/pro-regular-svg-icons/faSortAlphaDown'),
        "farSortAlphaDownAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortAlphaDownAlt'),
        "farSortAlphaUp": () => import('@fortawesome/pro-regular-svg-icons/faSortAlphaUp'),
        "farSortAlphaUpAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortAlphaUpAlt'),
        "farSortAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortAlt'),
        "farSortAmountDown": () => import('@fortawesome/pro-regular-svg-icons/faSortAmountDown'),
        "farSortAmountDownAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortAmountDownAlt'),
        "farSortAmountUp": () => import('@fortawesome/pro-regular-svg-icons/faSortAmountUp'),
        "farSortAmountUpAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortAmountUpAlt'),
        "farSortCircle": () => import('@fortawesome/pro-regular-svg-icons/faSortCircle'),
        "farSortCircleDown": () => import('@fortawesome/pro-regular-svg-icons/faSortCircleDown'),
        "farSortCircleUp": () => import('@fortawesome/pro-regular-svg-icons/faSortCircleUp'),
        "farSortDown": () => import('@fortawesome/pro-regular-svg-icons/faSortDown'),
        "farSortNumericDown": () => import('@fortawesome/pro-regular-svg-icons/faSortNumericDown'),
        "farSortNumericDownAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortNumericDownAlt'),
        "farSortNumericUp": () => import('@fortawesome/pro-regular-svg-icons/faSortNumericUp'),
        "farSortNumericUpAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortNumericUpAlt'),
        "farSortShapesDown": () => import('@fortawesome/pro-regular-svg-icons/faSortShapesDown'),
        "farSortShapesDownAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortShapesDownAlt'),
        "farSortShapesUp": () => import('@fortawesome/pro-regular-svg-icons/faSortShapesUp'),
        "farSortShapesUpAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortShapesUpAlt'),
        "farSortSizeDown": () => import('@fortawesome/pro-regular-svg-icons/faSortSizeDown'),
        "farSortSizeDownAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortSizeDownAlt'),
        "farSortSizeUp": () => import('@fortawesome/pro-regular-svg-icons/faSortSizeUp'),
        "farSortSizeUpAlt": () => import('@fortawesome/pro-regular-svg-icons/faSortSizeUpAlt'),
        "farSortUp": () => import('@fortawesome/pro-regular-svg-icons/faSortUp'),
        "farSoup": () => import('@fortawesome/pro-regular-svg-icons/faSoup'),
        "farSpa": () => import('@fortawesome/pro-regular-svg-icons/faSpa'),
        "farSpaceShuttle": () => import('@fortawesome/pro-regular-svg-icons/faSpaceShuttle'),
        "farSpaceStationMoon": () => import('@fortawesome/pro-regular-svg-icons/faSpaceStationMoon'),
        "farSpaceStationMoonAlt": () => import('@fortawesome/pro-regular-svg-icons/faSpaceStationMoonAlt'),
        "farSpade": () => import('@fortawesome/pro-regular-svg-icons/faSpade'),
        "farSparkles": () => import('@fortawesome/pro-regular-svg-icons/faSparkles'),
        "farSpeaker": () => import('@fortawesome/pro-regular-svg-icons/faSpeaker'),
        "farSpeakers": () => import('@fortawesome/pro-regular-svg-icons/faSpeakers'),
        "farSpellCheck": () => import('@fortawesome/pro-regular-svg-icons/faSpellCheck'),
        "farSpider": () => import('@fortawesome/pro-regular-svg-icons/faSpider'),
        "farSpiderBlackWidow": () => import('@fortawesome/pro-regular-svg-icons/faSpiderBlackWidow'),
        "farSpiderWeb": () => import('@fortawesome/pro-regular-svg-icons/faSpiderWeb'),
        "farSpinner": () => import('@fortawesome/pro-regular-svg-icons/faSpinner'),
        "farSpinnerThird": () => import('@fortawesome/pro-regular-svg-icons/faSpinnerThird'),
        "farSplotch": () => import('@fortawesome/pro-regular-svg-icons/faSplotch'),
        "farSprayCan": () => import('@fortawesome/pro-regular-svg-icons/faSprayCan'),
        "farSprinkler": () => import('@fortawesome/pro-regular-svg-icons/faSprinkler'),
        "farSquare": () => import('@fortawesome/pro-regular-svg-icons/faSquare'),
        "farSquareFull": () => import('@fortawesome/pro-regular-svg-icons/faSquareFull'),
        "farSquareRoot": () => import('@fortawesome/pro-regular-svg-icons/faSquareRoot'),
        "farSquareRootAlt": () => import('@fortawesome/pro-regular-svg-icons/faSquareRootAlt'),
        "farSquirrel": () => import('@fortawesome/pro-regular-svg-icons/faSquirrel'),
        "farStaff": () => import('@fortawesome/pro-regular-svg-icons/faStaff'),
        "farStamp": () => import('@fortawesome/pro-regular-svg-icons/faStamp'),
        "farStar": () => import('@fortawesome/pro-regular-svg-icons/faStar'),
        "farStarAndCrescent": () => import('@fortawesome/pro-regular-svg-icons/faStarAndCrescent'),
        "farStarChristmas": () => import('@fortawesome/pro-regular-svg-icons/faStarChristmas'),
        "farStarExclamation": () => import('@fortawesome/pro-regular-svg-icons/faStarExclamation'),
        "farStarHalf": () => import('@fortawesome/pro-regular-svg-icons/faStarHalf'),
        "farStarHalfAlt": () => import('@fortawesome/pro-regular-svg-icons/faStarHalfAlt'),
        "farStarOfDavid": () => import('@fortawesome/pro-regular-svg-icons/faStarOfDavid'),
        "farStarOfLife": () => import('@fortawesome/pro-regular-svg-icons/faStarOfLife'),
        "farStarShooting": () => import('@fortawesome/pro-regular-svg-icons/faStarShooting'),
        "farStarfighter": () => import('@fortawesome/pro-regular-svg-icons/faStarfighter'),
        "farStarfighterAlt": () => import('@fortawesome/pro-regular-svg-icons/faStarfighterAlt'),
        "farStars": () => import('@fortawesome/pro-regular-svg-icons/faStars'),
        "farStarship": () => import('@fortawesome/pro-regular-svg-icons/faStarship'),
        "farStarshipFreighter": () => import('@fortawesome/pro-regular-svg-icons/faStarshipFreighter'),
        "farSteak": () => import('@fortawesome/pro-regular-svg-icons/faSteak'),
        "farSteeringWheel": () => import('@fortawesome/pro-regular-svg-icons/faSteeringWheel'),
        "farStepBackward": () => import('@fortawesome/pro-regular-svg-icons/faStepBackward'),
        "farStepForward": () => import('@fortawesome/pro-regular-svg-icons/faStepForward'),
        "farStethoscope": () => import('@fortawesome/pro-regular-svg-icons/faStethoscope'),
        "farStickyNote": () => import('@fortawesome/pro-regular-svg-icons/faStickyNote'),
        "farStocking": () => import('@fortawesome/pro-regular-svg-icons/faStocking'),
        "farStomach": () => import('@fortawesome/pro-regular-svg-icons/faStomach'),
        "farStop": () => import('@fortawesome/pro-regular-svg-icons/faStop'),
        "farStopCircle": () => import('@fortawesome/pro-regular-svg-icons/faStopCircle'),
        "farStopwatch": () => import('@fortawesome/pro-regular-svg-icons/faStopwatch'),
        "farStopwatch20": () => import('@fortawesome/pro-regular-svg-icons/faStopwatch20'),
        "farStore": () => import('@fortawesome/pro-regular-svg-icons/faStore'),
        "farStoreAlt": () => import('@fortawesome/pro-regular-svg-icons/faStoreAlt'),
        "farStoreAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faStoreAltSlash'),
        "farStoreSlash": () => import('@fortawesome/pro-regular-svg-icons/faStoreSlash'),
        "farStream": () => import('@fortawesome/pro-regular-svg-icons/faStream'),
        "farStreetView": () => import('@fortawesome/pro-regular-svg-icons/faStreetView'),
        "farStretcher": () => import('@fortawesome/pro-regular-svg-icons/faStretcher'),
        "farStrikethrough": () => import('@fortawesome/pro-regular-svg-icons/faStrikethrough'),
        "farStroopwafel": () => import('@fortawesome/pro-regular-svg-icons/faStroopwafel'),
        "farSubscript": () => import('@fortawesome/pro-regular-svg-icons/faSubscript'),
        "farSubway": () => import('@fortawesome/pro-regular-svg-icons/faSubway'),
        "farSuitcase": () => import('@fortawesome/pro-regular-svg-icons/faSuitcase'),
        "farSuitcaseRolling": () => import('@fortawesome/pro-regular-svg-icons/faSuitcaseRolling'),
        "farSun": () => import('@fortawesome/pro-regular-svg-icons/faSun'),
        "farSunCloud": () => import('@fortawesome/pro-regular-svg-icons/faSunCloud'),
        "farSunDust": () => import('@fortawesome/pro-regular-svg-icons/faSunDust'),
        "farSunHaze": () => import('@fortawesome/pro-regular-svg-icons/faSunHaze'),
        "farSunglasses": () => import('@fortawesome/pro-regular-svg-icons/faSunglasses'),
        "farSunrise": () => import('@fortawesome/pro-regular-svg-icons/faSunrise'),
        "farSunset": () => import('@fortawesome/pro-regular-svg-icons/faSunset'),
        "farSuperscript": () => import('@fortawesome/pro-regular-svg-icons/faSuperscript'),
        "farSurprise": () => import('@fortawesome/pro-regular-svg-icons/faSurprise'),
        "farSwatchbook": () => import('@fortawesome/pro-regular-svg-icons/faSwatchbook'),
        "farSwimmer": () => import('@fortawesome/pro-regular-svg-icons/faSwimmer'),
        "farSwimmingPool": () => import('@fortawesome/pro-regular-svg-icons/faSwimmingPool'),
        "farSword": () => import('@fortawesome/pro-regular-svg-icons/faSword'),
        "farSwordLaser": () => import('@fortawesome/pro-regular-svg-icons/faSwordLaser'),
        "farSwordLaserAlt": () => import('@fortawesome/pro-regular-svg-icons/faSwordLaserAlt'),
        "farSwords": () => import('@fortawesome/pro-regular-svg-icons/faSwords'),
        "farSwordsLaser": () => import('@fortawesome/pro-regular-svg-icons/faSwordsLaser'),
        "farSynagogue": () => import('@fortawesome/pro-regular-svg-icons/faSynagogue'),
        "farSync": () => import('@fortawesome/pro-regular-svg-icons/faSync'),
        "farSyncAlt": () => import('@fortawesome/pro-regular-svg-icons/faSyncAlt'),
        "farSyringe": () => import('@fortawesome/pro-regular-svg-icons/faSyringe'),
        "farTable": () => import('@fortawesome/pro-regular-svg-icons/faTable'),
        "farTableTennis": () => import('@fortawesome/pro-regular-svg-icons/faTableTennis'),
        "farTablet": () => import('@fortawesome/pro-regular-svg-icons/faTablet'),
        "farTabletAlt": () => import('@fortawesome/pro-regular-svg-icons/faTabletAlt'),
        "farTabletAndroid": () => import('@fortawesome/pro-regular-svg-icons/faTabletAndroid'),
        "farTabletAndroidAlt": () => import('@fortawesome/pro-regular-svg-icons/faTabletAndroidAlt'),
        "farTabletRugged": () => import('@fortawesome/pro-regular-svg-icons/faTabletRugged'),
        "farTablets": () => import('@fortawesome/pro-regular-svg-icons/faTablets'),
        "farTachometer": () => import('@fortawesome/pro-regular-svg-icons/faTachometer'),
        "farTachometerAlt": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAlt'),
        "farTachometerAltAverage": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAltAverage'),
        "farTachometerAltFast": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAltFast'),
        "farTachometerAltFastest": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAltFastest'),
        "farTachometerAltSlow": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAltSlow'),
        "farTachometerAltSlowest": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAltSlowest'),
        "farTachometerAverage": () => import('@fortawesome/pro-regular-svg-icons/faTachometerAverage'),
        "farTachometerFast": () => import('@fortawesome/pro-regular-svg-icons/faTachometerFast'),
        "farTachometerFastest": () => import('@fortawesome/pro-regular-svg-icons/faTachometerFastest'),
        "farTachometerSlow": () => import('@fortawesome/pro-regular-svg-icons/faTachometerSlow'),
        "farTachometerSlowest": () => import('@fortawesome/pro-regular-svg-icons/faTachometerSlowest'),
        "farTaco": () => import('@fortawesome/pro-regular-svg-icons/faTaco'),
        "farTag": () => import('@fortawesome/pro-regular-svg-icons/faTag'),
        "farTags": () => import('@fortawesome/pro-regular-svg-icons/faTags'),
        "farTally": () => import('@fortawesome/pro-regular-svg-icons/faTally'),
        "farTanakh": () => import('@fortawesome/pro-regular-svg-icons/faTanakh'),
        "farTape": () => import('@fortawesome/pro-regular-svg-icons/faTape'),
        "farTasks": () => import('@fortawesome/pro-regular-svg-icons/faTasks'),
        "farTasksAlt": () => import('@fortawesome/pro-regular-svg-icons/faTasksAlt'),
        "farTaxi": () => import('@fortawesome/pro-regular-svg-icons/faTaxi'),
        "farTeeth": () => import('@fortawesome/pro-regular-svg-icons/faTeeth'),
        "farTeethOpen": () => import('@fortawesome/pro-regular-svg-icons/faTeethOpen'),
        "farTelescope": () => import('@fortawesome/pro-regular-svg-icons/faTelescope'),
        "farTemperatureDown": () => import('@fortawesome/pro-regular-svg-icons/faTemperatureDown'),
        "farTemperatureFrigid": () => import('@fortawesome/pro-regular-svg-icons/faTemperatureFrigid'),
        "farTemperatureHigh": () => import('@fortawesome/pro-regular-svg-icons/faTemperatureHigh'),
        "farTemperatureHot": () => import('@fortawesome/pro-regular-svg-icons/faTemperatureHot'),
        "farTemperatureLow": () => import('@fortawesome/pro-regular-svg-icons/faTemperatureLow'),
        "farTemperatureUp": () => import('@fortawesome/pro-regular-svg-icons/faTemperatureUp'),
        "farTenge": () => import('@fortawesome/pro-regular-svg-icons/faTenge'),
        "farTennisBall": () => import('@fortawesome/pro-regular-svg-icons/faTennisBall'),
        "farTerminal": () => import('@fortawesome/pro-regular-svg-icons/faTerminal'),
        "farText": () => import('@fortawesome/pro-regular-svg-icons/faText'),
        "farTextHeight": () => import('@fortawesome/pro-regular-svg-icons/faTextHeight'),
        "farTextSize": () => import('@fortawesome/pro-regular-svg-icons/faTextSize'),
        "farTextWidth": () => import('@fortawesome/pro-regular-svg-icons/faTextWidth'),
        "farTh": () => import('@fortawesome/pro-regular-svg-icons/faTh'),
        "farThLarge": () => import('@fortawesome/pro-regular-svg-icons/faThLarge'),
        "farThList": () => import('@fortawesome/pro-regular-svg-icons/faThList'),
        "farTheaterMasks": () => import('@fortawesome/pro-regular-svg-icons/faTheaterMasks'),
        "farThermometer": () => import('@fortawesome/pro-regular-svg-icons/faThermometer'),
        "farThermometerEmpty": () => import('@fortawesome/pro-regular-svg-icons/faThermometerEmpty'),
        "farThermometerFull": () => import('@fortawesome/pro-regular-svg-icons/faThermometerFull'),
        "farThermometerHalf": () => import('@fortawesome/pro-regular-svg-icons/faThermometerHalf'),
        "farThermometerQuarter": () => import('@fortawesome/pro-regular-svg-icons/faThermometerQuarter'),
        "farThermometerThreeQuarters": () => import('@fortawesome/pro-regular-svg-icons/faThermometerThreeQuarters'),
        "farTheta": () => import('@fortawesome/pro-regular-svg-icons/faTheta'),
        "farThumbsDown": () => import('@fortawesome/pro-regular-svg-icons/faThumbsDown'),
        "farThumbsUp": () => import('@fortawesome/pro-regular-svg-icons/faThumbsUp'),
        "farThumbtack": () => import('@fortawesome/pro-regular-svg-icons/faThumbtack'),
        "farThunderstorm": () => import('@fortawesome/pro-regular-svg-icons/faThunderstorm'),
        "farThunderstormMoon": () => import('@fortawesome/pro-regular-svg-icons/faThunderstormMoon'),
        "farThunderstormSun": () => import('@fortawesome/pro-regular-svg-icons/faThunderstormSun'),
        "farTicket": () => import('@fortawesome/pro-regular-svg-icons/faTicket'),
        "farTicketAlt": () => import('@fortawesome/pro-regular-svg-icons/faTicketAlt'),
        "farTilde": () => import('@fortawesome/pro-regular-svg-icons/faTilde'),
        "farTimes": () => import('@fortawesome/pro-regular-svg-icons/faTimes'),
        "farTimesCircle": () => import('@fortawesome/pro-regular-svg-icons/faTimesCircle'),
        "farTimesHexagon": () => import('@fortawesome/pro-regular-svg-icons/faTimesHexagon'),
        "farTimesOctagon": () => import('@fortawesome/pro-regular-svg-icons/faTimesOctagon'),
        "farTimesSquare": () => import('@fortawesome/pro-regular-svg-icons/faTimesSquare'),
        "farTint": () => import('@fortawesome/pro-regular-svg-icons/faTint'),
        "farTintSlash": () => import('@fortawesome/pro-regular-svg-icons/faTintSlash'),
        "farTire": () => import('@fortawesome/pro-regular-svg-icons/faTire'),
        "farTireFlat": () => import('@fortawesome/pro-regular-svg-icons/faTireFlat'),
        "farTirePressureWarning": () => import('@fortawesome/pro-regular-svg-icons/faTirePressureWarning'),
        "farTireRugged": () => import('@fortawesome/pro-regular-svg-icons/faTireRugged'),
        "farTired": () => import('@fortawesome/pro-regular-svg-icons/faTired'),
        "farToggleOff": () => import('@fortawesome/pro-regular-svg-icons/faToggleOff'),
        "farToggleOn": () => import('@fortawesome/pro-regular-svg-icons/faToggleOn'),
        "farToilet": () => import('@fortawesome/pro-regular-svg-icons/faToilet'),
        "farToiletPaper": () => import('@fortawesome/pro-regular-svg-icons/faToiletPaper'),
        "farToiletPaperAlt": () => import('@fortawesome/pro-regular-svg-icons/faToiletPaperAlt'),
        "farToiletPaperSlash": () => import('@fortawesome/pro-regular-svg-icons/faToiletPaperSlash'),
        "farTombstone": () => import('@fortawesome/pro-regular-svg-icons/faTombstone'),
        "farTombstoneAlt": () => import('@fortawesome/pro-regular-svg-icons/faTombstoneAlt'),
        "farToolbox": () => import('@fortawesome/pro-regular-svg-icons/faToolbox'),
        "farTools": () => import('@fortawesome/pro-regular-svg-icons/faTools'),
        "farTooth": () => import('@fortawesome/pro-regular-svg-icons/faTooth'),
        "farToothbrush": () => import('@fortawesome/pro-regular-svg-icons/faToothbrush'),
        "farTorah": () => import('@fortawesome/pro-regular-svg-icons/faTorah'),
        "farToriiGate": () => import('@fortawesome/pro-regular-svg-icons/faToriiGate'),
        "farTornado": () => import('@fortawesome/pro-regular-svg-icons/faTornado'),
        "farTractor": () => import('@fortawesome/pro-regular-svg-icons/faTractor'),
        "farTrademark": () => import('@fortawesome/pro-regular-svg-icons/faTrademark'),
        "farTrafficCone": () => import('@fortawesome/pro-regular-svg-icons/faTrafficCone'),
        "farTrafficLight": () => import('@fortawesome/pro-regular-svg-icons/faTrafficLight'),
        "farTrafficLightGo": () => import('@fortawesome/pro-regular-svg-icons/faTrafficLightGo'),
        "farTrafficLightSlow": () => import('@fortawesome/pro-regular-svg-icons/faTrafficLightSlow'),
        "farTrafficLightStop": () => import('@fortawesome/pro-regular-svg-icons/faTrafficLightStop'),
        "farTrailer": () => import('@fortawesome/pro-regular-svg-icons/faTrailer'),
        "farTrain": () => import('@fortawesome/pro-regular-svg-icons/faTrain'),
        "farTram": () => import('@fortawesome/pro-regular-svg-icons/faTram'),
        "farTransgender": () => import('@fortawesome/pro-regular-svg-icons/faTransgender'),
        "farTransgenderAlt": () => import('@fortawesome/pro-regular-svg-icons/faTransgenderAlt'),
        "farTransporter": () => import('@fortawesome/pro-regular-svg-icons/faTransporter'),
        "farTransporter1": () => import('@fortawesome/pro-regular-svg-icons/faTransporter1'),
        "farTransporter2": () => import('@fortawesome/pro-regular-svg-icons/faTransporter2'),
        "farTransporter3": () => import('@fortawesome/pro-regular-svg-icons/faTransporter3'),
        "farTransporterEmpty": () => import('@fortawesome/pro-regular-svg-icons/faTransporterEmpty'),
        "farTrash": () => import('@fortawesome/pro-regular-svg-icons/faTrash'),
        "farTrashAlt": () => import('@fortawesome/pro-regular-svg-icons/faTrashAlt'),
        "farTrashRestore": () => import('@fortawesome/pro-regular-svg-icons/faTrashRestore'),
        "farTrashRestoreAlt": () => import('@fortawesome/pro-regular-svg-icons/faTrashRestoreAlt'),
        "farTrashUndo": () => import('@fortawesome/pro-regular-svg-icons/faTrashUndo'),
        "farTrashUndoAlt": () => import('@fortawesome/pro-regular-svg-icons/faTrashUndoAlt'),
        "farTreasureChest": () => import('@fortawesome/pro-regular-svg-icons/faTreasureChest'),
        "farTree": () => import('@fortawesome/pro-regular-svg-icons/faTree'),
        "farTreeAlt": () => import('@fortawesome/pro-regular-svg-icons/faTreeAlt'),
        "farTreeChristmas": () => import('@fortawesome/pro-regular-svg-icons/faTreeChristmas'),
        "farTreeDecorated": () => import('@fortawesome/pro-regular-svg-icons/faTreeDecorated'),
        "farTreeLarge": () => import('@fortawesome/pro-regular-svg-icons/faTreeLarge'),
        "farTreePalm": () => import('@fortawesome/pro-regular-svg-icons/faTreePalm'),
        "farTrees": () => import('@fortawesome/pro-regular-svg-icons/faTrees'),
        "farTriangle": () => import('@fortawesome/pro-regular-svg-icons/faTriangle'),
        "farTriangleMusic": () => import('@fortawesome/pro-regular-svg-icons/faTriangleMusic'),
        "farTrophy": () => import('@fortawesome/pro-regular-svg-icons/faTrophy'),
        "farTrophyAlt": () => import('@fortawesome/pro-regular-svg-icons/faTrophyAlt'),
        "farTruck": () => import('@fortawesome/pro-regular-svg-icons/faTruck'),
        "farTruckContainer": () => import('@fortawesome/pro-regular-svg-icons/faTruckContainer'),
        "farTruckCouch": () => import('@fortawesome/pro-regular-svg-icons/faTruckCouch'),
        "farTruckLoading": () => import('@fortawesome/pro-regular-svg-icons/faTruckLoading'),
        "farTruckMonster": () => import('@fortawesome/pro-regular-svg-icons/faTruckMonster'),
        "farTruckMoving": () => import('@fortawesome/pro-regular-svg-icons/faTruckMoving'),
        "farTruckPickup": () => import('@fortawesome/pro-regular-svg-icons/faTruckPickup'),
        "farTruckPlow": () => import('@fortawesome/pro-regular-svg-icons/faTruckPlow'),
        "farTruckRamp": () => import('@fortawesome/pro-regular-svg-icons/faTruckRamp'),
        "farTrumpet": () => import('@fortawesome/pro-regular-svg-icons/faTrumpet'),
        "farTshirt": () => import('@fortawesome/pro-regular-svg-icons/faTshirt'),
        "farTty": () => import('@fortawesome/pro-regular-svg-icons/faTty'),
        "farTurkey": () => import('@fortawesome/pro-regular-svg-icons/faTurkey'),
        "farTurntable": () => import('@fortawesome/pro-regular-svg-icons/faTurntable'),
        "farTurtle": () => import('@fortawesome/pro-regular-svg-icons/faTurtle'),
        "farTv": () => import('@fortawesome/pro-regular-svg-icons/faTv'),
        "farTvAlt": () => import('@fortawesome/pro-regular-svg-icons/faTvAlt'),
        "farTvMusic": () => import('@fortawesome/pro-regular-svg-icons/faTvMusic'),
        "farTvRetro": () => import('@fortawesome/pro-regular-svg-icons/faTvRetro'),
        "farTypewriter": () => import('@fortawesome/pro-regular-svg-icons/faTypewriter'),
        "farUfo": () => import('@fortawesome/pro-regular-svg-icons/faUfo'),
        "farUfoBeam": () => import('@fortawesome/pro-regular-svg-icons/faUfoBeam'),
        "farUmbrella": () => import('@fortawesome/pro-regular-svg-icons/faUmbrella'),
        "farUmbrellaBeach": () => import('@fortawesome/pro-regular-svg-icons/faUmbrellaBeach'),
        "farUnderline": () => import('@fortawesome/pro-regular-svg-icons/faUnderline'),
        "farUndo": () => import('@fortawesome/pro-regular-svg-icons/faUndo'),
        "farUndoAlt": () => import('@fortawesome/pro-regular-svg-icons/faUndoAlt'),
        "farUnicorn": () => import('@fortawesome/pro-regular-svg-icons/faUnicorn'),
        "farUnion": () => import('@fortawesome/pro-regular-svg-icons/faUnion'),
        "farUniversalAccess": () => import('@fortawesome/pro-regular-svg-icons/faUniversalAccess'),
        "farUniversity": () => import('@fortawesome/pro-regular-svg-icons/faUniversity'),
        "farUnlink": () => import('@fortawesome/pro-regular-svg-icons/faUnlink'),
        "farUnlock": () => import('@fortawesome/pro-regular-svg-icons/faUnlock'),
        "farUnlockAlt": () => import('@fortawesome/pro-regular-svg-icons/faUnlockAlt'),
        "farUpload": () => import('@fortawesome/pro-regular-svg-icons/faUpload'),
        "farUsbDrive": () => import('@fortawesome/pro-regular-svg-icons/faUsbDrive'),
        "farUsdCircle": () => import('@fortawesome/pro-regular-svg-icons/faUsdCircle'),
        "farUsdSquare": () => import('@fortawesome/pro-regular-svg-icons/faUsdSquare'),
        "farUser": () => import('@fortawesome/pro-regular-svg-icons/faUser'),
        "farUserAlien": () => import('@fortawesome/pro-regular-svg-icons/faUserAlien'),
        "farUserAlt": () => import('@fortawesome/pro-regular-svg-icons/faUserAlt'),
        "farUserAltSlash": () => import('@fortawesome/pro-regular-svg-icons/faUserAltSlash'),
        "farUserAstronaut": () => import('@fortawesome/pro-regular-svg-icons/faUserAstronaut'),
        "farUserChart": () => import('@fortawesome/pro-regular-svg-icons/faUserChart'),
        "farUserCheck": () => import('@fortawesome/pro-regular-svg-icons/faUserCheck'),
        "farUserCircle": () => import('@fortawesome/pro-regular-svg-icons/faUserCircle'),
        "farUserClock": () => import('@fortawesome/pro-regular-svg-icons/faUserClock'),
        "farUserCog": () => import('@fortawesome/pro-regular-svg-icons/faUserCog'),
        "farUserCowboy": () => import('@fortawesome/pro-regular-svg-icons/faUserCowboy'),
        "farUserCrown": () => import('@fortawesome/pro-regular-svg-icons/faUserCrown'),
        "farUserEdit": () => import('@fortawesome/pro-regular-svg-icons/faUserEdit'),
        "farUserFriends": () => import('@fortawesome/pro-regular-svg-icons/faUserFriends'),
        "farUserGraduate": () => import('@fortawesome/pro-regular-svg-icons/faUserGraduate'),
        "farUserHardHat": () => import('@fortawesome/pro-regular-svg-icons/faUserHardHat'),
        "farUserHeadset": () => import('@fortawesome/pro-regular-svg-icons/faUserHeadset'),
        "farUserInjured": () => import('@fortawesome/pro-regular-svg-icons/faUserInjured'),
        "farUserLock": () => import('@fortawesome/pro-regular-svg-icons/faUserLock'),
        "farUserMd": () => import('@fortawesome/pro-regular-svg-icons/faUserMd'),
        "farUserMdChat": () => import('@fortawesome/pro-regular-svg-icons/faUserMdChat'),
        "farUserMinus": () => import('@fortawesome/pro-regular-svg-icons/faUserMinus'),
        "farUserMusic": () => import('@fortawesome/pro-regular-svg-icons/faUserMusic'),
        "farUserNinja": () => import('@fortawesome/pro-regular-svg-icons/faUserNinja'),
        "farUserNurse": () => import('@fortawesome/pro-regular-svg-icons/faUserNurse'),
        "farUserPlus": () => import('@fortawesome/pro-regular-svg-icons/faUserPlus'),
        "farUserRobot": () => import('@fortawesome/pro-regular-svg-icons/faUserRobot'),
        "farUserSecret": () => import('@fortawesome/pro-regular-svg-icons/faUserSecret'),
        "farUserShield": () => import('@fortawesome/pro-regular-svg-icons/faUserShield'),
        "farUserSlash": () => import('@fortawesome/pro-regular-svg-icons/faUserSlash'),
        "farUserTag": () => import('@fortawesome/pro-regular-svg-icons/faUserTag'),
        "farUserTie": () => import('@fortawesome/pro-regular-svg-icons/faUserTie'),
        "farUserTimes": () => import('@fortawesome/pro-regular-svg-icons/faUserTimes'),
        "farUserUnlock": () => import('@fortawesome/pro-regular-svg-icons/faUserUnlock'),
        "farUserVisor": () => import('@fortawesome/pro-regular-svg-icons/faUserVisor'),
        "farUsers": () => import('@fortawesome/pro-regular-svg-icons/faUsers'),
        "farUsersClass": () => import('@fortawesome/pro-regular-svg-icons/faUsersClass'),
        "farUsersCog": () => import('@fortawesome/pro-regular-svg-icons/faUsersCog'),
        "farUsersCrown": () => import('@fortawesome/pro-regular-svg-icons/faUsersCrown'),
        "farUsersMedical": () => import('@fortawesome/pro-regular-svg-icons/faUsersMedical'),
        "farUsersSlash": () => import('@fortawesome/pro-regular-svg-icons/faUsersSlash'),
        "farUtensilFork": () => import('@fortawesome/pro-regular-svg-icons/faUtensilFork'),
        "farUtensilKnife": () => import('@fortawesome/pro-regular-svg-icons/faUtensilKnife'),
        "farUtensilSpoon": () => import('@fortawesome/pro-regular-svg-icons/faUtensilSpoon'),
        "farUtensils": () => import('@fortawesome/pro-regular-svg-icons/faUtensils'),
        "farUtensilsAlt": () => import('@fortawesome/pro-regular-svg-icons/faUtensilsAlt'),
        "farVacuum": () => import('@fortawesome/pro-regular-svg-icons/faVacuum'),
        "farVacuumRobot": () => import('@fortawesome/pro-regular-svg-icons/faVacuumRobot'),
        "farValueAbsolute": () => import('@fortawesome/pro-regular-svg-icons/faValueAbsolute'),
        "farVectorSquare": () => import('@fortawesome/pro-regular-svg-icons/faVectorSquare'),
        "farVenus": () => import('@fortawesome/pro-regular-svg-icons/faVenus'),
        "farVenusDouble": () => import('@fortawesome/pro-regular-svg-icons/faVenusDouble'),
        "farVenusMars": () => import('@fortawesome/pro-regular-svg-icons/faVenusMars'),
        "farVest": () => import('@fortawesome/pro-regular-svg-icons/faVest'),
        "farVestPatches": () => import('@fortawesome/pro-regular-svg-icons/faVestPatches'),
        "farVhs": () => import('@fortawesome/pro-regular-svg-icons/faVhs'),
        "farVial": () => import('@fortawesome/pro-regular-svg-icons/faVial'),
        "farVials": () => import('@fortawesome/pro-regular-svg-icons/faVials'),
        "farVideo": () => import('@fortawesome/pro-regular-svg-icons/faVideo'),
        "farVideoPlus": () => import('@fortawesome/pro-regular-svg-icons/faVideoPlus'),
        "farVideoSlash": () => import('@fortawesome/pro-regular-svg-icons/faVideoSlash'),
        "farVihara": () => import('@fortawesome/pro-regular-svg-icons/faVihara'),
        "farViolin": () => import('@fortawesome/pro-regular-svg-icons/faViolin'),
        "farVirus": () => import('@fortawesome/pro-regular-svg-icons/faVirus'),
        "farVirusSlash": () => import('@fortawesome/pro-regular-svg-icons/faVirusSlash'),
        "farViruses": () => import('@fortawesome/pro-regular-svg-icons/faViruses'),
        "farVoicemail": () => import('@fortawesome/pro-regular-svg-icons/faVoicemail'),
        "farVolcano": () => import('@fortawesome/pro-regular-svg-icons/faVolcano'),
        "farVolleyballBall": () => import('@fortawesome/pro-regular-svg-icons/faVolleyballBall'),
        "farVolume": () => import('@fortawesome/pro-regular-svg-icons/faVolume'),
        "farVolumeDown": () => import('@fortawesome/pro-regular-svg-icons/faVolumeDown'),
        "farVolumeMute": () => import('@fortawesome/pro-regular-svg-icons/faVolumeMute'),
        "farVolumeOff": () => import('@fortawesome/pro-regular-svg-icons/faVolumeOff'),
        "farVolumeSlash": () => import('@fortawesome/pro-regular-svg-icons/faVolumeSlash'),
        "farVolumeUp": () => import('@fortawesome/pro-regular-svg-icons/faVolumeUp'),
        "farVoteNay": () => import('@fortawesome/pro-regular-svg-icons/faVoteNay'),
        "farVoteYea": () => import('@fortawesome/pro-regular-svg-icons/faVoteYea'),
        "farVrCardboard": () => import('@fortawesome/pro-regular-svg-icons/faVrCardboard'),
        "farWagonCovered": () => import('@fortawesome/pro-regular-svg-icons/faWagonCovered'),
        "farWalker": () => import('@fortawesome/pro-regular-svg-icons/faWalker'),
        "farWalkieTalkie": () => import('@fortawesome/pro-regular-svg-icons/faWalkieTalkie'),
        "farWalking": () => import('@fortawesome/pro-regular-svg-icons/faWalking'),
        "farWallet": () => import('@fortawesome/pro-regular-svg-icons/faWallet'),
        "farWand": () => import('@fortawesome/pro-regular-svg-icons/faWand'),
        "farWandMagic": () => import('@fortawesome/pro-regular-svg-icons/faWandMagic'),
        "farWarehouse": () => import('@fortawesome/pro-regular-svg-icons/faWarehouse'),
        "farWarehouseAlt": () => import('@fortawesome/pro-regular-svg-icons/faWarehouseAlt'),
        "farWasher": () => import('@fortawesome/pro-regular-svg-icons/faWasher'),
        "farWatch": () => import('@fortawesome/pro-regular-svg-icons/faWatch'),
        "farWatchCalculator": () => import('@fortawesome/pro-regular-svg-icons/faWatchCalculator'),
        "farWatchFitness": () => import('@fortawesome/pro-regular-svg-icons/faWatchFitness'),
        "farWater": () => import('@fortawesome/pro-regular-svg-icons/faWater'),
        "farWaterLower": () => import('@fortawesome/pro-regular-svg-icons/faWaterLower'),
        "farWaterRise": () => import('@fortawesome/pro-regular-svg-icons/faWaterRise'),
        "farWaveSine": () => import('@fortawesome/pro-regular-svg-icons/faWaveSine'),
        "farWaveSquare": () => import('@fortawesome/pro-regular-svg-icons/faWaveSquare'),
        "farWaveTriangle": () => import('@fortawesome/pro-regular-svg-icons/faWaveTriangle'),
        "farWaveform": () => import('@fortawesome/pro-regular-svg-icons/faWaveform'),
        "farWaveformPath": () => import('@fortawesome/pro-regular-svg-icons/faWaveformPath'),
        "farWebcam": () => import('@fortawesome/pro-regular-svg-icons/faWebcam'),
        "farWebcamSlash": () => import('@fortawesome/pro-regular-svg-icons/faWebcamSlash'),
        "farWeight": () => import('@fortawesome/pro-regular-svg-icons/faWeight'),
        "farWeightHanging": () => import('@fortawesome/pro-regular-svg-icons/faWeightHanging'),
        "farWhale": () => import('@fortawesome/pro-regular-svg-icons/faWhale'),
        "farWheat": () => import('@fortawesome/pro-regular-svg-icons/faWheat'),
        "farWheelchair": () => import('@fortawesome/pro-regular-svg-icons/faWheelchair'),
        "farWhistle": () => import('@fortawesome/pro-regular-svg-icons/faWhistle'),
        "farWifi": () => import('@fortawesome/pro-regular-svg-icons/faWifi'),
        "farWifi1": () => import('@fortawesome/pro-regular-svg-icons/faWifi1'),
        "farWifi2": () => import('@fortawesome/pro-regular-svg-icons/faWifi2'),
        "farWifiSlash": () => import('@fortawesome/pro-regular-svg-icons/faWifiSlash'),
        "farWind": () => import('@fortawesome/pro-regular-svg-icons/faWind'),
        "farWindTurbine": () => import('@fortawesome/pro-regular-svg-icons/faWindTurbine'),
        "farWindWarning": () => import('@fortawesome/pro-regular-svg-icons/faWindWarning'),
        "farWindow": () => import('@fortawesome/pro-regular-svg-icons/faWindow'),
        "farWindowAlt": () => import('@fortawesome/pro-regular-svg-icons/faWindowAlt'),
        "farWindowClose": () => import('@fortawesome/pro-regular-svg-icons/faWindowClose'),
        "farWindowFrame": () => import('@fortawesome/pro-regular-svg-icons/faWindowFrame'),
        "farWindowFrameOpen": () => import('@fortawesome/pro-regular-svg-icons/faWindowFrameOpen'),
        "farWindowMaximize": () => import('@fortawesome/pro-regular-svg-icons/faWindowMaximize'),
        "farWindowMinimize": () => import('@fortawesome/pro-regular-svg-icons/faWindowMinimize'),
        "farWindowRestore": () => import('@fortawesome/pro-regular-svg-icons/faWindowRestore'),
        "farWindsock": () => import('@fortawesome/pro-regular-svg-icons/faWindsock'),
        "farWineBottle": () => import('@fortawesome/pro-regular-svg-icons/faWineBottle'),
        "farWineGlass": () => import('@fortawesome/pro-regular-svg-icons/faWineGlass'),
        "farWineGlassAlt": () => import('@fortawesome/pro-regular-svg-icons/faWineGlassAlt'),
        "farWonSign": () => import('@fortawesome/pro-regular-svg-icons/faWonSign'),
        "farWreath": () => import('@fortawesome/pro-regular-svg-icons/faWreath'),
        "farWrench": () => import('@fortawesome/pro-regular-svg-icons/faWrench'),
        "farXRay": () => import('@fortawesome/pro-regular-svg-icons/faXRay'),
        "farYenSign": () => import('@fortawesome/pro-regular-svg-icons/faYenSign'),
        "farYinYang": () => import('@fortawesome/pro-regular-svg-icons/faYinYang')
    }
};
export default packInfo;
