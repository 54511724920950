const packInfo = {
    lib: "node_modules/@fortawesome/pro-light-svg-icons",
    prefix: "fal",
    iconsNameMap: {
        "falAbacus": () => import('@fortawesome/pro-light-svg-icons/faAbacus'),
        "falAcorn": () => import('@fortawesome/pro-light-svg-icons/faAcorn'),
        "falAd": () => import('@fortawesome/pro-light-svg-icons/faAd'),
        "falAddressBook": () => import('@fortawesome/pro-light-svg-icons/faAddressBook'),
        "falAddressCard": () => import('@fortawesome/pro-light-svg-icons/faAddressCard'),
        "falAdjust": () => import('@fortawesome/pro-light-svg-icons/faAdjust'),
        "falAirConditioner": () => import('@fortawesome/pro-light-svg-icons/faAirConditioner'),
        "falAirFreshener": () => import('@fortawesome/pro-light-svg-icons/faAirFreshener'),
        "falAlarmClock": () => import('@fortawesome/pro-light-svg-icons/faAlarmClock'),
        "falAlarmExclamation": () => import('@fortawesome/pro-light-svg-icons/faAlarmExclamation'),
        "falAlarmPlus": () => import('@fortawesome/pro-light-svg-icons/faAlarmPlus'),
        "falAlarmSnooze": () => import('@fortawesome/pro-light-svg-icons/faAlarmSnooze'),
        "falAlbum": () => import('@fortawesome/pro-light-svg-icons/faAlbum'),
        "falAlbumCollection": () => import('@fortawesome/pro-light-svg-icons/faAlbumCollection'),
        "falAlicorn": () => import('@fortawesome/pro-light-svg-icons/faAlicorn'),
        "falAlien": () => import('@fortawesome/pro-light-svg-icons/faAlien'),
        "falAlienMonster": () => import('@fortawesome/pro-light-svg-icons/faAlienMonster'),
        "falAlignCenter": () => import('@fortawesome/pro-light-svg-icons/faAlignCenter'),
        "falAlignJustify": () => import('@fortawesome/pro-light-svg-icons/faAlignJustify'),
        "falAlignLeft": () => import('@fortawesome/pro-light-svg-icons/faAlignLeft'),
        "falAlignRight": () => import('@fortawesome/pro-light-svg-icons/faAlignRight'),
        "falAlignSlash": () => import('@fortawesome/pro-light-svg-icons/faAlignSlash'),
        "falAllergies": () => import('@fortawesome/pro-light-svg-icons/faAllergies'),
        "falAmbulance": () => import('@fortawesome/pro-light-svg-icons/faAmbulance'),
        "falAmericanSignLanguageInterpreting": () => import('@fortawesome/pro-light-svg-icons/faAmericanSignLanguageInterpreting'),
        "falAmpGuitar": () => import('@fortawesome/pro-light-svg-icons/faAmpGuitar'),
        "falAnalytics": () => import('@fortawesome/pro-light-svg-icons/faAnalytics'),
        "falAnchor": () => import('@fortawesome/pro-light-svg-icons/faAnchor'),
        "falAngel": () => import('@fortawesome/pro-light-svg-icons/faAngel'),
        "falAngleDoubleDown": () => import('@fortawesome/pro-light-svg-icons/faAngleDoubleDown'),
        "falAngleDoubleLeft": () => import('@fortawesome/pro-light-svg-icons/faAngleDoubleLeft'),
        "falAngleDoubleRight": () => import('@fortawesome/pro-light-svg-icons/faAngleDoubleRight'),
        "falAngleDoubleUp": () => import('@fortawesome/pro-light-svg-icons/faAngleDoubleUp'),
        "falAngleDown": () => import('@fortawesome/pro-light-svg-icons/faAngleDown'),
        "falAngleLeft": () => import('@fortawesome/pro-light-svg-icons/faAngleLeft'),
        "falAngleRight": () => import('@fortawesome/pro-light-svg-icons/faAngleRight'),
        "falAngleUp": () => import('@fortawesome/pro-light-svg-icons/faAngleUp'),
        "falAngry": () => import('@fortawesome/pro-light-svg-icons/faAngry'),
        "falAnkh": () => import('@fortawesome/pro-light-svg-icons/faAnkh'),
        "falAppleAlt": () => import('@fortawesome/pro-light-svg-icons/faAppleAlt'),
        "falAppleCrate": () => import('@fortawesome/pro-light-svg-icons/faAppleCrate'),
        "falArchive": () => import('@fortawesome/pro-light-svg-icons/faArchive'),
        "falArchway": () => import('@fortawesome/pro-light-svg-icons/faArchway'),
        "falArrowAltCircleDown": () => import('@fortawesome/pro-light-svg-icons/faArrowAltCircleDown'),
        "falArrowAltCircleLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowAltCircleLeft'),
        "falArrowAltCircleRight": () => import('@fortawesome/pro-light-svg-icons/faArrowAltCircleRight'),
        "falArrowAltCircleUp": () => import('@fortawesome/pro-light-svg-icons/faArrowAltCircleUp'),
        "falArrowAltDown": () => import('@fortawesome/pro-light-svg-icons/faArrowAltDown'),
        "falArrowAltFromBottom": () => import('@fortawesome/pro-light-svg-icons/faArrowAltFromBottom'),
        "falArrowAltFromLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowAltFromLeft'),
        "falArrowAltFromRight": () => import('@fortawesome/pro-light-svg-icons/faArrowAltFromRight'),
        "falArrowAltFromTop": () => import('@fortawesome/pro-light-svg-icons/faArrowAltFromTop'),
        "falArrowAltLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowAltLeft'),
        "falArrowAltRight": () => import('@fortawesome/pro-light-svg-icons/faArrowAltRight'),
        "falArrowAltSquareDown": () => import('@fortawesome/pro-light-svg-icons/faArrowAltSquareDown'),
        "falArrowAltSquareLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowAltSquareLeft'),
        "falArrowAltSquareRight": () => import('@fortawesome/pro-light-svg-icons/faArrowAltSquareRight'),
        "falArrowAltSquareUp": () => import('@fortawesome/pro-light-svg-icons/faArrowAltSquareUp'),
        "falArrowAltToBottom": () => import('@fortawesome/pro-light-svg-icons/faArrowAltToBottom'),
        "falArrowAltToLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowAltToLeft'),
        "falArrowAltToRight": () => import('@fortawesome/pro-light-svg-icons/faArrowAltToRight'),
        "falArrowAltToTop": () => import('@fortawesome/pro-light-svg-icons/faArrowAltToTop'),
        "falArrowAltUp": () => import('@fortawesome/pro-light-svg-icons/faArrowAltUp'),
        "falArrowCircleDown": () => import('@fortawesome/pro-light-svg-icons/faArrowCircleDown'),
        "falArrowCircleLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowCircleLeft'),
        "falArrowCircleRight": () => import('@fortawesome/pro-light-svg-icons/faArrowCircleRight'),
        "falArrowCircleUp": () => import('@fortawesome/pro-light-svg-icons/faArrowCircleUp'),
        "falArrowDown": () => import('@fortawesome/pro-light-svg-icons/faArrowDown'),
        "falArrowFromBottom": () => import('@fortawesome/pro-light-svg-icons/faArrowFromBottom'),
        "falArrowFromLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowFromLeft'),
        "falArrowFromRight": () => import('@fortawesome/pro-light-svg-icons/faArrowFromRight'),
        "falArrowFromTop": () => import('@fortawesome/pro-light-svg-icons/faArrowFromTop'),
        "falArrowLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowLeft'),
        "falArrowRight": () => import('@fortawesome/pro-light-svg-icons/faArrowRight'),
        "falArrowSquareDown": () => import('@fortawesome/pro-light-svg-icons/faArrowSquareDown'),
        "falArrowSquareLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowSquareLeft'),
        "falArrowSquareRight": () => import('@fortawesome/pro-light-svg-icons/faArrowSquareRight'),
        "falArrowSquareUp": () => import('@fortawesome/pro-light-svg-icons/faArrowSquareUp'),
        "falArrowToBottom": () => import('@fortawesome/pro-light-svg-icons/faArrowToBottom'),
        "falArrowToLeft": () => import('@fortawesome/pro-light-svg-icons/faArrowToLeft'),
        "falArrowToRight": () => import('@fortawesome/pro-light-svg-icons/faArrowToRight'),
        "falArrowToTop": () => import('@fortawesome/pro-light-svg-icons/faArrowToTop'),
        "falArrowUp": () => import('@fortawesome/pro-light-svg-icons/faArrowUp'),
        "falArrows": () => import('@fortawesome/pro-light-svg-icons/faArrows'),
        "falArrowsAlt": () => import('@fortawesome/pro-light-svg-icons/faArrowsAlt'),
        "falArrowsAltH": () => import('@fortawesome/pro-light-svg-icons/faArrowsAltH'),
        "falArrowsAltV": () => import('@fortawesome/pro-light-svg-icons/faArrowsAltV'),
        "falArrowsH": () => import('@fortawesome/pro-light-svg-icons/faArrowsH'),
        "falArrowsV": () => import('@fortawesome/pro-light-svg-icons/faArrowsV'),
        "falAssistiveListeningSystems": () => import('@fortawesome/pro-light-svg-icons/faAssistiveListeningSystems'),
        "falAsterisk": () => import('@fortawesome/pro-light-svg-icons/faAsterisk'),
        "falAt": () => import('@fortawesome/pro-light-svg-icons/faAt'),
        "falAtlas": () => import('@fortawesome/pro-light-svg-icons/faAtlas'),
        "falAtom": () => import('@fortawesome/pro-light-svg-icons/faAtom'),
        "falAtomAlt": () => import('@fortawesome/pro-light-svg-icons/faAtomAlt'),
        "falAudioDescription": () => import('@fortawesome/pro-light-svg-icons/faAudioDescription'),
        "falAward": () => import('@fortawesome/pro-light-svg-icons/faAward'),
        "falAxe": () => import('@fortawesome/pro-light-svg-icons/faAxe'),
        "falAxeBattle": () => import('@fortawesome/pro-light-svg-icons/faAxeBattle'),
        "falBaby": () => import('@fortawesome/pro-light-svg-icons/faBaby'),
        "falBabyCarriage": () => import('@fortawesome/pro-light-svg-icons/faBabyCarriage'),
        "falBackpack": () => import('@fortawesome/pro-light-svg-icons/faBackpack'),
        "falBackspace": () => import('@fortawesome/pro-light-svg-icons/faBackspace'),
        "falBackward": () => import('@fortawesome/pro-light-svg-icons/faBackward'),
        "falBacon": () => import('@fortawesome/pro-light-svg-icons/faBacon'),
        "falBacteria": () => import('@fortawesome/pro-light-svg-icons/faBacteria'),
        "falBacterium": () => import('@fortawesome/pro-light-svg-icons/faBacterium'),
        "falBadge": () => import('@fortawesome/pro-light-svg-icons/faBadge'),
        "falBadgeCheck": () => import('@fortawesome/pro-light-svg-icons/faBadgeCheck'),
        "falBadgeDollar": () => import('@fortawesome/pro-light-svg-icons/faBadgeDollar'),
        "falBadgePercent": () => import('@fortawesome/pro-light-svg-icons/faBadgePercent'),
        "falBadgeSheriff": () => import('@fortawesome/pro-light-svg-icons/faBadgeSheriff'),
        "falBadgerHoney": () => import('@fortawesome/pro-light-svg-icons/faBadgerHoney'),
        "falBagsShopping": () => import('@fortawesome/pro-light-svg-icons/faBagsShopping'),
        "falBahai": () => import('@fortawesome/pro-light-svg-icons/faBahai'),
        "falBalanceScale": () => import('@fortawesome/pro-light-svg-icons/faBalanceScale'),
        "falBalanceScaleLeft": () => import('@fortawesome/pro-light-svg-icons/faBalanceScaleLeft'),
        "falBalanceScaleRight": () => import('@fortawesome/pro-light-svg-icons/faBalanceScaleRight'),
        "falBallPile": () => import('@fortawesome/pro-light-svg-icons/faBallPile'),
        "falBallot": () => import('@fortawesome/pro-light-svg-icons/faBallot'),
        "falBallotCheck": () => import('@fortawesome/pro-light-svg-icons/faBallotCheck'),
        "falBan": () => import('@fortawesome/pro-light-svg-icons/faBan'),
        "falBandAid": () => import('@fortawesome/pro-light-svg-icons/faBandAid'),
        "falBanjo": () => import('@fortawesome/pro-light-svg-icons/faBanjo'),
        "falBarcode": () => import('@fortawesome/pro-light-svg-icons/faBarcode'),
        "falBarcodeAlt": () => import('@fortawesome/pro-light-svg-icons/faBarcodeAlt'),
        "falBarcodeRead": () => import('@fortawesome/pro-light-svg-icons/faBarcodeRead'),
        "falBarcodeScan": () => import('@fortawesome/pro-light-svg-icons/faBarcodeScan'),
        "falBars": () => import('@fortawesome/pro-light-svg-icons/faBars'),
        "falBaseball": () => import('@fortawesome/pro-light-svg-icons/faBaseball'),
        "falBaseballBall": () => import('@fortawesome/pro-light-svg-icons/faBaseballBall'),
        "falBasketballBall": () => import('@fortawesome/pro-light-svg-icons/faBasketballBall'),
        "falBasketballHoop": () => import('@fortawesome/pro-light-svg-icons/faBasketballHoop'),
        "falBat": () => import('@fortawesome/pro-light-svg-icons/faBat'),
        "falBath": () => import('@fortawesome/pro-light-svg-icons/faBath'),
        "falBatteryBolt": () => import('@fortawesome/pro-light-svg-icons/faBatteryBolt'),
        "falBatteryEmpty": () => import('@fortawesome/pro-light-svg-icons/faBatteryEmpty'),
        "falBatteryFull": () => import('@fortawesome/pro-light-svg-icons/faBatteryFull'),
        "falBatteryHalf": () => import('@fortawesome/pro-light-svg-icons/faBatteryHalf'),
        "falBatteryQuarter": () => import('@fortawesome/pro-light-svg-icons/faBatteryQuarter'),
        "falBatterySlash": () => import('@fortawesome/pro-light-svg-icons/faBatterySlash'),
        "falBatteryThreeQuarters": () => import('@fortawesome/pro-light-svg-icons/faBatteryThreeQuarters'),
        "falBed": () => import('@fortawesome/pro-light-svg-icons/faBed'),
        "falBedAlt": () => import('@fortawesome/pro-light-svg-icons/faBedAlt'),
        "falBedBunk": () => import('@fortawesome/pro-light-svg-icons/faBedBunk'),
        "falBedEmpty": () => import('@fortawesome/pro-light-svg-icons/faBedEmpty'),
        "falBeer": () => import('@fortawesome/pro-light-svg-icons/faBeer'),
        "falBell": () => import('@fortawesome/pro-light-svg-icons/faBell'),
        "falBellExclamation": () => import('@fortawesome/pro-light-svg-icons/faBellExclamation'),
        "falBellOn": () => import('@fortawesome/pro-light-svg-icons/faBellOn'),
        "falBellPlus": () => import('@fortawesome/pro-light-svg-icons/faBellPlus'),
        "falBellSchool": () => import('@fortawesome/pro-light-svg-icons/faBellSchool'),
        "falBellSchoolSlash": () => import('@fortawesome/pro-light-svg-icons/faBellSchoolSlash'),
        "falBellSlash": () => import('@fortawesome/pro-light-svg-icons/faBellSlash'),
        "falBells": () => import('@fortawesome/pro-light-svg-icons/faBells'),
        "falBetamax": () => import('@fortawesome/pro-light-svg-icons/faBetamax'),
        "falBezierCurve": () => import('@fortawesome/pro-light-svg-icons/faBezierCurve'),
        "falBible": () => import('@fortawesome/pro-light-svg-icons/faBible'),
        "falBicycle": () => import('@fortawesome/pro-light-svg-icons/faBicycle'),
        "falBiking": () => import('@fortawesome/pro-light-svg-icons/faBiking'),
        "falBikingMountain": () => import('@fortawesome/pro-light-svg-icons/faBikingMountain'),
        "falBinoculars": () => import('@fortawesome/pro-light-svg-icons/faBinoculars'),
        "falBiohazard": () => import('@fortawesome/pro-light-svg-icons/faBiohazard'),
        "falBirthdayCake": () => import('@fortawesome/pro-light-svg-icons/faBirthdayCake'),
        "falBlanket": () => import('@fortawesome/pro-light-svg-icons/faBlanket'),
        "falBlender": () => import('@fortawesome/pro-light-svg-icons/faBlender'),
        "falBlenderPhone": () => import('@fortawesome/pro-light-svg-icons/faBlenderPhone'),
        "falBlind": () => import('@fortawesome/pro-light-svg-icons/faBlind'),
        "falBlinds": () => import('@fortawesome/pro-light-svg-icons/faBlinds'),
        "falBlindsOpen": () => import('@fortawesome/pro-light-svg-icons/faBlindsOpen'),
        "falBlindsRaised": () => import('@fortawesome/pro-light-svg-icons/faBlindsRaised'),
        "falBlog": () => import('@fortawesome/pro-light-svg-icons/faBlog'),
        "falBold": () => import('@fortawesome/pro-light-svg-icons/faBold'),
        "falBolt": () => import('@fortawesome/pro-light-svg-icons/faBolt'),
        "falBomb": () => import('@fortawesome/pro-light-svg-icons/faBomb'),
        "falBone": () => import('@fortawesome/pro-light-svg-icons/faBone'),
        "falBoneBreak": () => import('@fortawesome/pro-light-svg-icons/faBoneBreak'),
        "falBong": () => import('@fortawesome/pro-light-svg-icons/faBong'),
        "falBook": () => import('@fortawesome/pro-light-svg-icons/faBook'),
        "falBookAlt": () => import('@fortawesome/pro-light-svg-icons/faBookAlt'),
        "falBookDead": () => import('@fortawesome/pro-light-svg-icons/faBookDead'),
        "falBookHeart": () => import('@fortawesome/pro-light-svg-icons/faBookHeart'),
        "falBookMedical": () => import('@fortawesome/pro-light-svg-icons/faBookMedical'),
        "falBookOpen": () => import('@fortawesome/pro-light-svg-icons/faBookOpen'),
        "falBookReader": () => import('@fortawesome/pro-light-svg-icons/faBookReader'),
        "falBookSpells": () => import('@fortawesome/pro-light-svg-icons/faBookSpells'),
        "falBookUser": () => import('@fortawesome/pro-light-svg-icons/faBookUser'),
        "falBookmark": () => import('@fortawesome/pro-light-svg-icons/faBookmark'),
        "falBooks": () => import('@fortawesome/pro-light-svg-icons/faBooks'),
        "falBooksMedical": () => import('@fortawesome/pro-light-svg-icons/faBooksMedical'),
        "falBoombox": () => import('@fortawesome/pro-light-svg-icons/faBoombox'),
        "falBoot": () => import('@fortawesome/pro-light-svg-icons/faBoot'),
        "falBoothCurtain": () => import('@fortawesome/pro-light-svg-icons/faBoothCurtain'),
        "falBorderAll": () => import('@fortawesome/pro-light-svg-icons/faBorderAll'),
        "falBorderBottom": () => import('@fortawesome/pro-light-svg-icons/faBorderBottom'),
        "falBorderCenterH": () => import('@fortawesome/pro-light-svg-icons/faBorderCenterH'),
        "falBorderCenterV": () => import('@fortawesome/pro-light-svg-icons/faBorderCenterV'),
        "falBorderInner": () => import('@fortawesome/pro-light-svg-icons/faBorderInner'),
        "falBorderLeft": () => import('@fortawesome/pro-light-svg-icons/faBorderLeft'),
        "falBorderNone": () => import('@fortawesome/pro-light-svg-icons/faBorderNone'),
        "falBorderOuter": () => import('@fortawesome/pro-light-svg-icons/faBorderOuter'),
        "falBorderRight": () => import('@fortawesome/pro-light-svg-icons/faBorderRight'),
        "falBorderStyle": () => import('@fortawesome/pro-light-svg-icons/faBorderStyle'),
        "falBorderStyleAlt": () => import('@fortawesome/pro-light-svg-icons/faBorderStyleAlt'),
        "falBorderTop": () => import('@fortawesome/pro-light-svg-icons/faBorderTop'),
        "falBowArrow": () => import('@fortawesome/pro-light-svg-icons/faBowArrow'),
        "falBowlingBall": () => import('@fortawesome/pro-light-svg-icons/faBowlingBall'),
        "falBowlingPins": () => import('@fortawesome/pro-light-svg-icons/faBowlingPins'),
        "falBox": () => import('@fortawesome/pro-light-svg-icons/faBox'),
        "falBoxAlt": () => import('@fortawesome/pro-light-svg-icons/faBoxAlt'),
        "falBoxBallot": () => import('@fortawesome/pro-light-svg-icons/faBoxBallot'),
        "falBoxCheck": () => import('@fortawesome/pro-light-svg-icons/faBoxCheck'),
        "falBoxFragile": () => import('@fortawesome/pro-light-svg-icons/faBoxFragile'),
        "falBoxFull": () => import('@fortawesome/pro-light-svg-icons/faBoxFull'),
        "falBoxHeart": () => import('@fortawesome/pro-light-svg-icons/faBoxHeart'),
        "falBoxOpen": () => import('@fortawesome/pro-light-svg-icons/faBoxOpen'),
        "falBoxTissue": () => import('@fortawesome/pro-light-svg-icons/faBoxTissue'),
        "falBoxUp": () => import('@fortawesome/pro-light-svg-icons/faBoxUp'),
        "falBoxUsd": () => import('@fortawesome/pro-light-svg-icons/faBoxUsd'),
        "falBoxes": () => import('@fortawesome/pro-light-svg-icons/faBoxes'),
        "falBoxesAlt": () => import('@fortawesome/pro-light-svg-icons/faBoxesAlt'),
        "falBoxingGlove": () => import('@fortawesome/pro-light-svg-icons/faBoxingGlove'),
        "falBrackets": () => import('@fortawesome/pro-light-svg-icons/faBrackets'),
        "falBracketsCurly": () => import('@fortawesome/pro-light-svg-icons/faBracketsCurly'),
        "falBraille": () => import('@fortawesome/pro-light-svg-icons/faBraille'),
        "falBrain": () => import('@fortawesome/pro-light-svg-icons/faBrain'),
        "falBreadLoaf": () => import('@fortawesome/pro-light-svg-icons/faBreadLoaf'),
        "falBreadSlice": () => import('@fortawesome/pro-light-svg-icons/faBreadSlice'),
        "falBriefcase": () => import('@fortawesome/pro-light-svg-icons/faBriefcase'),
        "falBriefcaseMedical": () => import('@fortawesome/pro-light-svg-icons/faBriefcaseMedical'),
        "falBringForward": () => import('@fortawesome/pro-light-svg-icons/faBringForward'),
        "falBringFront": () => import('@fortawesome/pro-light-svg-icons/faBringFront'),
        "falBroadcastTower": () => import('@fortawesome/pro-light-svg-icons/faBroadcastTower'),
        "falBroom": () => import('@fortawesome/pro-light-svg-icons/faBroom'),
        "falBrowser": () => import('@fortawesome/pro-light-svg-icons/faBrowser'),
        "falBrush": () => import('@fortawesome/pro-light-svg-icons/faBrush'),
        "falBug": () => import('@fortawesome/pro-light-svg-icons/faBug'),
        "falBuilding": () => import('@fortawesome/pro-light-svg-icons/faBuilding'),
        "falBullhorn": () => import('@fortawesome/pro-light-svg-icons/faBullhorn'),
        "falBullseye": () => import('@fortawesome/pro-light-svg-icons/faBullseye'),
        "falBullseyeArrow": () => import('@fortawesome/pro-light-svg-icons/faBullseyeArrow'),
        "falBullseyePointer": () => import('@fortawesome/pro-light-svg-icons/faBullseyePointer'),
        "falBurgerSoda": () => import('@fortawesome/pro-light-svg-icons/faBurgerSoda'),
        "falBurn": () => import('@fortawesome/pro-light-svg-icons/faBurn'),
        "falBurrito": () => import('@fortawesome/pro-light-svg-icons/faBurrito'),
        "falBus": () => import('@fortawesome/pro-light-svg-icons/faBus'),
        "falBusAlt": () => import('@fortawesome/pro-light-svg-icons/faBusAlt'),
        "falBusSchool": () => import('@fortawesome/pro-light-svg-icons/faBusSchool'),
        "falBusinessTime": () => import('@fortawesome/pro-light-svg-icons/faBusinessTime'),
        "falCabinetFiling": () => import('@fortawesome/pro-light-svg-icons/faCabinetFiling'),
        "falCactus": () => import('@fortawesome/pro-light-svg-icons/faCactus'),
        "falCalculator": () => import('@fortawesome/pro-light-svg-icons/faCalculator'),
        "falCalculatorAlt": () => import('@fortawesome/pro-light-svg-icons/faCalculatorAlt'),
        "falCalendar": () => import('@fortawesome/pro-light-svg-icons/faCalendar'),
        "falCalendarAlt": () => import('@fortawesome/pro-light-svg-icons/faCalendarAlt'),
        "falCalendarCheck": () => import('@fortawesome/pro-light-svg-icons/faCalendarCheck'),
        "falCalendarDay": () => import('@fortawesome/pro-light-svg-icons/faCalendarDay'),
        "falCalendarEdit": () => import('@fortawesome/pro-light-svg-icons/faCalendarEdit'),
        "falCalendarExclamation": () => import('@fortawesome/pro-light-svg-icons/faCalendarExclamation'),
        "falCalendarMinus": () => import('@fortawesome/pro-light-svg-icons/faCalendarMinus'),
        "falCalendarPlus": () => import('@fortawesome/pro-light-svg-icons/faCalendarPlus'),
        "falCalendarStar": () => import('@fortawesome/pro-light-svg-icons/faCalendarStar'),
        "falCalendarTimes": () => import('@fortawesome/pro-light-svg-icons/faCalendarTimes'),
        "falCalendarWeek": () => import('@fortawesome/pro-light-svg-icons/faCalendarWeek'),
        "falCamcorder": () => import('@fortawesome/pro-light-svg-icons/faCamcorder'),
        "falCamera": () => import('@fortawesome/pro-light-svg-icons/faCamera'),
        "falCameraAlt": () => import('@fortawesome/pro-light-svg-icons/faCameraAlt'),
        "falCameraHome": () => import('@fortawesome/pro-light-svg-icons/faCameraHome'),
        "falCameraMovie": () => import('@fortawesome/pro-light-svg-icons/faCameraMovie'),
        "falCameraPolaroid": () => import('@fortawesome/pro-light-svg-icons/faCameraPolaroid'),
        "falCameraRetro": () => import('@fortawesome/pro-light-svg-icons/faCameraRetro'),
        "falCampfire": () => import('@fortawesome/pro-light-svg-icons/faCampfire'),
        "falCampground": () => import('@fortawesome/pro-light-svg-icons/faCampground'),
        "falCandleHolder": () => import('@fortawesome/pro-light-svg-icons/faCandleHolder'),
        "falCandyCane": () => import('@fortawesome/pro-light-svg-icons/faCandyCane'),
        "falCandyCorn": () => import('@fortawesome/pro-light-svg-icons/faCandyCorn'),
        "falCannabis": () => import('@fortawesome/pro-light-svg-icons/faCannabis'),
        "falCapsules": () => import('@fortawesome/pro-light-svg-icons/faCapsules'),
        "falCar": () => import('@fortawesome/pro-light-svg-icons/faCar'),
        "falCarAlt": () => import('@fortawesome/pro-light-svg-icons/faCarAlt'),
        "falCarBattery": () => import('@fortawesome/pro-light-svg-icons/faCarBattery'),
        "falCarBuilding": () => import('@fortawesome/pro-light-svg-icons/faCarBuilding'),
        "falCarBump": () => import('@fortawesome/pro-light-svg-icons/faCarBump'),
        "falCarBus": () => import('@fortawesome/pro-light-svg-icons/faCarBus'),
        "falCarCrash": () => import('@fortawesome/pro-light-svg-icons/faCarCrash'),
        "falCarGarage": () => import('@fortawesome/pro-light-svg-icons/faCarGarage'),
        "falCarMechanic": () => import('@fortawesome/pro-light-svg-icons/faCarMechanic'),
        "falCarSide": () => import('@fortawesome/pro-light-svg-icons/faCarSide'),
        "falCarTilt": () => import('@fortawesome/pro-light-svg-icons/faCarTilt'),
        "falCarWash": () => import('@fortawesome/pro-light-svg-icons/faCarWash'),
        "falCaravan": () => import('@fortawesome/pro-light-svg-icons/faCaravan'),
        "falCaravanAlt": () => import('@fortawesome/pro-light-svg-icons/faCaravanAlt'),
        "falCaretCircleDown": () => import('@fortawesome/pro-light-svg-icons/faCaretCircleDown'),
        "falCaretCircleLeft": () => import('@fortawesome/pro-light-svg-icons/faCaretCircleLeft'),
        "falCaretCircleRight": () => import('@fortawesome/pro-light-svg-icons/faCaretCircleRight'),
        "falCaretCircleUp": () => import('@fortawesome/pro-light-svg-icons/faCaretCircleUp'),
        "falCaretDown": () => import('@fortawesome/pro-light-svg-icons/faCaretDown'),
        "falCaretLeft": () => import('@fortawesome/pro-light-svg-icons/faCaretLeft'),
        "falCaretRight": () => import('@fortawesome/pro-light-svg-icons/faCaretRight'),
        "falCaretSquareDown": () => import('@fortawesome/pro-light-svg-icons/faCaretSquareDown'),
        "falCaretSquareLeft": () => import('@fortawesome/pro-light-svg-icons/faCaretSquareLeft'),
        "falCaretSquareRight": () => import('@fortawesome/pro-light-svg-icons/faCaretSquareRight'),
        "falCaretSquareUp": () => import('@fortawesome/pro-light-svg-icons/faCaretSquareUp'),
        "falCaretUp": () => import('@fortawesome/pro-light-svg-icons/faCaretUp'),
        "falCarrot": () => import('@fortawesome/pro-light-svg-icons/faCarrot'),
        "falCars": () => import('@fortawesome/pro-light-svg-icons/faCars'),
        "falCartArrowDown": () => import('@fortawesome/pro-light-svg-icons/faCartArrowDown'),
        "falCartPlus": () => import('@fortawesome/pro-light-svg-icons/faCartPlus'),
        "falCashRegister": () => import('@fortawesome/pro-light-svg-icons/faCashRegister'),
        "falCassetteTape": () => import('@fortawesome/pro-light-svg-icons/faCassetteTape'),
        "falCat": () => import('@fortawesome/pro-light-svg-icons/faCat'),
        "falCatSpace": () => import('@fortawesome/pro-light-svg-icons/faCatSpace'),
        "falCauldron": () => import('@fortawesome/pro-light-svg-icons/faCauldron'),
        "falCctv": () => import('@fortawesome/pro-light-svg-icons/faCctv'),
        "falCertificate": () => import('@fortawesome/pro-light-svg-icons/faCertificate'),
        "falChair": () => import('@fortawesome/pro-light-svg-icons/faChair'),
        "falChairOffice": () => import('@fortawesome/pro-light-svg-icons/faChairOffice'),
        "falChalkboard": () => import('@fortawesome/pro-light-svg-icons/faChalkboard'),
        "falChalkboardTeacher": () => import('@fortawesome/pro-light-svg-icons/faChalkboardTeacher'),
        "falChargingStation": () => import('@fortawesome/pro-light-svg-icons/faChargingStation'),
        "falChartArea": () => import('@fortawesome/pro-light-svg-icons/faChartArea'),
        "falChartBar": () => import('@fortawesome/pro-light-svg-icons/faChartBar'),
        "falChartLine": () => import('@fortawesome/pro-light-svg-icons/faChartLine'),
        "falChartLineDown": () => import('@fortawesome/pro-light-svg-icons/faChartLineDown'),
        "falChartNetwork": () => import('@fortawesome/pro-light-svg-icons/faChartNetwork'),
        "falChartPie": () => import('@fortawesome/pro-light-svg-icons/faChartPie'),
        "falChartPieAlt": () => import('@fortawesome/pro-light-svg-icons/faChartPieAlt'),
        "falChartScatter": () => import('@fortawesome/pro-light-svg-icons/faChartScatter'),
        "falCheck": () => import('@fortawesome/pro-light-svg-icons/faCheck'),
        "falCheckCircle": () => import('@fortawesome/pro-light-svg-icons/faCheckCircle'),
        "falCheckDouble": () => import('@fortawesome/pro-light-svg-icons/faCheckDouble'),
        "falCheckSquare": () => import('@fortawesome/pro-light-svg-icons/faCheckSquare'),
        "falCheese": () => import('@fortawesome/pro-light-svg-icons/faCheese'),
        "falCheeseSwiss": () => import('@fortawesome/pro-light-svg-icons/faCheeseSwiss'),
        "falCheeseburger": () => import('@fortawesome/pro-light-svg-icons/faCheeseburger'),
        "falChess": () => import('@fortawesome/pro-light-svg-icons/faChess'),
        "falChessBishop": () => import('@fortawesome/pro-light-svg-icons/faChessBishop'),
        "falChessBishopAlt": () => import('@fortawesome/pro-light-svg-icons/faChessBishopAlt'),
        "falChessBoard": () => import('@fortawesome/pro-light-svg-icons/faChessBoard'),
        "falChessClock": () => import('@fortawesome/pro-light-svg-icons/faChessClock'),
        "falChessClockAlt": () => import('@fortawesome/pro-light-svg-icons/faChessClockAlt'),
        "falChessKing": () => import('@fortawesome/pro-light-svg-icons/faChessKing'),
        "falChessKingAlt": () => import('@fortawesome/pro-light-svg-icons/faChessKingAlt'),
        "falChessKnight": () => import('@fortawesome/pro-light-svg-icons/faChessKnight'),
        "falChessKnightAlt": () => import('@fortawesome/pro-light-svg-icons/faChessKnightAlt'),
        "falChessPawn": () => import('@fortawesome/pro-light-svg-icons/faChessPawn'),
        "falChessPawnAlt": () => import('@fortawesome/pro-light-svg-icons/faChessPawnAlt'),
        "falChessQueen": () => import('@fortawesome/pro-light-svg-icons/faChessQueen'),
        "falChessQueenAlt": () => import('@fortawesome/pro-light-svg-icons/faChessQueenAlt'),
        "falChessRook": () => import('@fortawesome/pro-light-svg-icons/faChessRook'),
        "falChessRookAlt": () => import('@fortawesome/pro-light-svg-icons/faChessRookAlt'),
        "falChevronCircleDown": () => import('@fortawesome/pro-light-svg-icons/faChevronCircleDown'),
        "falChevronCircleLeft": () => import('@fortawesome/pro-light-svg-icons/faChevronCircleLeft'),
        "falChevronCircleRight": () => import('@fortawesome/pro-light-svg-icons/faChevronCircleRight'),
        "falChevronCircleUp": () => import('@fortawesome/pro-light-svg-icons/faChevronCircleUp'),
        "falChevronDoubleDown": () => import('@fortawesome/pro-light-svg-icons/faChevronDoubleDown'),
        "falChevronDoubleLeft": () => import('@fortawesome/pro-light-svg-icons/faChevronDoubleLeft'),
        "falChevronDoubleRight": () => import('@fortawesome/pro-light-svg-icons/faChevronDoubleRight'),
        "falChevronDoubleUp": () => import('@fortawesome/pro-light-svg-icons/faChevronDoubleUp'),
        "falChevronDown": () => import('@fortawesome/pro-light-svg-icons/faChevronDown'),
        "falChevronLeft": () => import('@fortawesome/pro-light-svg-icons/faChevronLeft'),
        "falChevronRight": () => import('@fortawesome/pro-light-svg-icons/faChevronRight'),
        "falChevronSquareDown": () => import('@fortawesome/pro-light-svg-icons/faChevronSquareDown'),
        "falChevronSquareLeft": () => import('@fortawesome/pro-light-svg-icons/faChevronSquareLeft'),
        "falChevronSquareRight": () => import('@fortawesome/pro-light-svg-icons/faChevronSquareRight'),
        "falChevronSquareUp": () => import('@fortawesome/pro-light-svg-icons/faChevronSquareUp'),
        "falChevronUp": () => import('@fortawesome/pro-light-svg-icons/faChevronUp'),
        "falChild": () => import('@fortawesome/pro-light-svg-icons/faChild'),
        "falChimney": () => import('@fortawesome/pro-light-svg-icons/faChimney'),
        "falChurch": () => import('@fortawesome/pro-light-svg-icons/faChurch'),
        "falCircle": () => import('@fortawesome/pro-light-svg-icons/faCircle'),
        "falCircleNotch": () => import('@fortawesome/pro-light-svg-icons/faCircleNotch'),
        "falCity": () => import('@fortawesome/pro-light-svg-icons/faCity'),
        "falClarinet": () => import('@fortawesome/pro-light-svg-icons/faClarinet'),
        "falClawMarks": () => import('@fortawesome/pro-light-svg-icons/faClawMarks'),
        "falClinicMedical": () => import('@fortawesome/pro-light-svg-icons/faClinicMedical'),
        "falClipboard": () => import('@fortawesome/pro-light-svg-icons/faClipboard'),
        "falClipboardCheck": () => import('@fortawesome/pro-light-svg-icons/faClipboardCheck'),
        "falClipboardList": () => import('@fortawesome/pro-light-svg-icons/faClipboardList'),
        "falClipboardListCheck": () => import('@fortawesome/pro-light-svg-icons/faClipboardListCheck'),
        "falClipboardPrescription": () => import('@fortawesome/pro-light-svg-icons/faClipboardPrescription'),
        "falClipboardUser": () => import('@fortawesome/pro-light-svg-icons/faClipboardUser'),
        "falClock": () => import('@fortawesome/pro-light-svg-icons/faClock'),
        "falClone": () => import('@fortawesome/pro-light-svg-icons/faClone'),
        "falClosedCaptioning": () => import('@fortawesome/pro-light-svg-icons/faClosedCaptioning'),
        "falCloud": () => import('@fortawesome/pro-light-svg-icons/faCloud'),
        "falCloudDownload": () => import('@fortawesome/pro-light-svg-icons/faCloudDownload'),
        "falCloudDownloadAlt": () => import('@fortawesome/pro-light-svg-icons/faCloudDownloadAlt'),
        "falCloudDrizzle": () => import('@fortawesome/pro-light-svg-icons/faCloudDrizzle'),
        "falCloudHail": () => import('@fortawesome/pro-light-svg-icons/faCloudHail'),
        "falCloudHailMixed": () => import('@fortawesome/pro-light-svg-icons/faCloudHailMixed'),
        "falCloudMeatball": () => import('@fortawesome/pro-light-svg-icons/faCloudMeatball'),
        "falCloudMoon": () => import('@fortawesome/pro-light-svg-icons/faCloudMoon'),
        "falCloudMoonRain": () => import('@fortawesome/pro-light-svg-icons/faCloudMoonRain'),
        "falCloudMusic": () => import('@fortawesome/pro-light-svg-icons/faCloudMusic'),
        "falCloudRain": () => import('@fortawesome/pro-light-svg-icons/faCloudRain'),
        "falCloudRainbow": () => import('@fortawesome/pro-light-svg-icons/faCloudRainbow'),
        "falCloudShowers": () => import('@fortawesome/pro-light-svg-icons/faCloudShowers'),
        "falCloudShowersHeavy": () => import('@fortawesome/pro-light-svg-icons/faCloudShowersHeavy'),
        "falCloudSleet": () => import('@fortawesome/pro-light-svg-icons/faCloudSleet'),
        "falCloudSnow": () => import('@fortawesome/pro-light-svg-icons/faCloudSnow'),
        "falCloudSun": () => import('@fortawesome/pro-light-svg-icons/faCloudSun'),
        "falCloudSunRain": () => import('@fortawesome/pro-light-svg-icons/faCloudSunRain'),
        "falCloudUpload": () => import('@fortawesome/pro-light-svg-icons/faCloudUpload'),
        "falCloudUploadAlt": () => import('@fortawesome/pro-light-svg-icons/faCloudUploadAlt'),
        "falClouds": () => import('@fortawesome/pro-light-svg-icons/faClouds'),
        "falCloudsMoon": () => import('@fortawesome/pro-light-svg-icons/faCloudsMoon'),
        "falCloudsSun": () => import('@fortawesome/pro-light-svg-icons/faCloudsSun'),
        "falClub": () => import('@fortawesome/pro-light-svg-icons/faClub'),
        "falCocktail": () => import('@fortawesome/pro-light-svg-icons/faCocktail'),
        "falCode": () => import('@fortawesome/pro-light-svg-icons/faCode'),
        "falCodeBranch": () => import('@fortawesome/pro-light-svg-icons/faCodeBranch'),
        "falCodeCommit": () => import('@fortawesome/pro-light-svg-icons/faCodeCommit'),
        "falCodeMerge": () => import('@fortawesome/pro-light-svg-icons/faCodeMerge'),
        "falCoffee": () => import('@fortawesome/pro-light-svg-icons/faCoffee'),
        "falCoffeePot": () => import('@fortawesome/pro-light-svg-icons/faCoffeePot'),
        "falCoffeeTogo": () => import('@fortawesome/pro-light-svg-icons/faCoffeeTogo'),
        "falCoffin": () => import('@fortawesome/pro-light-svg-icons/faCoffin'),
        "falCoffinCross": () => import('@fortawesome/pro-light-svg-icons/faCoffinCross'),
        "falCog": () => import('@fortawesome/pro-light-svg-icons/faCog'),
        "falCogs": () => import('@fortawesome/pro-light-svg-icons/faCogs'),
        "falCoin": () => import('@fortawesome/pro-light-svg-icons/faCoin'),
        "falCoins": () => import('@fortawesome/pro-light-svg-icons/faCoins'),
        "falColumns": () => import('@fortawesome/pro-light-svg-icons/faColumns'),
        "falComet": () => import('@fortawesome/pro-light-svg-icons/faComet'),
        "falComment": () => import('@fortawesome/pro-light-svg-icons/faComment'),
        "falCommentAlt": () => import('@fortawesome/pro-light-svg-icons/faCommentAlt'),
        "falCommentAltCheck": () => import('@fortawesome/pro-light-svg-icons/faCommentAltCheck'),
        "falCommentAltDollar": () => import('@fortawesome/pro-light-svg-icons/faCommentAltDollar'),
        "falCommentAltDots": () => import('@fortawesome/pro-light-svg-icons/faCommentAltDots'),
        "falCommentAltEdit": () => import('@fortawesome/pro-light-svg-icons/faCommentAltEdit'),
        "falCommentAltExclamation": () => import('@fortawesome/pro-light-svg-icons/faCommentAltExclamation'),
        "falCommentAltLines": () => import('@fortawesome/pro-light-svg-icons/faCommentAltLines'),
        "falCommentAltMedical": () => import('@fortawesome/pro-light-svg-icons/faCommentAltMedical'),
        "falCommentAltMinus": () => import('@fortawesome/pro-light-svg-icons/faCommentAltMinus'),
        "falCommentAltMusic": () => import('@fortawesome/pro-light-svg-icons/faCommentAltMusic'),
        "falCommentAltPlus": () => import('@fortawesome/pro-light-svg-icons/faCommentAltPlus'),
        "falCommentAltSlash": () => import('@fortawesome/pro-light-svg-icons/faCommentAltSlash'),
        "falCommentAltSmile": () => import('@fortawesome/pro-light-svg-icons/faCommentAltSmile'),
        "falCommentAltTimes": () => import('@fortawesome/pro-light-svg-icons/faCommentAltTimes'),
        "falCommentCheck": () => import('@fortawesome/pro-light-svg-icons/faCommentCheck'),
        "falCommentDollar": () => import('@fortawesome/pro-light-svg-icons/faCommentDollar'),
        "falCommentDots": () => import('@fortawesome/pro-light-svg-icons/faCommentDots'),
        "falCommentEdit": () => import('@fortawesome/pro-light-svg-icons/faCommentEdit'),
        "falCommentExclamation": () => import('@fortawesome/pro-light-svg-icons/faCommentExclamation'),
        "falCommentLines": () => import('@fortawesome/pro-light-svg-icons/faCommentLines'),
        "falCommentMedical": () => import('@fortawesome/pro-light-svg-icons/faCommentMedical'),
        "falCommentMinus": () => import('@fortawesome/pro-light-svg-icons/faCommentMinus'),
        "falCommentMusic": () => import('@fortawesome/pro-light-svg-icons/faCommentMusic'),
        "falCommentPlus": () => import('@fortawesome/pro-light-svg-icons/faCommentPlus'),
        "falCommentSlash": () => import('@fortawesome/pro-light-svg-icons/faCommentSlash'),
        "falCommentSmile": () => import('@fortawesome/pro-light-svg-icons/faCommentSmile'),
        "falCommentTimes": () => import('@fortawesome/pro-light-svg-icons/faCommentTimes'),
        "falComments": () => import('@fortawesome/pro-light-svg-icons/faComments'),
        "falCommentsAlt": () => import('@fortawesome/pro-light-svg-icons/faCommentsAlt'),
        "falCommentsAltDollar": () => import('@fortawesome/pro-light-svg-icons/faCommentsAltDollar'),
        "falCommentsDollar": () => import('@fortawesome/pro-light-svg-icons/faCommentsDollar'),
        "falCompactDisc": () => import('@fortawesome/pro-light-svg-icons/faCompactDisc'),
        "falCompass": () => import('@fortawesome/pro-light-svg-icons/faCompass'),
        "falCompassSlash": () => import('@fortawesome/pro-light-svg-icons/faCompassSlash'),
        "falCompress": () => import('@fortawesome/pro-light-svg-icons/faCompress'),
        "falCompressAlt": () => import('@fortawesome/pro-light-svg-icons/faCompressAlt'),
        "falCompressArrowsAlt": () => import('@fortawesome/pro-light-svg-icons/faCompressArrowsAlt'),
        "falCompressWide": () => import('@fortawesome/pro-light-svg-icons/faCompressWide'),
        "falComputerClassic": () => import('@fortawesome/pro-light-svg-icons/faComputerClassic'),
        "falComputerSpeaker": () => import('@fortawesome/pro-light-svg-icons/faComputerSpeaker'),
        "falConciergeBell": () => import('@fortawesome/pro-light-svg-icons/faConciergeBell'),
        "falConstruction": () => import('@fortawesome/pro-light-svg-icons/faConstruction'),
        "falContainerStorage": () => import('@fortawesome/pro-light-svg-icons/faContainerStorage'),
        "falConveyorBelt": () => import('@fortawesome/pro-light-svg-icons/faConveyorBelt'),
        "falConveyorBeltAlt": () => import('@fortawesome/pro-light-svg-icons/faConveyorBeltAlt'),
        "falCookie": () => import('@fortawesome/pro-light-svg-icons/faCookie'),
        "falCookieBite": () => import('@fortawesome/pro-light-svg-icons/faCookieBite'),
        "falCopy": () => import('@fortawesome/pro-light-svg-icons/faCopy'),
        "falCopyright": () => import('@fortawesome/pro-light-svg-icons/faCopyright'),
        "falCorn": () => import('@fortawesome/pro-light-svg-icons/faCorn'),
        "falCouch": () => import('@fortawesome/pro-light-svg-icons/faCouch'),
        "falCow": () => import('@fortawesome/pro-light-svg-icons/faCow'),
        "falCowbell": () => import('@fortawesome/pro-light-svg-icons/faCowbell'),
        "falCowbellMore": () => import('@fortawesome/pro-light-svg-icons/faCowbellMore'),
        "falCreditCard": () => import('@fortawesome/pro-light-svg-icons/faCreditCard'),
        "falCreditCardBlank": () => import('@fortawesome/pro-light-svg-icons/faCreditCardBlank'),
        "falCreditCardFront": () => import('@fortawesome/pro-light-svg-icons/faCreditCardFront'),
        "falCricket": () => import('@fortawesome/pro-light-svg-icons/faCricket'),
        "falCroissant": () => import('@fortawesome/pro-light-svg-icons/faCroissant'),
        "falCrop": () => import('@fortawesome/pro-light-svg-icons/faCrop'),
        "falCropAlt": () => import('@fortawesome/pro-light-svg-icons/faCropAlt'),
        "falCross": () => import('@fortawesome/pro-light-svg-icons/faCross'),
        "falCrosshairs": () => import('@fortawesome/pro-light-svg-icons/faCrosshairs'),
        "falCrow": () => import('@fortawesome/pro-light-svg-icons/faCrow'),
        "falCrown": () => import('@fortawesome/pro-light-svg-icons/faCrown'),
        "falCrutch": () => import('@fortawesome/pro-light-svg-icons/faCrutch'),
        "falCrutches": () => import('@fortawesome/pro-light-svg-icons/faCrutches'),
        "falCube": () => import('@fortawesome/pro-light-svg-icons/faCube'),
        "falCubes": () => import('@fortawesome/pro-light-svg-icons/faCubes'),
        "falCurling": () => import('@fortawesome/pro-light-svg-icons/faCurling'),
        "falCut": () => import('@fortawesome/pro-light-svg-icons/faCut'),
        "falDagger": () => import('@fortawesome/pro-light-svg-icons/faDagger'),
        "falDatabase": () => import('@fortawesome/pro-light-svg-icons/faDatabase'),
        "falDeaf": () => import('@fortawesome/pro-light-svg-icons/faDeaf'),
        "falDebug": () => import('@fortawesome/pro-light-svg-icons/faDebug'),
        "falDeer": () => import('@fortawesome/pro-light-svg-icons/faDeer'),
        "falDeerRudolph": () => import('@fortawesome/pro-light-svg-icons/faDeerRudolph'),
        "falDemocrat": () => import('@fortawesome/pro-light-svg-icons/faDemocrat'),
        "falDesktop": () => import('@fortawesome/pro-light-svg-icons/faDesktop'),
        "falDesktopAlt": () => import('@fortawesome/pro-light-svg-icons/faDesktopAlt'),
        "falDewpoint": () => import('@fortawesome/pro-light-svg-icons/faDewpoint'),
        "falDharmachakra": () => import('@fortawesome/pro-light-svg-icons/faDharmachakra'),
        "falDiagnoses": () => import('@fortawesome/pro-light-svg-icons/faDiagnoses'),
        "falDiamond": () => import('@fortawesome/pro-light-svg-icons/faDiamond'),
        "falDice": () => import('@fortawesome/pro-light-svg-icons/faDice'),
        "falDiceD10": () => import('@fortawesome/pro-light-svg-icons/faDiceD10'),
        "falDiceD12": () => import('@fortawesome/pro-light-svg-icons/faDiceD12'),
        "falDiceD20": () => import('@fortawesome/pro-light-svg-icons/faDiceD20'),
        "falDiceD4": () => import('@fortawesome/pro-light-svg-icons/faDiceD4'),
        "falDiceD6": () => import('@fortawesome/pro-light-svg-icons/faDiceD6'),
        "falDiceD8": () => import('@fortawesome/pro-light-svg-icons/faDiceD8'),
        "falDiceFive": () => import('@fortawesome/pro-light-svg-icons/faDiceFive'),
        "falDiceFour": () => import('@fortawesome/pro-light-svg-icons/faDiceFour'),
        "falDiceOne": () => import('@fortawesome/pro-light-svg-icons/faDiceOne'),
        "falDiceSix": () => import('@fortawesome/pro-light-svg-icons/faDiceSix'),
        "falDiceThree": () => import('@fortawesome/pro-light-svg-icons/faDiceThree'),
        "falDiceTwo": () => import('@fortawesome/pro-light-svg-icons/faDiceTwo'),
        "falDigging": () => import('@fortawesome/pro-light-svg-icons/faDigging'),
        "falDigitalTachograph": () => import('@fortawesome/pro-light-svg-icons/faDigitalTachograph'),
        "falDiploma": () => import('@fortawesome/pro-light-svg-icons/faDiploma'),
        "falDirections": () => import('@fortawesome/pro-light-svg-icons/faDirections'),
        "falDiscDrive": () => import('@fortawesome/pro-light-svg-icons/faDiscDrive'),
        "falDisease": () => import('@fortawesome/pro-light-svg-icons/faDisease'),
        "falDivide": () => import('@fortawesome/pro-light-svg-icons/faDivide'),
        "falDizzy": () => import('@fortawesome/pro-light-svg-icons/faDizzy'),
        "falDna": () => import('@fortawesome/pro-light-svg-icons/faDna'),
        "falDoNotEnter": () => import('@fortawesome/pro-light-svg-icons/faDoNotEnter'),
        "falDog": () => import('@fortawesome/pro-light-svg-icons/faDog'),
        "falDogLeashed": () => import('@fortawesome/pro-light-svg-icons/faDogLeashed'),
        "falDollarSign": () => import('@fortawesome/pro-light-svg-icons/faDollarSign'),
        "falDolly": () => import('@fortawesome/pro-light-svg-icons/faDolly'),
        "falDollyEmpty": () => import('@fortawesome/pro-light-svg-icons/faDollyEmpty'),
        "falDollyFlatbed": () => import('@fortawesome/pro-light-svg-icons/faDollyFlatbed'),
        "falDollyFlatbedAlt": () => import('@fortawesome/pro-light-svg-icons/faDollyFlatbedAlt'),
        "falDollyFlatbedEmpty": () => import('@fortawesome/pro-light-svg-icons/faDollyFlatbedEmpty'),
        "falDonate": () => import('@fortawesome/pro-light-svg-icons/faDonate'),
        "falDoorClosed": () => import('@fortawesome/pro-light-svg-icons/faDoorClosed'),
        "falDoorOpen": () => import('@fortawesome/pro-light-svg-icons/faDoorOpen'),
        "falDotCircle": () => import('@fortawesome/pro-light-svg-icons/faDotCircle'),
        "falDove": () => import('@fortawesome/pro-light-svg-icons/faDove'),
        "falDownload": () => import('@fortawesome/pro-light-svg-icons/faDownload'),
        "falDraftingCompass": () => import('@fortawesome/pro-light-svg-icons/faDraftingCompass'),
        "falDragon": () => import('@fortawesome/pro-light-svg-icons/faDragon'),
        "falDrawCircle": () => import('@fortawesome/pro-light-svg-icons/faDrawCircle'),
        "falDrawPolygon": () => import('@fortawesome/pro-light-svg-icons/faDrawPolygon'),
        "falDrawSquare": () => import('@fortawesome/pro-light-svg-icons/faDrawSquare'),
        "falDreidel": () => import('@fortawesome/pro-light-svg-icons/faDreidel'),
        "falDrone": () => import('@fortawesome/pro-light-svg-icons/faDrone'),
        "falDroneAlt": () => import('@fortawesome/pro-light-svg-icons/faDroneAlt'),
        "falDrum": () => import('@fortawesome/pro-light-svg-icons/faDrum'),
        "falDrumSteelpan": () => import('@fortawesome/pro-light-svg-icons/faDrumSteelpan'),
        "falDrumstick": () => import('@fortawesome/pro-light-svg-icons/faDrumstick'),
        "falDrumstickBite": () => import('@fortawesome/pro-light-svg-icons/faDrumstickBite'),
        "falDryer": () => import('@fortawesome/pro-light-svg-icons/faDryer'),
        "falDryerAlt": () => import('@fortawesome/pro-light-svg-icons/faDryerAlt'),
        "falDuck": () => import('@fortawesome/pro-light-svg-icons/faDuck'),
        "falDumbbell": () => import('@fortawesome/pro-light-svg-icons/faDumbbell'),
        "falDumpster": () => import('@fortawesome/pro-light-svg-icons/faDumpster'),
        "falDumpsterFire": () => import('@fortawesome/pro-light-svg-icons/faDumpsterFire'),
        "falDungeon": () => import('@fortawesome/pro-light-svg-icons/faDungeon'),
        "falEar": () => import('@fortawesome/pro-light-svg-icons/faEar'),
        "falEarMuffs": () => import('@fortawesome/pro-light-svg-icons/faEarMuffs'),
        "falEclipse": () => import('@fortawesome/pro-light-svg-icons/faEclipse'),
        "falEclipseAlt": () => import('@fortawesome/pro-light-svg-icons/faEclipseAlt'),
        "falEdit": () => import('@fortawesome/pro-light-svg-icons/faEdit'),
        "falEgg": () => import('@fortawesome/pro-light-svg-icons/faEgg'),
        "falEggFried": () => import('@fortawesome/pro-light-svg-icons/faEggFried'),
        "falEject": () => import('@fortawesome/pro-light-svg-icons/faEject'),
        "falElephant": () => import('@fortawesome/pro-light-svg-icons/faElephant'),
        "falEllipsisH": () => import('@fortawesome/pro-light-svg-icons/faEllipsisH'),
        "falEllipsisHAlt": () => import('@fortawesome/pro-light-svg-icons/faEllipsisHAlt'),
        "falEllipsisV": () => import('@fortawesome/pro-light-svg-icons/faEllipsisV'),
        "falEllipsisVAlt": () => import('@fortawesome/pro-light-svg-icons/faEllipsisVAlt'),
        "falEmptySet": () => import('@fortawesome/pro-light-svg-icons/faEmptySet'),
        "falEngineWarning": () => import('@fortawesome/pro-light-svg-icons/faEngineWarning'),
        "falEnvelope": () => import('@fortawesome/pro-light-svg-icons/faEnvelope'),
        "falEnvelopeOpen": () => import('@fortawesome/pro-light-svg-icons/faEnvelopeOpen'),
        "falEnvelopeOpenDollar": () => import('@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar'),
        "falEnvelopeOpenText": () => import('@fortawesome/pro-light-svg-icons/faEnvelopeOpenText'),
        "falEnvelopeSquare": () => import('@fortawesome/pro-light-svg-icons/faEnvelopeSquare'),
        "falEquals": () => import('@fortawesome/pro-light-svg-icons/faEquals'),
        "falEraser": () => import('@fortawesome/pro-light-svg-icons/faEraser'),
        "falEthernet": () => import('@fortawesome/pro-light-svg-icons/faEthernet'),
        "falEuroSign": () => import('@fortawesome/pro-light-svg-icons/faEuroSign'),
        "falExchange": () => import('@fortawesome/pro-light-svg-icons/faExchange'),
        "falExchangeAlt": () => import('@fortawesome/pro-light-svg-icons/faExchangeAlt'),
        "falExclamation": () => import('@fortawesome/pro-light-svg-icons/faExclamation'),
        "falExclamationCircle": () => import('@fortawesome/pro-light-svg-icons/faExclamationCircle'),
        "falExclamationSquare": () => import('@fortawesome/pro-light-svg-icons/faExclamationSquare'),
        "falExclamationTriangle": () => import('@fortawesome/pro-light-svg-icons/faExclamationTriangle'),
        "falExpand": () => import('@fortawesome/pro-light-svg-icons/faExpand'),
        "falExpandAlt": () => import('@fortawesome/pro-light-svg-icons/faExpandAlt'),
        "falExpandArrows": () => import('@fortawesome/pro-light-svg-icons/faExpandArrows'),
        "falExpandArrowsAlt": () => import('@fortawesome/pro-light-svg-icons/faExpandArrowsAlt'),
        "falExpandWide": () => import('@fortawesome/pro-light-svg-icons/faExpandWide'),
        "falExternalLink": () => import('@fortawesome/pro-light-svg-icons/faExternalLink'),
        "falExternalLinkAlt": () => import('@fortawesome/pro-light-svg-icons/faExternalLinkAlt'),
        "falExternalLinkSquare": () => import('@fortawesome/pro-light-svg-icons/faExternalLinkSquare'),
        "falExternalLinkSquareAlt": () => import('@fortawesome/pro-light-svg-icons/faExternalLinkSquareAlt'),
        "falEye": () => import('@fortawesome/pro-light-svg-icons/faEye'),
        "falEyeDropper": () => import('@fortawesome/pro-light-svg-icons/faEyeDropper'),
        "falEyeEvil": () => import('@fortawesome/pro-light-svg-icons/faEyeEvil'),
        "falEyeSlash": () => import('@fortawesome/pro-light-svg-icons/faEyeSlash'),
        "falFan": () => import('@fortawesome/pro-light-svg-icons/faFan'),
        "falFanTable": () => import('@fortawesome/pro-light-svg-icons/faFanTable'),
        "falFarm": () => import('@fortawesome/pro-light-svg-icons/faFarm'),
        "falFastBackward": () => import('@fortawesome/pro-light-svg-icons/faFastBackward'),
        "falFastForward": () => import('@fortawesome/pro-light-svg-icons/faFastForward'),
        "falFaucet": () => import('@fortawesome/pro-light-svg-icons/faFaucet'),
        "falFaucetDrip": () => import('@fortawesome/pro-light-svg-icons/faFaucetDrip'),
        "falFax": () => import('@fortawesome/pro-light-svg-icons/faFax'),
        "falFeather": () => import('@fortawesome/pro-light-svg-icons/faFeather'),
        "falFeatherAlt": () => import('@fortawesome/pro-light-svg-icons/faFeatherAlt'),
        "falFemale": () => import('@fortawesome/pro-light-svg-icons/faFemale'),
        "falFieldHockey": () => import('@fortawesome/pro-light-svg-icons/faFieldHockey'),
        "falFighterJet": () => import('@fortawesome/pro-light-svg-icons/faFighterJet'),
        "falFile": () => import('@fortawesome/pro-light-svg-icons/faFile'),
        "falFileAlt": () => import('@fortawesome/pro-light-svg-icons/faFileAlt'),
        "falFileArchive": () => import('@fortawesome/pro-light-svg-icons/faFileArchive'),
        "falFileAudio": () => import('@fortawesome/pro-light-svg-icons/faFileAudio'),
        "falFileCertificate": () => import('@fortawesome/pro-light-svg-icons/faFileCertificate'),
        "falFileChartLine": () => import('@fortawesome/pro-light-svg-icons/faFileChartLine'),
        "falFileChartPie": () => import('@fortawesome/pro-light-svg-icons/faFileChartPie'),
        "falFileCheck": () => import('@fortawesome/pro-light-svg-icons/faFileCheck'),
        "falFileCode": () => import('@fortawesome/pro-light-svg-icons/faFileCode'),
        "falFileContract": () => import('@fortawesome/pro-light-svg-icons/faFileContract'),
        "falFileCsv": () => import('@fortawesome/pro-light-svg-icons/faFileCsv'),
        "falFileDownload": () => import('@fortawesome/pro-light-svg-icons/faFileDownload'),
        "falFileEdit": () => import('@fortawesome/pro-light-svg-icons/faFileEdit'),
        "falFileExcel": () => import('@fortawesome/pro-light-svg-icons/faFileExcel'),
        "falFileExclamation": () => import('@fortawesome/pro-light-svg-icons/faFileExclamation'),
        "falFileExport": () => import('@fortawesome/pro-light-svg-icons/faFileExport'),
        "falFileImage": () => import('@fortawesome/pro-light-svg-icons/faFileImage'),
        "falFileImport": () => import('@fortawesome/pro-light-svg-icons/faFileImport'),
        "falFileInvoice": () => import('@fortawesome/pro-light-svg-icons/faFileInvoice'),
        "falFileInvoiceDollar": () => import('@fortawesome/pro-light-svg-icons/faFileInvoiceDollar'),
        "falFileMedical": () => import('@fortawesome/pro-light-svg-icons/faFileMedical'),
        "falFileMedicalAlt": () => import('@fortawesome/pro-light-svg-icons/faFileMedicalAlt'),
        "falFileMinus": () => import('@fortawesome/pro-light-svg-icons/faFileMinus'),
        "falFileMusic": () => import('@fortawesome/pro-light-svg-icons/faFileMusic'),
        "falFilePdf": () => import('@fortawesome/pro-light-svg-icons/faFilePdf'),
        "falFilePlus": () => import('@fortawesome/pro-light-svg-icons/faFilePlus'),
        "falFilePowerpoint": () => import('@fortawesome/pro-light-svg-icons/faFilePowerpoint'),
        "falFilePrescription": () => import('@fortawesome/pro-light-svg-icons/faFilePrescription'),
        "falFileSearch": () => import('@fortawesome/pro-light-svg-icons/faFileSearch'),
        "falFileSignature": () => import('@fortawesome/pro-light-svg-icons/faFileSignature'),
        "falFileSpreadsheet": () => import('@fortawesome/pro-light-svg-icons/faFileSpreadsheet'),
        "falFileTimes": () => import('@fortawesome/pro-light-svg-icons/faFileTimes'),
        "falFileUpload": () => import('@fortawesome/pro-light-svg-icons/faFileUpload'),
        "falFileUser": () => import('@fortawesome/pro-light-svg-icons/faFileUser'),
        "falFileVideo": () => import('@fortawesome/pro-light-svg-icons/faFileVideo'),
        "falFileWord": () => import('@fortawesome/pro-light-svg-icons/faFileWord'),
        "falFilesMedical": () => import('@fortawesome/pro-light-svg-icons/faFilesMedical'),
        "falFill": () => import('@fortawesome/pro-light-svg-icons/faFill'),
        "falFillDrip": () => import('@fortawesome/pro-light-svg-icons/faFillDrip'),
        "falFilm": () => import('@fortawesome/pro-light-svg-icons/faFilm'),
        "falFilmAlt": () => import('@fortawesome/pro-light-svg-icons/faFilmAlt'),
        "falFilmCanister": () => import('@fortawesome/pro-light-svg-icons/faFilmCanister'),
        "falFilter": () => import('@fortawesome/pro-light-svg-icons/faFilter'),
        "falFingerprint": () => import('@fortawesome/pro-light-svg-icons/faFingerprint'),
        "falFire": () => import('@fortawesome/pro-light-svg-icons/faFire'),
        "falFireAlt": () => import('@fortawesome/pro-light-svg-icons/faFireAlt'),
        "falFireExtinguisher": () => import('@fortawesome/pro-light-svg-icons/faFireExtinguisher'),
        "falFireSmoke": () => import('@fortawesome/pro-light-svg-icons/faFireSmoke'),
        "falFireplace": () => import('@fortawesome/pro-light-svg-icons/faFireplace'),
        "falFirstAid": () => import('@fortawesome/pro-light-svg-icons/faFirstAid'),
        "falFish": () => import('@fortawesome/pro-light-svg-icons/faFish'),
        "falFishCooked": () => import('@fortawesome/pro-light-svg-icons/faFishCooked'),
        "falFistRaised": () => import('@fortawesome/pro-light-svg-icons/faFistRaised'),
        "falFlag": () => import('@fortawesome/pro-light-svg-icons/faFlag'),
        "falFlagAlt": () => import('@fortawesome/pro-light-svg-icons/faFlagAlt'),
        "falFlagCheckered": () => import('@fortawesome/pro-light-svg-icons/faFlagCheckered'),
        "falFlagUsa": () => import('@fortawesome/pro-light-svg-icons/faFlagUsa'),
        "falFlame": () => import('@fortawesome/pro-light-svg-icons/faFlame'),
        "falFlashlight": () => import('@fortawesome/pro-light-svg-icons/faFlashlight'),
        "falFlask": () => import('@fortawesome/pro-light-svg-icons/faFlask'),
        "falFlaskPoison": () => import('@fortawesome/pro-light-svg-icons/faFlaskPoison'),
        "falFlaskPotion": () => import('@fortawesome/pro-light-svg-icons/faFlaskPotion'),
        "falFlower": () => import('@fortawesome/pro-light-svg-icons/faFlower'),
        "falFlowerDaffodil": () => import('@fortawesome/pro-light-svg-icons/faFlowerDaffodil'),
        "falFlowerTulip": () => import('@fortawesome/pro-light-svg-icons/faFlowerTulip'),
        "falFlushed": () => import('@fortawesome/pro-light-svg-icons/faFlushed'),
        "falFlute": () => import('@fortawesome/pro-light-svg-icons/faFlute'),
        "falFluxCapacitor": () => import('@fortawesome/pro-light-svg-icons/faFluxCapacitor'),
        "falFog": () => import('@fortawesome/pro-light-svg-icons/faFog'),
        "falFolder": () => import('@fortawesome/pro-light-svg-icons/faFolder'),
        "falFolderDownload": () => import('@fortawesome/pro-light-svg-icons/faFolderDownload'),
        "falFolderMinus": () => import('@fortawesome/pro-light-svg-icons/faFolderMinus'),
        "falFolderOpen": () => import('@fortawesome/pro-light-svg-icons/faFolderOpen'),
        "falFolderPlus": () => import('@fortawesome/pro-light-svg-icons/faFolderPlus'),
        "falFolderTimes": () => import('@fortawesome/pro-light-svg-icons/faFolderTimes'),
        "falFolderTree": () => import('@fortawesome/pro-light-svg-icons/faFolderTree'),
        "falFolderUpload": () => import('@fortawesome/pro-light-svg-icons/faFolderUpload'),
        "falFolders": () => import('@fortawesome/pro-light-svg-icons/faFolders'),
        "falFont": () => import('@fortawesome/pro-light-svg-icons/faFont'),
        "falFontAwesomeLogoFull": () => import('@fortawesome/pro-light-svg-icons/faFontAwesomeLogoFull'),
        "falFontCase": () => import('@fortawesome/pro-light-svg-icons/faFontCase'),
        "falFootballBall": () => import('@fortawesome/pro-light-svg-icons/faFootballBall'),
        "falFootballHelmet": () => import('@fortawesome/pro-light-svg-icons/faFootballHelmet'),
        "falForklift": () => import('@fortawesome/pro-light-svg-icons/faForklift'),
        "falForward": () => import('@fortawesome/pro-light-svg-icons/faForward'),
        "falFragile": () => import('@fortawesome/pro-light-svg-icons/faFragile'),
        "falFrenchFries": () => import('@fortawesome/pro-light-svg-icons/faFrenchFries'),
        "falFrog": () => import('@fortawesome/pro-light-svg-icons/faFrog'),
        "falFrostyHead": () => import('@fortawesome/pro-light-svg-icons/faFrostyHead'),
        "falFrown": () => import('@fortawesome/pro-light-svg-icons/faFrown'),
        "falFrownOpen": () => import('@fortawesome/pro-light-svg-icons/faFrownOpen'),
        "falFunction": () => import('@fortawesome/pro-light-svg-icons/faFunction'),
        "falFunnelDollar": () => import('@fortawesome/pro-light-svg-icons/faFunnelDollar'),
        "falFutbol": () => import('@fortawesome/pro-light-svg-icons/faFutbol'),
        "falGalaxy": () => import('@fortawesome/pro-light-svg-icons/faGalaxy'),
        "falGameBoard": () => import('@fortawesome/pro-light-svg-icons/faGameBoard'),
        "falGameBoardAlt": () => import('@fortawesome/pro-light-svg-icons/faGameBoardAlt'),
        "falGameConsoleHandheld": () => import('@fortawesome/pro-light-svg-icons/faGameConsoleHandheld'),
        "falGamepad": () => import('@fortawesome/pro-light-svg-icons/faGamepad'),
        "falGamepadAlt": () => import('@fortawesome/pro-light-svg-icons/faGamepadAlt'),
        "falGarage": () => import('@fortawesome/pro-light-svg-icons/faGarage'),
        "falGarageCar": () => import('@fortawesome/pro-light-svg-icons/faGarageCar'),
        "falGarageOpen": () => import('@fortawesome/pro-light-svg-icons/faGarageOpen'),
        "falGasPump": () => import('@fortawesome/pro-light-svg-icons/faGasPump'),
        "falGasPumpSlash": () => import('@fortawesome/pro-light-svg-icons/faGasPumpSlash'),
        "falGavel": () => import('@fortawesome/pro-light-svg-icons/faGavel'),
        "falGem": () => import('@fortawesome/pro-light-svg-icons/faGem'),
        "falGenderless": () => import('@fortawesome/pro-light-svg-icons/faGenderless'),
        "falGhost": () => import('@fortawesome/pro-light-svg-icons/faGhost'),
        "falGift": () => import('@fortawesome/pro-light-svg-icons/faGift'),
        "falGiftCard": () => import('@fortawesome/pro-light-svg-icons/faGiftCard'),
        "falGifts": () => import('@fortawesome/pro-light-svg-icons/faGifts'),
        "falGingerbreadMan": () => import('@fortawesome/pro-light-svg-icons/faGingerbreadMan'),
        "falGlass": () => import('@fortawesome/pro-light-svg-icons/faGlass'),
        "falGlassChampagne": () => import('@fortawesome/pro-light-svg-icons/faGlassChampagne'),
        "falGlassCheers": () => import('@fortawesome/pro-light-svg-icons/faGlassCheers'),
        "falGlassCitrus": () => import('@fortawesome/pro-light-svg-icons/faGlassCitrus'),
        "falGlassMartini": () => import('@fortawesome/pro-light-svg-icons/faGlassMartini'),
        "falGlassMartiniAlt": () => import('@fortawesome/pro-light-svg-icons/faGlassMartiniAlt'),
        "falGlassWhiskey": () => import('@fortawesome/pro-light-svg-icons/faGlassWhiskey'),
        "falGlassWhiskeyRocks": () => import('@fortawesome/pro-light-svg-icons/faGlassWhiskeyRocks'),
        "falGlasses": () => import('@fortawesome/pro-light-svg-icons/faGlasses'),
        "falGlassesAlt": () => import('@fortawesome/pro-light-svg-icons/faGlassesAlt'),
        "falGlobe": () => import('@fortawesome/pro-light-svg-icons/faGlobe'),
        "falGlobeAfrica": () => import('@fortawesome/pro-light-svg-icons/faGlobeAfrica'),
        "falGlobeAmericas": () => import('@fortawesome/pro-light-svg-icons/faGlobeAmericas'),
        "falGlobeAsia": () => import('@fortawesome/pro-light-svg-icons/faGlobeAsia'),
        "falGlobeEurope": () => import('@fortawesome/pro-light-svg-icons/faGlobeEurope'),
        "falGlobeSnow": () => import('@fortawesome/pro-light-svg-icons/faGlobeSnow'),
        "falGlobeStand": () => import('@fortawesome/pro-light-svg-icons/faGlobeStand'),
        "falGolfBall": () => import('@fortawesome/pro-light-svg-icons/faGolfBall'),
        "falGolfClub": () => import('@fortawesome/pro-light-svg-icons/faGolfClub'),
        "falGopuram": () => import('@fortawesome/pro-light-svg-icons/faGopuram'),
        "falGraduationCap": () => import('@fortawesome/pro-light-svg-icons/faGraduationCap'),
        "falGramophone": () => import('@fortawesome/pro-light-svg-icons/faGramophone'),
        "falGreaterThan": () => import('@fortawesome/pro-light-svg-icons/faGreaterThan'),
        "falGreaterThanEqual": () => import('@fortawesome/pro-light-svg-icons/faGreaterThanEqual'),
        "falGrimace": () => import('@fortawesome/pro-light-svg-icons/faGrimace'),
        "falGrin": () => import('@fortawesome/pro-light-svg-icons/faGrin'),
        "falGrinAlt": () => import('@fortawesome/pro-light-svg-icons/faGrinAlt'),
        "falGrinBeam": () => import('@fortawesome/pro-light-svg-icons/faGrinBeam'),
        "falGrinBeamSweat": () => import('@fortawesome/pro-light-svg-icons/faGrinBeamSweat'),
        "falGrinHearts": () => import('@fortawesome/pro-light-svg-icons/faGrinHearts'),
        "falGrinSquint": () => import('@fortawesome/pro-light-svg-icons/faGrinSquint'),
        "falGrinSquintTears": () => import('@fortawesome/pro-light-svg-icons/faGrinSquintTears'),
        "falGrinStars": () => import('@fortawesome/pro-light-svg-icons/faGrinStars'),
        "falGrinTears": () => import('@fortawesome/pro-light-svg-icons/faGrinTears'),
        "falGrinTongue": () => import('@fortawesome/pro-light-svg-icons/faGrinTongue'),
        "falGrinTongueSquint": () => import('@fortawesome/pro-light-svg-icons/faGrinTongueSquint'),
        "falGrinTongueWink": () => import('@fortawesome/pro-light-svg-icons/faGrinTongueWink'),
        "falGrinWink": () => import('@fortawesome/pro-light-svg-icons/faGrinWink'),
        "falGripHorizontal": () => import('@fortawesome/pro-light-svg-icons/faGripHorizontal'),
        "falGripLines": () => import('@fortawesome/pro-light-svg-icons/faGripLines'),
        "falGripLinesVertical": () => import('@fortawesome/pro-light-svg-icons/faGripLinesVertical'),
        "falGripVertical": () => import('@fortawesome/pro-light-svg-icons/faGripVertical'),
        "falGuitar": () => import('@fortawesome/pro-light-svg-icons/faGuitar'),
        "falGuitarElectric": () => import('@fortawesome/pro-light-svg-icons/faGuitarElectric'),
        "falGuitars": () => import('@fortawesome/pro-light-svg-icons/faGuitars'),
        "falH1": () => import('@fortawesome/pro-light-svg-icons/faH1'),
        "falH2": () => import('@fortawesome/pro-light-svg-icons/faH2'),
        "falH3": () => import('@fortawesome/pro-light-svg-icons/faH3'),
        "falH4": () => import('@fortawesome/pro-light-svg-icons/faH4'),
        "falHSquare": () => import('@fortawesome/pro-light-svg-icons/faHSquare'),
        "falHamburger": () => import('@fortawesome/pro-light-svg-icons/faHamburger'),
        "falHammer": () => import('@fortawesome/pro-light-svg-icons/faHammer'),
        "falHammerWar": () => import('@fortawesome/pro-light-svg-icons/faHammerWar'),
        "falHamsa": () => import('@fortawesome/pro-light-svg-icons/faHamsa'),
        "falHandHeart": () => import('@fortawesome/pro-light-svg-icons/faHandHeart'),
        "falHandHolding": () => import('@fortawesome/pro-light-svg-icons/faHandHolding'),
        "falHandHoldingBox": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingBox'),
        "falHandHoldingHeart": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingHeart'),
        "falHandHoldingMagic": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingMagic'),
        "falHandHoldingMedical": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingMedical'),
        "falHandHoldingSeedling": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingSeedling'),
        "falHandHoldingUsd": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingUsd'),
        "falHandHoldingWater": () => import('@fortawesome/pro-light-svg-icons/faHandHoldingWater'),
        "falHandLizard": () => import('@fortawesome/pro-light-svg-icons/faHandLizard'),
        "falHandMiddleFinger": () => import('@fortawesome/pro-light-svg-icons/faHandMiddleFinger'),
        "falHandPaper": () => import('@fortawesome/pro-light-svg-icons/faHandPaper'),
        "falHandPeace": () => import('@fortawesome/pro-light-svg-icons/faHandPeace'),
        "falHandPointDown": () => import('@fortawesome/pro-light-svg-icons/faHandPointDown'),
        "falHandPointLeft": () => import('@fortawesome/pro-light-svg-icons/faHandPointLeft'),
        "falHandPointRight": () => import('@fortawesome/pro-light-svg-icons/faHandPointRight'),
        "falHandPointUp": () => import('@fortawesome/pro-light-svg-icons/faHandPointUp'),
        "falHandPointer": () => import('@fortawesome/pro-light-svg-icons/faHandPointer'),
        "falHandReceiving": () => import('@fortawesome/pro-light-svg-icons/faHandReceiving'),
        "falHandRock": () => import('@fortawesome/pro-light-svg-icons/faHandRock'),
        "falHandScissors": () => import('@fortawesome/pro-light-svg-icons/faHandScissors'),
        "falHandSparkles": () => import('@fortawesome/pro-light-svg-icons/faHandSparkles'),
        "falHandSpock": () => import('@fortawesome/pro-light-svg-icons/faHandSpock'),
        "falHands": () => import('@fortawesome/pro-light-svg-icons/faHands'),
        "falHandsHeart": () => import('@fortawesome/pro-light-svg-icons/faHandsHeart'),
        "falHandsHelping": () => import('@fortawesome/pro-light-svg-icons/faHandsHelping'),
        "falHandsUsd": () => import('@fortawesome/pro-light-svg-icons/faHandsUsd'),
        "falHandsWash": () => import('@fortawesome/pro-light-svg-icons/faHandsWash'),
        "falHandshake": () => import('@fortawesome/pro-light-svg-icons/faHandshake'),
        "falHandshakeAlt": () => import('@fortawesome/pro-light-svg-icons/faHandshakeAlt'),
        "falHandshakeAltSlash": () => import('@fortawesome/pro-light-svg-icons/faHandshakeAltSlash'),
        "falHandshakeSlash": () => import('@fortawesome/pro-light-svg-icons/faHandshakeSlash'),
        "falHanukiah": () => import('@fortawesome/pro-light-svg-icons/faHanukiah'),
        "falHardHat": () => import('@fortawesome/pro-light-svg-icons/faHardHat'),
        "falHashtag": () => import('@fortawesome/pro-light-svg-icons/faHashtag'),
        "falHatChef": () => import('@fortawesome/pro-light-svg-icons/faHatChef'),
        "falHatCowboy": () => import('@fortawesome/pro-light-svg-icons/faHatCowboy'),
        "falHatCowboySide": () => import('@fortawesome/pro-light-svg-icons/faHatCowboySide'),
        "falHatSanta": () => import('@fortawesome/pro-light-svg-icons/faHatSanta'),
        "falHatWinter": () => import('@fortawesome/pro-light-svg-icons/faHatWinter'),
        "falHatWitch": () => import('@fortawesome/pro-light-svg-icons/faHatWitch'),
        "falHatWizard": () => import('@fortawesome/pro-light-svg-icons/faHatWizard'),
        "falHdd": () => import('@fortawesome/pro-light-svg-icons/faHdd'),
        "falHeadSide": () => import('@fortawesome/pro-light-svg-icons/faHeadSide'),
        "falHeadSideBrain": () => import('@fortawesome/pro-light-svg-icons/faHeadSideBrain'),
        "falHeadSideCough": () => import('@fortawesome/pro-light-svg-icons/faHeadSideCough'),
        "falHeadSideCoughSlash": () => import('@fortawesome/pro-light-svg-icons/faHeadSideCoughSlash'),
        "falHeadSideHeadphones": () => import('@fortawesome/pro-light-svg-icons/faHeadSideHeadphones'),
        "falHeadSideMask": () => import('@fortawesome/pro-light-svg-icons/faHeadSideMask'),
        "falHeadSideMedical": () => import('@fortawesome/pro-light-svg-icons/faHeadSideMedical'),
        "falHeadSideVirus": () => import('@fortawesome/pro-light-svg-icons/faHeadSideVirus'),
        "falHeadVr": () => import('@fortawesome/pro-light-svg-icons/faHeadVr'),
        "falHeading": () => import('@fortawesome/pro-light-svg-icons/faHeading'),
        "falHeadphones": () => import('@fortawesome/pro-light-svg-icons/faHeadphones'),
        "falHeadphonesAlt": () => import('@fortawesome/pro-light-svg-icons/faHeadphonesAlt'),
        "falHeadset": () => import('@fortawesome/pro-light-svg-icons/faHeadset'),
        "falHeart": () => import('@fortawesome/pro-light-svg-icons/faHeart'),
        "falHeartBroken": () => import('@fortawesome/pro-light-svg-icons/faHeartBroken'),
        "falHeartCircle": () => import('@fortawesome/pro-light-svg-icons/faHeartCircle'),
        "falHeartRate": () => import('@fortawesome/pro-light-svg-icons/faHeartRate'),
        "falHeartSquare": () => import('@fortawesome/pro-light-svg-icons/faHeartSquare'),
        "falHeartbeat": () => import('@fortawesome/pro-light-svg-icons/faHeartbeat'),
        "falHeat": () => import('@fortawesome/pro-light-svg-icons/faHeat'),
        "falHelicopter": () => import('@fortawesome/pro-light-svg-icons/faHelicopter'),
        "falHelmetBattle": () => import('@fortawesome/pro-light-svg-icons/faHelmetBattle'),
        "falHexagon": () => import('@fortawesome/pro-light-svg-icons/faHexagon'),
        "falHighlighter": () => import('@fortawesome/pro-light-svg-icons/faHighlighter'),
        "falHiking": () => import('@fortawesome/pro-light-svg-icons/faHiking'),
        "falHippo": () => import('@fortawesome/pro-light-svg-icons/faHippo'),
        "falHistory": () => import('@fortawesome/pro-light-svg-icons/faHistory'),
        "falHockeyMask": () => import('@fortawesome/pro-light-svg-icons/faHockeyMask'),
        "falHockeyPuck": () => import('@fortawesome/pro-light-svg-icons/faHockeyPuck'),
        "falHockeySticks": () => import('@fortawesome/pro-light-svg-icons/faHockeySticks'),
        "falHollyBerry": () => import('@fortawesome/pro-light-svg-icons/faHollyBerry'),
        "falHome": () => import('@fortawesome/pro-light-svg-icons/faHome'),
        "falHomeAlt": () => import('@fortawesome/pro-light-svg-icons/faHomeAlt'),
        "falHomeHeart": () => import('@fortawesome/pro-light-svg-icons/faHomeHeart'),
        "falHomeLg": () => import('@fortawesome/pro-light-svg-icons/faHomeLg'),
        "falHomeLgAlt": () => import('@fortawesome/pro-light-svg-icons/faHomeLgAlt'),
        "falHoodCloak": () => import('@fortawesome/pro-light-svg-icons/faHoodCloak'),
        "falHorizontalRule": () => import('@fortawesome/pro-light-svg-icons/faHorizontalRule'),
        "falHorse": () => import('@fortawesome/pro-light-svg-icons/faHorse'),
        "falHorseHead": () => import('@fortawesome/pro-light-svg-icons/faHorseHead'),
        "falHorseSaddle": () => import('@fortawesome/pro-light-svg-icons/faHorseSaddle'),
        "falHospital": () => import('@fortawesome/pro-light-svg-icons/faHospital'),
        "falHospitalAlt": () => import('@fortawesome/pro-light-svg-icons/faHospitalAlt'),
        "falHospitalSymbol": () => import('@fortawesome/pro-light-svg-icons/faHospitalSymbol'),
        "falHospitalUser": () => import('@fortawesome/pro-light-svg-icons/faHospitalUser'),
        "falHospitals": () => import('@fortawesome/pro-light-svg-icons/faHospitals'),
        "falHotTub": () => import('@fortawesome/pro-light-svg-icons/faHotTub'),
        "falHotdog": () => import('@fortawesome/pro-light-svg-icons/faHotdog'),
        "falHotel": () => import('@fortawesome/pro-light-svg-icons/faHotel'),
        "falHourglass": () => import('@fortawesome/pro-light-svg-icons/faHourglass'),
        "falHourglassEnd": () => import('@fortawesome/pro-light-svg-icons/faHourglassEnd'),
        "falHourglassHalf": () => import('@fortawesome/pro-light-svg-icons/faHourglassHalf'),
        "falHourglassStart": () => import('@fortawesome/pro-light-svg-icons/faHourglassStart'),
        "falHouse": () => import('@fortawesome/pro-light-svg-icons/faHouse'),
        "falHouseDamage": () => import('@fortawesome/pro-light-svg-icons/faHouseDamage'),
        "falHouseDay": () => import('@fortawesome/pro-light-svg-icons/faHouseDay'),
        "falHouseFlood": () => import('@fortawesome/pro-light-svg-icons/faHouseFlood'),
        "falHouseLeave": () => import('@fortawesome/pro-light-svg-icons/faHouseLeave'),
        "falHouseNight": () => import('@fortawesome/pro-light-svg-icons/faHouseNight'),
        "falHouseReturn": () => import('@fortawesome/pro-light-svg-icons/faHouseReturn'),
        "falHouseSignal": () => import('@fortawesome/pro-light-svg-icons/faHouseSignal'),
        "falHouseUser": () => import('@fortawesome/pro-light-svg-icons/faHouseUser'),
        "falHryvnia": () => import('@fortawesome/pro-light-svg-icons/faHryvnia'),
        "falHumidity": () => import('@fortawesome/pro-light-svg-icons/faHumidity'),
        "falHurricane": () => import('@fortawesome/pro-light-svg-icons/faHurricane'),
        "falICursor": () => import('@fortawesome/pro-light-svg-icons/faICursor'),
        "falIceCream": () => import('@fortawesome/pro-light-svg-icons/faIceCream'),
        "falIceSkate": () => import('@fortawesome/pro-light-svg-icons/faIceSkate'),
        "falIcicles": () => import('@fortawesome/pro-light-svg-icons/faIcicles'),
        "falIcons": () => import('@fortawesome/pro-light-svg-icons/faIcons'),
        "falIconsAlt": () => import('@fortawesome/pro-light-svg-icons/faIconsAlt'),
        "falIdBadge": () => import('@fortawesome/pro-light-svg-icons/faIdBadge'),
        "falIdCard": () => import('@fortawesome/pro-light-svg-icons/faIdCard'),
        "falIdCardAlt": () => import('@fortawesome/pro-light-svg-icons/faIdCardAlt'),
        "falIgloo": () => import('@fortawesome/pro-light-svg-icons/faIgloo'),
        "falImage": () => import('@fortawesome/pro-light-svg-icons/faImage'),
        "falImagePolaroid": () => import('@fortawesome/pro-light-svg-icons/faImagePolaroid'),
        "falImages": () => import('@fortawesome/pro-light-svg-icons/faImages'),
        "falInbox": () => import('@fortawesome/pro-light-svg-icons/faInbox'),
        "falInboxIn": () => import('@fortawesome/pro-light-svg-icons/faInboxIn'),
        "falInboxOut": () => import('@fortawesome/pro-light-svg-icons/faInboxOut'),
        "falIndent": () => import('@fortawesome/pro-light-svg-icons/faIndent'),
        "falIndustry": () => import('@fortawesome/pro-light-svg-icons/faIndustry'),
        "falIndustryAlt": () => import('@fortawesome/pro-light-svg-icons/faIndustryAlt'),
        "falInfinity": () => import('@fortawesome/pro-light-svg-icons/faInfinity'),
        "falInfo": () => import('@fortawesome/pro-light-svg-icons/faInfo'),
        "falInfoCircle": () => import('@fortawesome/pro-light-svg-icons/faInfoCircle'),
        "falInfoSquare": () => import('@fortawesome/pro-light-svg-icons/faInfoSquare'),
        "falInhaler": () => import('@fortawesome/pro-light-svg-icons/faInhaler'),
        "falIntegral": () => import('@fortawesome/pro-light-svg-icons/faIntegral'),
        "falIntersection": () => import('@fortawesome/pro-light-svg-icons/faIntersection'),
        "falInventory": () => import('@fortawesome/pro-light-svg-icons/faInventory'),
        "falIslandTropical": () => import('@fortawesome/pro-light-svg-icons/faIslandTropical'),
        "falItalic": () => import('@fortawesome/pro-light-svg-icons/faItalic'),
        "falJackOLantern": () => import('@fortawesome/pro-light-svg-icons/faJackOLantern'),
        "falJedi": () => import('@fortawesome/pro-light-svg-icons/faJedi'),
        "falJoint": () => import('@fortawesome/pro-light-svg-icons/faJoint'),
        "falJournalWhills": () => import('@fortawesome/pro-light-svg-icons/faJournalWhills'),
        "falJoystick": () => import('@fortawesome/pro-light-svg-icons/faJoystick'),
        "falJug": () => import('@fortawesome/pro-light-svg-icons/faJug'),
        "falKaaba": () => import('@fortawesome/pro-light-svg-icons/faKaaba'),
        "falKazoo": () => import('@fortawesome/pro-light-svg-icons/faKazoo'),
        "falKerning": () => import('@fortawesome/pro-light-svg-icons/faKerning'),
        "falKey": () => import('@fortawesome/pro-light-svg-icons/faKey'),
        "falKeySkeleton": () => import('@fortawesome/pro-light-svg-icons/faKeySkeleton'),
        "falKeyboard": () => import('@fortawesome/pro-light-svg-icons/faKeyboard'),
        "falKeynote": () => import('@fortawesome/pro-light-svg-icons/faKeynote'),
        "falKhanda": () => import('@fortawesome/pro-light-svg-icons/faKhanda'),
        "falKidneys": () => import('@fortawesome/pro-light-svg-icons/faKidneys'),
        "falKiss": () => import('@fortawesome/pro-light-svg-icons/faKiss'),
        "falKissBeam": () => import('@fortawesome/pro-light-svg-icons/faKissBeam'),
        "falKissWinkHeart": () => import('@fortawesome/pro-light-svg-icons/faKissWinkHeart'),
        "falKite": () => import('@fortawesome/pro-light-svg-icons/faKite'),
        "falKiwiBird": () => import('@fortawesome/pro-light-svg-icons/faKiwiBird'),
        "falKnifeKitchen": () => import('@fortawesome/pro-light-svg-icons/faKnifeKitchen'),
        "falLambda": () => import('@fortawesome/pro-light-svg-icons/faLambda'),
        "falLamp": () => import('@fortawesome/pro-light-svg-icons/faLamp'),
        "falLampDesk": () => import('@fortawesome/pro-light-svg-icons/faLampDesk'),
        "falLampFloor": () => import('@fortawesome/pro-light-svg-icons/faLampFloor'),
        "falLandmark": () => import('@fortawesome/pro-light-svg-icons/faLandmark'),
        "falLandmarkAlt": () => import('@fortawesome/pro-light-svg-icons/faLandmarkAlt'),
        "falLanguage": () => import('@fortawesome/pro-light-svg-icons/faLanguage'),
        "falLaptop": () => import('@fortawesome/pro-light-svg-icons/faLaptop'),
        "falLaptopCode": () => import('@fortawesome/pro-light-svg-icons/faLaptopCode'),
        "falLaptopHouse": () => import('@fortawesome/pro-light-svg-icons/faLaptopHouse'),
        "falLaptopMedical": () => import('@fortawesome/pro-light-svg-icons/faLaptopMedical'),
        "falLasso": () => import('@fortawesome/pro-light-svg-icons/faLasso'),
        "falLaugh": () => import('@fortawesome/pro-light-svg-icons/faLaugh'),
        "falLaughBeam": () => import('@fortawesome/pro-light-svg-icons/faLaughBeam'),
        "falLaughSquint": () => import('@fortawesome/pro-light-svg-icons/faLaughSquint'),
        "falLaughWink": () => import('@fortawesome/pro-light-svg-icons/faLaughWink'),
        "falLayerGroup": () => import('@fortawesome/pro-light-svg-icons/faLayerGroup'),
        "falLayerMinus": () => import('@fortawesome/pro-light-svg-icons/faLayerMinus'),
        "falLayerPlus": () => import('@fortawesome/pro-light-svg-icons/faLayerPlus'),
        "falLeaf": () => import('@fortawesome/pro-light-svg-icons/faLeaf'),
        "falLeafHeart": () => import('@fortawesome/pro-light-svg-icons/faLeafHeart'),
        "falLeafMaple": () => import('@fortawesome/pro-light-svg-icons/faLeafMaple'),
        "falLeafOak": () => import('@fortawesome/pro-light-svg-icons/faLeafOak'),
        "falLemon": () => import('@fortawesome/pro-light-svg-icons/faLemon'),
        "falLessThan": () => import('@fortawesome/pro-light-svg-icons/faLessThan'),
        "falLessThanEqual": () => import('@fortawesome/pro-light-svg-icons/faLessThanEqual'),
        "falLevelDown": () => import('@fortawesome/pro-light-svg-icons/faLevelDown'),
        "falLevelDownAlt": () => import('@fortawesome/pro-light-svg-icons/faLevelDownAlt'),
        "falLevelUp": () => import('@fortawesome/pro-light-svg-icons/faLevelUp'),
        "falLevelUpAlt": () => import('@fortawesome/pro-light-svg-icons/faLevelUpAlt'),
        "falLifeRing": () => import('@fortawesome/pro-light-svg-icons/faLifeRing'),
        "falLightCeiling": () => import('@fortawesome/pro-light-svg-icons/faLightCeiling'),
        "falLightSwitch": () => import('@fortawesome/pro-light-svg-icons/faLightSwitch'),
        "falLightSwitchOff": () => import('@fortawesome/pro-light-svg-icons/faLightSwitchOff'),
        "falLightSwitchOn": () => import('@fortawesome/pro-light-svg-icons/faLightSwitchOn'),
        "falLightbulb": () => import('@fortawesome/pro-light-svg-icons/faLightbulb'),
        "falLightbulbDollar": () => import('@fortawesome/pro-light-svg-icons/faLightbulbDollar'),
        "falLightbulbExclamation": () => import('@fortawesome/pro-light-svg-icons/faLightbulbExclamation'),
        "falLightbulbOn": () => import('@fortawesome/pro-light-svg-icons/faLightbulbOn'),
        "falLightbulbSlash": () => import('@fortawesome/pro-light-svg-icons/faLightbulbSlash'),
        "falLightsHoliday": () => import('@fortawesome/pro-light-svg-icons/faLightsHoliday'),
        "falLineColumns": () => import('@fortawesome/pro-light-svg-icons/faLineColumns'),
        "falLineHeight": () => import('@fortawesome/pro-light-svg-icons/faLineHeight'),
        "falLink": () => import('@fortawesome/pro-light-svg-icons/faLink'),
        "falLips": () => import('@fortawesome/pro-light-svg-icons/faLips'),
        "falLiraSign": () => import('@fortawesome/pro-light-svg-icons/faLiraSign'),
        "falList": () => import('@fortawesome/pro-light-svg-icons/faList'),
        "falListAlt": () => import('@fortawesome/pro-light-svg-icons/faListAlt'),
        "falListMusic": () => import('@fortawesome/pro-light-svg-icons/faListMusic'),
        "falListOl": () => import('@fortawesome/pro-light-svg-icons/faListOl'),
        "falListUl": () => import('@fortawesome/pro-light-svg-icons/faListUl'),
        "falLocation": () => import('@fortawesome/pro-light-svg-icons/faLocation'),
        "falLocationArrow": () => import('@fortawesome/pro-light-svg-icons/faLocationArrow'),
        "falLocationCircle": () => import('@fortawesome/pro-light-svg-icons/faLocationCircle'),
        "falLocationSlash": () => import('@fortawesome/pro-light-svg-icons/faLocationSlash'),
        "falLock": () => import('@fortawesome/pro-light-svg-icons/faLock'),
        "falLockAlt": () => import('@fortawesome/pro-light-svg-icons/faLockAlt'),
        "falLockOpen": () => import('@fortawesome/pro-light-svg-icons/faLockOpen'),
        "falLockOpenAlt": () => import('@fortawesome/pro-light-svg-icons/faLockOpenAlt'),
        "falLongArrowAltDown": () => import('@fortawesome/pro-light-svg-icons/faLongArrowAltDown'),
        "falLongArrowAltLeft": () => import('@fortawesome/pro-light-svg-icons/faLongArrowAltLeft'),
        "falLongArrowAltRight": () => import('@fortawesome/pro-light-svg-icons/faLongArrowAltRight'),
        "falLongArrowAltUp": () => import('@fortawesome/pro-light-svg-icons/faLongArrowAltUp'),
        "falLongArrowDown": () => import('@fortawesome/pro-light-svg-icons/faLongArrowDown'),
        "falLongArrowLeft": () => import('@fortawesome/pro-light-svg-icons/faLongArrowLeft'),
        "falLongArrowRight": () => import('@fortawesome/pro-light-svg-icons/faLongArrowRight'),
        "falLongArrowUp": () => import('@fortawesome/pro-light-svg-icons/faLongArrowUp'),
        "falLoveseat": () => import('@fortawesome/pro-light-svg-icons/faLoveseat'),
        "falLowVision": () => import('@fortawesome/pro-light-svg-icons/faLowVision'),
        "falLuchador": () => import('@fortawesome/pro-light-svg-icons/faLuchador'),
        "falLuggageCart": () => import('@fortawesome/pro-light-svg-icons/faLuggageCart'),
        "falLungs": () => import('@fortawesome/pro-light-svg-icons/faLungs'),
        "falLungsVirus": () => import('@fortawesome/pro-light-svg-icons/faLungsVirus'),
        "falMace": () => import('@fortawesome/pro-light-svg-icons/faMace'),
        "falMagic": () => import('@fortawesome/pro-light-svg-icons/faMagic'),
        "falMagnet": () => import('@fortawesome/pro-light-svg-icons/faMagnet'),
        "falMailBulk": () => import('@fortawesome/pro-light-svg-icons/faMailBulk'),
        "falMailbox": () => import('@fortawesome/pro-light-svg-icons/faMailbox'),
        "falMale": () => import('@fortawesome/pro-light-svg-icons/faMale'),
        "falMandolin": () => import('@fortawesome/pro-light-svg-icons/faMandolin'),
        "falMap": () => import('@fortawesome/pro-light-svg-icons/faMap'),
        "falMapMarked": () => import('@fortawesome/pro-light-svg-icons/faMapMarked'),
        "falMapMarkedAlt": () => import('@fortawesome/pro-light-svg-icons/faMapMarkedAlt'),
        "falMapMarker": () => import('@fortawesome/pro-light-svg-icons/faMapMarker'),
        "falMapMarkerAlt": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerAlt'),
        "falMapMarkerAltSlash": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerAltSlash'),
        "falMapMarkerCheck": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerCheck'),
        "falMapMarkerEdit": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerEdit'),
        "falMapMarkerExclamation": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerExclamation'),
        "falMapMarkerMinus": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerMinus'),
        "falMapMarkerPlus": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerPlus'),
        "falMapMarkerQuestion": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerQuestion'),
        "falMapMarkerSlash": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerSlash'),
        "falMapMarkerSmile": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerSmile'),
        "falMapMarkerTimes": () => import('@fortawesome/pro-light-svg-icons/faMapMarkerTimes'),
        "falMapPin": () => import('@fortawesome/pro-light-svg-icons/faMapPin'),
        "falMapSigns": () => import('@fortawesome/pro-light-svg-icons/faMapSigns'),
        "falMarker": () => import('@fortawesome/pro-light-svg-icons/faMarker'),
        "falMars": () => import('@fortawesome/pro-light-svg-icons/faMars'),
        "falMarsDouble": () => import('@fortawesome/pro-light-svg-icons/faMarsDouble'),
        "falMarsStroke": () => import('@fortawesome/pro-light-svg-icons/faMarsStroke'),
        "falMarsStrokeH": () => import('@fortawesome/pro-light-svg-icons/faMarsStrokeH'),
        "falMarsStrokeV": () => import('@fortawesome/pro-light-svg-icons/faMarsStrokeV'),
        "falMask": () => import('@fortawesome/pro-light-svg-icons/faMask'),
        "falMeat": () => import('@fortawesome/pro-light-svg-icons/faMeat'),
        "falMedal": () => import('@fortawesome/pro-light-svg-icons/faMedal'),
        "falMedkit": () => import('@fortawesome/pro-light-svg-icons/faMedkit'),
        "falMegaphone": () => import('@fortawesome/pro-light-svg-icons/faMegaphone'),
        "falMeh": () => import('@fortawesome/pro-light-svg-icons/faMeh'),
        "falMehBlank": () => import('@fortawesome/pro-light-svg-icons/faMehBlank'),
        "falMehRollingEyes": () => import('@fortawesome/pro-light-svg-icons/faMehRollingEyes'),
        "falMemory": () => import('@fortawesome/pro-light-svg-icons/faMemory'),
        "falMenorah": () => import('@fortawesome/pro-light-svg-icons/faMenorah'),
        "falMercury": () => import('@fortawesome/pro-light-svg-icons/faMercury'),
        "falMeteor": () => import('@fortawesome/pro-light-svg-icons/faMeteor'),
        "falMicrochip": () => import('@fortawesome/pro-light-svg-icons/faMicrochip'),
        "falMicrophone": () => import('@fortawesome/pro-light-svg-icons/faMicrophone'),
        "falMicrophoneAlt": () => import('@fortawesome/pro-light-svg-icons/faMicrophoneAlt'),
        "falMicrophoneAltSlash": () => import('@fortawesome/pro-light-svg-icons/faMicrophoneAltSlash'),
        "falMicrophoneSlash": () => import('@fortawesome/pro-light-svg-icons/faMicrophoneSlash'),
        "falMicrophoneStand": () => import('@fortawesome/pro-light-svg-icons/faMicrophoneStand'),
        "falMicroscope": () => import('@fortawesome/pro-light-svg-icons/faMicroscope'),
        "falMicrowave": () => import('@fortawesome/pro-light-svg-icons/faMicrowave'),
        "falMindShare": () => import('@fortawesome/pro-light-svg-icons/faMindShare'),
        "falMinus": () => import('@fortawesome/pro-light-svg-icons/faMinus'),
        "falMinusCircle": () => import('@fortawesome/pro-light-svg-icons/faMinusCircle'),
        "falMinusHexagon": () => import('@fortawesome/pro-light-svg-icons/faMinusHexagon'),
        "falMinusOctagon": () => import('@fortawesome/pro-light-svg-icons/faMinusOctagon'),
        "falMinusSquare": () => import('@fortawesome/pro-light-svg-icons/faMinusSquare'),
        "falMistletoe": () => import('@fortawesome/pro-light-svg-icons/faMistletoe'),
        "falMitten": () => import('@fortawesome/pro-light-svg-icons/faMitten'),
        "falMobile": () => import('@fortawesome/pro-light-svg-icons/faMobile'),
        "falMobileAlt": () => import('@fortawesome/pro-light-svg-icons/faMobileAlt'),
        "falMobileAndroid": () => import('@fortawesome/pro-light-svg-icons/faMobileAndroid'),
        "falMobileAndroidAlt": () => import('@fortawesome/pro-light-svg-icons/faMobileAndroidAlt'),
        "falMoneyBill": () => import('@fortawesome/pro-light-svg-icons/faMoneyBill'),
        "falMoneyBillAlt": () => import('@fortawesome/pro-light-svg-icons/faMoneyBillAlt'),
        "falMoneyBillWave": () => import('@fortawesome/pro-light-svg-icons/faMoneyBillWave'),
        "falMoneyBillWaveAlt": () => import('@fortawesome/pro-light-svg-icons/faMoneyBillWaveAlt'),
        "falMoneyCheck": () => import('@fortawesome/pro-light-svg-icons/faMoneyCheck'),
        "falMoneyCheckAlt": () => import('@fortawesome/pro-light-svg-icons/faMoneyCheckAlt'),
        "falMoneyCheckEdit": () => import('@fortawesome/pro-light-svg-icons/faMoneyCheckEdit'),
        "falMoneyCheckEditAlt": () => import('@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt'),
        "falMonitorHeartRate": () => import('@fortawesome/pro-light-svg-icons/faMonitorHeartRate'),
        "falMonkey": () => import('@fortawesome/pro-light-svg-icons/faMonkey'),
        "falMonument": () => import('@fortawesome/pro-light-svg-icons/faMonument'),
        "falMoon": () => import('@fortawesome/pro-light-svg-icons/faMoon'),
        "falMoonCloud": () => import('@fortawesome/pro-light-svg-icons/faMoonCloud'),
        "falMoonStars": () => import('@fortawesome/pro-light-svg-icons/faMoonStars'),
        "falMortarPestle": () => import('@fortawesome/pro-light-svg-icons/faMortarPestle'),
        "falMosque": () => import('@fortawesome/pro-light-svg-icons/faMosque'),
        "falMotorcycle": () => import('@fortawesome/pro-light-svg-icons/faMotorcycle'),
        "falMountain": () => import('@fortawesome/pro-light-svg-icons/faMountain'),
        "falMountains": () => import('@fortawesome/pro-light-svg-icons/faMountains'),
        "falMouse": () => import('@fortawesome/pro-light-svg-icons/faMouse'),
        "falMouseAlt": () => import('@fortawesome/pro-light-svg-icons/faMouseAlt'),
        "falMousePointer": () => import('@fortawesome/pro-light-svg-icons/faMousePointer'),
        "falMp3Player": () => import('@fortawesome/pro-light-svg-icons/faMp3Player'),
        "falMug": () => import('@fortawesome/pro-light-svg-icons/faMug'),
        "falMugHot": () => import('@fortawesome/pro-light-svg-icons/faMugHot'),
        "falMugMarshmallows": () => import('@fortawesome/pro-light-svg-icons/faMugMarshmallows'),
        "falMugTea": () => import('@fortawesome/pro-light-svg-icons/faMugTea'),
        "falMusic": () => import('@fortawesome/pro-light-svg-icons/faMusic'),
        "falMusicAlt": () => import('@fortawesome/pro-light-svg-icons/faMusicAlt'),
        "falMusicAltSlash": () => import('@fortawesome/pro-light-svg-icons/faMusicAltSlash'),
        "falMusicSlash": () => import('@fortawesome/pro-light-svg-icons/faMusicSlash'),
        "falNarwhal": () => import('@fortawesome/pro-light-svg-icons/faNarwhal'),
        "falNetworkWired": () => import('@fortawesome/pro-light-svg-icons/faNetworkWired'),
        "falNeuter": () => import('@fortawesome/pro-light-svg-icons/faNeuter'),
        "falNewspaper": () => import('@fortawesome/pro-light-svg-icons/faNewspaper'),
        "falNotEqual": () => import('@fortawesome/pro-light-svg-icons/faNotEqual'),
        "falNotesMedical": () => import('@fortawesome/pro-light-svg-icons/faNotesMedical'),
        "falObjectGroup": () => import('@fortawesome/pro-light-svg-icons/faObjectGroup'),
        "falObjectUngroup": () => import('@fortawesome/pro-light-svg-icons/faObjectUngroup'),
        "falOctagon": () => import('@fortawesome/pro-light-svg-icons/faOctagon'),
        "falOilCan": () => import('@fortawesome/pro-light-svg-icons/faOilCan'),
        "falOilTemp": () => import('@fortawesome/pro-light-svg-icons/faOilTemp'),
        "falOm": () => import('@fortawesome/pro-light-svg-icons/faOm'),
        "falOmega": () => import('@fortawesome/pro-light-svg-icons/faOmega'),
        "falOrnament": () => import('@fortawesome/pro-light-svg-icons/faOrnament'),
        "falOtter": () => import('@fortawesome/pro-light-svg-icons/faOtter'),
        "falOutdent": () => import('@fortawesome/pro-light-svg-icons/faOutdent'),
        "falOutlet": () => import('@fortawesome/pro-light-svg-icons/faOutlet'),
        "falOven": () => import('@fortawesome/pro-light-svg-icons/faOven'),
        "falOverline": () => import('@fortawesome/pro-light-svg-icons/faOverline'),
        "falPageBreak": () => import('@fortawesome/pro-light-svg-icons/faPageBreak'),
        "falPager": () => import('@fortawesome/pro-light-svg-icons/faPager'),
        "falPaintBrush": () => import('@fortawesome/pro-light-svg-icons/faPaintBrush'),
        "falPaintBrushAlt": () => import('@fortawesome/pro-light-svg-icons/faPaintBrushAlt'),
        "falPaintRoller": () => import('@fortawesome/pro-light-svg-icons/faPaintRoller'),
        "falPalette": () => import('@fortawesome/pro-light-svg-icons/faPalette'),
        "falPallet": () => import('@fortawesome/pro-light-svg-icons/faPallet'),
        "falPalletAlt": () => import('@fortawesome/pro-light-svg-icons/faPalletAlt'),
        "falPaperPlane": () => import('@fortawesome/pro-light-svg-icons/faPaperPlane'),
        "falPaperclip": () => import('@fortawesome/pro-light-svg-icons/faPaperclip'),
        "falParachuteBox": () => import('@fortawesome/pro-light-svg-icons/faParachuteBox'),
        "falParagraph": () => import('@fortawesome/pro-light-svg-icons/faParagraph'),
        "falParagraphRtl": () => import('@fortawesome/pro-light-svg-icons/faParagraphRtl'),
        "falParking": () => import('@fortawesome/pro-light-svg-icons/faParking'),
        "falParkingCircle": () => import('@fortawesome/pro-light-svg-icons/faParkingCircle'),
        "falParkingCircleSlash": () => import('@fortawesome/pro-light-svg-icons/faParkingCircleSlash'),
        "falParkingSlash": () => import('@fortawesome/pro-light-svg-icons/faParkingSlash'),
        "falPassport": () => import('@fortawesome/pro-light-svg-icons/faPassport'),
        "falPastafarianism": () => import('@fortawesome/pro-light-svg-icons/faPastafarianism'),
        "falPaste": () => import('@fortawesome/pro-light-svg-icons/faPaste'),
        "falPause": () => import('@fortawesome/pro-light-svg-icons/faPause'),
        "falPauseCircle": () => import('@fortawesome/pro-light-svg-icons/faPauseCircle'),
        "falPaw": () => import('@fortawesome/pro-light-svg-icons/faPaw'),
        "falPawAlt": () => import('@fortawesome/pro-light-svg-icons/faPawAlt'),
        "falPawClaws": () => import('@fortawesome/pro-light-svg-icons/faPawClaws'),
        "falPeace": () => import('@fortawesome/pro-light-svg-icons/faPeace'),
        "falPegasus": () => import('@fortawesome/pro-light-svg-icons/faPegasus'),
        "falPen": () => import('@fortawesome/pro-light-svg-icons/faPen'),
        "falPenAlt": () => import('@fortawesome/pro-light-svg-icons/faPenAlt'),
        "falPenFancy": () => import('@fortawesome/pro-light-svg-icons/faPenFancy'),
        "falPenNib": () => import('@fortawesome/pro-light-svg-icons/faPenNib'),
        "falPenSquare": () => import('@fortawesome/pro-light-svg-icons/faPenSquare'),
        "falPencil": () => import('@fortawesome/pro-light-svg-icons/faPencil'),
        "falPencilAlt": () => import('@fortawesome/pro-light-svg-icons/faPencilAlt'),
        "falPencilPaintbrush": () => import('@fortawesome/pro-light-svg-icons/faPencilPaintbrush'),
        "falPencilRuler": () => import('@fortawesome/pro-light-svg-icons/faPencilRuler'),
        "falPennant": () => import('@fortawesome/pro-light-svg-icons/faPennant'),
        "falPeopleArrows": () => import('@fortawesome/pro-light-svg-icons/faPeopleArrows'),
        "falPeopleCarry": () => import('@fortawesome/pro-light-svg-icons/faPeopleCarry'),
        "falPepperHot": () => import('@fortawesome/pro-light-svg-icons/faPepperHot'),
        "falPercent": () => import('@fortawesome/pro-light-svg-icons/faPercent'),
        "falPercentage": () => import('@fortawesome/pro-light-svg-icons/faPercentage'),
        "falPersonBooth": () => import('@fortawesome/pro-light-svg-icons/faPersonBooth'),
        "falPersonCarry": () => import('@fortawesome/pro-light-svg-icons/faPersonCarry'),
        "falPersonDolly": () => import('@fortawesome/pro-light-svg-icons/faPersonDolly'),
        "falPersonDollyEmpty": () => import('@fortawesome/pro-light-svg-icons/faPersonDollyEmpty'),
        "falPersonSign": () => import('@fortawesome/pro-light-svg-icons/faPersonSign'),
        "falPhone": () => import('@fortawesome/pro-light-svg-icons/faPhone'),
        "falPhoneAlt": () => import('@fortawesome/pro-light-svg-icons/faPhoneAlt'),
        "falPhoneLaptop": () => import('@fortawesome/pro-light-svg-icons/faPhoneLaptop'),
        "falPhoneOffice": () => import('@fortawesome/pro-light-svg-icons/faPhoneOffice'),
        "falPhonePlus": () => import('@fortawesome/pro-light-svg-icons/faPhonePlus'),
        "falPhoneRotary": () => import('@fortawesome/pro-light-svg-icons/faPhoneRotary'),
        "falPhoneSlash": () => import('@fortawesome/pro-light-svg-icons/faPhoneSlash'),
        "falPhoneSquare": () => import('@fortawesome/pro-light-svg-icons/faPhoneSquare'),
        "falPhoneSquareAlt": () => import('@fortawesome/pro-light-svg-icons/faPhoneSquareAlt'),
        "falPhoneVolume": () => import('@fortawesome/pro-light-svg-icons/faPhoneVolume'),
        "falPhotoVideo": () => import('@fortawesome/pro-light-svg-icons/faPhotoVideo'),
        "falPi": () => import('@fortawesome/pro-light-svg-icons/faPi'),
        "falPiano": () => import('@fortawesome/pro-light-svg-icons/faPiano'),
        "falPianoKeyboard": () => import('@fortawesome/pro-light-svg-icons/faPianoKeyboard'),
        "falPie": () => import('@fortawesome/pro-light-svg-icons/faPie'),
        "falPig": () => import('@fortawesome/pro-light-svg-icons/faPig'),
        "falPiggyBank": () => import('@fortawesome/pro-light-svg-icons/faPiggyBank'),
        "falPills": () => import('@fortawesome/pro-light-svg-icons/faPills'),
        "falPizza": () => import('@fortawesome/pro-light-svg-icons/faPizza'),
        "falPizzaSlice": () => import('@fortawesome/pro-light-svg-icons/faPizzaSlice'),
        "falPlaceOfWorship": () => import('@fortawesome/pro-light-svg-icons/faPlaceOfWorship'),
        "falPlane": () => import('@fortawesome/pro-light-svg-icons/faPlane'),
        "falPlaneAlt": () => import('@fortawesome/pro-light-svg-icons/faPlaneAlt'),
        "falPlaneArrival": () => import('@fortawesome/pro-light-svg-icons/faPlaneArrival'),
        "falPlaneDeparture": () => import('@fortawesome/pro-light-svg-icons/faPlaneDeparture'),
        "falPlaneSlash": () => import('@fortawesome/pro-light-svg-icons/faPlaneSlash'),
        "falPlanetMoon": () => import('@fortawesome/pro-light-svg-icons/faPlanetMoon'),
        "falPlanetRinged": () => import('@fortawesome/pro-light-svg-icons/faPlanetRinged'),
        "falPlay": () => import('@fortawesome/pro-light-svg-icons/faPlay'),
        "falPlayCircle": () => import('@fortawesome/pro-light-svg-icons/faPlayCircle'),
        "falPlug": () => import('@fortawesome/pro-light-svg-icons/faPlug'),
        "falPlus": () => import('@fortawesome/pro-light-svg-icons/faPlus'),
        "falPlusCircle": () => import('@fortawesome/pro-light-svg-icons/faPlusCircle'),
        "falPlusHexagon": () => import('@fortawesome/pro-light-svg-icons/faPlusHexagon'),
        "falPlusOctagon": () => import('@fortawesome/pro-light-svg-icons/faPlusOctagon'),
        "falPlusSquare": () => import('@fortawesome/pro-light-svg-icons/faPlusSquare'),
        "falPodcast": () => import('@fortawesome/pro-light-svg-icons/faPodcast'),
        "falPodium": () => import('@fortawesome/pro-light-svg-icons/faPodium'),
        "falPodiumStar": () => import('@fortawesome/pro-light-svg-icons/faPodiumStar'),
        "falPoliceBox": () => import('@fortawesome/pro-light-svg-icons/faPoliceBox'),
        "falPoll": () => import('@fortawesome/pro-light-svg-icons/faPoll'),
        "falPollH": () => import('@fortawesome/pro-light-svg-icons/faPollH'),
        "falPollPeople": () => import('@fortawesome/pro-light-svg-icons/faPollPeople'),
        "falPoo": () => import('@fortawesome/pro-light-svg-icons/faPoo'),
        "falPooStorm": () => import('@fortawesome/pro-light-svg-icons/faPooStorm'),
        "falPoop": () => import('@fortawesome/pro-light-svg-icons/faPoop'),
        "falPopcorn": () => import('@fortawesome/pro-light-svg-icons/faPopcorn'),
        "falPortalEnter": () => import('@fortawesome/pro-light-svg-icons/faPortalEnter'),
        "falPortalExit": () => import('@fortawesome/pro-light-svg-icons/faPortalExit'),
        "falPortrait": () => import('@fortawesome/pro-light-svg-icons/faPortrait'),
        "falPoundSign": () => import('@fortawesome/pro-light-svg-icons/faPoundSign'),
        "falPowerOff": () => import('@fortawesome/pro-light-svg-icons/faPowerOff'),
        "falPray": () => import('@fortawesome/pro-light-svg-icons/faPray'),
        "falPrayingHands": () => import('@fortawesome/pro-light-svg-icons/faPrayingHands'),
        "falPrescription": () => import('@fortawesome/pro-light-svg-icons/faPrescription'),
        "falPrescriptionBottle": () => import('@fortawesome/pro-light-svg-icons/faPrescriptionBottle'),
        "falPrescriptionBottleAlt": () => import('@fortawesome/pro-light-svg-icons/faPrescriptionBottleAlt'),
        "falPresentation": () => import('@fortawesome/pro-light-svg-icons/faPresentation'),
        "falPrint": () => import('@fortawesome/pro-light-svg-icons/faPrint'),
        "falPrintSearch": () => import('@fortawesome/pro-light-svg-icons/faPrintSearch'),
        "falPrintSlash": () => import('@fortawesome/pro-light-svg-icons/faPrintSlash'),
        "falProcedures": () => import('@fortawesome/pro-light-svg-icons/faProcedures'),
        "falProjectDiagram": () => import('@fortawesome/pro-light-svg-icons/faProjectDiagram'),
        "falProjector": () => import('@fortawesome/pro-light-svg-icons/faProjector'),
        "falPumpMedical": () => import('@fortawesome/pro-light-svg-icons/faPumpMedical'),
        "falPumpSoap": () => import('@fortawesome/pro-light-svg-icons/faPumpSoap'),
        "falPumpkin": () => import('@fortawesome/pro-light-svg-icons/faPumpkin'),
        "falPuzzlePiece": () => import('@fortawesome/pro-light-svg-icons/faPuzzlePiece'),
        "falQrcode": () => import('@fortawesome/pro-light-svg-icons/faQrcode'),
        "falQuestion": () => import('@fortawesome/pro-light-svg-icons/faQuestion'),
        "falQuestionCircle": () => import('@fortawesome/pro-light-svg-icons/faQuestionCircle'),
        "falQuestionSquare": () => import('@fortawesome/pro-light-svg-icons/faQuestionSquare'),
        "falQuidditch": () => import('@fortawesome/pro-light-svg-icons/faQuidditch'),
        "falQuoteLeft": () => import('@fortawesome/pro-light-svg-icons/faQuoteLeft'),
        "falQuoteRight": () => import('@fortawesome/pro-light-svg-icons/faQuoteRight'),
        "falQuran": () => import('@fortawesome/pro-light-svg-icons/faQuran'),
        "falRabbit": () => import('@fortawesome/pro-light-svg-icons/faRabbit'),
        "falRabbitFast": () => import('@fortawesome/pro-light-svg-icons/faRabbitFast'),
        "falRacquet": () => import('@fortawesome/pro-light-svg-icons/faRacquet'),
        "falRadar": () => import('@fortawesome/pro-light-svg-icons/faRadar'),
        "falRadiation": () => import('@fortawesome/pro-light-svg-icons/faRadiation'),
        "falRadiationAlt": () => import('@fortawesome/pro-light-svg-icons/faRadiationAlt'),
        "falRadio": () => import('@fortawesome/pro-light-svg-icons/faRadio'),
        "falRadioAlt": () => import('@fortawesome/pro-light-svg-icons/faRadioAlt'),
        "falRainbow": () => import('@fortawesome/pro-light-svg-icons/faRainbow'),
        "falRaindrops": () => import('@fortawesome/pro-light-svg-icons/faRaindrops'),
        "falRam": () => import('@fortawesome/pro-light-svg-icons/faRam'),
        "falRampLoading": () => import('@fortawesome/pro-light-svg-icons/faRampLoading'),
        "falRandom": () => import('@fortawesome/pro-light-svg-icons/faRandom'),
        "falRaygun": () => import('@fortawesome/pro-light-svg-icons/faRaygun'),
        "falReceipt": () => import('@fortawesome/pro-light-svg-icons/faReceipt'),
        "falRecordVinyl": () => import('@fortawesome/pro-light-svg-icons/faRecordVinyl'),
        "falRectangleLandscape": () => import('@fortawesome/pro-light-svg-icons/faRectangleLandscape'),
        "falRectanglePortrait": () => import('@fortawesome/pro-light-svg-icons/faRectanglePortrait'),
        "falRectangleWide": () => import('@fortawesome/pro-light-svg-icons/faRectangleWide'),
        "falRecycle": () => import('@fortawesome/pro-light-svg-icons/faRecycle'),
        "falRedo": () => import('@fortawesome/pro-light-svg-icons/faRedo'),
        "falRedoAlt": () => import('@fortawesome/pro-light-svg-icons/faRedoAlt'),
        "falRefrigerator": () => import('@fortawesome/pro-light-svg-icons/faRefrigerator'),
        "falRegistered": () => import('@fortawesome/pro-light-svg-icons/faRegistered'),
        "falRemoveFormat": () => import('@fortawesome/pro-light-svg-icons/faRemoveFormat'),
        "falRepeat": () => import('@fortawesome/pro-light-svg-icons/faRepeat'),
        "falRepeat1": () => import('@fortawesome/pro-light-svg-icons/faRepeat1'),
        "falRepeat1Alt": () => import('@fortawesome/pro-light-svg-icons/faRepeat1Alt'),
        "falRepeatAlt": () => import('@fortawesome/pro-light-svg-icons/faRepeatAlt'),
        "falReply": () => import('@fortawesome/pro-light-svg-icons/faReply'),
        "falReplyAll": () => import('@fortawesome/pro-light-svg-icons/faReplyAll'),
        "falRepublican": () => import('@fortawesome/pro-light-svg-icons/faRepublican'),
        "falRestroom": () => import('@fortawesome/pro-light-svg-icons/faRestroom'),
        "falRetweet": () => import('@fortawesome/pro-light-svg-icons/faRetweet'),
        "falRetweetAlt": () => import('@fortawesome/pro-light-svg-icons/faRetweetAlt'),
        "falRibbon": () => import('@fortawesome/pro-light-svg-icons/faRibbon'),
        "falRing": () => import('@fortawesome/pro-light-svg-icons/faRing'),
        "falRingsWedding": () => import('@fortawesome/pro-light-svg-icons/faRingsWedding'),
        "falRoad": () => import('@fortawesome/pro-light-svg-icons/faRoad'),
        "falRobot": () => import('@fortawesome/pro-light-svg-icons/faRobot'),
        "falRocket": () => import('@fortawesome/pro-light-svg-icons/faRocket'),
        "falRocketLaunch": () => import('@fortawesome/pro-light-svg-icons/faRocketLaunch'),
        "falRoute": () => import('@fortawesome/pro-light-svg-icons/faRoute'),
        "falRouteHighway": () => import('@fortawesome/pro-light-svg-icons/faRouteHighway'),
        "falRouteInterstate": () => import('@fortawesome/pro-light-svg-icons/faRouteInterstate'),
        "falRouter": () => import('@fortawesome/pro-light-svg-icons/faRouter'),
        "falRss": () => import('@fortawesome/pro-light-svg-icons/faRss'),
        "falRssSquare": () => import('@fortawesome/pro-light-svg-icons/faRssSquare'),
        "falRubleSign": () => import('@fortawesome/pro-light-svg-icons/faRubleSign'),
        "falRuler": () => import('@fortawesome/pro-light-svg-icons/faRuler'),
        "falRulerCombined": () => import('@fortawesome/pro-light-svg-icons/faRulerCombined'),
        "falRulerHorizontal": () => import('@fortawesome/pro-light-svg-icons/faRulerHorizontal'),
        "falRulerTriangle": () => import('@fortawesome/pro-light-svg-icons/faRulerTriangle'),
        "falRulerVertical": () => import('@fortawesome/pro-light-svg-icons/faRulerVertical'),
        "falRunning": () => import('@fortawesome/pro-light-svg-icons/faRunning'),
        "falRupeeSign": () => import('@fortawesome/pro-light-svg-icons/faRupeeSign'),
        "falRv": () => import('@fortawesome/pro-light-svg-icons/faRv'),
        "falSack": () => import('@fortawesome/pro-light-svg-icons/faSack'),
        "falSackDollar": () => import('@fortawesome/pro-light-svg-icons/faSackDollar'),
        "falSadCry": () => import('@fortawesome/pro-light-svg-icons/faSadCry'),
        "falSadTear": () => import('@fortawesome/pro-light-svg-icons/faSadTear'),
        "falSalad": () => import('@fortawesome/pro-light-svg-icons/faSalad'),
        "falSandwich": () => import('@fortawesome/pro-light-svg-icons/faSandwich'),
        "falSatellite": () => import('@fortawesome/pro-light-svg-icons/faSatellite'),
        "falSatelliteDish": () => import('@fortawesome/pro-light-svg-icons/faSatelliteDish'),
        "falSausage": () => import('@fortawesome/pro-light-svg-icons/faSausage'),
        "falSave": () => import('@fortawesome/pro-light-svg-icons/faSave'),
        "falSaxHot": () => import('@fortawesome/pro-light-svg-icons/faSaxHot'),
        "falSaxophone": () => import('@fortawesome/pro-light-svg-icons/faSaxophone'),
        "falScalpel": () => import('@fortawesome/pro-light-svg-icons/faScalpel'),
        "falScalpelPath": () => import('@fortawesome/pro-light-svg-icons/faScalpelPath'),
        "falScanner": () => import('@fortawesome/pro-light-svg-icons/faScanner'),
        "falScannerImage": () => import('@fortawesome/pro-light-svg-icons/faScannerImage'),
        "falScannerKeyboard": () => import('@fortawesome/pro-light-svg-icons/faScannerKeyboard'),
        "falScannerTouchscreen": () => import('@fortawesome/pro-light-svg-icons/faScannerTouchscreen'),
        "falScarecrow": () => import('@fortawesome/pro-light-svg-icons/faScarecrow'),
        "falScarf": () => import('@fortawesome/pro-light-svg-icons/faScarf'),
        "falSchool": () => import('@fortawesome/pro-light-svg-icons/faSchool'),
        "falScrewdriver": () => import('@fortawesome/pro-light-svg-icons/faScrewdriver'),
        "falScroll": () => import('@fortawesome/pro-light-svg-icons/faScroll'),
        "falScrollOld": () => import('@fortawesome/pro-light-svg-icons/faScrollOld'),
        "falScrubber": () => import('@fortawesome/pro-light-svg-icons/faScrubber'),
        "falScythe": () => import('@fortawesome/pro-light-svg-icons/faScythe'),
        "falSdCard": () => import('@fortawesome/pro-light-svg-icons/faSdCard'),
        "falSearch": () => import('@fortawesome/pro-light-svg-icons/faSearch'),
        "falSearchDollar": () => import('@fortawesome/pro-light-svg-icons/faSearchDollar'),
        "falSearchLocation": () => import('@fortawesome/pro-light-svg-icons/faSearchLocation'),
        "falSearchMinus": () => import('@fortawesome/pro-light-svg-icons/faSearchMinus'),
        "falSearchPlus": () => import('@fortawesome/pro-light-svg-icons/faSearchPlus'),
        "falSeedling": () => import('@fortawesome/pro-light-svg-icons/faSeedling'),
        "falSendBack": () => import('@fortawesome/pro-light-svg-icons/faSendBack'),
        "falSendBackward": () => import('@fortawesome/pro-light-svg-icons/faSendBackward'),
        "falSensor": () => import('@fortawesome/pro-light-svg-icons/faSensor'),
        "falSensorAlert": () => import('@fortawesome/pro-light-svg-icons/faSensorAlert'),
        "falSensorFire": () => import('@fortawesome/pro-light-svg-icons/faSensorFire'),
        "falSensorOn": () => import('@fortawesome/pro-light-svg-icons/faSensorOn'),
        "falSensorSmoke": () => import('@fortawesome/pro-light-svg-icons/faSensorSmoke'),
        "falServer": () => import('@fortawesome/pro-light-svg-icons/faServer'),
        "falShapes": () => import('@fortawesome/pro-light-svg-icons/faShapes'),
        "falShare": () => import('@fortawesome/pro-light-svg-icons/faShare'),
        "falShareAll": () => import('@fortawesome/pro-light-svg-icons/faShareAll'),
        "falShareAlt": () => import('@fortawesome/pro-light-svg-icons/faShareAlt'),
        "falShareAltSquare": () => import('@fortawesome/pro-light-svg-icons/faShareAltSquare'),
        "falShareSquare": () => import('@fortawesome/pro-light-svg-icons/faShareSquare'),
        "falSheep": () => import('@fortawesome/pro-light-svg-icons/faSheep'),
        "falShekelSign": () => import('@fortawesome/pro-light-svg-icons/faShekelSign'),
        "falShield": () => import('@fortawesome/pro-light-svg-icons/faShield'),
        "falShieldAlt": () => import('@fortawesome/pro-light-svg-icons/faShieldAlt'),
        "falShieldCheck": () => import('@fortawesome/pro-light-svg-icons/faShieldCheck'),
        "falShieldCross": () => import('@fortawesome/pro-light-svg-icons/faShieldCross'),
        "falShieldVirus": () => import('@fortawesome/pro-light-svg-icons/faShieldVirus'),
        "falShip": () => import('@fortawesome/pro-light-svg-icons/faShip'),
        "falShippingFast": () => import('@fortawesome/pro-light-svg-icons/faShippingFast'),
        "falShippingTimed": () => import('@fortawesome/pro-light-svg-icons/faShippingTimed'),
        "falShishKebab": () => import('@fortawesome/pro-light-svg-icons/faShishKebab'),
        "falShoePrints": () => import('@fortawesome/pro-light-svg-icons/faShoePrints'),
        "falShoppingBag": () => import('@fortawesome/pro-light-svg-icons/faShoppingBag'),
        "falShoppingBasket": () => import('@fortawesome/pro-light-svg-icons/faShoppingBasket'),
        "falShoppingCart": () => import('@fortawesome/pro-light-svg-icons/faShoppingCart'),
        "falShovel": () => import('@fortawesome/pro-light-svg-icons/faShovel'),
        "falShovelSnow": () => import('@fortawesome/pro-light-svg-icons/faShovelSnow'),
        "falShower": () => import('@fortawesome/pro-light-svg-icons/faShower'),
        "falShredder": () => import('@fortawesome/pro-light-svg-icons/faShredder'),
        "falShuttleVan": () => import('@fortawesome/pro-light-svg-icons/faShuttleVan'),
        "falShuttlecock": () => import('@fortawesome/pro-light-svg-icons/faShuttlecock'),
        "falSickle": () => import('@fortawesome/pro-light-svg-icons/faSickle'),
        "falSigma": () => import('@fortawesome/pro-light-svg-icons/faSigma'),
        "falSign": () => import('@fortawesome/pro-light-svg-icons/faSign'),
        "falSignIn": () => import('@fortawesome/pro-light-svg-icons/faSignIn'),
        "falSignInAlt": () => import('@fortawesome/pro-light-svg-icons/faSignInAlt'),
        "falSignLanguage": () => import('@fortawesome/pro-light-svg-icons/faSignLanguage'),
        "falSignOut": () => import('@fortawesome/pro-light-svg-icons/faSignOut'),
        "falSignOutAlt": () => import('@fortawesome/pro-light-svg-icons/faSignOutAlt'),
        "falSignal": () => import('@fortawesome/pro-light-svg-icons/faSignal'),
        "falSignal1": () => import('@fortawesome/pro-light-svg-icons/faSignal1'),
        "falSignal2": () => import('@fortawesome/pro-light-svg-icons/faSignal2'),
        "falSignal3": () => import('@fortawesome/pro-light-svg-icons/faSignal3'),
        "falSignal4": () => import('@fortawesome/pro-light-svg-icons/faSignal4'),
        "falSignalAlt": () => import('@fortawesome/pro-light-svg-icons/faSignalAlt'),
        "falSignalAlt1": () => import('@fortawesome/pro-light-svg-icons/faSignalAlt1'),
        "falSignalAlt2": () => import('@fortawesome/pro-light-svg-icons/faSignalAlt2'),
        "falSignalAlt3": () => import('@fortawesome/pro-light-svg-icons/faSignalAlt3'),
        "falSignalAltSlash": () => import('@fortawesome/pro-light-svg-icons/faSignalAltSlash'),
        "falSignalSlash": () => import('@fortawesome/pro-light-svg-icons/faSignalSlash'),
        "falSignalStream": () => import('@fortawesome/pro-light-svg-icons/faSignalStream'),
        "falSignature": () => import('@fortawesome/pro-light-svg-icons/faSignature'),
        "falSimCard": () => import('@fortawesome/pro-light-svg-icons/faSimCard'),
        "falSink": () => import('@fortawesome/pro-light-svg-icons/faSink'),
        "falSiren": () => import('@fortawesome/pro-light-svg-icons/faSiren'),
        "falSirenOn": () => import('@fortawesome/pro-light-svg-icons/faSirenOn'),
        "falSitemap": () => import('@fortawesome/pro-light-svg-icons/faSitemap'),
        "falSkating": () => import('@fortawesome/pro-light-svg-icons/faSkating'),
        "falSkeleton": () => import('@fortawesome/pro-light-svg-icons/faSkeleton'),
        "falSkiJump": () => import('@fortawesome/pro-light-svg-icons/faSkiJump'),
        "falSkiLift": () => import('@fortawesome/pro-light-svg-icons/faSkiLift'),
        "falSkiing": () => import('@fortawesome/pro-light-svg-icons/faSkiing'),
        "falSkiingNordic": () => import('@fortawesome/pro-light-svg-icons/faSkiingNordic'),
        "falSkull": () => import('@fortawesome/pro-light-svg-icons/faSkull'),
        "falSkullCow": () => import('@fortawesome/pro-light-svg-icons/faSkullCow'),
        "falSkullCrossbones": () => import('@fortawesome/pro-light-svg-icons/faSkullCrossbones'),
        "falSlash": () => import('@fortawesome/pro-light-svg-icons/faSlash'),
        "falSledding": () => import('@fortawesome/pro-light-svg-icons/faSledding'),
        "falSleigh": () => import('@fortawesome/pro-light-svg-icons/faSleigh'),
        "falSlidersH": () => import('@fortawesome/pro-light-svg-icons/faSlidersH'),
        "falSlidersHSquare": () => import('@fortawesome/pro-light-svg-icons/faSlidersHSquare'),
        "falSlidersV": () => import('@fortawesome/pro-light-svg-icons/faSlidersV'),
        "falSlidersVSquare": () => import('@fortawesome/pro-light-svg-icons/faSlidersVSquare'),
        "falSmile": () => import('@fortawesome/pro-light-svg-icons/faSmile'),
        "falSmileBeam": () => import('@fortawesome/pro-light-svg-icons/faSmileBeam'),
        "falSmilePlus": () => import('@fortawesome/pro-light-svg-icons/faSmilePlus'),
        "falSmileWink": () => import('@fortawesome/pro-light-svg-icons/faSmileWink'),
        "falSmog": () => import('@fortawesome/pro-light-svg-icons/faSmog'),
        "falSmoke": () => import('@fortawesome/pro-light-svg-icons/faSmoke'),
        "falSmoking": () => import('@fortawesome/pro-light-svg-icons/faSmoking'),
        "falSmokingBan": () => import('@fortawesome/pro-light-svg-icons/faSmokingBan'),
        "falSms": () => import('@fortawesome/pro-light-svg-icons/faSms'),
        "falSnake": () => import('@fortawesome/pro-light-svg-icons/faSnake'),
        "falSnooze": () => import('@fortawesome/pro-light-svg-icons/faSnooze'),
        "falSnowBlowing": () => import('@fortawesome/pro-light-svg-icons/faSnowBlowing'),
        "falSnowboarding": () => import('@fortawesome/pro-light-svg-icons/faSnowboarding'),
        "falSnowflake": () => import('@fortawesome/pro-light-svg-icons/faSnowflake'),
        "falSnowflakes": () => import('@fortawesome/pro-light-svg-icons/faSnowflakes'),
        "falSnowman": () => import('@fortawesome/pro-light-svg-icons/faSnowman'),
        "falSnowmobile": () => import('@fortawesome/pro-light-svg-icons/faSnowmobile'),
        "falSnowplow": () => import('@fortawesome/pro-light-svg-icons/faSnowplow'),
        "falSoap": () => import('@fortawesome/pro-light-svg-icons/faSoap'),
        "falSocks": () => import('@fortawesome/pro-light-svg-icons/faSocks'),
        "falSolarPanel": () => import('@fortawesome/pro-light-svg-icons/faSolarPanel'),
        "falSolarSystem": () => import('@fortawesome/pro-light-svg-icons/faSolarSystem'),
        "falSort": () => import('@fortawesome/pro-light-svg-icons/faSort'),
        "falSortAlphaDown": () => import('@fortawesome/pro-light-svg-icons/faSortAlphaDown'),
        "falSortAlphaDownAlt": () => import('@fortawesome/pro-light-svg-icons/faSortAlphaDownAlt'),
        "falSortAlphaUp": () => import('@fortawesome/pro-light-svg-icons/faSortAlphaUp'),
        "falSortAlphaUpAlt": () => import('@fortawesome/pro-light-svg-icons/faSortAlphaUpAlt'),
        "falSortAlt": () => import('@fortawesome/pro-light-svg-icons/faSortAlt'),
        "falSortAmountDown": () => import('@fortawesome/pro-light-svg-icons/faSortAmountDown'),
        "falSortAmountDownAlt": () => import('@fortawesome/pro-light-svg-icons/faSortAmountDownAlt'),
        "falSortAmountUp": () => import('@fortawesome/pro-light-svg-icons/faSortAmountUp'),
        "falSortAmountUpAlt": () => import('@fortawesome/pro-light-svg-icons/faSortAmountUpAlt'),
        "falSortCircle": () => import('@fortawesome/pro-light-svg-icons/faSortCircle'),
        "falSortCircleDown": () => import('@fortawesome/pro-light-svg-icons/faSortCircleDown'),
        "falSortCircleUp": () => import('@fortawesome/pro-light-svg-icons/faSortCircleUp'),
        "falSortDown": () => import('@fortawesome/pro-light-svg-icons/faSortDown'),
        "falSortNumericDown": () => import('@fortawesome/pro-light-svg-icons/faSortNumericDown'),
        "falSortNumericDownAlt": () => import('@fortawesome/pro-light-svg-icons/faSortNumericDownAlt'),
        "falSortNumericUp": () => import('@fortawesome/pro-light-svg-icons/faSortNumericUp'),
        "falSortNumericUpAlt": () => import('@fortawesome/pro-light-svg-icons/faSortNumericUpAlt'),
        "falSortShapesDown": () => import('@fortawesome/pro-light-svg-icons/faSortShapesDown'),
        "falSortShapesDownAlt": () => import('@fortawesome/pro-light-svg-icons/faSortShapesDownAlt'),
        "falSortShapesUp": () => import('@fortawesome/pro-light-svg-icons/faSortShapesUp'),
        "falSortShapesUpAlt": () => import('@fortawesome/pro-light-svg-icons/faSortShapesUpAlt'),
        "falSortSizeDown": () => import('@fortawesome/pro-light-svg-icons/faSortSizeDown'),
        "falSortSizeDownAlt": () => import('@fortawesome/pro-light-svg-icons/faSortSizeDownAlt'),
        "falSortSizeUp": () => import('@fortawesome/pro-light-svg-icons/faSortSizeUp'),
        "falSortSizeUpAlt": () => import('@fortawesome/pro-light-svg-icons/faSortSizeUpAlt'),
        "falSortUp": () => import('@fortawesome/pro-light-svg-icons/faSortUp'),
        "falSoup": () => import('@fortawesome/pro-light-svg-icons/faSoup'),
        "falSpa": () => import('@fortawesome/pro-light-svg-icons/faSpa'),
        "falSpaceShuttle": () => import('@fortawesome/pro-light-svg-icons/faSpaceShuttle'),
        "falSpaceStationMoon": () => import('@fortawesome/pro-light-svg-icons/faSpaceStationMoon'),
        "falSpaceStationMoonAlt": () => import('@fortawesome/pro-light-svg-icons/faSpaceStationMoonAlt'),
        "falSpade": () => import('@fortawesome/pro-light-svg-icons/faSpade'),
        "falSparkles": () => import('@fortawesome/pro-light-svg-icons/faSparkles'),
        "falSpeaker": () => import('@fortawesome/pro-light-svg-icons/faSpeaker'),
        "falSpeakers": () => import('@fortawesome/pro-light-svg-icons/faSpeakers'),
        "falSpellCheck": () => import('@fortawesome/pro-light-svg-icons/faSpellCheck'),
        "falSpider": () => import('@fortawesome/pro-light-svg-icons/faSpider'),
        "falSpiderBlackWidow": () => import('@fortawesome/pro-light-svg-icons/faSpiderBlackWidow'),
        "falSpiderWeb": () => import('@fortawesome/pro-light-svg-icons/faSpiderWeb'),
        "falSpinner": () => import('@fortawesome/pro-light-svg-icons/faSpinner'),
        "falSpinnerThird": () => import('@fortawesome/pro-light-svg-icons/faSpinnerThird'),
        "falSplotch": () => import('@fortawesome/pro-light-svg-icons/faSplotch'),
        "falSprayCan": () => import('@fortawesome/pro-light-svg-icons/faSprayCan'),
        "falSprinkler": () => import('@fortawesome/pro-light-svg-icons/faSprinkler'),
        "falSquare": () => import('@fortawesome/pro-light-svg-icons/faSquare'),
        "falSquareFull": () => import('@fortawesome/pro-light-svg-icons/faSquareFull'),
        "falSquareRoot": () => import('@fortawesome/pro-light-svg-icons/faSquareRoot'),
        "falSquareRootAlt": () => import('@fortawesome/pro-light-svg-icons/faSquareRootAlt'),
        "falSquirrel": () => import('@fortawesome/pro-light-svg-icons/faSquirrel'),
        "falStaff": () => import('@fortawesome/pro-light-svg-icons/faStaff'),
        "falStamp": () => import('@fortawesome/pro-light-svg-icons/faStamp'),
        "falStar": () => import('@fortawesome/pro-light-svg-icons/faStar'),
        "falStarAndCrescent": () => import('@fortawesome/pro-light-svg-icons/faStarAndCrescent'),
        "falStarChristmas": () => import('@fortawesome/pro-light-svg-icons/faStarChristmas'),
        "falStarExclamation": () => import('@fortawesome/pro-light-svg-icons/faStarExclamation'),
        "falStarHalf": () => import('@fortawesome/pro-light-svg-icons/faStarHalf'),
        "falStarHalfAlt": () => import('@fortawesome/pro-light-svg-icons/faStarHalfAlt'),
        "falStarOfDavid": () => import('@fortawesome/pro-light-svg-icons/faStarOfDavid'),
        "falStarOfLife": () => import('@fortawesome/pro-light-svg-icons/faStarOfLife'),
        "falStarShooting": () => import('@fortawesome/pro-light-svg-icons/faStarShooting'),
        "falStarfighter": () => import('@fortawesome/pro-light-svg-icons/faStarfighter'),
        "falStarfighterAlt": () => import('@fortawesome/pro-light-svg-icons/faStarfighterAlt'),
        "falStars": () => import('@fortawesome/pro-light-svg-icons/faStars'),
        "falStarship": () => import('@fortawesome/pro-light-svg-icons/faStarship'),
        "falStarshipFreighter": () => import('@fortawesome/pro-light-svg-icons/faStarshipFreighter'),
        "falSteak": () => import('@fortawesome/pro-light-svg-icons/faSteak'),
        "falSteeringWheel": () => import('@fortawesome/pro-light-svg-icons/faSteeringWheel'),
        "falStepBackward": () => import('@fortawesome/pro-light-svg-icons/faStepBackward'),
        "falStepForward": () => import('@fortawesome/pro-light-svg-icons/faStepForward'),
        "falStethoscope": () => import('@fortawesome/pro-light-svg-icons/faStethoscope'),
        "falStickyNote": () => import('@fortawesome/pro-light-svg-icons/faStickyNote'),
        "falStocking": () => import('@fortawesome/pro-light-svg-icons/faStocking'),
        "falStomach": () => import('@fortawesome/pro-light-svg-icons/faStomach'),
        "falStop": () => import('@fortawesome/pro-light-svg-icons/faStop'),
        "falStopCircle": () => import('@fortawesome/pro-light-svg-icons/faStopCircle'),
        "falStopwatch": () => import('@fortawesome/pro-light-svg-icons/faStopwatch'),
        "falStopwatch20": () => import('@fortawesome/pro-light-svg-icons/faStopwatch20'),
        "falStore": () => import('@fortawesome/pro-light-svg-icons/faStore'),
        "falStoreAlt": () => import('@fortawesome/pro-light-svg-icons/faStoreAlt'),
        "falStoreAltSlash": () => import('@fortawesome/pro-light-svg-icons/faStoreAltSlash'),
        "falStoreSlash": () => import('@fortawesome/pro-light-svg-icons/faStoreSlash'),
        "falStream": () => import('@fortawesome/pro-light-svg-icons/faStream'),
        "falStreetView": () => import('@fortawesome/pro-light-svg-icons/faStreetView'),
        "falStretcher": () => import('@fortawesome/pro-light-svg-icons/faStretcher'),
        "falStrikethrough": () => import('@fortawesome/pro-light-svg-icons/faStrikethrough'),
        "falStroopwafel": () => import('@fortawesome/pro-light-svg-icons/faStroopwafel'),
        "falSubscript": () => import('@fortawesome/pro-light-svg-icons/faSubscript'),
        "falSubway": () => import('@fortawesome/pro-light-svg-icons/faSubway'),
        "falSuitcase": () => import('@fortawesome/pro-light-svg-icons/faSuitcase'),
        "falSuitcaseRolling": () => import('@fortawesome/pro-light-svg-icons/faSuitcaseRolling'),
        "falSun": () => import('@fortawesome/pro-light-svg-icons/faSun'),
        "falSunCloud": () => import('@fortawesome/pro-light-svg-icons/faSunCloud'),
        "falSunDust": () => import('@fortawesome/pro-light-svg-icons/faSunDust'),
        "falSunHaze": () => import('@fortawesome/pro-light-svg-icons/faSunHaze'),
        "falSunglasses": () => import('@fortawesome/pro-light-svg-icons/faSunglasses'),
        "falSunrise": () => import('@fortawesome/pro-light-svg-icons/faSunrise'),
        "falSunset": () => import('@fortawesome/pro-light-svg-icons/faSunset'),
        "falSuperscript": () => import('@fortawesome/pro-light-svg-icons/faSuperscript'),
        "falSurprise": () => import('@fortawesome/pro-light-svg-icons/faSurprise'),
        "falSwatchbook": () => import('@fortawesome/pro-light-svg-icons/faSwatchbook'),
        "falSwimmer": () => import('@fortawesome/pro-light-svg-icons/faSwimmer'),
        "falSwimmingPool": () => import('@fortawesome/pro-light-svg-icons/faSwimmingPool'),
        "falSword": () => import('@fortawesome/pro-light-svg-icons/faSword'),
        "falSwordLaser": () => import('@fortawesome/pro-light-svg-icons/faSwordLaser'),
        "falSwordLaserAlt": () => import('@fortawesome/pro-light-svg-icons/faSwordLaserAlt'),
        "falSwords": () => import('@fortawesome/pro-light-svg-icons/faSwords'),
        "falSwordsLaser": () => import('@fortawesome/pro-light-svg-icons/faSwordsLaser'),
        "falSynagogue": () => import('@fortawesome/pro-light-svg-icons/faSynagogue'),
        "falSync": () => import('@fortawesome/pro-light-svg-icons/faSync'),
        "falSyncAlt": () => import('@fortawesome/pro-light-svg-icons/faSyncAlt'),
        "falSyringe": () => import('@fortawesome/pro-light-svg-icons/faSyringe'),
        "falTable": () => import('@fortawesome/pro-light-svg-icons/faTable'),
        "falTableTennis": () => import('@fortawesome/pro-light-svg-icons/faTableTennis'),
        "falTablet": () => import('@fortawesome/pro-light-svg-icons/faTablet'),
        "falTabletAlt": () => import('@fortawesome/pro-light-svg-icons/faTabletAlt'),
        "falTabletAndroid": () => import('@fortawesome/pro-light-svg-icons/faTabletAndroid'),
        "falTabletAndroidAlt": () => import('@fortawesome/pro-light-svg-icons/faTabletAndroidAlt'),
        "falTabletRugged": () => import('@fortawesome/pro-light-svg-icons/faTabletRugged'),
        "falTablets": () => import('@fortawesome/pro-light-svg-icons/faTablets'),
        "falTachometer": () => import('@fortawesome/pro-light-svg-icons/faTachometer'),
        "falTachometerAlt": () => import('@fortawesome/pro-light-svg-icons/faTachometerAlt'),
        "falTachometerAltAverage": () => import('@fortawesome/pro-light-svg-icons/faTachometerAltAverage'),
        "falTachometerAltFast": () => import('@fortawesome/pro-light-svg-icons/faTachometerAltFast'),
        "falTachometerAltFastest": () => import('@fortawesome/pro-light-svg-icons/faTachometerAltFastest'),
        "falTachometerAltSlow": () => import('@fortawesome/pro-light-svg-icons/faTachometerAltSlow'),
        "falTachometerAltSlowest": () => import('@fortawesome/pro-light-svg-icons/faTachometerAltSlowest'),
        "falTachometerAverage": () => import('@fortawesome/pro-light-svg-icons/faTachometerAverage'),
        "falTachometerFast": () => import('@fortawesome/pro-light-svg-icons/faTachometerFast'),
        "falTachometerFastest": () => import('@fortawesome/pro-light-svg-icons/faTachometerFastest'),
        "falTachometerSlow": () => import('@fortawesome/pro-light-svg-icons/faTachometerSlow'),
        "falTachometerSlowest": () => import('@fortawesome/pro-light-svg-icons/faTachometerSlowest'),
        "falTaco": () => import('@fortawesome/pro-light-svg-icons/faTaco'),
        "falTag": () => import('@fortawesome/pro-light-svg-icons/faTag'),
        "falTags": () => import('@fortawesome/pro-light-svg-icons/faTags'),
        "falTally": () => import('@fortawesome/pro-light-svg-icons/faTally'),
        "falTanakh": () => import('@fortawesome/pro-light-svg-icons/faTanakh'),
        "falTape": () => import('@fortawesome/pro-light-svg-icons/faTape'),
        "falTasks": () => import('@fortawesome/pro-light-svg-icons/faTasks'),
        "falTasksAlt": () => import('@fortawesome/pro-light-svg-icons/faTasksAlt'),
        "falTaxi": () => import('@fortawesome/pro-light-svg-icons/faTaxi'),
        "falTeeth": () => import('@fortawesome/pro-light-svg-icons/faTeeth'),
        "falTeethOpen": () => import('@fortawesome/pro-light-svg-icons/faTeethOpen'),
        "falTelescope": () => import('@fortawesome/pro-light-svg-icons/faTelescope'),
        "falTemperatureDown": () => import('@fortawesome/pro-light-svg-icons/faTemperatureDown'),
        "falTemperatureFrigid": () => import('@fortawesome/pro-light-svg-icons/faTemperatureFrigid'),
        "falTemperatureHigh": () => import('@fortawesome/pro-light-svg-icons/faTemperatureHigh'),
        "falTemperatureHot": () => import('@fortawesome/pro-light-svg-icons/faTemperatureHot'),
        "falTemperatureLow": () => import('@fortawesome/pro-light-svg-icons/faTemperatureLow'),
        "falTemperatureUp": () => import('@fortawesome/pro-light-svg-icons/faTemperatureUp'),
        "falTenge": () => import('@fortawesome/pro-light-svg-icons/faTenge'),
        "falTennisBall": () => import('@fortawesome/pro-light-svg-icons/faTennisBall'),
        "falTerminal": () => import('@fortawesome/pro-light-svg-icons/faTerminal'),
        "falText": () => import('@fortawesome/pro-light-svg-icons/faText'),
        "falTextHeight": () => import('@fortawesome/pro-light-svg-icons/faTextHeight'),
        "falTextSize": () => import('@fortawesome/pro-light-svg-icons/faTextSize'),
        "falTextWidth": () => import('@fortawesome/pro-light-svg-icons/faTextWidth'),
        "falTh": () => import('@fortawesome/pro-light-svg-icons/faTh'),
        "falThLarge": () => import('@fortawesome/pro-light-svg-icons/faThLarge'),
        "falThList": () => import('@fortawesome/pro-light-svg-icons/faThList'),
        "falTheaterMasks": () => import('@fortawesome/pro-light-svg-icons/faTheaterMasks'),
        "falThermometer": () => import('@fortawesome/pro-light-svg-icons/faThermometer'),
        "falThermometerEmpty": () => import('@fortawesome/pro-light-svg-icons/faThermometerEmpty'),
        "falThermometerFull": () => import('@fortawesome/pro-light-svg-icons/faThermometerFull'),
        "falThermometerHalf": () => import('@fortawesome/pro-light-svg-icons/faThermometerHalf'),
        "falThermometerQuarter": () => import('@fortawesome/pro-light-svg-icons/faThermometerQuarter'),
        "falThermometerThreeQuarters": () => import('@fortawesome/pro-light-svg-icons/faThermometerThreeQuarters'),
        "falTheta": () => import('@fortawesome/pro-light-svg-icons/faTheta'),
        "falThumbsDown": () => import('@fortawesome/pro-light-svg-icons/faThumbsDown'),
        "falThumbsUp": () => import('@fortawesome/pro-light-svg-icons/faThumbsUp'),
        "falThumbtack": () => import('@fortawesome/pro-light-svg-icons/faThumbtack'),
        "falThunderstorm": () => import('@fortawesome/pro-light-svg-icons/faThunderstorm'),
        "falThunderstormMoon": () => import('@fortawesome/pro-light-svg-icons/faThunderstormMoon'),
        "falThunderstormSun": () => import('@fortawesome/pro-light-svg-icons/faThunderstormSun'),
        "falTicket": () => import('@fortawesome/pro-light-svg-icons/faTicket'),
        "falTicketAlt": () => import('@fortawesome/pro-light-svg-icons/faTicketAlt'),
        "falTilde": () => import('@fortawesome/pro-light-svg-icons/faTilde'),
        "falTimes": () => import('@fortawesome/pro-light-svg-icons/faTimes'),
        "falTimesCircle": () => import('@fortawesome/pro-light-svg-icons/faTimesCircle'),
        "falTimesHexagon": () => import('@fortawesome/pro-light-svg-icons/faTimesHexagon'),
        "falTimesOctagon": () => import('@fortawesome/pro-light-svg-icons/faTimesOctagon'),
        "falTimesSquare": () => import('@fortawesome/pro-light-svg-icons/faTimesSquare'),
        "falTint": () => import('@fortawesome/pro-light-svg-icons/faTint'),
        "falTintSlash": () => import('@fortawesome/pro-light-svg-icons/faTintSlash'),
        "falTire": () => import('@fortawesome/pro-light-svg-icons/faTire'),
        "falTireFlat": () => import('@fortawesome/pro-light-svg-icons/faTireFlat'),
        "falTirePressureWarning": () => import('@fortawesome/pro-light-svg-icons/faTirePressureWarning'),
        "falTireRugged": () => import('@fortawesome/pro-light-svg-icons/faTireRugged'),
        "falTired": () => import('@fortawesome/pro-light-svg-icons/faTired'),
        "falToggleOff": () => import('@fortawesome/pro-light-svg-icons/faToggleOff'),
        "falToggleOn": () => import('@fortawesome/pro-light-svg-icons/faToggleOn'),
        "falToilet": () => import('@fortawesome/pro-light-svg-icons/faToilet'),
        "falToiletPaper": () => import('@fortawesome/pro-light-svg-icons/faToiletPaper'),
        "falToiletPaperAlt": () => import('@fortawesome/pro-light-svg-icons/faToiletPaperAlt'),
        "falToiletPaperSlash": () => import('@fortawesome/pro-light-svg-icons/faToiletPaperSlash'),
        "falTombstone": () => import('@fortawesome/pro-light-svg-icons/faTombstone'),
        "falTombstoneAlt": () => import('@fortawesome/pro-light-svg-icons/faTombstoneAlt'),
        "falToolbox": () => import('@fortawesome/pro-light-svg-icons/faToolbox'),
        "falTools": () => import('@fortawesome/pro-light-svg-icons/faTools'),
        "falTooth": () => import('@fortawesome/pro-light-svg-icons/faTooth'),
        "falToothbrush": () => import('@fortawesome/pro-light-svg-icons/faToothbrush'),
        "falTorah": () => import('@fortawesome/pro-light-svg-icons/faTorah'),
        "falToriiGate": () => import('@fortawesome/pro-light-svg-icons/faToriiGate'),
        "falTornado": () => import('@fortawesome/pro-light-svg-icons/faTornado'),
        "falTractor": () => import('@fortawesome/pro-light-svg-icons/faTractor'),
        "falTrademark": () => import('@fortawesome/pro-light-svg-icons/faTrademark'),
        "falTrafficCone": () => import('@fortawesome/pro-light-svg-icons/faTrafficCone'),
        "falTrafficLight": () => import('@fortawesome/pro-light-svg-icons/faTrafficLight'),
        "falTrafficLightGo": () => import('@fortawesome/pro-light-svg-icons/faTrafficLightGo'),
        "falTrafficLightSlow": () => import('@fortawesome/pro-light-svg-icons/faTrafficLightSlow'),
        "falTrafficLightStop": () => import('@fortawesome/pro-light-svg-icons/faTrafficLightStop'),
        "falTrailer": () => import('@fortawesome/pro-light-svg-icons/faTrailer'),
        "falTrain": () => import('@fortawesome/pro-light-svg-icons/faTrain'),
        "falTram": () => import('@fortawesome/pro-light-svg-icons/faTram'),
        "falTransgender": () => import('@fortawesome/pro-light-svg-icons/faTransgender'),
        "falTransgenderAlt": () => import('@fortawesome/pro-light-svg-icons/faTransgenderAlt'),
        "falTransporter": () => import('@fortawesome/pro-light-svg-icons/faTransporter'),
        "falTransporter1": () => import('@fortawesome/pro-light-svg-icons/faTransporter1'),
        "falTransporter2": () => import('@fortawesome/pro-light-svg-icons/faTransporter2'),
        "falTransporter3": () => import('@fortawesome/pro-light-svg-icons/faTransporter3'),
        "falTransporterEmpty": () => import('@fortawesome/pro-light-svg-icons/faTransporterEmpty'),
        "falTrash": () => import('@fortawesome/pro-light-svg-icons/faTrash'),
        "falTrashAlt": () => import('@fortawesome/pro-light-svg-icons/faTrashAlt'),
        "falTrashRestore": () => import('@fortawesome/pro-light-svg-icons/faTrashRestore'),
        "falTrashRestoreAlt": () => import('@fortawesome/pro-light-svg-icons/faTrashRestoreAlt'),
        "falTrashUndo": () => import('@fortawesome/pro-light-svg-icons/faTrashUndo'),
        "falTrashUndoAlt": () => import('@fortawesome/pro-light-svg-icons/faTrashUndoAlt'),
        "falTreasureChest": () => import('@fortawesome/pro-light-svg-icons/faTreasureChest'),
        "falTree": () => import('@fortawesome/pro-light-svg-icons/faTree'),
        "falTreeAlt": () => import('@fortawesome/pro-light-svg-icons/faTreeAlt'),
        "falTreeChristmas": () => import('@fortawesome/pro-light-svg-icons/faTreeChristmas'),
        "falTreeDecorated": () => import('@fortawesome/pro-light-svg-icons/faTreeDecorated'),
        "falTreeLarge": () => import('@fortawesome/pro-light-svg-icons/faTreeLarge'),
        "falTreePalm": () => import('@fortawesome/pro-light-svg-icons/faTreePalm'),
        "falTrees": () => import('@fortawesome/pro-light-svg-icons/faTrees'),
        "falTriangle": () => import('@fortawesome/pro-light-svg-icons/faTriangle'),
        "falTriangleMusic": () => import('@fortawesome/pro-light-svg-icons/faTriangleMusic'),
        "falTrophy": () => import('@fortawesome/pro-light-svg-icons/faTrophy'),
        "falTrophyAlt": () => import('@fortawesome/pro-light-svg-icons/faTrophyAlt'),
        "falTruck": () => import('@fortawesome/pro-light-svg-icons/faTruck'),
        "falTruckContainer": () => import('@fortawesome/pro-light-svg-icons/faTruckContainer'),
        "falTruckCouch": () => import('@fortawesome/pro-light-svg-icons/faTruckCouch'),
        "falTruckLoading": () => import('@fortawesome/pro-light-svg-icons/faTruckLoading'),
        "falTruckMonster": () => import('@fortawesome/pro-light-svg-icons/faTruckMonster'),
        "falTruckMoving": () => import('@fortawesome/pro-light-svg-icons/faTruckMoving'),
        "falTruckPickup": () => import('@fortawesome/pro-light-svg-icons/faTruckPickup'),
        "falTruckPlow": () => import('@fortawesome/pro-light-svg-icons/faTruckPlow'),
        "falTruckRamp": () => import('@fortawesome/pro-light-svg-icons/faTruckRamp'),
        "falTrumpet": () => import('@fortawesome/pro-light-svg-icons/faTrumpet'),
        "falTshirt": () => import('@fortawesome/pro-light-svg-icons/faTshirt'),
        "falTty": () => import('@fortawesome/pro-light-svg-icons/faTty'),
        "falTurkey": () => import('@fortawesome/pro-light-svg-icons/faTurkey'),
        "falTurntable": () => import('@fortawesome/pro-light-svg-icons/faTurntable'),
        "falTurtle": () => import('@fortawesome/pro-light-svg-icons/faTurtle'),
        "falTv": () => import('@fortawesome/pro-light-svg-icons/faTv'),
        "falTvAlt": () => import('@fortawesome/pro-light-svg-icons/faTvAlt'),
        "falTvMusic": () => import('@fortawesome/pro-light-svg-icons/faTvMusic'),
        "falTvRetro": () => import('@fortawesome/pro-light-svg-icons/faTvRetro'),
        "falTypewriter": () => import('@fortawesome/pro-light-svg-icons/faTypewriter'),
        "falUfo": () => import('@fortawesome/pro-light-svg-icons/faUfo'),
        "falUfoBeam": () => import('@fortawesome/pro-light-svg-icons/faUfoBeam'),
        "falUmbrella": () => import('@fortawesome/pro-light-svg-icons/faUmbrella'),
        "falUmbrellaBeach": () => import('@fortawesome/pro-light-svg-icons/faUmbrellaBeach'),
        "falUnderline": () => import('@fortawesome/pro-light-svg-icons/faUnderline'),
        "falUndo": () => import('@fortawesome/pro-light-svg-icons/faUndo'),
        "falUndoAlt": () => import('@fortawesome/pro-light-svg-icons/faUndoAlt'),
        "falUnicorn": () => import('@fortawesome/pro-light-svg-icons/faUnicorn'),
        "falUnion": () => import('@fortawesome/pro-light-svg-icons/faUnion'),
        "falUniversalAccess": () => import('@fortawesome/pro-light-svg-icons/faUniversalAccess'),
        "falUniversity": () => import('@fortawesome/pro-light-svg-icons/faUniversity'),
        "falUnlink": () => import('@fortawesome/pro-light-svg-icons/faUnlink'),
        "falUnlock": () => import('@fortawesome/pro-light-svg-icons/faUnlock'),
        "falUnlockAlt": () => import('@fortawesome/pro-light-svg-icons/faUnlockAlt'),
        "falUpload": () => import('@fortawesome/pro-light-svg-icons/faUpload'),
        "falUsbDrive": () => import('@fortawesome/pro-light-svg-icons/faUsbDrive'),
        "falUsdCircle": () => import('@fortawesome/pro-light-svg-icons/faUsdCircle'),
        "falUsdSquare": () => import('@fortawesome/pro-light-svg-icons/faUsdSquare'),
        "falUser": () => import('@fortawesome/pro-light-svg-icons/faUser'),
        "falUserAlien": () => import('@fortawesome/pro-light-svg-icons/faUserAlien'),
        "falUserAlt": () => import('@fortawesome/pro-light-svg-icons/faUserAlt'),
        "falUserAltSlash": () => import('@fortawesome/pro-light-svg-icons/faUserAltSlash'),
        "falUserAstronaut": () => import('@fortawesome/pro-light-svg-icons/faUserAstronaut'),
        "falUserChart": () => import('@fortawesome/pro-light-svg-icons/faUserChart'),
        "falUserCheck": () => import('@fortawesome/pro-light-svg-icons/faUserCheck'),
        "falUserCircle": () => import('@fortawesome/pro-light-svg-icons/faUserCircle'),
        "falUserClock": () => import('@fortawesome/pro-light-svg-icons/faUserClock'),
        "falUserCog": () => import('@fortawesome/pro-light-svg-icons/faUserCog'),
        "falUserCowboy": () => import('@fortawesome/pro-light-svg-icons/faUserCowboy'),
        "falUserCrown": () => import('@fortawesome/pro-light-svg-icons/faUserCrown'),
        "falUserEdit": () => import('@fortawesome/pro-light-svg-icons/faUserEdit'),
        "falUserFriends": () => import('@fortawesome/pro-light-svg-icons/faUserFriends'),
        "falUserGraduate": () => import('@fortawesome/pro-light-svg-icons/faUserGraduate'),
        "falUserHardHat": () => import('@fortawesome/pro-light-svg-icons/faUserHardHat'),
        "falUserHeadset": () => import('@fortawesome/pro-light-svg-icons/faUserHeadset'),
        "falUserInjured": () => import('@fortawesome/pro-light-svg-icons/faUserInjured'),
        "falUserLock": () => import('@fortawesome/pro-light-svg-icons/faUserLock'),
        "falUserMd": () => import('@fortawesome/pro-light-svg-icons/faUserMd'),
        "falUserMdChat": () => import('@fortawesome/pro-light-svg-icons/faUserMdChat'),
        "falUserMinus": () => import('@fortawesome/pro-light-svg-icons/faUserMinus'),
        "falUserMusic": () => import('@fortawesome/pro-light-svg-icons/faUserMusic'),
        "falUserNinja": () => import('@fortawesome/pro-light-svg-icons/faUserNinja'),
        "falUserNurse": () => import('@fortawesome/pro-light-svg-icons/faUserNurse'),
        "falUserPlus": () => import('@fortawesome/pro-light-svg-icons/faUserPlus'),
        "falUserRobot": () => import('@fortawesome/pro-light-svg-icons/faUserRobot'),
        "falUserSecret": () => import('@fortawesome/pro-light-svg-icons/faUserSecret'),
        "falUserShield": () => import('@fortawesome/pro-light-svg-icons/faUserShield'),
        "falUserSlash": () => import('@fortawesome/pro-light-svg-icons/faUserSlash'),
        "falUserTag": () => import('@fortawesome/pro-light-svg-icons/faUserTag'),
        "falUserTie": () => import('@fortawesome/pro-light-svg-icons/faUserTie'),
        "falUserTimes": () => import('@fortawesome/pro-light-svg-icons/faUserTimes'),
        "falUserUnlock": () => import('@fortawesome/pro-light-svg-icons/faUserUnlock'),
        "falUserVisor": () => import('@fortawesome/pro-light-svg-icons/faUserVisor'),
        "falUsers": () => import('@fortawesome/pro-light-svg-icons/faUsers'),
        "falUsersClass": () => import('@fortawesome/pro-light-svg-icons/faUsersClass'),
        "falUsersCog": () => import('@fortawesome/pro-light-svg-icons/faUsersCog'),
        "falUsersCrown": () => import('@fortawesome/pro-light-svg-icons/faUsersCrown'),
        "falUsersMedical": () => import('@fortawesome/pro-light-svg-icons/faUsersMedical'),
        "falUsersSlash": () => import('@fortawesome/pro-light-svg-icons/faUsersSlash'),
        "falUtensilFork": () => import('@fortawesome/pro-light-svg-icons/faUtensilFork'),
        "falUtensilKnife": () => import('@fortawesome/pro-light-svg-icons/faUtensilKnife'),
        "falUtensilSpoon": () => import('@fortawesome/pro-light-svg-icons/faUtensilSpoon'),
        "falUtensils": () => import('@fortawesome/pro-light-svg-icons/faUtensils'),
        "falUtensilsAlt": () => import('@fortawesome/pro-light-svg-icons/faUtensilsAlt'),
        "falVacuum": () => import('@fortawesome/pro-light-svg-icons/faVacuum'),
        "falVacuumRobot": () => import('@fortawesome/pro-light-svg-icons/faVacuumRobot'),
        "falValueAbsolute": () => import('@fortawesome/pro-light-svg-icons/faValueAbsolute'),
        "falVectorSquare": () => import('@fortawesome/pro-light-svg-icons/faVectorSquare'),
        "falVenus": () => import('@fortawesome/pro-light-svg-icons/faVenus'),
        "falVenusDouble": () => import('@fortawesome/pro-light-svg-icons/faVenusDouble'),
        "falVenusMars": () => import('@fortawesome/pro-light-svg-icons/faVenusMars'),
        "falVest": () => import('@fortawesome/pro-light-svg-icons/faVest'),
        "falVestPatches": () => import('@fortawesome/pro-light-svg-icons/faVestPatches'),
        "falVhs": () => import('@fortawesome/pro-light-svg-icons/faVhs'),
        "falVial": () => import('@fortawesome/pro-light-svg-icons/faVial'),
        "falVials": () => import('@fortawesome/pro-light-svg-icons/faVials'),
        "falVideo": () => import('@fortawesome/pro-light-svg-icons/faVideo'),
        "falVideoPlus": () => import('@fortawesome/pro-light-svg-icons/faVideoPlus'),
        "falVideoSlash": () => import('@fortawesome/pro-light-svg-icons/faVideoSlash'),
        "falVihara": () => import('@fortawesome/pro-light-svg-icons/faVihara'),
        "falViolin": () => import('@fortawesome/pro-light-svg-icons/faViolin'),
        "falVirus": () => import('@fortawesome/pro-light-svg-icons/faVirus'),
        "falVirusSlash": () => import('@fortawesome/pro-light-svg-icons/faVirusSlash'),
        "falViruses": () => import('@fortawesome/pro-light-svg-icons/faViruses'),
        "falVoicemail": () => import('@fortawesome/pro-light-svg-icons/faVoicemail'),
        "falVolcano": () => import('@fortawesome/pro-light-svg-icons/faVolcano'),
        "falVolleyballBall": () => import('@fortawesome/pro-light-svg-icons/faVolleyballBall'),
        "falVolume": () => import('@fortawesome/pro-light-svg-icons/faVolume'),
        "falVolumeDown": () => import('@fortawesome/pro-light-svg-icons/faVolumeDown'),
        "falVolumeMute": () => import('@fortawesome/pro-light-svg-icons/faVolumeMute'),
        "falVolumeOff": () => import('@fortawesome/pro-light-svg-icons/faVolumeOff'),
        "falVolumeSlash": () => import('@fortawesome/pro-light-svg-icons/faVolumeSlash'),
        "falVolumeUp": () => import('@fortawesome/pro-light-svg-icons/faVolumeUp'),
        "falVoteNay": () => import('@fortawesome/pro-light-svg-icons/faVoteNay'),
        "falVoteYea": () => import('@fortawesome/pro-light-svg-icons/faVoteYea'),
        "falVrCardboard": () => import('@fortawesome/pro-light-svg-icons/faVrCardboard'),
        "falWagonCovered": () => import('@fortawesome/pro-light-svg-icons/faWagonCovered'),
        "falWalker": () => import('@fortawesome/pro-light-svg-icons/faWalker'),
        "falWalkieTalkie": () => import('@fortawesome/pro-light-svg-icons/faWalkieTalkie'),
        "falWalking": () => import('@fortawesome/pro-light-svg-icons/faWalking'),
        "falWallet": () => import('@fortawesome/pro-light-svg-icons/faWallet'),
        "falWand": () => import('@fortawesome/pro-light-svg-icons/faWand'),
        "falWandMagic": () => import('@fortawesome/pro-light-svg-icons/faWandMagic'),
        "falWarehouse": () => import('@fortawesome/pro-light-svg-icons/faWarehouse'),
        "falWarehouseAlt": () => import('@fortawesome/pro-light-svg-icons/faWarehouseAlt'),
        "falWasher": () => import('@fortawesome/pro-light-svg-icons/faWasher'),
        "falWatch": () => import('@fortawesome/pro-light-svg-icons/faWatch'),
        "falWatchCalculator": () => import('@fortawesome/pro-light-svg-icons/faWatchCalculator'),
        "falWatchFitness": () => import('@fortawesome/pro-light-svg-icons/faWatchFitness'),
        "falWater": () => import('@fortawesome/pro-light-svg-icons/faWater'),
        "falWaterLower": () => import('@fortawesome/pro-light-svg-icons/faWaterLower'),
        "falWaterRise": () => import('@fortawesome/pro-light-svg-icons/faWaterRise'),
        "falWaveSine": () => import('@fortawesome/pro-light-svg-icons/faWaveSine'),
        "falWaveSquare": () => import('@fortawesome/pro-light-svg-icons/faWaveSquare'),
        "falWaveTriangle": () => import('@fortawesome/pro-light-svg-icons/faWaveTriangle'),
        "falWaveform": () => import('@fortawesome/pro-light-svg-icons/faWaveform'),
        "falWaveformPath": () => import('@fortawesome/pro-light-svg-icons/faWaveformPath'),
        "falWebcam": () => import('@fortawesome/pro-light-svg-icons/faWebcam'),
        "falWebcamSlash": () => import('@fortawesome/pro-light-svg-icons/faWebcamSlash'),
        "falWeight": () => import('@fortawesome/pro-light-svg-icons/faWeight'),
        "falWeightHanging": () => import('@fortawesome/pro-light-svg-icons/faWeightHanging'),
        "falWhale": () => import('@fortawesome/pro-light-svg-icons/faWhale'),
        "falWheat": () => import('@fortawesome/pro-light-svg-icons/faWheat'),
        "falWheelchair": () => import('@fortawesome/pro-light-svg-icons/faWheelchair'),
        "falWhistle": () => import('@fortawesome/pro-light-svg-icons/faWhistle'),
        "falWifi": () => import('@fortawesome/pro-light-svg-icons/faWifi'),
        "falWifi1": () => import('@fortawesome/pro-light-svg-icons/faWifi1'),
        "falWifi2": () => import('@fortawesome/pro-light-svg-icons/faWifi2'),
        "falWifiSlash": () => import('@fortawesome/pro-light-svg-icons/faWifiSlash'),
        "falWind": () => import('@fortawesome/pro-light-svg-icons/faWind'),
        "falWindTurbine": () => import('@fortawesome/pro-light-svg-icons/faWindTurbine'),
        "falWindWarning": () => import('@fortawesome/pro-light-svg-icons/faWindWarning'),
        "falWindow": () => import('@fortawesome/pro-light-svg-icons/faWindow'),
        "falWindowAlt": () => import('@fortawesome/pro-light-svg-icons/faWindowAlt'),
        "falWindowClose": () => import('@fortawesome/pro-light-svg-icons/faWindowClose'),
        "falWindowFrame": () => import('@fortawesome/pro-light-svg-icons/faWindowFrame'),
        "falWindowFrameOpen": () => import('@fortawesome/pro-light-svg-icons/faWindowFrameOpen'),
        "falWindowMaximize": () => import('@fortawesome/pro-light-svg-icons/faWindowMaximize'),
        "falWindowMinimize": () => import('@fortawesome/pro-light-svg-icons/faWindowMinimize'),
        "falWindowRestore": () => import('@fortawesome/pro-light-svg-icons/faWindowRestore'),
        "falWindsock": () => import('@fortawesome/pro-light-svg-icons/faWindsock'),
        "falWineBottle": () => import('@fortawesome/pro-light-svg-icons/faWineBottle'),
        "falWineGlass": () => import('@fortawesome/pro-light-svg-icons/faWineGlass'),
        "falWineGlassAlt": () => import('@fortawesome/pro-light-svg-icons/faWineGlassAlt'),
        "falWonSign": () => import('@fortawesome/pro-light-svg-icons/faWonSign'),
        "falWreath": () => import('@fortawesome/pro-light-svg-icons/faWreath'),
        "falWrench": () => import('@fortawesome/pro-light-svg-icons/faWrench'),
        "falXRay": () => import('@fortawesome/pro-light-svg-icons/faXRay'),
        "falYenSign": () => import('@fortawesome/pro-light-svg-icons/faYenSign'),
        "falYinYang": () => import('@fortawesome/pro-light-svg-icons/faYinYang')
    }
};
export default packInfo;
